import React, { useState } from 'react';
import {
  ADD_PROFESSIONAL_EXPERIENCE_TITLE,
  CANCEL_BTN,
  EDIT_PROFESSIONAL_EXPERIENCE_TITLE,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { DrawerElem } from '@shared-components/elements';
import { useForm } from 'antd/lib/form/Form';
import { Button, Space } from 'antd';
import { ProfessionalExperienceForm } from 'libs/shared/src/crm-modules/forms/contact-details/qualifications';

const ProfessionalExperienceDrawer = ({
  showDrawer,
  setShowDrawer,
  initialData,
  editMode,
  setEditMode,
}: {
  showDrawer: boolean;
  setShowDrawer: any;
  initialData?: any;
  editMode: boolean;
  setEditMode: any;
}) => {
  const [form] = useForm();
  const [disabled, setDisabled] = useState(true);

  const handleClose = () => {
    setDisabled(true);
    setShowDrawer(false);
    setEditMode(false);
    form.resetFields();
  };
  const handleSubmitBtnClick = () => {
    form.submit();
  };

  return (
    <DrawerElem
      title={
        editMode
          ? EDIT_PROFESSIONAL_EXPERIENCE_TITLE
          : ADD_PROFESSIONAL_EXPERIENCE_TITLE
      }
      width={720}
      onClose={handleClose}
      visible={showDrawer}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={handleClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              onClick={handleSubmitBtnClick}
              type="primary"
              disabled={disabled}
            >
              {editMode ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
      data-testid="crm-professionalexperience"
    >
      <ProfessionalExperienceForm
        form={form}
        setDisabled={setDisabled}
        onClose={handleClose}
        initialData={initialData}
      />
    </DrawerElem>
  );
};

export { ProfessionalExperienceDrawer };
