import React, { useContext, useEffect, useState } from 'react';
import { CANCEL_BTN, DRAWER_WIDTH, SAVE_BTN, TEXT } from '@moxie/constants';
import { errorHandler } from '@moxie/utils';
import { DrawerElem } from '@shared-components/elements';
import { Button, Space } from 'antd';
import {
  errorNotificationHandler, successNotificationHandler,
} from 'libs/shared/src/functions';
import { useQueryClient } from '@tanstack/react-query';
import { useAppSelector } from '@crm/core';
import ApplicationDetailContext from 'apps/crm/src/pages/application-details/application-detail-context';
import { SelectedStage } from '@model/application';
import { Checklist } from '@model/workflow';
import { addMedia } from '@crm/services.api';
import { Document, MediaPayload } from '@model/document';
import { ContactDocumentDragger } from '@crm/src/shared/contact-document-dragger';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedStage: SelectedStage;
  selectedChecklist: Checklist | undefined;
  applicationId: string;

}
export const ContactDocumentDrawer: React.FC<Props> = ({
  isOpen,
  onClose,
  selectedStage,
  selectedChecklist,
  applicationId,
}) => {
  const queryClient = useQueryClient();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [disabled, setIsDisabled] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const user = useAppSelector(store => store.auth.user);
  const socket = useAppSelector(store => store.socket.wss);
  const contact = useAppSelector(store => store.contact.singleData);
  const { applicationData: application } = useContext(ApplicationDetailContext);

  const handleClose = () => {
    setIsDisabled(true);
    setDocuments([]);
    onClose();
  };

  const handleSubmit = async () => {
    if (documents.length > 20) {
      return errorNotificationHandler(TEXT.MAX_TASK_DOCUMENTS_COUNT);
    }
    setIsLoading(true);
    setIsDisabled(true);
    try {
      if (selectedChecklist && selectedStage.wfAppStageId !== null) {
        const path = `contacts/contact__${contact.internalId}/applications/application__${application.appIdentifier}/stage__application/${selectedChecklist.name.toLowerCase().replace('', '-')}`
        const promises = documents.map((file) => {
          const payload: MediaPayload = {
            documentType: selectedChecklist.name,
            documentTypeCategory: selectedChecklist.type,
            file,
            path,
            subjectId: application.id,
            subjectType: 'application',
            name: file.fileName ?? file.name,
            stageId: selectedStage.wfAppStageId as string,
          }
          return addMedia(payload)
        });

        await Promise.all(promises);
        successNotificationHandler('Documents uploaded successfully.');
        await queryClient.invalidateQueries({ queryKey: ['stage-documents', selectedChecklist.id] });
        await queryClient.invalidateQueries({ queryKey: ['application-checklist-docs', application.id, selectedChecklist.name] });
        await queryClient.invalidateQueries({ queryKey: ['docs-count', application.id] });
        handleClose();
        socket?.emit('server::application-updated', {
          activitiesTypeId: applicationId,
          userId: user?.id as string,
          contactId: application.contactId,
          data: {
            data: {
              ...application,
              id: applicationId,
              currentStage: {
                stage: selectedStage.stage
              },
              documentType: selectedChecklist.name
            }
          },
          companyId: user?.companyId as string,
          activitiesAction: 'created',
          activitiesType: 'application-document'
        })
      }
    } catch (error) {
      errorHandler(error);
    }
    finally {
      setIsDisabled(false);
      setIsLoading(false);

    }
  };

  useEffect(() => {
    if (documents.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [documents]);

  return (
    <DrawerElem
      title={TEXT.UPLOAD_FROM_DEVICE}
      width={DRAWER_WIDTH}
      onClose={handleClose}
      visible={isOpen}
      closable={!loading}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button
              onClick={handleClose}
              className="lead-margin-right"
              disabled={loading}
            >
              {CANCEL_BTN}
            </Button>
            <Button type="primary" onClick={handleSubmit} disabled={disabled}>
              {SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <ContactDocumentDragger
        setLoading={setIsLoading}
        loading={loading}
        onSetDocument={setDocuments}
        documents={documents}
      />
    </DrawerElem>
  );
};
