import { useQuery } from '@tanstack/react-query';
import { getAllPartnerAgentByType } from '@crm/libs/services.api/lib/agent-partner.api';

export const useFetchSuperAgents = () => {
  const type = 'superAgent';
  const { data, isLoading: isSuperAgentsLoading } = useQuery({
    queryKey: ['partner-agents', type],
    queryFn: async () => {
      const { data } = await getAllPartnerAgentByType(type);
      return data?.data;
    },
  });
  return { superAgents: data ?? [], isSuperAgentsLoading };
};

export const useFetchSubAgents = () => {
  const type = 'subAgent';
  const { data, isLoading: isSubAgentsLoading } = useQuery({
    queryKey: ['partner-agents', 'application-details', type],
    queryFn: async () => {
      const { data } = await getAllPartnerAgentByType(type);
      return data?.data;
    },
  });
  return { subAgents: data ?? [], isSubAgentsLoading };
};
