import { NotesPayload } from "@model/crm/elastic-search";
import { TableProps } from "antd/es/table";

const notesColumns: TableProps<NotesPayload>['columns'] = [
  {
    dataIndex: 'note_description',
    title: 'Description',
    render(value, record, index) {
      return <div dangerouslySetInnerHTML={{ __html: value }} />
    },
  },
  {
    dataIndex: 'type',
    title: 'Type',
  },
  {
    dataIndex: 'related_entity',
    title: 'Record'
  }
]

export default notesColumns
