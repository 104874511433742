import { EditOutlined, MinusCircleTwoTone } from '@ant-design/icons';
import {
    DocumentFileIcons,
} from '@moxie/shared';
import {
    IDocNameForm,
} from '@shared-components/models';
import { Col, List, Row, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { DocNameEdit } from './DocNameEdit';
import { Media } from '@model/document';

interface Props {
    item: Media;
    documents: Media[];
    onSetDocument: (media: Media[]) => void;
    onRemove: (media: Media) => void;
}
const DocumentFileName: React.FC<Props> = ({
    item,
    documents,
    onSetDocument,
    onRemove,
}) => {
    const [isEdit, setIsEdit] = useState(false);
    const [form] = useForm();

    const handleSubmit = async (data: IDocNameForm) => {
        const updatedDocs = documents?.map((d: any) => {
            if (d.id === item.id) {
                return { ...d, name: data?.fileName }
            } else {
                return d;
            }
        })
        setIsEdit(false);
        onSetDocument(updatedDocs);
    };

    const handleCancel = () => {
        setIsEdit(false);
    };

    const handleEdit = () => {
        setIsEdit(true);
    };
    return (
        <List.Item key={item?.id}>
            <List.Item.Meta
                avatar={<DocumentFileIcons mimetype={item?.mimetype} />}
                title={
                    <Space>
                        {isEdit ? (
                            <DocNameEdit
                                handleSubmit={handleSubmit}
                                handleCancel={handleCancel}
                                form={form}
                                documentData={item}
                            />
                        ) : (
                            <Row>
                                <Col>
                                    <div className="margin-top-1 document-file-name ">
                                        {item?.name || ''}
                                    </div>
                                </Col>
                                <Col>
                                    <EditOutlined
                                        className="document-edit-btn margin-top-1 margin-left-1"
                                        onClick={handleEdit}
                                        title="Edit"
                                    />
                                </Col>
                            </Row>
                        )}
                    </Space>
                }
            />
            <Space>
                {!isEdit ? (
                    <>
                        <MinusCircleTwoTone
                            twoToneColor="red"
                            className="action__button__icon margin-left-1"
                            onClick={() => {
                                onRemove(item);
                            }}
                            title="Remove"
                        />
                    </>
                ) : null}
            </Space>
        </List.Item>
    );
};

export { DocumentFileName };
