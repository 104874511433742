import { DELETE_BTN, EDIT_BTN, GUTTER, LABEL } from "@moxie/constants";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Dropdown from "antd/es/dropdown";
import { Col, Row } from "antd/es/grid";
import Menu from "antd/es/menu";
import Progress from "antd/es/progress";
import Space from "antd/es/space";
import Typography from "antd/es/typography";
import { MoreOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';


interface IProps {
  label: string;
  data: any;
  handleEdit: VoidFunction;
  onDelete: VoidFunction;
  testScoreProgressPercent: number;
  progressFormat: ((percent?: number, successPercent?: number) => React.ReactNode);
  cardClassName?: string;
}

const { Text } = Typography;

const TestScoreCard = ({
  label,
  data,
  onDelete,
  handleEdit,
  testScoreProgressPercent,
  progressFormat,
  cardClassName
}: IProps) => {
  return (
    <Card className={`${cardClassName ?? 'test-score-card margin-top-1'}`}>
      <Row gutter={GUTTER}>
        <Col span={7}>
          <Row className="margin-top-2" justify="center">
            <Text className="test-score-title">{label}</Text>
          </Row>

          <Row className="margin-top-2" justify="center">
            <Progress
              type={'circle'}
              strokeWidth={10}
              strokeColor={'#4a2362'}
              percent={testScoreProgressPercent}
              format={progressFormat}
              width={40}
            />
          </Row>
        </Col>
        <Col span={14} xl={13} xxl={14}>
          <Row justify="space-around" className="margin-top-2">
            <Col>
              <Row justify="center">
                <Text className="test-score-label-text">
                  {LABEL.WRITING}
                </Text>
              </Row>
              <Row justify="center">
                <Text> {data?.writing?.score} </Text>
              </Row>
            </Col>
            <Col>
              <Row justify="center">
                <Text className="test-score-label-text">
                  {LABEL.SPEAKING}
                </Text>
              </Row>
              <Row justify="center">
                <Text>{data?.speaking?.score} </Text>
              </Row>
            </Col>
          </Row>
          <Row justify="space-around" className="margin-top-2">
            <Col>
              <Row justify="center">
                <Text className="test-score-label-text">
                  {LABEL.READING}
                </Text>
              </Row>
              <Row justify="center">
                <Text>{data?.reading?.score} </Text>
              </Row>
            </Col>
            <Col>
              <Row justify="center">
                <Text className="test-score-label-text">
                  {LABEL.LISTENING}
                </Text>
              </Row>
              <Row justify="center">
                <Text>{data?.listening?.score} </Text>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={1} className="margin-top-2 margin-left-1">
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key={1} onClick={handleEdit}>
                  <Space direction="horizontal">
                    <EditOutlined />
                    {EDIT_BTN}
                  </Space>
                </Menu.Item>
                <Menu.Item key={2} danger onClick={onDelete}>
                  <Space direction="horizontal">
                    <DeleteOutlined />
                    {DELETE_BTN}
                  </Space>
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="text"
              className="table-cell-action-btn"
              icon={<MoreOutlined className="font-size-18px" />}
            />
          </Dropdown>
        </Col>
      </Row>
    </Card>
  )
}
export default TestScoreCard;
