import React from 'react';
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import { URL_DASHBOARD } from '@moxie/constants';

const ForbiddenAccess: React.FC = () => (
  <Result
    status="403"
    title="403"
    subTitle="Sorry, you are not authorized to access this page."
    extra={<Link to={URL_DASHBOARD}><Button type="primary">Back Home</Button></Link>}
  />
);

export default ForbiddenAccess;
