import React, { useState } from 'react';
import { ProfessionalExperienceDrawer } from './drawer/professional-experience-drawer';
import { ProfessionalExperienceContent } from './professional-experience-content';

const ProfessionalExperiencePanelContent = ({
  showDrawer,
  setShowDrawer,
}: {
  showDrawer: boolean;
  setShowDrawer: any;
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <>
      <div className="prof-exp-panel-content">
        <ProfessionalExperienceContent />
      </div>
      <ProfessionalExperienceDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        editMode={editMode}
        setEditMode={setEditMode}
      />
    </>
  );
};
export { ProfessionalExperiencePanelContent };
