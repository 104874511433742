import React from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { capitalizeAllLetter } from '@moxie/shared';
const { Title } = Typography;

const HeaderMenu = ({ applicationIdentifier }: any) => {
  const history = useHistory();
  const handleBack = () => history.goBack();

  return (
    <Row
      align="middle"
      justify="space-between"
      style={{
        borderBottom: '1px solid #E3E2E2',
        padding: '20px',
      }}
    >
      <Col>
        <Title className="margin-bottom-0" type="secondary" level={4}>
          Application ID:{' '}
          <span className="text-primary">
            {capitalizeAllLetter(applicationIdentifier)}
          </span>
        </Title>
      </Col>
      <Col>
        <Button
          onClick={() => {
            handleBack();
          }}
          icon={<LeftOutlined />}
        >
          Back to Application List
        </Button>
      </Col>
    </Row>
  );
};

export default HeaderMenu;
