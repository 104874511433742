import React, { useEffect, useState } from 'react';
import { DrawerElem } from '@shared-components/elements';
import {
  ADD_EDUCATION_TITLE,
  CANCEL_BTN,
  EDIT_EDUCATION_TITLE,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { Button, Space, Spin } from 'antd';
import { EducationBackgroundForm } from 'libs/shared/src/crm-modules/forms/contact-details/qualifications';
import { useForm } from 'antd/lib/form/Form';
import { subjectDisciplineActions, useAppSelector } from '@crm/core';
import { RootState } from '@crm/src/core/store';
import { useDispatch } from 'react-redux';
import {useFetchDegreeLevelsQuery  } from '@crm/src/pages/contact-details/hooks/useFetchDegreeLevelsQuery';

const EducationBackgroundDrawer = ({
  showDrawer,
  setShowDrawer,
  initialData,
  editMode,
  setEditMode,
}: {
  showDrawer: boolean;
  setShowDrawer: any;
  initialData?: any;
  editMode?: boolean;
  setEditMode?: any;
}) => {
  const { loading , allData } = useAppSelector(
    (state: RootState) => state?.subject_discipline
  );
  const dispatch = useDispatch();
  const [form] = useForm();
  const [disabled, setDisabled] = useState(true);
  const { degreeLevels } = useFetchDegreeLevelsQuery();

  useEffect(() => {
    if (!allData.length) {
      dispatch(subjectDisciplineActions.getSubjectDisciplineRequest());
    }
  }, [dispatch, allData.length]);

  const handleClose = () => {
    setDisabled(true);
    setShowDrawer(false);
    setEditMode(false);
    form.resetFields();
  };
  const handleSubmitBtnClick = () => {
    form.submit();
  };

  return (
    <DrawerElem
      title={editMode ? EDIT_EDUCATION_TITLE : ADD_EDUCATION_TITLE}
      width={720}
      onClose={handleClose}
      visible={showDrawer}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={handleClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              onClick={handleSubmitBtnClick}
              type="primary"
              disabled={disabled}
            >
              {editMode ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
      data-testid="crm-editeducationbackground"
    >
      {loading ?
        <Spin />
        : (
          <EducationBackgroundForm
            form={form}
            setDisabled={setDisabled}
            isOpen={showDrawer}
            handleClose={handleClose}
            initialData={initialData}
            degreeLevels={degreeLevels ?? []}
          />
        )}
    </DrawerElem>
  );
};
export { EducationBackgroundDrawer };
