import { getApplicationWorkflowStages } from "@crm/libs/services.api/lib/workflow.api";
import { useQuery } from "@tanstack/react-query";

export const useFetchApplicationStagesByWorkflowId = (workflowId: string) => {
  const { data } = useQuery({
    queryKey: ['applciation-stages', workflowId],
    enabled: !!workflowId,
    queryFn: () => getApplicationWorkflowStages(workflowId),
  });
  return {
    stages: data?.data?.data ?? [],
  };
}
