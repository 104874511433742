import React from 'react';
import { Col, Input, InputNumber, Row, Skeleton, Typography } from 'antd';
import { IForm, ITestTypeForForm } from '@shared-components/models';
import Form from 'antd/lib/form';
import { GUTTER, LABEL } from '@moxie/constants';
import { useParams } from 'react-router-dom';
import { useGetAllCRMProductLanguageRequirements } from '../../../hooks/useGetProductLanguageRequirement';
import { ITestTypeForFormCrm } from '../../../product.model';
const { Text } = Typography;

type RequirementTestName = 'IELTS' | 'TOEFL' | 'PTE';
const LanguageRequirementForm: React.FC<IForm<ITestTypeForForm>> = ({
  form,
  name,
  onSubmit,
  onFieldsChange,
}: IForm<ITestTypeForForm>) => {
  const { productId } = useParams<{ productId: string }>();
  const { isLoading } = useGetAllCRMProductLanguageRequirements(productId);

  const handleSubmit = async (data: ITestTypeForFormCrm) => {
    data.testName = name;
    data.productId = productId;
    onSubmit && onSubmit(data);
  };

  const maxTestScore = {
    IELTS: 9,
    PTE: 90,
    TOEFL: 30,
  };

  return isLoading ? (
    <Skeleton active />
  ) : (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      onFieldsChange={onFieldsChange}
    >
      <Col span={3} className="align-vertically-centered">
        <Text strong>{name}</Text>
      </Col>
      <Row gutter={GUTTER} className="margin-top-2">
        <Col span={12}>
          <Form.Item
            name={['eachBands', 'reading']}
            label={LABEL.READING}
            rules={[
              {
                type: 'number',
                min: 1,
                max: maxTestScore[name as RequirementTestName],
              },
            ]}
          >
            <InputNumber className="full-width" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['eachBands', 'listening']}
            label={LABEL.LISTENING}
            rules={[
              {
                type: 'number',
                min: 1,
                max: maxTestScore[name as RequirementTestName],
              },
            ]}
          >
            <InputNumber className="full-width" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={GUTTER}>
        <Col span={12}>
          <Form.Item
            name={['eachBands', 'writing']}
            label={LABEL.WRITING}
            rules={[
              {
                type: 'number',
                min: 1,
                max: maxTestScore[name as RequirementTestName],
              },
            ]}
          >
            <InputNumber className="full-width" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name={['eachBands', 'speaking']}
            label={LABEL.SPEAKING}
            rules={[
              {
                type: 'number',
                min: 1,
                max: maxTestScore[name as RequirementTestName],
              },
            ]}
          >
            <InputNumber className="full-width" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="overallScore"
            label={LABEL.OVERALL_SCORE}
            rules={[
              {
                type: 'number',
                min: 1,
                max: maxTestScore[name as RequirementTestName],
              },
              {
                required: true,
              },
            ]}
          >
            <InputNumber className="full-width" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="remark" label={LABEL.REMARK}>
            <Input.TextArea rows={4} className="full-width" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export { LanguageRequirementForm };
