import React from 'react';
import { LinkOutlined } from '@ant-design/icons';
import {
  DURATION,
  TEXT,
  URL_INSTITUTION_DETAIL_LINK,
  regex
} from '@moxie/constants';
import { Avatar, Card, Col, Row, Skeleton, Spin, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetProductById } from '../../hooks/useGetProductById';
import { InstitutionBranchTagGroup } from './tag-group/institution-branches-tag';
import { IntakesTagGroup } from './tag-group/intakes-tag';
import { WorkflowTagGroup } from './tag-group/workflow-tag';
import { capitalizeAllLetter } from '@moxie/shared';
import { IProductFeeCrm, ProductFeeItemCrm } from '../../product.model';

const ProductOverview = () => {
  const { productId }: { productId: string } = useParams();
  const { data, isLoading } = useGetProductById(productId);
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };
  const screenWidth = window.innerWidth;
  if (data) {
    const {
      name,
      institution: { name: institution },
      identificationSystemCode,
      productDurationMonth: month,
      productDurationYear: year,
      productDurationWeek: week,
      revenueType,
      programLink,
    } = data;
    const defaultFee: IProductFeeCrm | undefined = data?.fees?.find((fee: IProductFeeCrm) => fee.feeName.toLowerCase() === 'default fee')
    const fees: number = defaultFee?.feeItems?.reduce((acc: number, productFee: ProductFeeItemCrm) => acc += productFee.installmentAmount * productFee.installments, 0) ?? 0;

    return (
      <Spin spinning={isLoading}>
        <section className="profile">
          <Card>
            <Row gutter={24} justify="start">
              <Col span={8} className="custom_divider">
                <>
                  <Col span={24}>
                    <div className="profile__info__item profile__info__user">
                      <Avatar
                        size="default"
                        className="initial_capital margin-right-2"
                      >
                        {name?.substring(0, 1)}
                      </Avatar>
                      <div className="initial_capital">
                        {screenWidth >= 1200 && screenWidth < 1600 ? (
                          String(name).length >= 25 ? (
                            <Tooltip title={name}>
                              <Text
                                className="name-fixed-width font-bold initial_capital product-ellipsis"
                                ellipsis
                              >
                                {name}
                              </Text>
                            </Tooltip>
                          ) : (
                            <Text className="font-bold initial_capital">
                              {name}
                            </Text>
                          )
                        ) : screenWidth >= 1600 && String(name).length >= 30 ? (
                          <Tooltip title={name}>
                            <Text
                              className="font-bold initial_capital product-ellipsis"
                              ellipsis
                            >
                              {name}
                            </Text>
                          </Tooltip>
                        ) : (
                          <Text className="font-bold initial_capital">
                            {name}
                          </Text>
                        )}
                        <div className="display-flex products-text">
                          <Link
                            to={`${URL_INSTITUTION_DETAIL_LINK}${data?.institution?.id}/branches`}
                          >
                            {screenWidth >= 1200 && screenWidth < 1600 ? (
                              String(institution).length >= 25 ? (
                                <Tooltip title={institution}>
                                  <Text
                                    className="initial_capital institution-ellipsis"
                                    ellipsis
                                  >
                                    {institution}
                                  </Text>
                                </Tooltip>
                              ) : (
                                <Text className="initial_capital">
                                  {institution}
                                </Text>
                              )
                            ) : screenWidth >= 1600 && String(institution).length >= 30 ? (
                              <Tooltip title={institution}>
                                <Text
                                  className="initial_capital institution-ellipsis"
                                  ellipsis
                                >
                                  {institution}
                                </Text>
                              </Tooltip>
                            ) : (
                              <Text className="initial_capital">
                                {institution}
                              </Text>
                            )}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Col
                        span={24}
                        className="product-discipline margin-bottom-1 margin-top-1"
                      >
                        <Row className="margin-left-1">
                          <Col md={5} xxl={4}>
                            <h3 className="profile__title">{TEXT.BRANCHES}:</h3>
                          </Col>
                          <Col md={17} xxl={20}>
                            {data?.branches ? (
                              <InstitutionBranchTagGroup
                                data={data?.branches?.map(
                                  (item: any) => item?.name
                                )}
                              />
                            ) : (
                              '-'
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <h3 className="profile__title">
                      {TEXT.COURSE_CODE}: &nbsp;{' '}
                      <span className="text-link-color">
                        {identificationSystemCode
                          ? identificationSystemCode?.toUpperCase()
                          : '-'}
                      </span>
                    </h3>
                  </Col>
                </>
              </Col>
              <Col span={8} className="custom_divider">
                {isLoading ? (
                  <Skeleton paragraph={{ rows: 2 }} title={false} active />
                ) : (
                  <>
                    <Row>
                      <Col>
                        <h3 className="profile__title">{TEXT.FEES}:</h3>
                      </Col>
                      <Col className="padding-left-1">
                        <h3 className="profile__title">
                          {fees ? (
                            <div>
                              {fees
                                ?.toString()
                                .replace(new RegExp(regex.FEES), ',')}
                              &nbsp;
                              {capitalizeAllLetter(data?.institution?.currency)}
                            </div>
                          ) : (
                            '-'
                          )}{' '}
                        </h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h3 className="profile__title">{TEXT.DURATION}:</h3>
                      </Col>
                      <Col className="padding-left-1">
                        {year || month || week ? (
                          <h3 className="profile__title">
                            {year
                              ? `${year} ${Number(year) > 1 ? DURATION.YEARS : DURATION.YEAR
                              }`
                              : ''}
                            {month
                              ? `${month} ${Number(month) > 1 ? DURATION.MONTHS : DURATION.MONTH
                              }`
                              : ''}
                            {week
                              ? `${week} ${Number(week) > 1 ? DURATION.WEEKS : DURATION.WEEK
                              }`
                              : ''}
                          </h3>
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h3 className="profile__title">{TEXT.INTAKES}:</h3>
                      </Col>
                      <Col className="padding-left-1">
                        {data?.intakes ? (
                          <IntakesTagGroup
                            data={data?.intakes.map(
                              (item: any) => item
                            )}
                          />
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
              <Col span={8}>
                {isLoading ? (
                  <Skeleton paragraph={{ rows: 1 }} active />
                ) : (
                  <>
                    <Row>
                      <Col md={6} xxl={4}>
                        <h3 className="profile__title">{TEXT.WORKFLOW}:</h3>
                      </Col>
                      <Col md={18} xxl={20} className="padding-left-1">
                        {data?.institutionWorkflows ? (
                          <WorkflowTagGroup
                            data={data?.institutionWorkflows?.map(
                              (item: any) => item.workflow?.workflowType?.name
                            )}
                          />
                        ) : (
                          '-'
                        )}
                      </Col>
                    </Row>
                    {revenueType && (
                      <Row>
                        <Col>
                          <h3 className="profile__title">
                            {TEXT.REVENUE_TYPE}:
                          </h3>
                        </Col>
                        <Col className="padding-left-1">
                          <h3 className="profile__title">
                            {revenueType ? revenueType : '-'}
                          </h3>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col>
                        <h3 className="profile__title">
                          {TEXT.PROGRAM_LINK}: &nbsp;
                        </h3>
                      </Col>
                      {programLink ? (
                        <>
                          <Col>
                            <a
                              className="text-primary-color "
                              target="_blank"
                              rel="noopener noreferrer"
                              href={
                                programLink.startsWith('http://') ||
                                  programLink.startsWith('https://')
                                  ? programLink
                                  : `http://${programLink}`
                              }
                            >
                              {TEXT.WEBSITE}
                            </a>
                          </Col>
                          <Col className="padding-left-1">
                            <Tooltip
                              placement="right"
                              color={isCopied ? '#8EAF74' : '#9B9D9A '}
                              title={
                                isCopied
                                  ? `${TEXT.COPIED_SUCCESS}`
                                  : `${TEXT.COPY_LINK}`
                              }
                            >
                              <LinkOutlined
                                onClick={() =>
                                  copyToClipboard(`${programLink}`)
                                }
                              />
                            </Tooltip>
                          </Col>
                        </>
                      ) : (
                        '-'
                      )}
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Card>
        </section>
      </Spin>
    );
  }
  return null;
};

export default ProductOverview;
