import { ActivitiesType, ChangeLog } from '@model/contact-activity';

export const UpdatedActivityMessage = ({
  activitiesType,
  data,
  followerLabel,
  dealLink,
  applicationLink,
}: {
  activitiesType: ActivitiesType;
  data: ChangeLog;
  followerLabel: string;
  dealLink: false | JSX.Element;
  applicationLink: false | JSX.Element;
}) => {
  switch (activitiesType) {
    case 'application-partner-client-id':
      return (
        <span>
          Updated{' '}
          <span className="contact-activities-stage">
            application partner client ID
          </span>{' '}
          {applicationLink}
        </span>
      );

    case 'language-test':
      return (
        <span>
          Updated the{' '}
          <span className="contact-activities-stage">
            english test score for {data?.testName?.toUpperCase()}
          </span>
        </span>
      );

    case 'address-information':
      return (
        <span>
          Updated{' '}
          <span className="contact-activities-stage">address information</span>
        </span>
      );

    case 'personal-information':
      return (
        <span>
          Updated{' '}
          <span className="contact-activities-stage">personal information</span>
        </span>
      );

    case 'passport-and-visa':
      return (
        <span>
          Updated{' '}
          <span className="contact-activities-stage">
            passport and visa information
          </span>
        </span>
      );

    case 'emergency-contact':
      return (
        <span>
          Updated{' '}
          <span className="contact-activities-stage">
            emergency contact details
          </span>
        </span>
      );

    case 'assignee':
      return <span>Updated Assignee {applicationLink}</span>;

    case 'intake':
      return <span>Updated Intake {applicationLink}</span>;

    case 'enrollment-period':
      return <span>Updated Enrollment Period {applicationLink}</span>;

    case 'discontinued':
      return (
        <span>
          Discontinued the application
          {applicationLink}
        </span>
      );

    case 'professional-experience':
      return (
        <span>
          Updated the{' '}
          <span className="contact-activities-stage">
            Professional experience
          </span>
        </span>
      );

    case 'education':
      return (
        <span>
          Updated{' '}
          <span className="contact-activities-stage">education background</span>
        </span>
      );

    case 'document':
      return (
        <span>
          Document updated in{' '}
          <span className="contact-activities-stage">{data?.subCategory}</span>{' '}
        </span>
      );

    case 'application-intake':
      return (
        <span>
          Updated <span className="contact-activities-stage">Intake</span>{' '}
          {applicationLink}
        </span>
      );

    case 'application-enrollment':
      return (
        <span>
          Updated{' '}
          <span className="contact-activities-stage">enrollment period</span>{' '}
          {applicationLink}
        </span>
      );

    case 'application-fee':
      return (
        <span>
          Updated{' '}
          <span className="contact-activities-stage">application fees</span> for
          the application {applicationLink}
        </span>
      );

    case 'application-discount':
      return (
        <span>
          Updated{' '}
          <span className="contact-activities-stage">application discount</span>{' '}
          for the application {applicationLink}
        </span>
      );

    case 'application-sub-agent':
      return (
        <span>
          Updated <span className="contact-activities-stage">Sub agent</span>{' '}
          for the application {applicationLink}
        </span>
      );

    case 'application-super-agent':
      return (
        <span>
          Updated <span className="contact-activities-stage">Super agent</span>{' '}
          for the application {applicationLink}
        </span>
      );

    case 'application-document':
      return (
        <span>
          Updated document<span className="initial_small">(s)</span> for{' '}
          <span className="contact-activities-stage">
            {data?.data?.documentType}
          </span>{' '}
          in{' '}
          <span className="contact-activities-stage">
            {data?.data?.currentStage?.stage}
          </span>
        </span>
      );

    case 'archive':
      return (
        <span>
          <span className="contact-activities-stage">
            {data?.archived ? 'Archived' : 'Restored'}
          </span>{' '}
          the lead
        </span>
      );

    case 'basic-detail':
      return (
        <span>
          Updated the{' '}
          <span className="contact-activities-stage">basic detail</span>
        </span>
      );

    case 'deal':
      return <span>Updated {dealLink}</span>;

    case 'application-misc':
      return (
        <span>
          Updated{' '}
          <span className="contact-activities-stage">
            {data?.data?.activitiesLabel}
          </span>{' '}
          for the application {applicationLink}
        </span>
      );

    case 'bulk-document':
      return (
        <span>
          Bulk document Updated
        </span >
      );

    case 'bulk-application-document':
      return (
        <span>
          Bulk application stage document Updated
        </span >
      );

    default:
      return <span>Updated the application</span>;
  }
};
