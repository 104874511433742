import { SearchBox } from '@elastic/react-search-ui';
import React from 'react';

export interface SearchBarProps {
  openSearchResults: () => void;
}
const SearchBar: React.FC<SearchBarProps> = ({ openSearchResults }) => {

  return (
    <SearchBox
      inputProps={{
        onFocus() {
          openSearchResults()
        },
        className: "ant-input"
      }}
      searchAsYouType
    />
  )
}

export default SearchBar;
