import React from "react";
import { Row, Modal, Space, Typography } from "antd";
import { InfoCircleOutlined, MailOutlined } from "@ant-design/icons";
import { useSheetDataContext } from "../import-context";

interface IDupModalProp {
    list: string[];
    updateStep: (value: number) => void;
    show: boolean;
    setShow: (status: boolean) => void;
}

const DuplicateEmailModal = ({ list, updateStep, show, setShow }: IDupModalProp) => {
    const { updateSheetData } = useSheetDataContext();

    const handleOkClick = () => {
        setShow(false);
        updateStep(0);
        updateSheetData([]);
    }
    const modalFooter = [
        <button key="ok" type="button" className="ant-btn ant-btn-primary" onClick={handleOkClick}>
            OK
        </button>,
    ];
    return (
        <Modal title={
            <Row>
                <Space direction="horizontal">
                    <InfoCircleOutlined className="required font-size-18px" />
                    <Space direction="vertical" size={1}>
                        <Typography.Text className="font-size-1-rem">Duplicate Emails Found!</Typography.Text>
                        <Typography.Text className="text-grey font-regular">Please verify the emails and reupload the file.</Typography.Text>
                    </Space>
                </Space>
            </Row>
        }
            closable={false}
            visible={show}
            footer={modalFooter}

        >
            <div className="contact-modal-scroll">
                {list?.map((item: string) => (
                    <>
                        <Space direction="horizontal"><MailOutlined className="text-grey" /><Typography.Text key={item} className="border-bottom-1">{item}</Typography.Text></Space><br />
                    </>
                ))}
            </div>
        </Modal>
    )
}
export { DuplicateEmailModal };