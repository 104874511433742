
import { InboxOutlined } from '@ant-design/icons';
import {
  AUDIO_TYPE,
  DOCUMENT_TYPE,
  FILE_SIZE_EXCEED,
  IMAGE_TYPE,
  MAXIMUM_NUMBER_OF_FILES,
  MAXIMUM_SIZE_OF_FILES,
  NO_FILES_SELECTED,
  PRESENTATION_TYPE,
  TEXT,
  TITLE,
  TOTAL_FILE_SIZE_EXCEED,
  VIDEO_TYPE,
} from '@moxie/constants';
import { IDocumentDragger, IDocumentItem } from '@shared-components/models';
import { List, Spin, Upload } from 'antd';
import { errorNotificationHandler } from 'libs/shared/src/functions';
import React, { useEffect, useState } from 'react';
import { DocumentFileNameComponent } from 'apps/crm/src/pages/document';
import { UploadProps } from 'antd/es/upload';

const validFileExtensions = `${PRESENTATION_TYPE}, ${DOCUMENT_TYPE}, ${IMAGE_TYPE}, ${VIDEO_TYPE}, ${AUDIO_TYPE}`;

export const isValidFile = (file: File, validFileExtensions: string): boolean => {
  const substrings = file.name.toLowerCase().split('.')
  return validFileExtensions.includes(substrings[substrings.length - 1])
}

const beforeUpload = (file: File) => {
  if (!isValidFile(file, validFileExtensions)) {
    errorNotificationHandler("Invalid file type")
    return false
  }
  return true
}

export const ContactDocumentDragger: React.FC<IDocumentDragger> = ({
  onSetDocument,
  loading,
  documents,
}: IDocumentDragger) => {
  const { Dragger } = Upload;
  const [documentsSize, setDocumentsSize] = useState<number>(0);

  const handleRemove = async (file: IDocumentItem | undefined | any) => {
    const filterDocument = await documents.filter(
      (item: IDocumentItem) => item?.uid !== file?.uid
    );
    onSetDocument(filterDocument);
  };

  const handleUpload = async (fileInfo: any) => {
    // Check if uploaded file is above 10MB
    if (fileInfo.file.size / 1024 / 1024 >= 10) {
      errorNotificationHandler(FILE_SIZE_EXCEED);
      return;
    }
    const totalDocsSize: number = (documents as any[]).reduce((acc, doc) => acc + doc.size, 0);

    // Check if uploaded files exceed 50mb
    if ((totalDocsSize + fileInfo.file.size) / 1024 / 1024 >= 50) {
      errorNotificationHandler(TOTAL_FILE_SIZE_EXCEED);
      return;
    }
    onSetDocument((files: []) => [...files, fileInfo.file]);
  };

  const SETTING: UploadProps = {
    name: 'files',
    multiple: true,
    accept: validFileExtensions,
    showUploadList: false,
    customRequest: handleUpload,
    disabled: loading,
  };

  useEffect(() => {
    setDocumentsSize(() => {
      return documents?.reduce((acc: number, curr: any) => {
        return acc + curr.size;
      }, 0);
    });
  }, [documents]);

  const docSize = documentsSize / 1024 / 1024;

  return (
    <div>
      <Dragger className="dragger_height" {...SETTING} listType="picture" beforeUpload={beforeUpload}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{TITLE.DRAG_TO_UPLOAD}</p>
        <div>
          <div>
            <p className="font-size-20-px">
              {TEXT.SELECTED_DOCUMENTS}{' '}
              <span
                style={{
                  color: documents?.length > 20 ? 'red' : 'black',
                }}
              >
                ({documents?.length})
              </span>{' '}
              <span
                style={{
                  color: docSize > 50 ? 'red' : 'black',
                }}
              >{docSize > 0 ? `(${docSize.toFixed(3)} MB)` : "0 MB"}</span>
            </p>
            <p>{MAXIMUM_NUMBER_OF_FILES}: 20</p>
            <p>{MAXIMUM_SIZE_OF_FILES}: 50 MB</p>
            <p className="dragger_font">
              {documents?.length === 0 ? NO_FILES_SELECTED : null}
            </p>
          </div>
        </div>
      </Dragger>
      <div className="margin-top-1">
        <Spin
          spinning={loading}
          tip={TEXT.UPLOAD_DOCUMENTS}
          className="margin-top-3"
        >
          {documents?.length !== 0 ? (
            <List
              bordered={true}
              dataSource={documents}
              renderItem={(item: IDocumentItem) => {
                return (
                  <DocumentFileNameComponent
                    item={item}
                    documents={documents}
                    onSetDocument={onSetDocument}
                    onRemove={handleRemove}
                  />
                );
              }}
            />
          ) : null}
        </Spin>
      </div>
    </div>
  );
};
