import React, { useEffect, useState } from 'react';
import { Col, DatePicker, InputNumber, Row, Select } from 'antd';
import {
  contactActions,
  useAppSelector,
} from '@crm/core';
import {
  ICrmEducation,
  ICrmEducationBgLevel,
  IEducation,
  IForm,
  IGetPopupContainer,
  ISubject,
} from '@shared-components/models';
import Form from 'antd/lib/form';
import {
  GUTTER,
  LABEL,
  SUBJECT_AREA_PLACEHOLDER,
  SUBJECT_PLACEHOLDER,
  SUBJECT_AREA_EXTRA,
  AUTH_MESSAGE,
} from '@moxie/constants';
import { Input, SearchableSelect } from 'libs/shared/src/shared';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import moment, { Moment } from 'moment';
import { RootState } from 'apps/crm/src/core/store';
import { useDispatch } from 'react-redux';
import { ActivityAction } from '@model/contact-activity';

const { Option } = Select;

type IFormWithDegree<T> = IForm<T> & { degreeLevels: ICrmEducationBgLevel[] };

const EducationBackgroundForm: React.FC<IFormWithDegree<IEducation>> = ({
  form,
  setDisabled,
  isOpen,
  handleClose,
  initialData,
  degreeLevels,
}: IFormWithDegree<IEducation | any>) => {
  const dispatch = useDispatch();
  const disciplines = useAppSelector(
    (state: RootState) => state?.subject_discipline?.allData
  );
  const contactData = useAppSelector(
    (state: RootState) => state?.contact?.singleData
  );

  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);

  const changeDateToMoment = (date?: string | Moment): Moment | undefined => {
    if (date) return moment(date);
    return;
  };

  const [isGpa, setGpa] = useState(false);
  const [isTrue, setTrue] = useState(false);
  const [subjectOptions, setSubjectOptions] = useState<ISubject[]>([]);
  const [subjectAreaOptions, setSubjectAreaOptions] = useState<ISubject[]>([]);

  const handleChangePercentageCheckbox = (e: CheckboxChangeEvent) => {
    setGpa(!e.target.checked);
    setDisabled(false);
  };

  const handleChangeGPACheckbox = (e: CheckboxChangeEvent) => {
    setGpa(e.target.checked);
    setDisabled(false);
  };

  const logChangeActivity = (event: ActivityAction, response: any) =>
    socket?.emit('server::profile-updated', {
      activitiesTypeId: contactData.id,
      activitiesType: 'education',
      activitiesAction: event,
      userId: user?.id,
      contactId: contactData.id,
      companyId: user?.companyId as string,
      data: response,
    });

  const handleSubmit = async (data: ICrmEducation) => {
    data.contactId = contactData.id;
    data.subjectId = data?.subjectArea as string;
    if (data.courseStartAndEnd) {
      data.courseStart = moment(data.courseStartAndEnd[0])
        .format('YYYY-MM-DD')
        .toString();
      data.courseEnd = moment(data.courseStartAndEnd[1])
        .format('YYYY-MM-DD')
        .toString();
    }
    if (isGpa) {
      data.percentage = null;
    } else {
      data.securedGpa = null;
      data.totalGpa = null;
    }
    delete data['courseStartAndEnd'];
    if (data.discipline === undefined) {
      data.subjectArea = undefined;
    }

    if (initialData?.id) {
      data.id = initialData?.id;
      if (!data.courseStart && !data.courseEnd) {
        data.courseStart = null;
        data.courseEnd = null;
      }

      const payload = { ...data, discipline: data.discipline ?? null, subjectId: data.subjectArea ?? null };
      dispatch(
        contactActions.updateContactEducationBackgroundRequest(
          payload,
          (response) => logChangeActivity('updated', response)
        )
      );
    } else {
      dispatch(
        contactActions.addContactEducationBackgroundRequest(data, (response) =>
          logChangeActivity('created', response)
        )
      );
    }
    handleClose && handleClose();
  };

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  const setSubjectArea = (id: number) => {
    const discipline = disciplines.find((item) => item.id === id);
    setSubjectAreaOptions(
      discipline?.subjectAreas?.map((item: any) => {
        return {
          name: item.name,
          value: item.id,
        };
      }) ?? []
    );
  };
  const handleFormChange = (formValue: IEducation) => {
    const haveKey = Object.keys(formValue).some(
      (key: string) => key === 'discipline'
    );
    if (haveKey) {
      if (formValue.discipline) {
        setSubjectArea(Number(formValue.discipline));
        form.setFieldsValue({ ...form.getFieldsValue(), subjectArea: '' });
      } else {
        setSubjectAreaOptions([]);
        form.setFieldsValue({ ...form.getFieldsValue(), subjectArea: '' });
      }
    }
  };

  useEffect(() => {
    if (disciplines.length > 0) {
      const mapData: ISubject[] = disciplines.map((item: any) => {
        return {
          name: item.discipline,
          value: item.id,
        };
      });

      setSubjectOptions(mapData);
    }
  }, [disciplines, setSubjectOptions]);


  const changeSubjectAreaRequire = async (data: any) => {
    if (isTrue || data === undefined) {
      return setTrue(false);
    } else {
      return setTrue(true);
    }
  };

  useEffect(() => {
    if (initialData && Object.keys(initialData).length > 0) {
      if (initialData.courseStart && initialData.courseEnd) {
        initialData.courseStartAndEnd = [
          changeDateToMoment(initialData.courseStart),
          changeDateToMoment(initialData.courseEnd),
        ];
      } else {
        initialData.courseStartAndEnd = null;
      }

      if (initialData.securedGpa && initialData.totalGpa) {
        setGpa(true);
      }

      const initialValue = {
        ...initialData,
        discipline: initialData?.discipline?.id,
      };
      if (initialData?.discipline?.id) {
        setSubjectArea(Number(initialData?.discipline?.id));
        initialValue.subjectArea = initialData?.subjectId;
      } else {
        initialValue.subjectArea = null;
      }
      form.setFieldsValue(initialValue);
    } else form.resetFields();
  }, [isOpen]);

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      form={form}
      onValuesChange={handleFormChange}
      onFieldsChange={onFieldsChange}
    >
      <Row gutter={GUTTER}>
        <Col span={24}>
          <Form.Item
            name="degreeTitle"
            label={LABEL.DEGREE_TITLE}
            rules={[{ required: true, whitespace: true }]}
          >
            <Input data-testid="crm-contactdetails-degreetitle" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={24}>
          <Form.Item
            name="degreeLevelId"
            label={LABEL.DEGREE_LEVEL}
            rules={[{ required: true }]}
          >
            <SearchableSelect
              optionFilter="search_prop"
              data-testid="crm-contactdetails-degreelevel"
            >
              {degreeLevels &&
                degreeLevels.map((item: any) => (
                  <Option
                    value={item.id}
                    key={item.id}
                    search_prop={item.name}
                  >
                    {item.name}
                  </Option>
                ))}
            </SearchableSelect>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={24}>
          <Form.Item
            name="institution"
            label={LABEL.INSTITUTION}
            rules={[{ required: true, whitespace: true }]}
          >
            <Input data-testid="crm-contactdetails-institution" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={12}>
          <Form.Item name="courseStartAndEnd" label={LABEL.COURSE_DURATION}>
            <DatePicker.RangePicker
              className="full-width"
              picker="date"
              format={'DD/MM/YYYY'}
              placeholder={["DD/MM/YYYY", "DD/MM/YYYY"]}
              data-testid="crm-contactdetails-courseduration"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={24}>
          <div id="lead_education_discipline" className="relative">
            <Form.Item name="discipline" label={LABEL.SUBJECT}>
              <Select
                data-testid="crm-contactdetails-discipline"
                allowClear={true}
                showSearch
                placeholder={SUBJECT_PLACEHOLDER}
                optionFilterProp="children"
                onChange={changeSubjectAreaRequire}
                filterOption={(input, option: any) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                getPopupContainer={(): IGetPopupContainer =>
                  document.getElementById('lead_education_discipline')
                }
              >
                {subjectOptions.map((item) => (
                  <Option value={item.value} key={item.value}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={24}>
          <div id="lead_subject_area" className="relative">
            <Form.Item
              name="subjectArea"
              label={LABEL.SUBJECT_AREA}
              extra={SUBJECT_AREA_EXTRA}
              rules={[{ required: isTrue }]}
            >
              <Select
                data-testid="crm-contactdetails-subjectarea"
                showSearch
                loading={!subjectAreaOptions.length}
                placeholder={SUBJECT_AREA_PLACEHOLDER}
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                getPopupContainer={(): IGetPopupContainer =>
                  document.getElementById('lead_subject_area')
                }
              >
                {subjectAreaOptions &&
                  subjectAreaOptions.map((item) => {
                    return (
                      <Option value={item.value} key={item.value}>
                        {item.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={24}>
          {isGpa ? (
            <Row gutter={GUTTER}>
              <Col span={12}>
                <Form.Item
                  name="securedGpa"
                  rules={[
                    { min: 0, max: 10, type: 'number' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const totalGpa = getFieldValue('totalGpa');
                        if (!value && totalGpa) {
                          return Promise.reject(
                            AUTH_MESSAGE.SECURED_GPA_REQUIRED
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  validateFirst
                  label={LABEL.GPA}
                >
                  <InputNumber
                    className="full-width"
                    type="number"
                    data-testid="crm-contactdetails-gpa"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="totalGpa"
                  rules={[
                    {
                      min: 0,
                      max: 10,
                      type: 'number',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const securedGpa = getFieldValue('securedGpa');
                        if (securedGpa && !value) {
                          return Promise.reject(
                            AUTH_MESSAGE.TOTAL_GPA_REQUIRED
                          );
                        }
                        if (value < securedGpa) {
                          return Promise.reject(AUTH_MESSAGE.GREATER_GPA);
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  validateFirst
                  label={LABEL.TOTAL_GPA}
                >
                  <InputNumber
                    className="full-width"
                    type="number"
                    data-testid="crm-contactdetails-totalgpa"
                  />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <Form.Item
              name="percentage"
              label={LABEL.PERCENTAGE}
              rules={[{ min: 0, max: 100, type: 'number' }]}
              validateFirst={true}
            >
              <InputNumber
                type="number"
                className="full-width"
                data-testid="crm-contactdetails-percentage"
              />
            </Form.Item>
          )}
          <Col span={24}>
            <Checkbox
              onChange={handleChangePercentageCheckbox}
              checked={!isGpa}
              data-testid="crm-contactdetails-checkpercentage"
            >
              {LABEL.PERCENTAGE}
            </Checkbox>

            <Checkbox
              onChange={handleChangeGPACheckbox}
              checked={isGpa}
              data-testid="crm-contactdetails-checkgpa"
            >
              {LABEL.GPA}
            </Checkbox>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};

export { EducationBackgroundForm };
