import moment from 'moment';
import { ReactNode } from 'react'
  ;
import { LABEL, TEXT } from '@moxie/constants';
import { IContactCrm } from '@model/crm/contact.model';
import { capitalizeFirstLetter, formatDateWithTimezone } from '@moxie/shared';

export const exportContactReportColumns = (userTimeZone: string) => [
  {
    title: LABEL.INTERNAL_ID,
    width: 200,
    dataIndex: 'internalId',
    render: (value: string, data: IContactCrm): ReactNode => {
      return value;
    },
  },

  {
    title: LABEL.CONTACT,
    width: 300,
    dataIndex: 'contact',
    render: (
      value: string,
      { firstName, lastName }: Required<IContactCrm>
    ): ReactNode => {
      return `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(
        lastName
      )}`;
    },
  },

  {
    title: TEXT.PHONE,
    width: 200,
    dataIndex: 'phone',
    render: (phoneNumber: number, value: any): ReactNode => {
      return phoneNumber ? `${value?.countryCode} ${phoneNumber}` : '-';
    },
  },
  {
    title: LABEL.EMAIL,
    width: 200,
    dataIndex: 'email',
    render: (email: string): ReactNode => {
      return email ?? '-';
    },
  },

  {
    title: LABEL.DATE_OF_BIRTH,
    width: 300,
    dataIndex: 'dateOfBirth',
    render: (value: string): ReactNode => {
      return value ? moment(value).format('MMM DD, YYYY') : '-';
    },
  },

  {
    title: LABEL.ADDED_DATE,
    width: 200,
    dataIndex: 'createdAt',
    render: (value: string): ReactNode => {
      return formatDateWithTimezone(value, userTimeZone);
    },
  },

  {
    title: LABEL.ASSIGNEE,
    width: 200,
    dataIndex: 'assignee',
    render: (data: IContactCrm): ReactNode => {
      return data ? `${data?.firstName} ${data?.lastName}` : 'Unassigned';
    },
  },

  {
    title: LABEL.PRIMARY_BRANCH,
    width: 200,
    dataIndex: 'branch',
    render: (data: any): ReactNode => {
      return data ? data?.name : '-';
    },
  },

  {
    title: LABEL.CITY,
    width: 200,
    dataIndex: 'city',
    render: (value: string): ReactNode => {
      return value ? capitalizeFirstLetter(value) : '-';
    },
  },

  {
    title: LABEL.STATE,
    width: 200,
    dataIndex: 'state',
    render: (value: string): ReactNode => {
      return value ? capitalizeFirstLetter(value) : '-';
    },
  },

  {
    title: LABEL.COUNTRY_OF_RESIDENCE,
    width: 200,
    dataIndex: 'country',
    render: (value: string): ReactNode => {
      return value ?? '-';
    },
  },

  {
    title: LABEL.COUNTRY_OF_PASSPORT,
    width: 200,
    dataIndex: 'countryOfPassport',
    render: (value: string): ReactNode => {
      return value ?? '-';
    },
  },

  {
    title: LABEL.PASSPORT_NUMBER,
    width: 200,
    dataIndex: 'passportNo',
    render: (value: string): ReactNode => {
      return value ?? '-';
    },
  },

  {
    title: LABEL.PASSPORT_EXPIRY_DATE,
    width: 200,
    dataIndex: 'passportExpiryDate',
    render: (value: string): ReactNode => {
      return value ? moment(value).format('MMM DD, YYYY') : '-';
    },
  },

  {
    title: LABEL.VISA_EXPIRY_DATE,
    width: 200,
    dataIndex: 'visaExpiryDate',
    render: (value: string): ReactNode => {
      return value ? moment(value).format('MMM DD, YYYY') : '-';
    },
  },
];
