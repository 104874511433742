import { useQuery } from '@tanstack/react-query';
import { errorNotificationHandler } from '@moxie/shared';
import { findAllMediaBySubjectIdAndMediaType } from '@crm/services.api';

export const useChecklistDocs = (
  subjectId: string | undefined,
  mediaType: string | undefined,
  stageId: string | undefined,
) => {
  const { data, isLoading: isLoadingDocs } = useQuery({
    enabled: (!!subjectId && !!mediaType),
    queryKey: ['application-checklist-docs', subjectId, mediaType, stageId],
    queryFn: async () => findAllMediaBySubjectIdAndMediaType({ subjectId, mediaType, stageId }),
    onError: () => {
      errorNotificationHandler('Error fetching documents.');
    },
  });

  return { checklistDocs: data?.data ?? [], isLoadingDocs };
};
