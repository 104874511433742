import { InputButtonWrapper } from '@crm/src/shared/input-button-wrapper';
import { successNotificationHandler } from '@moxie/shared';
import { DatePicker, Input, Space, Typography } from 'antd';
import { Moment } from 'moment';
import { useContext, useState } from 'react';
import ApplicationDetailContext from '../../../application-detail-context';
import useUpdateApplicationMutation from '../../../hooks/useUpdateApplicationMutation';
import { parseDate } from './application-details-tab';

const { Text } = Typography;

export const EducationForm = () => {
  const { applicationData } = useContext(ApplicationDetailContext);
  const {
    endDate,
    startDate,
    intakeMonth,
    intakeYear,
    workflowMetadata,
  } = applicationData;
  const updateApplicationMutation = useUpdateApplicationMutation(
    applicationData,
    applicationData?.dealId
  );
  const initialInput = {
    partnerClientId: applicationData.partnerClientId ?? '',
  };
  const [input, setInput] = useState(initialInput);

  const handleIntakeUpdate = (data: Moment | null) => {
    const intakeYear = Number(data?.format('YYYY') ?? 0);
    const intakeMonth = data?.format('MMMM') as string;
    const payload = { intakeMonth, intakeYear };
    updateApplicationMutation.mutate(
      {
        payload,
        activitiesInfo: {
          activitiesType: 'application-intake',
        },
      },
      {
        onSuccess() {
          successNotificationHandler('Application updated.', 1.5);
        },
      }
    );
  };

  const handleEnrollmentUpdate = (data: Moment[] | any) => {
    const startDate = data[0]?.format('DD/MM/YYYY');
    const endDate = data[1]?.format('DD/MM/YYYY');
    const payload = { startDate, endDate };
    updateApplicationMutation.mutate(
      {
        payload,
        activitiesInfo: {
          activitiesType: 'application-enrollment',
        },
      },
      {
        onSuccess() {
          successNotificationHandler('Application updated.', 1.5);
        },
      }
    );
  };

  const updatePartnerClientId = (partnerClientId: string) => {
    const payload = { partnerClientId };
    updateApplicationMutation.mutate(
      {
        payload,
        activitiesInfo: {
          activitiesType: 'application-misc',
          activitesLabel: 'Provider Student ID',
        },
      },
      {
        onSuccess() {
          successNotificationHandler(
            'Provider student ID updated successfully.'
          );
        },
      }
    );
  };

  return (
    <>
      <div className="form-container">
        <Space direction="vertical">
          <Text strong>Intake</Text>
          <DatePicker
            style={{ width: '100%' }}
            format="MMMM YYYY"
            defaultValue={parseDate(
              `${intakeMonth} ${intakeYear}`,
              'MMMM YYYY'
            )}
            picker="month"
            onChange={handleIntakeUpdate}
            allowClear={false}
          />
        </Space>

        <Space direction="vertical">
          <Text strong>Enrollment period</Text>
          <DatePicker.RangePicker
            format="DD/MM/YYYY"
            picker="date"
            onChange={handleEnrollmentUpdate}
            defaultValue={[
              parseDate(startDate, 'DD/MM/YYYY') as Moment,
              parseDate(endDate, 'DD/MM/YYYY') as Moment,
            ]}
            allowClear={false}
          />
        </Space>

        <InputButtonWrapper
          onSubmit={() => updatePartnerClientId(input.partnerClientId)}
          onCancel={() =>
            setInput((input) => ({
              ...input,
              partnerClientId: initialInput.partnerClientId,
            }))
          }
        >
          <Space direction="vertical">
            <Text strong>Provider Student ID</Text>
            <Input
              onChange={(e) =>
                setInput((input) => ({
                  ...input,
                  partnerClientId: e.target.value,
                }))
              }
              value={input.partnerClientId}
            />
          </Space>
        </InputButtonWrapper>

        <Space direction="vertical">
          <Text strong>Application Date</Text>
          <DatePicker
            format="DD-MM-YYYY"
            defaultValue={parseDate(
              workflowMetadata?.applicationDate,
              'DD/MM/YYYY'
            )}
            picker="date"
            onChange={(data) => {
              updateApplicationMutation.mutate(
                {
                  payload: {
                    workflowMetadata: {
                      ...workflowMetadata,
                      applicationDate: data?.format('DD/MM/YYYY'),
                    },
                  },
                  activitiesInfo: {
                    activitiesType: 'application-misc',
                    activitesLabel: 'Application Date',
                  },
                },
                {
                  onSuccess: () => {
                    successNotificationHandler(
                      'Application date updated successfully.'
                    );
                  },
                }
              );
            }}
            allowClear={false}
          />
        </Space>
      </div>
    </>
  );
};
