import React from 'react';
import { Tag, Tooltip } from 'antd';
import { IProductIntake } from '@model/data.model';

const IntakesTagGroup = ({
  data,
  numberOfIntake,
}: {
  data: IProductIntake[];
  numberOfIntake: number;
}) => {
  const text = (
    <div>
      {data &&
        data
          .slice(numberOfIntake, data.length)
          .map((Intake: IProductIntake, index:number) => {
            return (
              <Tag
                className="margin-bottom-1 initial_capital"
                key={Intake?.id ?? index}
                color="default"
              >
                {Intake}
              </Tag>
            );
          })}
    </div>
  );
  return (
    <>
      {data &&
        data.slice(0, numberOfIntake).map((Intake: IProductIntake, index: number) => {
          return (
            <Tag key={Intake?.id ?? index} color="default" className="initial_capital">
              {Intake}
            </Tag>
          );
        })}

      {data && data.length > numberOfIntake && (
        <Tooltip placement="topLeft" color="white" title={text}>
          <Tag color="default">+{data?.length - numberOfIntake}</Tag>
        </Tooltip>
      )}
    </>
  );
};

IntakesTagGroup.defaultProps = {
  numberOfIntake: 3,
};

export { IntakesTagGroup };
