import React, { useState } from "react";
import { SheetData, useSheetDataContext, useStepContext } from "../import-context";
import { Button, Row, Table, Typography } from "antd";
import { IMPORT_CONTACT } from "@moxie/constants";
import { ArrowLeftOutlined, RightCircleOutlined } from "@ant-design/icons";

const SheetDataList = ({ data }: { data: SheetData[] }) => {
    const { currentStep, updateStep } = useStepContext();
    const { updateSheetData } = useSheetDataContext();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(11);

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedData = data.slice(startIndex, endIndex);

    const handleStateUpdate = () => {
        updateStep(currentStep + 1);
    }

    const handlePrevClick = () => {
        updateStep(currentStep - 1);
        updateSheetData([]);
    }

    const columns = data?.length ? Object.keys(data[0]).map((header) => ({
        title: header,
        dataIndex: header,
        width: '250px',
        key: header
    })) : undefined;

    return (
        <>
            <Table
                dataSource={paginatedData}
                columns={columns}
                scroll={{ x: '800px', y: window.screen.height > 700 ? '60vh' : '40vh' }}
                pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: data.length,
                    onChange: (page: number, pageSize?: number) => setCurrentPage(page),
                    onShowSizeChange: (current: number, size: number) => setPageSize(size),
                    hideOnSinglePage: true,
                    showSizeChanger: false,
                }}
            />
            <Row justify="space-between" align="middle" className="margin-top-1">
                <Button type="ghost" onClick={handlePrevClick}> <ArrowLeftOutlined /> Previous</Button>
                <Button type="primary" onClick={handleStateUpdate} > Next <RightCircleOutlined /> </Button>
            </Row>
        </>
    )
}
const PreviewUploadedData = () => {
    const { sheetData } = useSheetDataContext();
    return (
        <div>
            <Typography.Title level={5}>{IMPORT_CONTACT.PREVIEW_UPLOADED_DATA}</Typography.Title>
            <Typography.Paragraph>{IMPORT_CONTACT.PREVIEW_STEP_DESC}</Typography.Paragraph>
            <SheetDataList data={sheetData} />
        </div>
    )
}
export { PreviewUploadedData };
