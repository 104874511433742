import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Spin from 'antd/es/spin';
import Empty from 'antd/es/empty';
import Card from 'antd/es/card';
import Tag from 'antd/es/tag';

import { LABEL, TEXT } from '@moxie/constants';
import { EditDeleteButton } from '@shared-components/elements';
import useProductFees from '../../hooks/useProductFees';
import ProductFeeItem from './ProductFeeItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Divider } from 'antd';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';
import { IProductFeeCrm, ProductFeeItemCrm as ProductFeeItemModel } from '../../product.model';


interface Props {
  onEdit: (fee: IProductFeeCrm) => void;
  onDelete: (id: string) => void;
  currency?: string
}

interface GroupBy {
  [key: string]: number
}

const ProductFees: React.FC<Props> = ({ onDelete, onEdit, currency }) => {

  const { productId } = useParams<{ productId: string }>()
  const { fees, isLoading, hasMore, next } = useProductFees(productId);
  const canAccess = useCanAccess();

  const totalFeeMap = useMemo(() => {
    return fees.reduce((fee, acc) => {
      fee[acc.id] = acc.feeItems.reduce((item: number, prev: ProductFeeItemModel) => item += Number(prev?.totalFee), 0)
      return fee;
    }, {} as GroupBy)
  }, [fees])

  if (isLoading) {
    return <Spin />
  }

  if (!fees.length) {
    return (
      <Empty
        className="padding-bottom-2"
        description="No Data"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    )
  }

  return (
    <React.Fragment>
      <div id="feesScroll" className="product-fees">
        <InfiniteScroll
          hasMore={hasMore}
          next={() => next()}
          scrollableTarget="feesScroll"
          dataLength={fees.length}
          loader={isLoading ? <Spin /> : null}
          endMessage={fees.length > 10 ? <Divider plain>You are all caught up</Divider> : null}
        >
          {fees.map(fee => (
            <Card
              className="fee_card margin-top-1"
              title={
                <div className="fee_card__header">
                  <span className="fee_card__title">{fee.feeName}</span>
                  {fee.country && (
                    <Tag>{TEXT.VALID_FOR_ALL_COUNTRIES}</Tag>
                  )}
                </div>
              }
              key={fee.id}
              extra={
                <EditDeleteButton
                  item={fee}
                  onEdit={() => onEdit(fee)}
                  onDelete={() => onDelete(fee.id)}
                  canDelete={canAccess('product-fee', 'delete')}
                  canUpdate={canAccess('product-fee', 'update')}
                />
              }
            >
              <div className="fee_lists">
                {fee?.feeItems?.map((feeItem: ProductFeeItemModel) => (
                  <ProductFeeItem
                    feeList={feeItem}
                    currency={currency}
                    key={feeItem.id}
                  />
                ))}
              </div>
              <div className="fee_lists__total">
                <span className="fee_lists__total_label">
                  {LABEL.FEE_NET_TOTAL}:
                </span>
                <span className="fee_lists__total_price">
                  {currency}{' '}
                  {totalFeeMap[fee.id]}
                </span>
              </div>
            </Card>
          ))}
        </InfiniteScroll>
      </div>
    </React.Fragment>
  )
}

export default ProductFees;
