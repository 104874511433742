import React from 'react';
import { Tag, Tooltip, Typography } from 'antd';

const AgentsBranchTagGroup = ({
  data,
  numberOfBranch,
}: {
  data: any;
  numberOfBranch: number;
}) => {
  const text = (
    <div>
      {data &&
        data.slice(numberOfBranch, data.length).map((branch: any) => {
          return (
            <Tag
              className="margin-bottom-1 initial_capital"
              key={branch?.id}
              color="default"
            >
              <Typography.Paragraph ellipsis={{ rows: 1, expandable: false, tooltip: true }} className="para-ellipsis-height agent-branch-tooltip-width">{branch?.branch_name}</Typography.Paragraph>
            </Tag>
          );
        })}
    </div>
  );
  return (
    <>
      {data &&
        data.slice(0, numberOfBranch).map((branch: any) => {
          return (
            <Tag key={branch?.id} color="default" className="initial_capital">
              {branch?.name}
            </Tag>
          );
        })}

      {data && data.length > numberOfBranch && (
        <Tooltip placement="topLeft" color="white" title={text}>
          <Tag color="default">+{data?.length - numberOfBranch}</Tag>
        </Tooltip>
      )}
    </>
  );
};

AgentsBranchTagGroup.defaultProps = {
  numberOfBranch: 2,
};

export { AgentsBranchTagGroup };
