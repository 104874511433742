import {
  ADD_LANGUAGE_TEST_SCORE,
  CANCEL_BTN,
  EDIT_LANGUAGE_TEST_SCORE,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { DrawerElem } from '@shared-components/elements';
import { Button, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { PTEForm } from '../form/pte';

const PTEDrawer = ({
  showDrawer,
  setShowDrawer,
  editMode,
  setEditMode,
  pteData,
}: {
  showDrawer: boolean;
  setShowDrawer: any;
  editMode?: boolean;
  setEditMode?: any;
  pteData?: any;
}) => {
  const [form] = useForm();
  const [disabled, setDisabled] = useState(true);
  const handleClose = () => {
    setDisabled(true);
    setEditMode(false);
    setShowDrawer();
    form.resetFields();
  };
  const handleSubmitBtnClick = () => {
    form.submit();
  };

  return (
    <DrawerElem
      title={editMode ? EDIT_LANGUAGE_TEST_SCORE : ADD_LANGUAGE_TEST_SCORE}
      width={720}
      visible={showDrawer}
      onClose={handleClose}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={handleClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              onClick={handleSubmitBtnClick}
              type="primary"
              disabled={disabled}
            >
              {editMode ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
      data-testid="pte"
    >
      <PTEForm
        form={form}
        setDisabled={setDisabled}
        handleClose={handleClose}
        pteData={pteData}
        editMode={editMode}
      />
    </DrawerElem>
  );
};

export { PTEDrawer };
