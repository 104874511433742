import React from 'react';
import { Row, Typography } from 'antd';
import { LABEL, HYPHEN } from '@moxie/constants';
import { useAppSelector } from '@crm/core';
import { EditPersonalInformation } from './edit-personal-information';
import { formatDate } from '@moxie/shared';

const { Text } = Typography;

const PersonalDetailsPanel: React.FC<any> = ({
  showDrawer,
  setShowDrawer,
}: {
  showDrawer: boolean;
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const data = useAppSelector(state => state?.contact?.singleData);

  return (
    <>
      <div className="profile-panel-content">
        <Row>
          <div className="flex-direction-vertical">
            <h4 className="text-primary">
              <span>{LABEL.GENDER}</span>
              <span className="margin-left-1-half-px">:</span>
            </h4>
            <Text className="margin-top-minus-5-px text-grey">
              {data?.gender ? data?.gender : HYPHEN}
            </Text>
          </div>
        </Row>
        <Row className="padding-top-2">
          <div className="flex-direction-vertical">
            <h4 className="text-primary">
              <span>{LABEL.DATE_OF_BIRTH}</span>
              <span className="margin-left-1-half-px">:</span>
            </h4>
            <Text className="margin-top-minus-5-px text-grey">
              {data?.dateOfBirth ? formatDate(data?.dateOfBirth, 'DD-MM-YYYY') : HYPHEN}
            </Text>
          </div>
        </Row>
        <Row className="padding-top-2">
          <div className="flex-direction-vertical">
            <h4 className="text-primary">
              <span>{LABEL.NATIONALITY}</span>
              <span className="margin-left-1-half-px">:</span>
            </h4>
            <Text className="margin-top-minus-5-px text-grey">
              {data?.nationality ? data?.nationality : HYPHEN}
            </Text>
          </div>
        </Row>

        <Row className="padding-top-2">
          <div className="flex-direction-vertical">
            <h4 className="text-primary">
              <span>{LABEL.RELATIONSHIP_STATUS}</span>
              <span className="margin-left-1-half-px">:</span>
            </h4>
            <Text className="margin-top-minus-5-px text-grey padding-bottom-2">
              {data?.relationshipStatus ? data?.relationshipStatus : HYPHEN}
            </Text>
          </div>
        </Row>
      </div>

      <EditPersonalInformation
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        initialData={data}
      />
    </>
  );
};
export { PersonalDetailsPanel };
