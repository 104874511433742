import Typography from "antd/es/typography";

interface Props {
  productName: string;
  institutionName: string;
}

const ProductNameCard: React.FC<Props> = ({ institutionName, productName }) => {

  return (
    <div>
      <Typography.Text className="text-primary-color">
        {productName}
      </Typography.Text>
      <br />
      <small
        className="table-elements-email-container">
        {institutionName}
      </small>
    </div>
  )

}

export default ProductNameCard;
