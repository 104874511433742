import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Form, Row, Select } from 'antd';
import {
  IContactDetailsInfoForm,
  IGetPopupContainer,
} from '@shared-components/models';
import { COUNTRIES, GENDER_ITEMS, GUTTER, LABEL } from '@moxie/constants';
import { SearchableSelect, Option } from '@shared-components/elements';
import { disableFutureDate } from '@moxie/shared';
import moment from 'moment';

export const relationshipStatusOptions = [
  'Married',
  'Engaged',
  'Separated',
  'Divorced',
  'Widowed',
  'Never married or been in a de facto relationship',
  'De facto relationship',
];
const PersonalInfoForm: React.FC<IContactDetailsInfoForm> = ({
  form,
  handleSubmit,
  setDisabled,
  initialData,
}: IContactDetailsInfoForm) => {
  const [relationshipStatus, setRelationshipStatus] = useState<string | undefined>(undefined);
  const handleFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const fields of allFields) {
        fields.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      dateOfBirth: initialData?.dateOfBirth
        ? moment(initialData?.dateOfBirth)
        : null,
      countryOfBirth: initialData?.countryOfBirth,
      nationality: initialData?.nationality,
      gender: initialData?.gender,
      relationshipStatus: initialData?.relationshipStatus
    });
    setRelationshipStatus(initialData?.relationshipStatus);
  }, [initialData]);

  const onFinish = (payload: any) => handleSubmit({ ...payload, relationshipStatus: relationshipStatus ?? null })

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFieldsChange={handleFieldsChange}
      scrollToFirstError={true}
    >
      <Row gutter={GUTTER}>
        <Col span={24}>
          <div id="gender" className="relative">
            <Form.Item name="gender" label={LABEL.GENDER}>
              <SearchableSelect
                data-testid="crm-editpersonalinfomation-gender"
                className="note-title-input"
                allowClear={false}
                getPopupContainer={(): IGetPopupContainer =>
                  document.getElementById('gender')
                }
              >
                {GENDER_ITEMS.map((item: string) => {
                  return (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  );
                })}
              </SearchableSelect>
            </Form.Item>
          </div>
        </Col>
        <Col span={24}>
          <div id="dateOfBirth" className="relative">
            <Form.Item name="dateOfBirth" label={LABEL.DATE_OF_BIRTH}>
              <DatePicker
                data-testid="crm-editpersonalinfomation-dob"
                disabledDate={disableFutureDate}
                className="full-width note-title-input"
                format="DD-MM-YYYY"
              />
            </Form.Item>
          </div>
        </Col>
        <Col span={24}>
          <div id="nationality" className="relative">
            <Form.Item name="nationality" label={LABEL.NATIONALITY}>
              <SearchableSelect
                data-testid="crm-editpersonalinfomation-nationality"
                className="note-title-input"
                allowClear={false}
                optionFilter="search_prop"
                getPopupContainer={(): IGetPopupContainer =>
                  document.getElementById('nationality')
                }
              >
                {COUNTRIES.map((item) => {
                  return (
                    <Option
                      value={item.name}
                      key={item.code}
                      search_prop={`${item.nationality} ${item.code} ${item.nationality}`}
                    >
                      {item.name}
                    </Option>
                  );
                })}
              </SearchableSelect>
            </Form.Item>
          </div>
        </Col>

        <Col span={24}>
          <div id="relationshipStatus" className="relative">
            <Form.Item name="relationshipStatus" label={LABEL.RELATIONSHIP_STATUS}>
              <Select
                options={relationshipStatusOptions.map(option => ({ label: option, value: option }))}
                data-testid="crm-editpersonalinfomation-relationshipStatus"
                className="note-title-input"
                value={relationshipStatus}
                onChange={setRelationshipStatus}
                allowClear
                onClear={() => setRelationshipStatus(undefined)}
                getPopupContainer={(): IGetPopupContainer =>
                  document.getElementById('relationshipStatus')
                }
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
export { PersonalInfoForm };
