import { ContactData } from "@model/crm/contact.model";
import { COUNTRIES, regex } from "@moxie/constants";
import moment from "moment";

const firstNameValidation = (value: string | undefined) => {
  if (!value) {
    return true;
  }
  if (!new RegExp(regex.ONLY_STRING).test(value)) {
    return true;
  }
  if (value.length > 50) {
    return true;
  }
  return false;
}

const lastNameValidation = (value: string | undefined) => {
  if (!value) {
    return true;
  }
  if (!new RegExp(regex.GLOBAL_ACCEPTED_LAST_NAME).test(value)) {
    return true;
  }
  if (value.length > 50) {
    return true;
  }
  return false;
}

const phoneValidation = (value: string | undefined) => {
  if (value !== undefined && value !== "") {
    if (!new RegExp(regex.TEN_TO_11_DIGIT_NUM).test(value!)) {
      return true;
    }
  }
  return false;
}

const countryCodeValidation = (value: string | undefined) => {
  if (value !== undefined && value !== "") {
    if (value) {
      const status = COUNTRIES.some(country => country.dial_code === value!);
      return !status;
    }
  }
  return false;
}

const emailValidation = (value: string | undefined) => {
  if (!value) {
    return true;
  }
  if (!new RegExp(regex.EMAIL).test(value)) {
    return true;
  }
  return false;
}

const countryValidation = (value: string | undefined) => {
  if (value !== undefined && value !== "") {
    if (!new RegExp(regex.STRING_WITH_SPECIAL_CHARS).test(value!)) {
      return true;
    }
    if (value) {
      const status = COUNTRIES.some(country => country.name.toLowerCase() === value!.toLowerCase());
      return !status;
    }
  }
  return false;
}

const fiftyCharValidationForOptional = (value: string | undefined) => {
  if (value !== undefined && value !== "") {
    if (!new RegExp(regex.ONLY_STRING).test(value!)) {
      return true;
    }
    if (value!.length > 50) {
      return true;
    }
  }
  return false;
}

const dobValidation = (value: string | undefined) => {
  if (value !== undefined && value !== "") {
    if (!new RegExp(regex.DD_MM_YYYY).test(value!)) {
      return true;
    }
    if (moment(value, 'DD/MM/YYYY', true).isAfter(moment(), 'date')) {
      return true;
    }
  }
  return false;
}

const visaExpiryValidation = (value: string | undefined) => {
  if (value !== undefined && value !== "") {
    if (!new RegExp(regex.DD_MM_YYYY).test(value!)) {
      return true;
    }
  }
  return false;
}

const zipCodeValidation = (value: string | undefined) => {
  if (value !== undefined && value !== "") {
    if (!new RegExp(regex.ALPHA_NUMERIC).test(value!)) {
      return true;
    }
  }
  return false;
}

export const checkValidation = (dataIndex: keyof ContactData, record: ContactData) => {
  switch (dataIndex) {
    case 'firstName':
      return firstNameValidation(record[dataIndex]);
    case 'lastName':
      return lastNameValidation(record[dataIndex]);
    case 'countryCode':
      return countryCodeValidation(record[dataIndex]);
    case 'phone':
      return phoneValidation(record[dataIndex]);
    case 'email':
      return emailValidation(record[dataIndex]);
    case 'nationality':
      return countryValidation(record[dataIndex]);
    case 'country':
      return countryValidation(record[dataIndex]);
    case 'countryOfPassport':
      return countryValidation(record[dataIndex]);
    case 'state':
      return fiftyCharValidationForOptional(record[dataIndex]);
    case 'city':
      return fiftyCharValidationForOptional(record[dataIndex]);
    case 'dateOfBirth':
      return dobValidation(record[dataIndex]);
    case 'visaExpiryDate':
      return visaExpiryValidation(record[dataIndex]);
    case 'zipCode':
      return zipCodeValidation(record[dataIndex]);
    default:
      return false;
  }
}
