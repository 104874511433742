import React from "react";
import Tag from "antd/es/tag";
import Space from "antd/es/space";
import { SyncOutlined } from "@ant-design/icons";
import Typography from "antd/es/typography";

interface IStatusProps {
  activeEnquiriesCount?: string | null;
  activeDealsCount?: string | null;
}

interface IStatusTagProps {
  text: string;
  color: string;
  count?: string | null;
}

const StatusTag = ({ text, count, color }: IStatusTagProps) => {
  if (!count) {
    return null;
  }
  return (
    <Tag color={color}><SyncOutlined /> {text}: {count}</Tag>
  )
}

const ActiveEnquiriesDealsStatus = ({ activeEnquiriesCount, activeDealsCount }: IStatusProps) => {
  return (
    <>
      <Space size={1}>
        <StatusTag color="geekblue" text="Active Deals" count={activeDealsCount} />
        <StatusTag color="cyan" text="Active Enquiries" count={activeEnquiriesCount} />
        {!activeDealsCount && !activeEnquiriesCount ?
          <Typography.Text>-</Typography.Text>
          : null}
      </Space>
    </>
  );
}

export default ActiveEnquiriesDealsStatus;
