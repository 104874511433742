import React, { useContext, useMemo } from 'react';
import { CrmTable, UserAvatarGroup, UserNameCard } from '@shared-components/elements';
import { fetchApplications } from '@crm/libs/services.api/lib/application.api';
import { ColumnsType } from 'antd/es/table';
import { Application } from '@model/application';
import ProductNameCard from './product-name-card';
import Typography from 'antd/es/typography';
import { Link, useHistory } from 'react-router-dom';
import { URL_APPLICATION, URL_DETAIL_LINK } from '@moxie/constants';
import { ApplicationContext } from '../application-context';
import { ViewDetailsButton } from '@crm/src/shared/buttons';

const { Paragraph } = Typography;


const useColumns = () => {
  const history = useHistory();
  const cols: ColumnsType<Application> = [
    {
      key: 'appIdentifier',
      title: 'ID',
      dataIndex: 'appIdentifier',
      render(value: string, application: Application) {
        return (
          <Link
            to={`${URL_APPLICATION}/detail/${application?.client?.id}/${application.id}`}
            className="primary-id-color"
          >
            <Typography.Text className="text-primary-color">{value}</Typography.Text>
          </Link>
        )
      }
    },
    {
      title: 'Contact Name',
      ellipsis: true,
      render({ client }: Application) {
        return (
          <UserNameCard
            firstName={client.firstName}
            lastName={client.lastName}
            email={client.email}
            link={`${URL_DETAIL_LINK}${client.id}/deals`}
            showMailIcon={true}
            showEmailAsLink={true}
          />
        )
      }
    },

    {
      title: 'Product Name',
      width: '5px',
      ellipsis: true,
      render(application: Application) {
        return (
          <div style={{ width: '180px' }}>
            <Paragraph className="initial_capital" ellipsis={{ tooltip: `${application.product.name}` }
            }>{application.product.name}</Paragraph >
          </div>
        )
      }
    },
    {
      title: 'Deal Name',
      width: 25,
      ellipsis: true,
      render(application: Application) {
        return (
          <div style={{ width: '180px' }}>
            <Link to={`/contact/${application.client.id}/deals/${application.deal.id}`}>
              <Paragraph className="text-primary-color initial_capital" ellipsis={{ tooltip: `${application.deal.name}` }} > {application.deal.name}</Paragraph>
            </Link >

          </div>
        )
      }
    },
    {
      title: 'Processing Branch',
      render({ processingBranch }: Application) {
        return (
          <>
            <Typography.Text>{processingBranch?.name}</Typography.Text>
          </>
        )
      }
    },
    {
      title: 'Institution/Branch',
      render(application: Application) {
        return (
          <ProductNameCard
            institutionName={application.institution.name}
            productName={application.institutionBranch.name}
          />
        )
      }
    },
    {
      title: 'Assignee',
      render({ assignees }: Application) {
        if (assignees.length) {
          return <UserAvatarGroup userList={assignees} />
        }
        return <Typography.Text className="padding-left-2"></Typography.Text>;
      }
    },
    {
      title: 'Partner Client ID',
      render(value: Application) {
        return <Typography.Text>{value.partnerClientId ?? '-'}</Typography.Text>
      }
    },
    {
      title: 'Status',
      render(value: Application) {
        return <Typography.Text>{value.status}</Typography.Text>
      }
    },
    {
      title: 'Current stage',
      render(value: Application) {
        return <Typography.Text>{value.activeStage.stage}</Typography.Text>
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render(value: string, application: Application) {
        return (
          <ViewDetailsButton
            onClick={() =>
              history.push(`${URL_APPLICATION}/detail/${application?.client?.id}/${application.id}`)
            }
          />
        );
      },
    },
  ]
  return cols;
}

interface Props {
  search: string | undefined;
  defaultBranch: string;
}

const ApplicationTable: React.FC<Props> = ({ search, defaultBranch }) => {

  const { filter, initialLoading, form } = useContext(ApplicationContext);

  const branch = useMemo(() => {
    const branches = filter.branch?.replace('$in:', '').replace('$eq:', '').replace(':', '').split(',');


    if (branches?.length) {
      form.setFieldsValue({
        branchId: branches.at(0)
      })
      return branches.at(0);
    }

    form.setFieldsValue({
      branchId: defaultBranch
    })
    return defaultBranch;

  }, [filter.branch, defaultBranch, form]);

  const columns = useColumns();

  if (initialLoading) {
    return null;
  }
  return (
    <>
      <CrmTable
        loading={false}
        hideSearch
        columns={columns}
        action={fetchApplications}
        search={search}
        filter={{
          "assignees": filter.assignees?.length ? filter.assignees.join(',') : undefined,
          "institution.serviceId": filter?.service?.length ? '$eq:' + filter?.service : undefined,
          "processingBranch.id": filter?.branch?.length ? '$in:' + branch : '$in:' + branch,
          "workflow.id": filter?.workflow?.length ? '$in:' + filter?.workflow.join(',') : undefined,
          status: filter?.status?.length ? '$in:' + filter?.status.join(',') : undefined,
          workflowType: filter?.workflowType ? filter?.workflowType : 'All workflows',
          "activeStage.stage": filter?.stage?.length ? '$eq:' + filter?.stage : undefined,
          country: filter?.country?.length ? '$eq:' + filter?.country : undefined
        }}
      />
    </>
  )
}

export default ApplicationTable;

