import { fetchContactApplicationActivities } from "@crm/services.api"
import { CrmResponse } from "@model/api-response.model"
import { ContactActivity } from "@model/contact-activity"
import { useInfiniteQuery } from "@tanstack/react-query"

const activityTypes = [
  'application',
  'application-intake',
  'application-enrollment',
  'application-document',
  'application-comment',
  'application-fee',
  'application-sub-agent',
  'application-super-agent',
  'application-discount',
  'application-misc',
  'bulk-application-document',
];
const useContactApplicationActivities = (applicationId: string) => {

  const query = useInfiniteQuery<CrmResponse<ContactActivity>>({
    queryKey: ['application-log', applicationId],
    queryFn: async (args) => fetchContactApplicationActivities({
      page: args.pageParam ?? 1,
      'filter.activitiesTypeId': applicationId,
      'filter.activitiesType': '$in:' + activityTypes.join(','),
      limit: 10
    }),
    getNextPageParam: (lastPage) => lastPage.data.meta.currentPage === lastPage.data.meta.totalPages ? false : lastPage.data.meta.currentPage + 1,
  })
  return {
    activities: query.data?.pages.map(p => p.data.data).flat(),
    hasMore: Boolean(query.hasNextPage),
    next: query.fetchNextPage,
    currentPage: query.data?.pages.at(-1)?.data.meta.currentPage,
    isLoading: query.isLoading
  }
}

export default useContactApplicationActivities
