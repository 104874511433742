import React, { useEffect, useState } from 'react';

import Select from 'antd/es/select';
import Row from 'antd/es/row';
import Form, { FormInstance } from 'antd/es/form';
import Col from 'antd/es/col';
import InputNumber from 'antd/es/input-number';
import Checkbox from 'antd/es/checkbox';
import Divider from 'antd/es/divider';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import Space from 'antd/es/space';

import { FEE_TYPE_LIST, INSTALLMENT_TYPE, DELETE_BTN, GUTTER, LABEL, PLACEHOLDER } from '@moxie/constants';
import { ProductFeeFieldValues } from '@model/data.model';
import { preventMinus } from '@moxie/shared';
import { IProductFeeCrm } from '../../product.model';

interface Props {
    fieldKey: number;
    name: number;
    currency: string | undefined;
    form: FormInstance<ProductFeeFieldValues>;
    setNetTotalFee: (val: number) => void;
    removeItem: (index: number | number[]) => void;
    netTotalFee: number;
    fee: IProductFeeCrm | undefined;
    setDeleteFeeItems: React.Dispatch<React.SetStateAction<string[]>>
}

const totalFeeList: number[] = []

const ProductFeeItemForm: React.FC<Props> = ({
    fieldKey,
    name,
    currency,
    form,
    setNetTotalFee,
    removeItem,
    netTotalFee,
    fee,
    setDeleteFeeItems
}) => {
    let totalFee = 0;
    const [commissionChecked, setCommissionChecked] = useState(false);
    const [total, setTotal] = useState(0.0);

  useEffect(() => {
    if (fee && Object.keys(fee).length > 0) {
        fee?.feeItems?.map((item: any, index: number) => {
        if (fieldKey === index) {
          setCommissionChecked(item?.commissionChecked);
          setTotal(item?.totalFee);
        }
      });
    } else {
      form.getFieldValue('feeItems')[name].totalFee = Number(
        totalFee?.toFixed(3)
      );
    }
  }, [fee]);

    const handleChange = () => {
         totalFee =
            form.getFieldValue('feeItems')[fieldKey]?.installments *
            form.getFieldValue('feeItems')[fieldKey]?.installmentAmount;

        form.getFieldValue('feeItems')[fieldKey].totalFee = totalFee;

        if (totalFee !== 0) {
            totalFeeList[fieldKey] = totalFee;

            netTotalFee = totalFeeList.map((datum) => datum).reduce((a, b) => a + b);
        } else {
            totalFeeList[fieldKey] = totalFee;

            netTotalFee = totalFeeList.map((datum) => datum).reduce((a, b) => a + b);
        }
        setTotal(totalFee);

        setNetTotalFee(netTotalFee);
    };

    const handleCommission = (e: any) => {
        setCommissionChecked(e.target.checked);
        form.getFieldValue('feeItems')[fieldKey].commission = Number(0);
        form.getFieldValue('feeItems')[fieldKey].quotation = false;
        form.getFieldValue('feeItems')[fieldKey].terms = 1;
    };

    const handleRemove = (fieldKey: number) => {
        const deletedFeeItem = form.getFieldValue('feeItems')[fieldKey].id;
        if (totalFeeList.length > 1) {
            totalFeeList.splice(fieldKey, 1);
            netTotalFee = totalFeeList.map((datum) => datum).reduce((a, b) => a + b, 0);
            setNetTotalFee(netTotalFee);
        }
        removeItem(fieldKey);
        if(deletedFeeItem){
            setDeleteFeeItems(feeItems => [
                ...feeItems,
                deletedFeeItem
            ]);
        }
    };

    return (
        <div>
            <Row justify="space-between" gutter={GUTTER}>
                <Col span={8}>
                    <Form.Item
                        name={[name, 'feeType']}
                        fieldKey={[fieldKey, 'feeType']}
                        label={LABEL.FEE_TYPE}
                        rules={[{ required: true }]}
                        initialValue={FEE_TYPE_LIST[0].value}
                    >
                        <Select
                            placeholder={PLACEHOLDER.SELECT_FEE_TYPE}
                            optionLabelProp="label"
                            options={FEE_TYPE_LIST.map(fee => ({ value: fee.value, label: fee.name }))}
                        />
                    </Form.Item>
                </Col>
                <Col span={16}></Col>
            </Row>
            <Row justify="space-between" gutter={GUTTER}>
                <Col span={8}>
                    <Form.Item
                        name={[name, 'installmentType']}
                        fieldKey={[fieldKey, 'installmentType']}
                        label={LABEL.INSTALLMENT_TYPE}
                        rules={[{ required: true }]}
                        initialValue={INSTALLMENT_TYPE[0].value}
                    >
                        <Select
                            placeholder={PLACEHOLDER.SELECT_INSTALLMENT_TYPE}
                            options={INSTALLMENT_TYPE.map(ins => ({ value: ins.value, label: ins.name }))}
                        />

                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={[name, 'installmentAmount']}
                        fieldKey={[fieldKey, 'installmentAmount']}
                        label="Installment Amount"
                        initialValue={0}
                        rules={[{ required: true }]}
                    >
                        <InputNumber
                            onChange={handleChange}
                            type="number"
                            min={0}
                            onKeyPress={preventMinus}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={[name, 'installments']}
                        fieldKey={[fieldKey, 'installments']}
                        label="Installments"
                        initialValue={1}
                        rules={[{ required: true }]}
                    >
                        <InputNumber
                            max={100}
                            onChange={handleChange}
                            type="number"
                            onKeyPress={preventMinus}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="space-between" gutter={GUTTER}>
                <Col span={8}>
                    <Form.Item
                        name={[name, 'commissionChecked']}
                        fieldKey={[fieldKey, 'commissionChecked']}
                        valuePropName="checked"
                        initialValue={commissionChecked}
                    >
                        <Checkbox value={commissionChecked} onChange={handleCommission}>
                            {LABEL.IS_COMMISSIONABLE}
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name={[name, 'totalFee']}
                fieldKey={[fieldKey, 'totalFee']}
                initialValue={0}
                className="display-none"
            />
            <Row gutter={GUTTER}>
                {commissionChecked && (
                    <>
                        <Col span={8}>
                            <Form.Item
                                name={[name, 'terms']}
                                fieldKey={[fieldKey, 'terms']}
                                label="Claimable Terms"
                            >
                                <InputNumber min={1} type="number" onKeyPress={preventMinus} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={[name, 'commission']}
                                fieldKey={[fieldKey, 'commission']}
                                label={LABEL.FEE_COMMISSION}
                            >
                                <InputNumber type="number" />
                            </Form.Item>
                        </Col>
                    </>
                )}
            </Row>
            <Row justify="space-between" gutter={GUTTER}>
                <Col span={12}>
                    <p>
                        {LABEL.FEE_TOTAL} : {currency} {total ? total : 0.0}
                    </p>
                </Col>
                {name !== 0 && (
                    <Col span={12}>
                        <Space
                            className="custom-delete-fee"
                            size={8}
                            onClick={() => handleRemove(name)}
                        >
                            <DeleteOutlined />
                            <span>{DELETE_BTN}</span>
                        </Space>
                    </Col>
                )}
            </Row>
            <Divider className="margin-top-1" />
        </div>
    )
}

export default ProductFeeItemForm;
