import React, { Key, useState } from 'react';
import { Button, Col, Row, Tooltip, Typography } from 'antd';
import { useStepContext, useTransformedDataContext } from '../import-context';
import { ImportContactOptionalFields, ImportContactRequiredFields } from '@moxie/constants';
import { InfoCircleOutlined, ArrowLeftOutlined, RightCircleOutlined } from "@ant-design/icons";
import { ContactBranchSelect } from './branch-select';
import { useDispatch } from 'react-redux';
import { useAppSelector, userActions } from '@crm/core';
import { checkValidation } from './check-validation';
import { EditableTable } from './editable-table';
import { ContactData } from '@model/crm/contact.model';


interface IModifiedDataSource extends ContactData {
  errors?: (string | null)[];
}

const ImportContactValidation = [
  ...ImportContactRequiredFields,
  ...ImportContactOptionalFields
]

const filterColumnData = (value: string) => ImportContactValidation.find((validationData) => validationData.name === value);

const listToolTip = (colValue: string) => (
  <Tooltip title={(
    <div className="padding-left-2">
      <ul>{filterColumnData(colValue)?.validations.map((item, index) => <li key={index}>{item}</li>)} </ul>
    </div>
  )}
    placement="topRight">
    <InfoCircleOutlined />
  </Tooltip>
);
const ValidateImportedContact = ({ drawerClose }: { drawerClose: () => void; }) => {
  const dispatch = useDispatch();

  const { transformedData, updateTransformedData } = useTransformedDataContext();
  const { currentStep, updateStep } = useStepContext();
  const [dataSource, setDataSource] = useState<ContactData[]>(transformedData);
  const [showErrStat, setShowErrStat] = useState<boolean>(false);
  const [showBranchModal, setShowBranchModal] = useState<boolean>(false);
  const [selectedRowList, setSelectedRowList] = useState<Key[]>([]);

  const loggedUser = useAppSelector((state) => state.auth.user);

  const defaultColumns = [
    {
      title: <Row align="middle">
        <Col> <Typography.Text>First Name</Typography.Text> </Col>
        <Col className="margin-left-1">{listToolTip('First Name')}</Col>
      </Row>,
      dataIndex: 'firstName',
      width: '300px',
      editable: true,
      ellipsis: true,
    },
    {
      title: <Row align="middle">
        <Col> <Typography.Text>Last Name</Typography.Text> </Col>
        <Col className="margin-left-1">{listToolTip('Last Name')}</Col>
      </Row>,
      dataIndex: 'lastName',
      width: '250px',
      editable: true,
      ellipsis: true,
    },
    {
      title: <Row align="middle">
        <Col> <Typography.Text>Email</Typography.Text> </Col>
        <Col className="margin-left-1">{listToolTip('Email')}</Col>
      </Row>,
      dataIndex: 'email',
      width: '300px',
      editable: true,
      ellipsis: true,
    },
    {
      title: <Row align="middle">
        <Col> <Typography.Text>Country Code</Typography.Text> </Col>
        <Col className="margin-left-1">{listToolTip('Country Code')}</Col>
      </Row>,
      dataIndex: 'countryCode',
      width: '200px',
      editable: true,
    },
    {
      title: <Row align="middle">
        <Col> <Typography.Text>Phone</Typography.Text> </Col>
        <Col className="margin-left-1">{listToolTip('Phone')}</Col>
      </Row>,
      dataIndex: 'phone',
      width: '300px',
      editable: true,
      ellipsis: true,
    },
    {
      title: <Row align="middle">
        <Col> <Typography.Text>Nationality</Typography.Text> </Col>
        <Col className="margin-left-1">{listToolTip('Nationality')}</Col>
      </Row>,
      dataIndex: 'nationality',
      width: '250px',
      editable: true,
      ellipsis: true,
    },
    {
      title: <Row align="middle">
        <Col> <Typography.Text>Country Of Residence</Typography.Text> </Col>
        <Col className="margin-left-1">{listToolTip('Country Of Residence')}</Col>
      </Row>,
      dataIndex: 'country',
      width: '250px',
      editable: true,
      ellipsis: true,
    },
    {
      title: <Row align="middle">
        <Col> <Typography.Text>Date Of Birth</Typography.Text> </Col>
        <Col className="margin-left-1">{listToolTip('Date Of Birth')}</Col>
      </Row>,
      dataIndex: 'dateOfBirth',
      width: '200px',
      editable: true,
      ellipsis: true,
    },
    {
      title: <Row align="middle">
        <Col> <Typography.Text>City</Typography.Text> </Col>
        <Col className="margin-left-1">{listToolTip('City')}</Col>
      </Row>,
      dataIndex: 'city',
      width: '250px',
      editable: true,
      ellipsis: true,
    },
    {
      title: <Row align="middle">
        <Col> <Typography.Text>State</Typography.Text> </Col>
        <Col className="margin-left-1">{listToolTip('State')}</Col>
      </Row>,
      dataIndex: 'state',
      width: '250px',
      editable: true,
      ellipsis: true,
    },
    {
      title: <Row align="middle">
        <Col> <Typography.Text>Zip Code</Typography.Text> </Col>
        <Col className="margin-left-1">{listToolTip('Zip Code')}</Col>
      </Row>,
      dataIndex: 'zipCode',
      width: '250px',
      editable: true,
      ellipsis: true,
    },
    {
      title: <Row align="middle">
        <Col> <Typography.Text>Country Of Passport</Typography.Text> </Col>
        <Col className="margin-left-1">{listToolTip('Country Of Passport')}</Col>
      </Row>,
      dataIndex: 'countryOfPassport',
      width: '200px',
      editable: true,
    },
    {
      title: <Row align="middle">
        <Col> <Typography.Text>Visa Expiry Date</Typography.Text> </Col>
        <Col className="margin-left-1">{listToolTip('Visa Expiry Date')}</Col>
      </Row>,
      dataIndex: 'visaExpiryDate',
      width: '250px',
      editable: true,
    },
  ];

  const handleErrorListToggle = (checked: boolean) => {
    setShowErrStat(checked);
    setSelectedRowList([]);
  }

  const errorDataSource: IModifiedDataSource[] = React.useMemo(() => {
    if (showErrStat) {
      const data = dataSource.map(record => ({
        ...record,
        errors: defaultColumns.map((col) => {
          const dataIndex = col.dataIndex as keyof ContactData;
          if (col.editable && checkValidation(dataIndex, record)) {
            return dataIndex;
          }
          return null
        }).filter(Boolean)
      })).filter((row) => {
        return row.errors.length
      })
      return data;
    }
    return dataSource.map(record => ({
      ...record,
      errors: defaultColumns.map((col) => {
        const dataIndex = col.dataIndex as keyof ContactData;
        if (col.editable && checkValidation(dataIndex, record)) {
          return dataIndex;
        }
        return null
      }).filter(Boolean)
    }))
  }, [dataSource, showErrStat, selectedRowList])

  const handleChange = (row: ContactData) => {
    const updated = dataSource.map(item => (item.key) === row.key ? { ...item, ...row } : item);
    setDataSource(updated);
  }
  const handleFinish = () => {
    setShowErrStat(false);
    dispatch(userActions.fetchUser(loggedUser?.id as string));
    setShowBranchModal(true);
  }
  const handlePrevClick = () => {
    updateStep(currentStep - 1);
    updateTransformedData([]);
  }

  return (
    <>
      <div className="validate-table">
        <EditableTable
          columns={defaultColumns}
          errorDataSource={errorDataSource}
          onChange={handleChange}
          setDataSource={setDataSource}
          showErrStat={showErrStat}
          setShowErrStat={setShowErrStat}
          setSelectedRowList={setSelectedRowList}
          selectedRowList={selectedRowList}
          handleErrorListToggle={handleErrorListToggle}
        />

        <Row align="middle" justify="space-between" className="margin-top-2" style={{ maxHeight: '1vh' }}>
          <Button type="ghost" onClick={handlePrevClick}><ArrowLeftOutlined />Previous</Button>
          <Button type="primary" onClick={handleFinish} disabled={errorDataSource.filter(record => record.errors?.length).length > 0 || errorDataSource.length === 0}>Import<RightCircleOutlined /></Button>
        </Row>
      </div >
      <ContactBranchSelect show={showBranchModal} setShow={setShowBranchModal} data={dataSource} handleDrawerClose={drawerClose} />
    </>
  );
}

export { ValidateImportedContact };
