import React from 'react';
import { IInstitutionBranch } from '@shared-components/models';
import { Tag, Tooltip } from 'antd';

const InstitutionBranchTagGroup = ({
  data,
  numberOfBranch,
}: {
  data: IInstitutionBranch[];
  numberOfBranch: number;
}) => {
  const text = (
    <div>
      {data &&
        data
          .slice(numberOfBranch, data.length)
          .map((branch: IInstitutionBranch, index :number) => {
            return (
              <Tag
                className="margin-bottom-1 initial_capital"
                key={branch?.id ?? index}
                color="default"
              >
                {branch}
              </Tag>
            );
          })}
    </div>
  );
  return (
    <>
      {data &&
        data.slice(0, numberOfBranch).map((branch: IInstitutionBranch, index: number) => {
          return (
            <Tag key={branch?.id ?? index} color="default" className="initial_capital">
              {branch}
            </Tag>
          );
        })}

      {data && data.length > numberOfBranch && (
        <Tooltip placement="topLeft" color="white" title={text}>
          <Tag color="default">+{data?.length - numberOfBranch}</Tag>
        </Tooltip>
      )}
    </>
  );
};

InstitutionBranchTagGroup.defaultProps = {
  numberOfBranch: 3,
};

export { InstitutionBranchTagGroup };
