import React, { useEffect, useState } from 'react';
import ProLayout from '@ant-design/pro-layout';
import { NavLink, useLocation } from 'react-router-dom';
import { IDashboardLayoutProps } from '@shared-components/models';
import { AdminLogoIconLight } from '@shared-components/elements';
import { CRM_COOKIE_ACCESS, PROJECT_PORTAL } from '@moxie/constants';
import { useDispatch } from 'react-redux';
import { useAppSelector, themeActions, authActions } from '@crm/core';
import Cookies from 'js-cookie';
import DashboardHeader from './dashboard-header';
import { routes } from './routes';
import SearchResults from './elastic-search/search-results';
import { SearchProvider } from '@elastic/react-search-ui';
import ElasticSearchConnector from './elastic-search/elastic-search-connector';
import useCanBranchAccess from '@crm/libs/privilege/useCanBranchAccess';
import { BranchPermission } from '@model/permission.model';

const CrmDashboardLayout: React.FC<IDashboardLayoutProps> = (
  props: IDashboardLayoutProps
) => {
  const { children, className } = props;
  const dispatch = useDispatch();
  const collapsed = useAppSelector((store) => store.theme.navCollapsed);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const currentUser = useAppSelector(store => store.auth.user);
  const permission = useAppSelector(store => store.auth.user?.branchPermission);

  const handleNavbarToggle = (bool: boolean) => dispatch(themeActions.toggleNavbar(bool));
  useEffect(() => {
    if (!Cookies.get(CRM_COOKIE_ACCESS)) {
      return;
    }
    dispatch(authActions.isAuthenticated());
  }, [dispatch]);

  const hideBarsRoutes = ['enquiries', 'application'];

  useEffect(() => {
    if (hideBarsRoutes.some(path => location.pathname.includes(path))) {
      dispatch(themeActions.toggleNavbar(true))
    }
    else {
      dispatch(themeActions.toggleNavbar(false))
    }
    setOpen(false)
  }, [location, dispatch])

  return (
    <SearchProvider
      config={{
        apiConnector: new ElasticSearchConnector(currentUser, useCanBranchAccess(permission as BranchPermission)),
        debug: false,
        trackUrlState: false,
      }}
    >
      <ProLayout
        {...routes}
        {...props}
        siderWidth={200}
        className={`dashboard-layout crm-dashboard ${className}`}
        navTheme="dark"
        headerHeight={60}
        collapsed={collapsed}
        collapsedButtonRender={false}
        logo={() => <AdminLogoIconLight />}
        title={PROJECT_PORTAL.CRM}
        menuItemRender={(item, dom) => <NavLink to={`${item.path}`}>{dom}</NavLink>}
        fixSiderbar={true}
        fixedHeader={true}
        onCollapse={handleNavbarToggle}
        headerContentRender={() => (
          <DashboardHeader
            setCollapsed={handleNavbarToggle}
            collapsed={collapsed}
            setOpen={setOpen}
          />
        )}
      >
        <div style={{ ...(open && { display: 'none' }) }}>
          {children}
        </div>
        <SearchResults
          visible={open}
          closeModal={() => setOpen(false)}
        />
      </ProLayout>
    </SearchProvider >
  );
};

export { CrmDashboardLayout };
