import React, { useState } from 'react';
import Button from 'antd/es/button';

import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { ADD_BTN, CONFIRM_MESSAGE, DELETE_BTN, PRODUCT_FEE_RESPONSE_MESSAGE } from '@moxie/constants';
import ProductFees from './ProductFees';
import { useGetProductById } from '../../hooks/useGetProductById';
import { useParams } from 'react-router-dom';
import ProductFeeDrawer from './ProductFeeDrawer';
import { deleteProductFee } from '@crm/services.api';
import { useQueryClient } from '@tanstack/react-query';
import { popConfirm, successNotificationHandler } from '@moxie/shared';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';
import { IProductFeeCrm } from '../../product.model';


const ProductFeeTab = () => {
  const { productId }: { productId: string } = useParams();
  const { data: product } = useGetProductById(productId);
  const [showDrawer, setDrawer] = useState(false);
  const [fee, setFee] = useState<IProductFeeCrm | undefined>();
  const queryClient = useQueryClient();
  const canAccess = useCanAccess();

  const deleteFee = (id: string) => {
    popConfirm(DELETE_BTN, `${CONFIRM_MESSAGE.DELETE_PRODUCT_FEE}`, async () => {
      await deleteProductFee(id)
      queryClient.invalidateQueries({
        queryKey: ['product-fees', productId]
      })
      successNotificationHandler(PRODUCT_FEE_RESPONSE_MESSAGE.DELETE_SUCCESS);
    })
  }

  const editFee = (fee: IProductFeeCrm) => {
    setFee(fee);
    setDrawer(true);
  }

  const onDrawerClose = () => {
    setDrawer(false);
    setFee(undefined);
  }
  if (!product) {
    return null;
  }

  return (
    <div>
      <div className="slider_content">
        {canAccess('product-fee', 'create') && <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setDrawer(true)}
        >
          {ADD_BTN}
        </Button>}
      </div>
      <ProductFees
        onDelete={deleteFee}
        onEdit={editFee}
        currency={product.institution.currency}
      />
      <ProductFeeDrawer
        currency={product.institution.currency}
        onClose={onDrawerClose}
        showDrawer={showDrawer}
        fee={fee}
      />
    </div>
  )
}

export default ProductFeeTab;
