import Table, { TableProps } from 'antd/es/table';
import Typography from 'antd/es/typography';
import React from 'react';
import dealColumns from './columns/deal-columns';
import { Mapping } from '@model/crm/elastic-search';
import contactColumns from './columns/contact-columns';
import applicationColumns from './columns/application-columns';
import notesColumns from './columns/notes-columns';
import enquiriesColumns from './columns/enquiries-columns';
import routesMapper from '@crm/libs/routes-mapper';

// eslint-disable-next-line @typescript-eslint/ban-types
interface Props<T extends object> {
  title: string;
  icon?: JSX.Element;
  columns: TableProps<T>['columns'];
  dataSource: T[];
  redirectLink: (record: T) => string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
function ListTemplate<T extends object>({ title, icon, columns, dataSource, redirectLink }: Props<T>) {
  return (
    <div>
      <Typography.Title level={3}>
        {icon}
        {title}
      </Typography.Title>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: 5
        }}
        onRow={(record) => ({
          onClick() {
            const win = window.open(redirectLink(record), '_blank')
            win?.focus()
          }
        }
        )}
      />
    </div>
  )
}

interface Values<T extends keyof Mapping> {
  indexKey: T;
  results: Mapping[T];
}

function SearchResultsMapper<T extends keyof Mapping>({ indexKey, results }: Values<T>) {

  switch (indexKey) {
    case 'idx_deals':
      return <ListTemplate
        title="Deals"
        columns={dealColumns}
        dataSource={results as any}
        redirectLink={(record) => routesMapper.deal.detail({
          contactId: record.contact_id,
          dealId: record.id
        })}
      />

    case 'idx_contacts':
      return (
        <ListTemplate
          title="Contacts"
          columns={contactColumns}
          dataSource={results as any}
          redirectLink={(record) => routesMapper.contact.detail({
            contactId: record.id,
            tab: 'deals'
          })}
        />
      )

    case 'idx_applications':
      return (
        <ListTemplate
          title="Applications"
          columns={applicationColumns}
          dataSource={results as any}
          redirectLink={(record) => routesMapper.application.detail({
            applicationId: record.id,
            contactId: record.contact_id,
          })}
        />
      )
    case 'idx_enquiries':
      return (
        <ListTemplate
          title="Enquiries"
          columns={enquiriesColumns}
          dataSource={results as any}
          redirectLink={(record) => routesMapper.enquiry.detail({
            enquiryId: record.id
          })}
        />
      )
    case 'idx_notes':
      return (
        <ListTemplate
          title="Notes"
          columns={notesColumns}
          dataSource={results as any}
          redirectLink={(record) => routesMapper.notes.detail({
            contactId: record.contact_id,
            type: record.type,
            relatedId: record.related_id,
            officeVisitStatus: record?.office_visit_status,
            branchId: record.office_visit_branch,
          })}
        />
      )
    default:
      return null;
  }
}

export default SearchResultsMapper;
