import { useAppSelector } from '@crm/core';
import useContactParam from '@crm/libs/hooks/contacts/useContactParam';
import { updateApplication } from '@crm/libs/services.api/lib/application.api';
import { ApplicationDetail } from '@model/application';
import { errorNotificationHandler } from '@moxie/shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useApplicationFeeMutation = (application: ApplicationDetail) => {
  const queryClient = useQueryClient();
  const socket = useAppSelector(state => state.socket.wss);
  const user = useAppSelector(state => state.auth.user);
  const contactId = useContactParam();

  return useMutation({
    mutationFn: (productFeeAmount: number) =>
      updateApplication(application.id, { productFeeAmount }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['application-details', application.id],
      });

      socket?.emit('server::application-updated', {
        activitiesAction: 'updated',
        activitiesType: 'application-fee',
        data: {
          data: application
        },
        activitiesTypeId: application.id,
        userId: user?.id as string,
        companyId: user?.companyId as string,
        contactId: contactId,
      })
    },
    onError: () => {
      errorNotificationHandler('Error updating fees');
    },
  });
};
