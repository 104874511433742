import { EnquiriesPayload } from "@model/crm/elastic-search";
import { TableProps } from "antd/es/table";

const enquiriesColumns: TableProps<EnquiriesPayload>['columns'] = [
  {
    dataIndex: 'internal_id',
    title: 'ID'
  },
  {
    dataIndex: 'contact_name',
    title: 'Contact'
  },
  {
    dataIndex: 'email',
    title: 'Email'
  },
  {
    dataIndex: 'phone',
    title: 'phone'
  },
  {
    dataIndex: 'status',
    title: 'Status'
  }
];


export default enquiriesColumns
