import { useAppSelector } from "@crm/core";
import { BranchPermission, PermissionObjects, Permissions } from "@model/permission.model";
import intersection from 'lodash/intersection';


const useCanBranchAccess = (branchPermission?: BranchPermission) => {
  const userPermission = useAppSelector(store => store.auth.authUserPermission) ?? branchPermission;

  return <T extends PermissionObjects>(permissionObject: T, ...permissions: Permissions[T]): boolean => {
    if (permissions) {
      const entityPermissions = userPermission?.payload.permissions[permissionObject];
      if (!entityPermissions?.length) {
        return false;
      }

      return Boolean(intersection(entityPermissions, permissions).length);
    }
    return false;
  }
}

export default useCanBranchAccess;
