import { useQuery } from '@tanstack/react-query';
import { errorNotificationHandler } from '@moxie/shared';
import { crmAxios } from '@crm/services.api';
import { ApplicationDetail } from '@model/application';
import { AxiosError } from 'axios';
import { crmErrorHandler } from 'libs/shared/src/crm-modules/shared';

export const useApplicationDetailsBycontactId = (contactId: string) => {
  const { data, isLoading: isLoadingApplication, refetch } = useQuery({
    enabled: !!contactId.length,
    queryKey: ['application-details', contactId],
    queryFn: async () => {
      return (await crmAxios.get<{ data: ApplicationDetail[] }>(`/v1/applications/contact/${contactId}`)).data.data
    },

    onError: (error: AxiosError<{ message: string }> | unknown) => {
      if (error instanceof AxiosError) {
        crmErrorHandler(error);
        return;
      }
      errorNotificationHandler('Error fetching details.');
    },
  });

  return { applicationData: data, isLoadingApplication, refetch };
};
