import React, { useEffect, useState } from 'react';
import {
  ICrmProfessionalExperience,
  IGetPopupContainer,
} from '@shared-components/models';
import { Checkbox, Col, Collapse, DatePicker, Form, Input, Row } from 'antd';
import {
  COUNTRIES,
  COUNTRY_LABEL,
  COUNTRY_PLACEHOLDER,
  EMPLOYMENT_TYPE,
  GUTTER,
  INDUSTRY,
  LABEL,
  PHONE_NUMBER_INVALID,
  PLACEHOLDER,
  TEXT,
  regex,
} from '@moxie/constants';
import {
  FormItem,
  SearchableSelect,
  Option,
} from '@shared-components/elements';
import { CaretRightOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { contactActions, useAppSelector } from '@crm/core';
import { useDispatch } from 'react-redux';
import { RootState } from 'apps/crm/src/core/store';

const { Panel } = Collapse;

function getFirstStrFromCamelCase(str: string) {
  const matches = str.match(/^[a-z]+/);
  return matches ? matches[0] : '';
}

const ProfessionalExperienceForm = ({
  form,
  setDisabled,
  onClose,
  initialData,
}: {
  form: any;
  setDisabled: any;
  onClose: any;
  initialData: any;
}) => {
  const [isStillWorking, setIsStillWorking] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState(['organization']);
  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  const contactData = useAppSelector(
    (state: RootState) => state?.contact?.singleData
  );

  const handleSubmit = (data: ICrmProfessionalExperience) => {
    data.contactId = contactData?.id;
    if (data.startDate)
      data.startDate = moment(data.startDate).format('YYYY-MM-DD').toString();
    if (data.startAndEndDate) {
      data.startDate = moment(data.startAndEndDate[0])
        .format('YYYY-MM-DD')
        .toString();
      data.endDate = moment(data.startAndEndDate[1])
        .format('YYYY-MM-DD')
        .toString();
    }
    if (isStillWorking) {
      data.endDate = null;
    }
    data.isStillWorking = isStillWorking;
    delete data['startAndEndDate'];
    if (initialData?.id) {
      data.id = initialData.id;
      if (data.country === undefined) {
        data.country = null;
      }
      dispatch(
        contactActions.updateContactProfessionalExperienceRequest(
          data,
          (response) => {
            socket?.emit('server::profile-updated', {
              activitiesTypeId: contactData?.id,
              activitiesType: 'professional-experience',
              activitiesAction: 'updated',
              userId: user?.id,
              contactId: contactData?.id,
              companyId: user?.companyId as string,
              data: response,
            });
          }
        )
      );
    } else {
      dispatch(
        contactActions.addContactProfessionalExperienceRequest(
          data,
          (response) => {
            socket?.emit('server::profile-updated', {
              activitiesTypeId: contactData?.id,
              activitiesType: 'professional-experience',
              activitiesAction: 'created',
              userId: user?.id,
              contactId: contactData?.id,
              companyId: user?.companyId as string,
              data: response,
            });
          }
        )
      );
    }
    onClose();
  };

  const handleFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  const handleOptionFilter = (input: string, option: any): boolean =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handleStillWorking = () => {
    form.resetFields(['startDate', 'startAndEndDate']);
    setIsStillWorking(!isStillWorking);
  };

  const handleDateDisable = (currentDate: any) => {
    const customDate = moment().format('YYYY-MM-DD');
    return currentDate && currentDate >= moment(customDate, 'YYYY-MM-DD');
  };

  const changeDateToMoment = (
    date?: string | Moment | null
  ): Moment | undefined => {
    if (date) return moment(date);
    return;
  };

  const handleSubmitFormFailed = (errors: any) => {
    setDisabled(true);
    const errorFields = errors.errorFields;
    const errorFieldNames = errorFields.map((el: any) => el.name[0]);
    errorFieldNames.map((item: any) => {
      const panelKey = getFirstStrFromCamelCase(item);
      setActiveKey([panelKey]);
      if (item === 'startAndEndDate' || item === 'startDate') {
        setActiveKey(['organization']);
      }
    });
  };

  useEffect(() => {
    if (initialData && Object.keys(initialData).length > 0) {
      initialData.startDate = changeDateToMoment(initialData.startDate);
      initialData.endDate = changeDateToMoment(initialData.endDate);
      initialData.startAndEndDate = [
        initialData.startDate,
        initialData.endDate,
      ];
      const initialValue = { ...initialData };
      form.setFieldsValue(initialValue);
    } else form.resetFields();
  }, []);

  useEffect(() => {
    if (initialData?.isStillWorking) {
      setIsStillWorking(initialData.isStillWorking);
    }
  }, []);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      onFieldsChange={handleFieldsChange}
      onFinishFailed={handleSubmitFormFailed}
    >
      <div>
        <div className="form_section_header">{TEXT.GENERAL_DETAILS}</div>
        <Row gutter={GUTTER}>
          <Col span="24">
            <Form.Item
              name="jobTitle"
              label={LABEL.JOB_TITLE}
              rules={[{ required: true, max: 50, type: 'string', whitespace: true }]}
            >
              <Input
                placeholder={PLACEHOLDER.JOB_TITLE}
                data-testid="jobtitle"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={GUTTER}>
          <Col span="12">
            <div id="professional_experience_type" className="relative">
              <FormItem
                name="jobType"
                label={LABEL.EMPLOYMENT_TYPE}
                rules={[{ required: true }]}
              >
                <SearchableSelect
                  placeholder={PLACEHOLDER.EMPLOYMENT_TYPE}
                  filterOption={handleOptionFilter}
                  getPopupContainer={(): IGetPopupContainer =>
                    document.getElementById('professional_experience_type')
                  }
                  data-testid="employmenttype"
                >
                  {EMPLOYMENT_TYPE.map((employment) => {
                    return (
                      <Option value={employment.value} key={employment.value}>
                        {employment.name}
                      </Option>
                    );
                  })}
                </SearchableSelect>
              </FormItem>
            </div>
          </Col>
          <Col span="12">
            <div id="professional_industry_type" className="relative">
              <FormItem name="industry" label={LABEL.INDUSTRY}>
                <SearchableSelect
                  placeholder={PLACEHOLDER.INDUSTRY}
                  filterOption={handleOptionFilter}
                  getPopupContainer={(): IGetPopupContainer =>
                    document.getElementById('professional_industry_type')
                  }
                  data-testid="industry"
                >
                  {INDUSTRY.map((industry) => {
                    return (
                      <Option value={industry.value} key={industry.id}>
                        {industry.name}
                      </Option>
                    );
                  })}
                </SearchableSelect>
              </FormItem>
            </div>
          </Col>
        </Row>
      </div>
      <Collapse
        activeKey={activeKey}
        bordered={false}
        ghost={true}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        onChange={(newActiveKey: any) => setActiveKey(newActiveKey)}
      >
        <Panel
          header={<span>{TEXT.ORGANIZATION_DETAILS}</span>}
          key="organization"
          forceRender={true}
        >
          <FormItem
            name="organizationName"
            label={LABEL.ORGANIZATION_NAME}
            rules={[{ required: true, type: 'string', max: 50, whitespace: true }]}
          >
            <Input
              placeholder={PLACEHOLDER.ORGANIZATION_NAME}
              data-testid="organizationname"
            />
          </FormItem>
          <Row gutter={GUTTER}>
            <Col span={12}>
              <div id="professional_country_list" className="relative">
                <FormItem name="country" label={COUNTRY_LABEL}>
                  <SearchableSelect
                    placeholder={COUNTRY_PLACEHOLDER}
                    filterOption={handleOptionFilter}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('professional_country_list')
                    }
                    data-testid="country"
                  >
                    {COUNTRIES.map((country) => {
                      return (
                        <Option value={country.name} key={country.code}>
                          {country.name}
                        </Option>
                      );
                    })}
                  </SearchableSelect>
                </FormItem>
              </div>
            </Col>
            <Col span={12}>
              <FormItem name="city" label={LABEL.CITY}>
                <Input placeholder={PLACEHOLDER.CITY} data-testid="city" />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={24}>
              <FormItem name="isStillWorking" valuePropName="checked">
                <Checkbox
                  onChange={handleStillWorking}
                  data-testid="stillworking"
                >
                  {LABEL.STILL_WORKING}
                </Checkbox>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            {isStillWorking !== true && (
              <Col span="12">
                <div id="professionalEndDate" className="relative">
                  <Form.Item
                    name="startAndEndDate"
                    label={LABEL.SELECT_JOB_PERIOD}
                    rules={[{ required: true }]}
                  >
                    <DatePicker.RangePicker
                      className="full-width"
                      picker="month"
                      format="MM/YYYY"
                      disabledDate={handleDateDisable}
                      getPopupContainer={(): IGetPopupContainer =>
                        document.getElementById('professionalEndDate')
                      }
                      data-testid="selectjobperiod"
                    />
                  </Form.Item>
                </div>
              </Col>
            )}
            {isStillWorking && (
              <Col span="12">
                <div id="professional_start_date" className="relative">
                  <Form.Item
                    name="startDate"
                    label={LABEL.START_DATE}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      disabledDate={handleDateDisable}
                      picker="month"
                      className="full-width"
                      format="MM/YYYY"
                      getPopupContainer={(): IGetPopupContainer =>
                        document.getElementById('professional_start_date')
                      }
                      data-testid="startdate"
                    />
                  </Form.Item>
                </div>
              </Col>
            )}
          </Row>
        </Panel>
        <Panel
          header={<span>{TEXT.REFERENCE_DETAILS} </span>}
          key="reference"
          forceRender={true}
        >
          <Row gutter={GUTTER} data-testid="reference-details">
            <Col span={12}>
              <Form.Item
                name="referenceName"
                label={LABEL.REFERENCE_NAME}
                rules={[{ required: true, whitespace: true }]}
              >
                <Input
                  placeholder={PLACEHOLDER.REFERENCE_NAME}
                  data-testid="referencename"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="referencePosition"
                label={LABEL.REFERENCE_POSITION}
                rules={[{ required: true, whitespace: true }]}
              >
                <Input
                  placeholder={PLACEHOLDER.REFERENCE_POSITION}
                  data-testid="referencepostition"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={12}>
              <Form.Item
                name="referencePhone"
                label={LABEL.REFERENCE_PHONE}
                rules={[
                  {
                    pattern: new RegExp(regex.ONLY_NUMBER),
                    message: PHONE_NUMBER_INVALID,
                  },
                  {
                    max: 20,
                  },
                ]}
              >
                <Input
                  placeholder={PLACEHOLDER.REFERENCE_PHONE}
                  data-testid="referencephoneno"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="referenceEmail"
                label={LABEL.REFERENCE_EMAIL}
                rules={[{ type: 'email' }]}
              >
                <Input
                  placeholder={PLACEHOLDER.REFERENCE_EMAIL}
                  data-testid="referenceemail"
                />
              </Form.Item>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Form>
  );
};
export { ProfessionalExperienceForm };
