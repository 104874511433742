import React, { useEffect } from 'react';
import { GUTTER, LABEL } from '@moxie/constants';
import { Col, Form, InputNumber, Row, Typography } from 'antd';
import { useIeltsMutation, useIeltsUpdate } from '@crm/libs/hooks/contacts';
import { useParams } from 'react-router-dom';

const calculateOverallScore = (scores: Record<string, number>) => {
  const sum = Object.values(scores).reduce((acc, score) => acc + score, 0);
  return sum / Object.keys(scores).length;
};

const { Text } = Typography;

const IELTSForm = ({
  form,
  setDisabled,
  handleClose,
  ieltsData,
  editMode,
}: {
  form: any;
  setDisabled: any;
  handleClose: any;
  ieltsData?: any;
  editMode?: boolean;
}) => {
  const { id }: { id: string } = useParams();
  const ieltsMutation = useIeltsMutation();
  const ieltsUpdate = useIeltsUpdate();
  const handleSubmit = async (data: any) => {
    let mutationData: any;
    let testTypeData: any;
    const overallScore = calculateOverallScore(data);
    if (ieltsData) {
      testTypeData = Object.entries(data).map(([name, score]) => ({
        id: ieltsData[name].id,
        name: name,
        score: score,
      }));

      mutationData = {
        id: ieltsData?.id,
        parentId: ieltsData?.parentId,
        tests: [
          {
            id: ieltsData?.testId,
            name: ieltsData?.testName,
            testScoreId: ieltsData?.testScoreId,
            testTypes: testTypeData,
            overallScore,
          },
        ],
      };
      await ieltsUpdate.mutateAsync(mutationData);
    } else {
      testTypeData = Object.entries(data).map(([name, score]) => ({
        name: name,
        score: score,
      }));

      mutationData = {
        parentId: id,
        tests: [
          {
            name: 'ielts',
            testTypes: testTypeData,
            overallScore,
          },
        ],
      };
      await ieltsMutation.mutateAsync(mutationData);
    }

    handleClose();
  };

  const handleFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  useEffect(() => {
    const initialData = {
      reading: Number(ieltsData?.reading?.score),
      speaking: Number(ieltsData?.speaking?.score),
      writing: Number(ieltsData?.writing?.score),
      listening: Number(ieltsData?.listening?.score),
    };
    form.setFieldsValue(initialData);
  }, [editMode === true]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      onFieldsChange={handleFieldsChange}
    >
      <Row gutter={GUTTER} align="top">
        <Col span={3} className="align-vertically-centered">
          <Text className="align-score-title" strong>
            {LABEL.IELTS}
          </Text>
        </Col>

        <Col span={5}>
          <Form.Item
            name="reading"
            label={LABEL.READING}
            rules={[{ type: 'number', min: 1, max: 9 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="reading" />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Form.Item
            name="listening"
            label={LABEL.LISTENING}
            rules={[{ type: 'number', min: 1, max: 9 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="listening" />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Form.Item
            name="writing"
            label={LABEL.WRITING}
            rules={[{ type: 'number', min: 1, max: 9 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="writing" />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Form.Item
            name="speaking"
            label={LABEL.SPEAKING}
            rules={[{ type: 'number', min: 1, max: 9 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="speaking" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export { IELTSForm };
