import { Button, Col, Empty, Row, Spin, Typography } from 'antd';
import { useGetDocumentsByCategory } from 'apps/crm/src/pages/contact-details/sections/details/components/documents/hooks/useGetDocument';
import React, { useContext, useEffect, useState } from 'react';
import { useChecklistDocs } from '../../../../hooks/useChecklistDocs';
import Checklist from './Checklist';
import DocumentDetailsTable from './DocumentDetailsTable';
import { ContactDocumentDrawer } from './documents-drawer/ContactDocumentDrawer';
import { ContactExistingDocumentDrawer } from './documents-drawer/ContactExistingDocumentDrawer';
import { popConfirm } from '@moxie/shared';
import { CONFIRM_MESSAGE, CONFIRM_TITLE } from '@moxie/constants';
import {
  LoadingOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import ApplicationDetailContext from '../../../../application-detail-context';
import type { Checklist as ChecklistModel } from '@model/workflow';
import useDocumentCount from '../../hooks/use-document-count';
import { downloadAllStageDocs, downloadFile } from '@crm/services.api';
import { leadActions, useAppSelector } from '@crm/core';
import { useDispatch } from 'react-redux';
const { Title, Text } = Typography;

const Documents = () => {
  const { applicationData: application, selectedStage, stageDocs: docs } = useContext(ApplicationDetailContext);
  const contactId = application.contactId
  const applicationId = application.id;
  const mediaTypes = docs.map(d => d.checklist.name);
  const { docsCountMap } = useDocumentCount({
    mediaTypes,
    subjectId: application.id,
    isDeletedFromApplication: false,
    stageId: selectedStage.wfAppStageId ?? undefined,
  });
  const downloading = useAppSelector(store => store.leads.downloading);
  const dispatch = useDispatch();

  const stageHasDocs = docs?.length > 0;
  const stageHasUploadedDocs = Array.from(docsCountMap?.values() ?? [])?.some((d) => d > 0);
  const [selectedChecklist, setSelectedChecklist] = useState<ChecklistModel | undefined>();
  const [docDrawerVisible, setDocDrawerVisible] = useState(false);
  const [existingDocDrawerVisible, setExistingDocDrawerVisible] = useState(false);
  const [dlAllDocs, setDlAllDocs] = useState(false);

  const openDocDrawer = () => setDocDrawerVisible(true);
  const closeDocDrawer = () => setDocDrawerVisible(false);

  const openExistingDocDrawer = () => setExistingDocDrawerVisible(true);
  const closeExistingDocDrawer = () => setExistingDocDrawerVisible(false);

  useEffect(() => {
    const hasChecklist = docs?.some((d) => {
      return d?.checklist?.id === selectedChecklist?.id;
    });
    if (!hasChecklist) {
      setSelectedChecklist(docs[0]?.checklist);
    }
  }, [docs]);

  const { checklistDocs, isLoadingDocs } = useChecklistDocs(applicationId, selectedChecklist?.name, selectedStage.wfAppStageId as string);

  const {
    documents: documentsByCategory,
    isLoading: isLoadingExistingDocs,
  } = useGetDocumentsByCategory(contactId, selectedChecklist?.name, true);

  const handleDownloadAll = () => {
    popConfirm(
      CONFIRM_TITLE.DOWNLOAD_DOCUMENTS,
      `${CONFIRM_MESSAGE.DOWNLOAD_DOCUMENTS}`,
      async () => {
        if (selectedStage.wfAppStageId) {
          dispatch(leadActions.downloading(true))
          setDlAllDocs(true);
          const url = await downloadAllStageDocs({ stageId: selectedStage.wfAppStageId, subjectId: application.id });
          dispatch(leadActions.downloading(false))
          setDlAllDocs(false);
          downloadFile(url);
        }
      }
    );
  };

  return (
    <>
      <Spin spinning={downloading}>
        <div className="crm-application-document-container">
          <Row
            justify="space-between"
            align="top"
            style={{ display: stageHasDocs ? 'block' : 'none' }}
          >
            <Col span={8}>
              <Row justify="space-between">
                <Title level={4}>Document Checklist</Title>
                {stageHasUploadedDocs && (
                  <Button
                    icon={
                      dlAllDocs ? (
                        <LoadingOutlined />
                      ) : (
                        <VerticalAlignBottomOutlined />
                      )
                    }
                    onClick={handleDownloadAll}
                  >
                    {dlAllDocs ? 'Preparing Documents ' : 'Download All'}
                  </Button>
                )}
              </Row>
              <Text
                type="secondary"
                style={{ marginTop: '0.3rem', display: 'block' }}
              >
                The changes made to documents in the checklist will only affect
                the current application.
              </Text>
            </Col>
          </Row>
          <Row className="doc-check-list-height">
            {stageHasDocs ? (
              <>
                <Col span={8}>
                  <Spin spinning={selectedStage && Object.values(selectedStage)?.length === 0}>
                    <Checklist
                      applicationId={applicationId}
                      setSelectedChecklist={setSelectedChecklist}
                      selectedChecklist={selectedChecklist}
                      selectedStage={selectedStage}
                      docs={docs}
                      openDocDrawer={openDocDrawer}
                      openExistingDocDrawer={openExistingDocDrawer}
                      docsCountMap={docsCountMap}
                      docsIds={checklistDocs.map(doc => doc.id)}
                    />
                  </Spin>
                </Col>
                <Col span={16}>
                  <DocumentDetailsTable
                    checklistDocs={checklistDocs}
                    isLoadingDocs={isLoadingDocs}
                    selectedStage={selectedStage}
                    selectedChecklist={selectedChecklist}
                    applicationId={applicationId}
                  />
                </Col>
              </>
            ) : (
              <Col>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No documents are required at this stage."
                />
              </Col>
            )}
          </Row>
        </div>
        <ContactDocumentDrawer
          isOpen={docDrawerVisible}
          onClose={closeDocDrawer}
          selectedChecklist={selectedChecklist}
          selectedStage={selectedStage}
          applicationId={applicationId}
        />
        <ContactExistingDocumentDrawer
          contactDocs={documentsByCategory}
          isLoadingDocs={isLoadingExistingDocs}
          applicationChecklistDocs={checklistDocs}
          isOpen={existingDocDrawerVisible}
          onClose={closeExistingDocDrawer}
          selectedChecklist={selectedChecklist}
          contactId={contactId}
        />
      </Spin>
    </>
  );
};

export default Documents;
