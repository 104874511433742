import React from 'react';
import { PageHeaderTitle } from '@shared-components/elements';
import { PageHeaderMenu } from './sections/header-menu';
import AgentTableList from './sections/table-list';
import { TEXT } from '@moxie/constants';

export const AGENTS_MENU = [
  {
    name: 'Sub Agents',
    id: 'sub_agent',
    url: 'agents/sub_agent',
  },
  {
    name: 'Super Agents',
    id: 'super_agent',
    url: 'agents/super_agent',
  },
];

const Agents = () => {
  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.AGENTS_HEADER} />
      <PageHeaderMenu menu={AGENTS_MENU} />
      <AgentTableList />
    </div>
  );
};

export default Agents;
