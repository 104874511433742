import React, { SetStateAction, useEffect, useState } from 'react';
import {
  COUNTRIES,
  DELETE_BTN,
  GUTTER,
  LABEL,
  NOT_AVAILABLE_MESSAGE,
  TEXT,
  VALIDATION_MESSAGE,
  regex,
  ALPHANUMERIC_REQUIRED,
} from '@moxie/constants';
import { SearchableSelect, Option } from '@shared-components/elements';
import { ICrmEmergencyContact, IGetPopupContainer } from '@shared-components/models';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { contactActions, useAppSelector } from '@crm/core';
import { RootState } from 'apps/crm/src/core/store';
import { PhoneWithCountryCodeFormItem } from "@crm/src/shared/phone-input-with-country-code";
import { FormInstance, RuleObject } from 'antd/es/form';

interface IProps {
  contactDetails: ICrmEmergencyContact[];
  setDisabled: React.Dispatch<SetStateAction<boolean>>;
  handleClose: () => void;
  form: FormInstance;
}

const EmergencyContactDetailsForm: React.FC<any> = (props: IProps) => {
  const { contactDetails, setDisabled, handleClose, form } = props;
  const [isEdit, setIsEdit] = useState<boolean>(true);

  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const contactId = useAppSelector(
    (state: RootState) => state?.contact?.singleData?.id
  );


  const validatePhoneNumber = (_: RuleObject, value: string) => {
    const cleanedVal = value?.replace(/[()\s-]/g, '');
    if (value && !regex.LEAD_PHONE_NUMBER.test(cleanedVal)) {
      setDisabled(true)
      return Promise.reject('Please enter a valid phone number');
    }
    return Promise.resolve();
  };


  const handleSubmit = (data: Omit<ICrmEmergencyContact, "id">[]) => {
    if (data) {
      dispatch(
        contactActions.updateContactEmergencyDetailsRequest(
          {
            ...data,
            id: contactId,
          },
          (response) => {
            socket?.emit('server::profile-updated', {
              activitiesTypeId: contactId,
              activitiesType: 'emergency-contact',
              userId: user?.id,
              companyId: user?.companyId as string,
              activitiesAction: 'updated',
              contactId: contactId,
              data: response,
            });
          }
        )
      );
      setIsEdit(false);
      handleClose();
    }
  };
  const handleFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const fields of allFields) {
        fields.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };
  useEffect(() => {
    if (contactDetails.length > 0) {
      form.setFieldsValue({ emergencyContact: contactDetails });
    } else {
      form.resetFields();
    }
  }, [contactDetails, form]);

  return (
    <Form
      onFinish={handleSubmit}
      initialValues={{
        emergencyContact: [{}],
      }}
      form={form}
      scrollToFirstError={true}
      onFieldsChange={handleFieldsChange}
      labelCol={{ span: 8 }}
    >
      <Form.List name="emergencyContact">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name }) => {
                return (
                  <Card key={key} className="margin-bottom-1">
                    <Row gutter={GUTTER}>
                      <Col span={12}>
                        <Form.Item
                          name={[name, 'contactName']}
                          className="custom_label_style"
                          label={LABEL.NAME}
                          rules={[{ required: true, whitespace: true }]}
                        >
                          {contactDetails.length === 0 || isEdit ? (
                            <Input
                              className="note-title-input"
                              disabled={!isEdit}
                              data-testid="crm-emergencycontactdetails-name"
                            />
                          ) : contactDetails[name]?.contactName ? (
                            <div className="initial_capital">
                              {contactDetails[name]?.contactName}
                            </div>
                          ) : (
                            NOT_AVAILABLE_MESSAGE
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[name, 'relationship']}
                          className="custom_label_style"
                          label={LABEL.RELATIONSHIP}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                            },
                            {
                              pattern: new RegExp(regex.ONLY_STRING),
                              message: VALIDATION_MESSAGE.INVALID_TEXT_INPUT,
                            },
                          ]}
                        >
                          {contactDetails.length === 0 || isEdit ? (
                            <Input
                              className="note-title-input"
                              disabled={!isEdit}
                              data-testid="crm-emergencycontactdetails-relationship"
                            />
                          ) : contactDetails[name]?.relationship ? (
                            <div className="initial_capital">
                              {contactDetails[name]?.relationship}
                            </div>
                          ) : (
                            NOT_AVAILABLE_MESSAGE
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[name, 'email']}
                          className="custom_label_style"
                          label={LABEL.EMAIL}
                          rules={[
                            {
                              pattern: new RegExp(regex.EMAIL),
                              message: VALIDATION_MESSAGE.INVALID_EMAIL,
                            },
                          ]}
                        >
                          {contactDetails.length === 0 || isEdit ? (
                            <Input
                              className="note-title-input"
                              disabled={!isEdit}
                              data-testid="crm-emergencycontactdetails-email"
                            />
                          ) : contactDetails[name]?.email ? (
                            contactDetails[name]?.email?.toLowerCase()
                          ) : (
                            NOT_AVAILABLE_MESSAGE
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <PhoneWithCountryCodeFormItem
                          labelClassName='custom_label_style margin-bottom-1'
                          requiredMark={true}
                          countryFormItemProps={{
                            name: [name, 'countryCode'],
                            initialValue: "+61"
                          }}
                          phoneFormItemProps={{
                            name: [name, 'phone'],
                            rules: [
                              { required: true, whitespace: true, message: "Please enter phone number" },
                              {
                                validator: validatePhoneNumber,

                              }]
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <div id="country_list" className="relative">
                          <Form.Item
                            name={[name, 'address', 'country']}
                            className="custom_label_style"
                            label={LABEL.COUNTRY}
                            rules={[{ required: true }]}
                          >
                            {contactDetails.length === 0 || isEdit ? (
                              <SearchableSelect
                                className="note-title-input"
                                disabled={!isEdit}
                                optionFilter="search_prop"
                                getPopupContainer={(): IGetPopupContainer =>
                                  document.getElementById('country_list')
                                }
                                data-testid="crm-emergencycontactdetails-country"
                              >
                                {COUNTRIES.map((item) => {
                                  return (
                                    <Option
                                      value={item.name}
                                      key={item.code}
                                      search_prop={`${item.name} ${item.code}`}
                                    >
                                      {item.name}
                                    </Option>
                                  );
                                })}
                              </SearchableSelect>
                            ) : contactDetails[name]?.address?.country ? (
                              contactDetails[name]?.address?.country
                            ) : (
                              NOT_AVAILABLE_MESSAGE
                            )}
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[name, 'address', 'state']}
                          className="custom_label_style"
                          label={LABEL.STATE}
                          rules={[
                            {
                              pattern: new RegExp(regex.ALPHA_NUMERIC_WITH_SPACE),
                              message: ALPHANUMERIC_REQUIRED,
                            },
                          ]}
                        >
                          {contactDetails.length === 0 || isEdit ? (
                            <Input
                              className="note-title-input"
                              disabled={!isEdit}
                              data-testid="crm-emergencycontactdetails-state"
                            />
                          ) : contactDetails[name]?.address?.state ? (
                            <div className="initial_capital">
                              {contactDetails[name]?.address?.state}
                            </div>
                          ) : (
                            NOT_AVAILABLE_MESSAGE
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[name, 'address', 'city']}
                          className="custom_label_style"
                          label={LABEL.CITY}
                          rules={[
                            {
                              pattern: new RegExp(regex.ONLY_STRING),
                              message: VALIDATION_MESSAGE.INVALID_TEXT_INPUT,
                            },
                          ]}
                        >
                          {contactDetails.length === 0 || isEdit ? (
                            <Input
                              className="note-title-input"
                              disabled={!isEdit}
                              data-testid="crm-emergencycontactdetails-city"
                            />
                          ) : contactDetails[name]?.address?.city ? (
                            <div className="initial_capital">
                              {contactDetails[name]?.address?.city}
                            </div>
                          ) : (
                            NOT_AVAILABLE_MESSAGE
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[name, 'address', 'street']}
                          className="custom_label_style"
                          label={LABEL.STREET}
                          rules={[
                            {
                              pattern: new RegExp(regex.ALPHA_NUMERIC_WITH_SPACE),
                              message: VALIDATION_MESSAGE.INVALID_TEXT_INPUT,
                            },
                          ]}
                        >
                          {contactDetails.length === 0 || isEdit ? (
                            <Input
                              className="note-title-input"
                              disabled={!isEdit}
                              data-testid="crm-emergencycontactdetails-street"
                            />
                          ) : contactDetails[name]?.address?.street ? (
                            <div className="initial_capital">
                              {contactDetails[name]?.address?.street}
                            </div>
                          ) : (
                            NOT_AVAILABLE_MESSAGE
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={[name, 'address', 'postalCode']}
                          className="custom_label_style"
                          label={LABEL.POSTAL_CODE}
                          rules={[
                            {
                              pattern: new RegExp(regex.ALPHA_NUMERIC),
                              message: VALIDATION_MESSAGE.INVALID_POSTAL_CODE,
                            },
                          ]}
                        >
                          {contactDetails.length === 0 || isEdit ? (
                            <Input
                              className="note-title-input"
                              disabled={!isEdit}
                              data-testid="crm-emergencycontactdetails-postalcode"
                            />
                          ) : contactDetails[name]?.address?.postalCode ? (
                            <div className="initial_capital">
                              {contactDetails[name]?.address?.postalCode}
                            </div>
                          ) : (
                            NOT_AVAILABLE_MESSAGE
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    {fields.length > 1 ? (
                      <Row justify="end">
                        <Col>
                          <Button
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              setIsEdit(true);
                              remove(name);
                            }}
                            data-testid="crm-emergencycontactdetails-delete"
                          >
                            {DELETE_BTN}
                          </Button>
                        </Col>
                      </Row>
                    ) : null}
                  </Card>
                );
              })}
              <Row justify="end">
                <Form.Item>
                  <Button
                    type="link"
                    onClick={() => {
                      add();
                      setIsEdit(true);
                    }}
                    icon={<PlusOutlined />}
                    data-testid="crm-emergencycontactdetails-addanothercontact"
                  >
                    {TEXT.ADD_ANOTHER_CONTACT}
                  </Button>
                </Form.Item>
              </Row>
            </>
          );
        }}
      </Form.List>
    </Form>
  );
};
export { EmergencyContactDetailsForm };
