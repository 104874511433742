import React, { useMemo, useState } from 'react';
import { TableProps } from 'antd';
import Drawer from '../drawer';
import Avatar from 'antd/lib/avatar/avatar';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Typography from 'antd/es/typography';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';

import BankOutlined from '@ant-design/icons/BankOutlined';
import MoreOutlined from '@ant-design/icons/MoreOutlined';

import { Agent, AgentPartnerBranch } from '@model/data.model';
import { AgentsBranchTagGroup } from '../../component/branch-tag-group';
import { AgentMenu } from './AgentMenu';
import { CrmTable, capitalizeFirstLetter } from '@moxie/shared';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';
import { getAgentPartners } from '@crm/libs/services.api/lib/agent-partner.api';
import { useParams } from 'react-router-dom';
import Toolbar from './toolbar';


const getMainBranch = (data: AgentPartnerBranch[] | undefined) =>
  data?.find((d: { isMainBranch: boolean }) => d.isMainBranch);

const useDefaultFilter = () => {
  const { type } = useParams<{ type: string }>();

  return useMemo(() => {
    switch (type) {
      case 'sub_agent':
        return {
          'isSubAgent': true
        }

      case 'super_agent':
        return {
          'isSuperAgent': true
        };
      default:
        return {

        }
    }
  }, [type])
}
const AgentTableList: React.FC = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [agent, setAgent] = useState<Agent | undefined>();
  const [refreshTable,setRefreshTable] = useState(false);
  const canAccess = useCanAccess();

  const defaultFilter = useDefaultFilter();
  const handleDrawerVisibility = (status: boolean) => {
    setShowDrawer(status);
    setAgent(undefined);
  };

  const resetPage = () => setRefreshTable(prev => !prev);

  const onEdit = (row: Agent) => {
    setAgent(row);
    setShowDrawer(true);
  };

  const columns: TableProps<Agent>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 320,
      render: (value: string, row: Agent) => (
        <Row align="middle">
          <Col lg={3} xl={3} xxl={2}>
            <Avatar icon={<BankOutlined />} className="margin-right-2" />
          </Col>
          <Col
            lg={{ span: 19, offset: 2 }}
            xl={{ span: 20, offset: 1 }}
            xxl={{ span: 21 }}
          >
            <Row align="middle">
              <Col span={21}>
                <Typography.Paragraph
                  ellipsis={{
                    rows: 1,
                    expandable: false,
                    tooltip: capitalizeFirstLetter(value),
                  }}
                  className="text-primary-color initial_capital para-ellipsis-height"
                  style={{ maxWidth: '310px' }}
                >
                  {value}
                </Typography.Paragraph>
              </Col>
              {canAccess('agent', 'update') && <Col span={3}>
                <Dropdown
                  overlay={<AgentMenu agent={row} onEdit={onEdit} />}
                  overlayClassName="z-index-zero"
                >
                  <Button
                    data-testid="crm-editagent-btn"
                    type="text"
                    className="table-cell-action-btn"
                    icon={<MoreOutlined />}
                  />
                </Dropdown>
              </Col>}
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Branches',
      dataIndex: 'branches',
      key: 'branches',
      width: 300,
      render(value: Agent) {
        return <AgentsBranchTagGroup data={value} />;
      },
    },
    {
      title: 'Phone',
      key: 'id',
      width: 200,
      ellipsis: true,
      render: (value: Agent) => {
        return getMainBranch(value?.branches)?.phone || '-';
      },
    },
    {
      title: 'Email',
      key: 'id',
      width: 200,
      ellipsis: true,
      render: (value: Agent) => {
        return getMainBranch(value?.branches)?.email || '-';
      },
    },
  ];

  return (
    <div className="bg-white padding-top-2">
      <CrmTable
        action={getAgentPartners}
        columns={columns}
        tableName=""
        refresh={refreshTable}
        filter={defaultFilter}
        tableButtons={<Toolbar openDrawer={() => handleDrawerVisibility(true)} />}

      />
      <Drawer
        showDrawer={showDrawer}
        handleDrawerVisibility={handleDrawerVisibility}
        resetPage={resetPage}
        agent={agent}
      />
    </div>
  );
};

export default AgentTableList;
