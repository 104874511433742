import React, { useEffect, useState } from 'react';
import { InboxOutlined, UploadOutlined, CloseOutlined, InfoCircleOutlined, EditOutlined, EyeOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Upload, Space, Typography, Select, Row, Input, Tooltip, Tag, Form } from 'antd';
import { TableProps } from 'antd/es/table';
import { UploadFile } from 'antd/lib/upload/interface';
import { documentCategories, searchFileName } from '@crm/src/config/fuseConfig';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@crm/core';
import { capitalizeFirstLetter, DocumentFileIcons, errorNotificationHandler, successNotificationHandler } from '@moxie/shared';
import { AUDIO_TYPE, BULK_FILE_NAME_MAXIMUM, DOCUMENT_TYPE, FILE_NAME_REQUIRED, IMAGE_TYPE, INVALID_FILE_NAME, MAXIMUM_NUMBER_OF_FILES, MAXIMUM_SIZE_OF_FILE, MAXIMUM_SIZE_OF_FILES, NO_FILES_SELECTED, regex, TEXT, VIDEO_TYPE } from '@moxie/constants';
import { addMedia } from '@crm/services.api';
import { useQueryClient } from '@tanstack/react-query';
import { groupBy } from 'lodash';
import { DocumentPreview } from 'libs/shared/src/crm-modules/shared';
import { BulkContactDocumentUploader } from './helper/bulk-contact-document-uploader';
import { BulkApplicationDocumentUploader } from './helper/bulk-application-document-uploader';
const { Dragger } = Upload;
const { Option, OptGroup } = Select;

interface SelectedDoc {
  url: string;
  extension: string;
}

export interface DataType {
  name: string;
  originFileObj?: any;
  editing: boolean;
  uid: string;
  key: string;
  filesName: string;
  documentType: string;
  documentTypeCategory: string;
  documentTypeLabel: string[];
  tempFileName?: string;
  mimetype?: string;
}
const validFileExtensions = `${DOCUMENT_TYPE}, ${IMAGE_TYPE}, ${VIDEO_TYPE}, ${AUDIO_TYPE}`;

const isValidFile = (file: File) => {
  if ((file.name.match(/\./g) || []).length > 1) {
    return false;
  }
  const substrings = file.name.toLowerCase().split('.')
  return validFileExtensions.includes(substrings[substrings.length - 1])
}

interface BulkDocumentUploadProps {
  requiredDocuments?: { workflowApplicationStageId: string, name: string, stage: string, checkListId: number }[];
  application?: any;
}
export const BulkDocumentUpload: React.FC<BulkDocumentUploadProps> = ({ requiredDocuments, application }) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const params = useParams<{ id: string }>();
  const user = useAppSelector(state => state.auth.user);
  const socket = useAppSelector(state => state.socket.wss);
  const contact = useAppSelector(state => state.contact.singleData)

  const [modalToOpen, setModalToOpen] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [documentsSize, setDocumentsSize] = useState<number>(0);
  const [classifiedFileList, setClassifiedFileList] = useState<any[]>([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [invalidFiles, setInvalidFiles] = useState<string[]>([]);
  const [selectedDoc, setSelectedDoc] = useState<SelectedDoc | null>(null);
  const [fieldNameError, setFieldNameError] = useState(false);
  const [editingUid, setEditingUid] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const hasUncategorized = classifiedFileList.some(file =>
      file?.documentTypeLabel?.includes('Uncategorized')
    );

    const hasEmpty = classifiedFileList.length === 0;

    const hasEditingEnabled = classifiedFileList.some((file) => file.editing === true)

    setSubmitDisabled(hasUncategorized || hasEmpty || hasEditingEnabled);
  }, [classifiedFileList]);

  useEffect(() => {
    setDocumentsSize(() => {
      return classifiedFileList?.reduce((acc: number, curr: { size: number }) => {
        return acc + curr.size;
      }, 0);
    });
  }, [classifiedFileList]);

  const handleChange = async (
    info: { file: UploadFile; fileList: UploadFile[]; }
    , isfirstUpload: boolean
  ): Promise<boolean> => {
    let hasError = false;
    if (!isValidFile(info.file as unknown as File)) {
      errorNotificationHandler("invalid file type : " + info.file.name);
      hasError = true;
    }
    if (info.file.size && info.file.size / 1024 / 1024 > 10) {
      errorNotificationHandler(`${info.file.name} exceeds the maximum file size of 10MB`);
      hasError = true;
    }
    if (fileList.length > 20) {
      errorNotificationHandler(MAXIMUM_NUMBER_OF_FILES + '20')
      hasError = true;
    }
    if (documentsSize / 1024 / 1024 > 200) {
      errorNotificationHandler(MAXIMUM_SIZE_OF_FILES + '200MB')
      hasError = true;
    }
    if (hasError) {
      setIsTableLoading(false);
      return false;
    }

    const validFiles: React.SetStateAction<any[]> = [];

    info?.fileList.forEach((file: any) => {
      if (file?.size / 1024 / 1024 > 10 || !isValidFile(file as unknown as File)) {
        if (!invalidFiles.includes(file.name)) {
          setInvalidFiles([...invalidFiles, file.name]);
        }
      }
      else {
        validFiles.push(file);
      }
    });

    if (validFiles && isfirstUpload) {
      setFileList(validFiles);
      await classifyFiles(validFiles);
    }

    setIsTableLoading(false);
    return true;
  };

  const handleDelete = (uid: string) => {
    const newFileList = classifiedFileList.filter(file => file.uid !== uid);
    setClassifiedFileList(newFileList);
    successNotificationHandler('File removed successfully.');
  };

  const classifyFiles = async (files: UploadFile[]) => {
    await new Promise((resolve) => setTimeout(resolve, 100));

    const unclassifiedFiles = files.filter(file =>
      !classifiedFileList.some(f => file.name === f.name)
    );
    if (unclassifiedFiles.length === 0) {
      setIsTableLoading(false);
      return;
    }

    const updatedFileList = unclassifiedFiles.map(file => {
      const searchResults = searchFileName(file.name);
      return {
        ...file,
        documentTypeCategory: searchResults?.[0]?.item?.category || 'Uncategorized',
        documentTypeLabel: searchResults?.[0]?.item?.label ? [searchResults[0].item.label] : ['Uncategorized'],
      };
    });
    setClassifiedFileList([...classifiedFileList, ...updatedFileList]);
    setIsTableLoading(false);
  };

  const bulkUploadApplicationStageDocuments = async (files: DataType[]) => {
    await BulkApplicationDocumentUploader({
      files,
      application,
      addMedia,
      requiredDocuments: requiredDocuments ?? null,
      socket,
      user,
      contact,
      queryClient,
      setClassifiedFileList,
      setFileList,
      setIsLoading
    })
  }

  const bulkUploadContactDocuments = async (files: DataType[]) => {
    await BulkContactDocumentUploader({
      files,
      subjectId: params.id,
      setClassifiedFileList,
      setFileList,
      socket,
      user,
      contact,
      addMedia,
      application,
      queryClient,
      setIsLoading
    });
  }

  const renderDocumentCategories = (
    text: any,
    record: DataType,
    fileList: any[],
    setFileList: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    const isMultiple = record.documentType === 'combined';

    const filteredCategories = requiredDocuments
      ? documentCategories
        .map((category) => ({
          ...category,
          labels: category.labels.filter((label) =>
            requiredDocuments.some((doc) => doc.name === label)
          ),
        }))
        .filter((category) => category.labels.length > 0)
      : documentCategories;


    const allowedLabels = filteredCategories.flatMap(category => category.labels);
    let categorizedLabels = record.documentTypeLabel.filter((label) =>
      allowedLabels.includes(label)
    );
    if (categorizedLabels.length === 0) {
      categorizedLabels = ['Uncategorized'];
      record.documentTypeCategory = 'Uncategorized';
      record.documentTypeLabel = ['Uncategorized'];
      setSubmitDisabled(true);
    }
    if (isMultiple && categorizedLabels.includes('Uncategorized')) {
      categorizedLabels = [];
      record.documentTypeLabel = [];
    }
    return (
      <Select
        showSearch
        mode={isMultiple ? 'multiple' : undefined}
        bordered
        allowClear={isMultiple}
        style={{
          width: '350px',
          color: categorizedLabels.includes('Uncategorized') ? 'darkgray' : 'black',
        }}
        value={
          categorizedLabels.length > 0 ? categorizedLabels : isMultiple ? ['Uncategorized'] : ['Uncategorized']
        }
        onMouseLeave={() => {
          if (isMultiple && record.documentTypeLabel.length === 0) {
            const updatedList = fileList.map((file) => {
              if (file.uid === record.uid) {
                return { ...file, documentTypeLabel: ['Uncategorized'] };
              }
              return file;
            });
            setFileList(updatedList);
          }
        }}
        onClick={() => {
          if (isMultiple && record.documentTypeLabel.includes("Uncategorized")) {
            const filteredValues = record.documentTypeLabel.filter(
              (item) => item !== 'Uncategorized'
            );
            const updatedList = fileList.map((file) => {
              if (file.uid === record.uid) {
                return { ...file, documentTypeLabel: filteredValues };
              }
              return file;
            });
            setFileList(updatedList);
          }
        }}
        onChange={(value) => {
          let newValue = Array.isArray(value) ? value : [value];

          if (isMultiple && newValue.length === 0) {
            newValue = ['Uncategorized'];
          }
          const updatedList = fileList.map((file) => {
            if (file.uid === record.uid) {
              return { ...file, documentTypeLabel: newValue };
            }
            return file;
          });
          setFileList(updatedList);
        }}
        tagRender={({ label, value, closable, onClose }) => {
          const isUncategorized = value === 'Uncategorized';
          const isTruncated = typeof label === 'string' ? label.length > 8 : false;
          if (isUncategorized && record.documentTypeLabel.length === 0) {
            return (
              <span className="render-tag">
                Uncategorized
              </span>
            );
          }
          return (
            <Tooltip title={isTruncated ? label : ''}>
              <Tag
                color={isUncategorized ? 'darkgray' : 'default'}
                closable={closable}
                onClose={onClose}
                style={{ fontWeight: isUncategorized ? 'bold' : 'normal' }}
              >
                {isTruncated ? `${(label as string).slice(0, 8)}...` : label}
              </Tag>
            </Tooltip>
          );
        }}
      >
        {filteredCategories.map((category) => (
          <OptGroup key={category.category} label={category.category}>
            {category.labels.map((label) => (
              <Option key={label} value={label}>
                {label}
              </Option>
            ))}
          </OptGroup>
        ))}
      </Select>
    );
  };

  const handlePreview = (file: any) => {
    setSelectedDoc({
      url: URL.createObjectURL(file),
      extension: file.name.split('.').pop() || '',
    });
  };

  const handlePreviewCancel = () => {
    setSelectedDoc(null);
  };

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Files Name',
      dataIndex: 'name',
      key: 'name',
      width: '300px',
      ellipsis: true,
      render: (text, record) => {
        const splitName = text.split('.');
        const handleRenameFile = () => {
          const updatedList = classifiedFileList.map((file) => {
            const changedName = record?.tempFileName ?? file.tempFileName
            const newClassifiedName = searchFileName((`${changedName}.${splitName[1]}`));
            return file.uid === record.uid
              ? {
                ...file,
                name: `${changedName}.${splitName[1]}`,
                editing: false,
                tempFileName: '',
                documentTypeCategory: newClassifiedName[0]?.item?.category || 'Uncategorized',
                documentTypeLabel: newClassifiedName[0]?.item?.label ? [newClassifiedName[0].item.label] : ['Uncategorized'],
              }
              : file
          });
          setClassifiedFileList([...updatedList]);
          setEditingUid(null);
        }

        const handleReUpload = async (file: any) => {
          const hasNoError = await handleChange({ file, fileList: [file] }, false);

          if (!hasNoError) {
            return false;
          }

          form.resetFields();
          const updatedList = classifiedFileList.map(f => {
            const newClassifiedName = searchFileName(file.name);
            const hasClassifiedName = newClassifiedName && newClassifiedName.length > 0;
            const documentTypeCategory = hasClassifiedName ? newClassifiedName[0].item.category : 'Uncategorized';
            const documentTypeLabel = hasClassifiedName && classifiedFileList[0]?.documentTypeLabel
              ? [newClassifiedName[0].item.label]
              : ['Uncategorized'];

            return f.uid === record.uid
              ? {
                ...f,
                name: file.name,
                originFileObj: file,
                size: file.size,
                documentTypeCategory,
                documentTypeLabel,
                tempFileName: file.name.split('.')[0],
              }
              : f;
          });
          setClassifiedFileList([...updatedList]);
          return false;
        }

        const handleEditFileName = (uid: string) => {
          const updatedList = classifiedFileList.map((file) => (
            file.uid === uid
              ? { ...file, editing: true, tempFileName: splitName[0] }
              : { ...file, editing: false, tempFileName: '' }
          ));

          setClassifiedFileList([...updatedList]);
          setEditingUid(uid);
        }
        return (
          <div key={record.uid} className="document-filename">
            <div style={{ marginRight: '8px' }}>
              <DocumentFileIcons mimetype={record.mimetype} />
            </div>
            {
              record.editing ?
                <>
                  <Form
                    form={form}
                    layout="inline"
                  >
                    <Form.Item
                      name={record.uid}
                      key={record.uid}
                      initialValue={splitName[0]}
                      rules={[
                        { required: true, message: FILE_NAME_REQUIRED },
                        { max: 50, message: BULK_FILE_NAME_MAXIMUM },
                        {
                          pattern: new RegExp(regex.FILE_NAME),
                          message: INVALID_FILE_NAME,
                        },
                      ]}
                    >
                      <Input
                        style={{ flex: 1, marginRight: '8px', width: '180px' }}
                        defaultValue={splitName[0]}

                        onChange={async (e) => {
                          const newValue = e.target.value;
                          if (newValue !== splitName[0]) {
                            record.tempFileName = newValue;
                          }
                          else {
                            record.tempFileName = splitName[0];
                          }
                          try {
                            await form.validateFields();
                            setFieldNameError(false);
                          }
                          catch (err) {
                            setFieldNameError(true);
                          }
                        }}
                        onPressEnter={() => handleRenameFile()}
                      />
                    </Form.Item>
                  </Form>

                  {!fieldNameError && (<CheckOutlined
                    className="document-checkoutlined"
                    onClick={handleRenameFile}
                    disabled={fieldNameError}
                  />)}

                  <CloseOutlined
                    style={{ cursor: 'pointer', color: 'red' }}
                    onClick={() => {
                      const updatedList = classifiedFileList.map((file) =>
                        file.uid === record.uid
                          ? { ...file, editing: false, tempFileName: splitName[0] }
                          : { ...file, tempFileName: '' }
                      );
                      form.resetFields();
                      setClassifiedFileList([...updatedList]);
                      setEditingUid(null);
                    }}
                  />
                </> : (
                  <>
                    <div style={{ flex: 1, marginRight: '8px', width: '180px' }}>
                      <Typography.Paragraph
                        ellipsis={{ tooltip: text }}
                        style={{ margin: 0, color: '#9653BF' }}
                      >
                        {text}
                      </Typography.Paragraph>
                    </div>

                    {!editingUid && (
                      <>
                        <Tooltip title={"Edit file name"}>
                          <EditOutlined
                            onClick={() => handleEditFileName(record.uid)}
                            style={{ marginRight: '8px', cursor: 'pointer' }}
                          />
                        </Tooltip>

                        <Tooltip title={"Replace with file"}>
                          <Upload
                            style={{ color: '#4A2362' }}
                            beforeUpload={async (file) => handleReUpload(file)}
                            showUploadList={false}
                          >
                            <UploadOutlined style={{ cursor: 'pointer', color: '#4A2362' }} />
                          </Upload>
                        </Tooltip>

                        <Tooltip title={"Preview"}>
                          <EyeOutlined
                            style={{ cursor: 'pointer', marginLeft: '8px', color: '#4A2362' }}
                            onClick={() => handlePreview(record.originFileObj)}
                          />
                        </Tooltip>
                      </>
                    )}
                  </>

                )
            }
          </div >
        );
      }
    },
    {
      title: 'Document Type',
      dataIndex: 'Type',
      key: 'Type',
      width: '200px',
      render: (text, record) => (
        <Select
          showSearch
          className="document-type-select"
          value={record.documentType}
          onChange={(value) => {
            const updatedList = classifiedFileList.map(file => {
              if (file.uid === record.uid) {
                const updatedCategories = value === 'combined' ? file.documentTypeLabel : [file.documentTypeLabel[0]];
                return { ...file, documentType: value, documentTypeLabel: updatedCategories };
              }
              return file;
            });
            setClassifiedFileList(updatedList);
          }}
        >
          <Select.Option value="single">Single</Select.Option>
          <Select.Option value="combined">Combined</Select.Option>
        </Select>
      ),
    },
    {
      title: 'Document Categories',
      dataIndex: 'categories',
      key: 'categories',
      width: '400px',
      render: (text, record) => renderDocumentCategories(text, record, classifiedFileList, setClassifiedFileList),
    },
    {
      title: '',
      key: 'action',
      render: (_, record: any) => (
        <div className="document-action"><Space size="middle">
          <CloseOutlined
            style={{ cursor: 'pointer' }}
            onClick={() => handleDelete(record.uid)}
          />
        </Space>
        </div>
      ),
    },
  ];

  const documentsSizeMB = documentsSize / 1024 / 1024;
  const formattedSize = Number.isInteger(documentsSizeMB) ? documentsSizeMB.toFixed(0) : documentsSizeMB.toFixed(2);
  const groupedDocuments = groupBy(requiredDocuments, 'stage');
  const tooltipContent = (
    <ul className="tooltip-content">
      {Object.entries(groupedDocuments).map(([stage, docs], index) => (
        <li key={index} style={{ marginBottom: '8px' }}>
          <strong>{capitalizeFirstLetter(stage)}</strong>
          <ul style={{ paddingLeft: '20px', margin: 0 }}>
            {docs.map((doc, idx) => (
              <li key={idx}>{doc.name}</li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );

  const handleUpload = async (info: any) => {
    setIsTableLoading(true);
    const combinedFiles = [...classifiedFileList, info.file];
    if (combinedFiles.length > 20) {
      errorNotificationHandler('You have selected too many files. Only 20 files are allowed at a time.');
      const allowedFiles = combinedFiles.slice(0, 20);
      handleChange({ ...info, fileList: allowedFiles }, true);
    } else {
      handleChange(info as { file: UploadFile; fileList: UploadFile[] }, true);
    }
  }

  return (
    <>
      <Row style={{ paddingBottom: '20px' }}>
        <Button className="custom_comments_button"
          style={{ paddingLeft: '20px' }} onClick={() => setModalToOpen(true)}>
          {application ? <>Application Document(s) <UploadOutlined /></> : <>Upload Document(s) <UploadOutlined /></>}
        </Button>
      </Row>

      <Modal
        width={1000}
        title={requiredDocuments?.length ? <>Upload bulk stage file(s) <Tooltip title={tooltipContent}> <InfoCircleOutlined /></Tooltip></> : 'Upload a file(s)'}
        centered
        visible={modalToOpen}
        okButtonProps={{ disabled: submitDisabled || isLoading }}
        cancelButtonProps={{ disabled: isLoading }}
        onOk={() => {
          if (application) {
            bulkUploadApplicationStageDocuments(classifiedFileList);
          } else {
            bulkUploadContactDocuments(classifiedFileList);
          }
          setModalToOpen(false);
        }}
        maskClosable={false}
        onCancel={() => {
          setEditingUid(null);
          setModalToOpen(false);
          setClassifiedFileList([]);
        }}
        bodyStyle={{ height: '700px' }}
      >
        <Dragger
          disabled={isLoading}
          beforeUpload={() => false}
          multiple
          maxCount={20}
          showUploadList={false}
          height={300}
          onChange={(info) => handleUpload(info)}
          fileList={classifiedFileList}
          onRemove={(file) => {
            handleDelete(file.uid);
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
            banned files.
          </p>
          <div>
            <p className="font-size-20-px">
              {TEXT.SELECTED_DOCUMENTS}&nbsp;
              <span
                style={{
                  color: classifiedFileList?.length > 20 ? 'red' : 'black',
                }}
              >
                ({classifiedFileList?.length})
              </span>{' '}
              <span
                style={{
                  color: documentsSize / 1024 / 1024 > 50 ? 'red' : 'black',
                }}
              >{` (${formattedSize} MB)`}</span>
            </p>
            <p>{MAXIMUM_NUMBER_OF_FILES}: 20</p>
            <p>{MAXIMUM_SIZE_OF_FILE}: 10 MB</p>
            <p>{MAXIMUM_SIZE_OF_FILES}: 200 MB</p>
            <p className="dragger_font">
              {classifiedFileList?.length === 0 ? NO_FILES_SELECTED : null}
            </p>
          </div>
        </Dragger>

        <Table
          loading={isTableLoading || isLoading}
          columns={columns}
          dataSource={classifiedFileList.map(file => ({
            key: file.uid,
            editing: file.editing || false,
            name: file.name,
            uid: file.uid,
            filesName: file.name,
            documentType: file.documentType || 'single',
            documentTypeCategory: file.documentTypeCategory,
            documentTypeLabel: file.documentTypeLabel,
            originFileObj: file.originFileObj,
            mimetype: file.originFileObj?.type,
          }))}
          className="bulk-upload-table"
          pagination={false}
          style={{ marginTop: '16px', height: '350px' }}
        />

        {selectedDoc && (
          <DocumentPreview
            doc={selectedDoc}
            onCancel={handlePreviewCancel}
            onOk={handlePreviewCancel}
          />
        )}

      </Modal >
    </>
  );
};
