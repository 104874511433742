import { RedoOutlined } from '@ant-design/icons';
import { DRAWER_WIDTH, TEXT } from '@moxie/constants';
import { Button, Drawer, FormInstance, Row } from 'antd';
import React from 'react';
import { useFetchCurrentStages, useFetchWorkFlows } from '../hooks/useGetApplicationsContact';
import ApplicationReportForm from './application-report-form';
import { IReportWorkflow } from '@model/crm/application-report.model';

interface IApplicationReportDrawer {
  drawerVisibility: boolean;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setFilter: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
  disabled: boolean;
  onClose: () => void;
  form: FormInstance<any>;
  onSubmit: (value: any) => void;
}

const ApplicationReportDrawer: React.FC<IApplicationReportDrawer> = ({
  drawerVisibility,
  disabled,
  setFilter,
  onClose,
  setDisabled,
  form,
  onSubmit,
}) => {
  const { workFlows } = useFetchWorkFlows();
  const { currentStage: stages } = useFetchCurrentStages();

  const handleSubmitBtnClick = () => form.submit();

  const onReset = () => {
    form.resetFields();
    setDisabled(true);
    setFilter({});
  };

  return (
    <div>
      <Drawer
        title={TEXT.APPLY_FILTERS}
        width={DRAWER_WIDTH}
        visible={drawerVisibility}
        onClose={onClose}
        footer={
          <div className="padding-bottom-2 text-align-right">
            <Row justify="space-between">
              <Button onClick={() => onReset()}>
                <RedoOutlined className="margin-right-1" />
                {TEXT.RESET_FILTERS}
              </Button>
              <Button
                type="primary"
                onClick={handleSubmitBtnClick}
                disabled={disabled}
              >
                {TEXT.APPLY_FILTERS}
              </Button>
            </Row>
          </div>
        }
      >
        <ApplicationReportForm
          form={form}
          setDisabled={setDisabled}
          onSubmit={onSubmit}
          stages={stages}
          workFlows={workFlows as IReportWorkflow[]}
        />
      </Drawer>
    </div>
  );
};
export default ApplicationReportDrawer;
