import React from 'react';
import { BUTTON } from '@moxie/constants';
import { Button } from '@shared-components/elements';
import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';

interface Props {
  id: string;
  onEdit: (id: string) => void;
}
const LanguageRequirementNameCard: React.FC<Props> = ({ id, onEdit }) => {
  const menu = (
    <Menu>
      <Menu.Item key="2" icon={<EditOutlined />} onClick={() => onEdit(id)}>
        {BUTTON.EDIT}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu}>
        <Button
          type="text"
          className="table-cell-action-btn"
          icon={<MoreOutlined />}
        />
      </Dropdown>
    </>
  );
};

export default LanguageRequirementNameCard;
