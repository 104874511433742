import { useQuery } from '@tanstack/react-query';
import { validateDomain } from '@crm/services.api';

export const useFetchDomainValidation = () => {
  const { data, isLoading } = useQuery({
    queryFn: () => validateDomain(),
    queryKey: ['validate-domain'],
  })

  return {
    domainValidationStatus: data?.data?.data,
    isLoading
  }
}

