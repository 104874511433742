import { Col, Row, Tabs } from 'antd';
import React from 'react';
import ProductLanguageRequirements from './product-language-requirements/products-language-requirements';
import ProductFeeTab from './ProductFeeTab';

const ProductDetailsTabs = () => {
  return (
    <section className="lead_content">
      <div className="pro">
        <Row align="top" justify="space-between" style={{ margin: '0 1rem' }}>
          <Col span={24}>
            <Tabs className="custom_tab">
              <Tabs.TabPane tab="Fees" key="fees">
                <ProductFeeTab />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Requirements" key="requirements">
                <ProductLanguageRequirements />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ProductDetailsTabs;
