import { useAppSelector } from "@crm/core";
import { updateApplication } from "@crm/libs/services.api/lib/application.api"
import { ApplicationDetail, UpdateApplicationPayload } from "@model/application"
import { ActivitiesType } from "@model/contact-activity";
import { errorNotificationHandler } from "@moxie/shared";
import { useMutation, useQueryClient } from "@tanstack/react-query"
import axios, { AxiosError } from "axios";

export type ActivitiesLabel = 'Application Date'
  | 'Visa TRN'
  | 'Visa lodgment date'
  | 'Visa outcome date'
  | 'Briding visa type'
  | 'Country of police check'
  | 'Police check application date'
  | 'Police check expiry date'
  | 'HAp ID'
  | 'Visa medical date'
  | 'Visa medical country'
  | 'RMA'
  | 'Assessment occupation'
  | 'Skills assessment body'
  | 'Skills assessment expiry date'
  | 'English test date'
  | 'English test expiry date'

interface ActivitiesInfo {
  activitiesType: ActivitiesType;
  activitesLabel?: string;
}

interface UpdateArg {
  payload: UpdateApplicationPayload;
  activitiesInfo?: ActivitiesInfo;
}

const useUpdateApplicationMutation = (application: ApplicationDetail, dealId?: string) => {
  const queryClient = useQueryClient();
  const socket = useAppSelector((store) => store.socket.wss);
  const user = useAppSelector((store) => store.auth.user);

  return useMutation({
    mutationFn: (mutationArg: UpdateArg) => {
      return updateApplication(application.id, mutationArg.payload)
    },
    onSuccess: (data, variable, context) => {
      const { activitiesInfo } = variable
      if (activitiesInfo) {
        socket?.emit("server::application-updated", {
          activitiesAction: 'updated',
          activitiesType: activitiesInfo.activitiesType,
          data: {
            data: {
              contactId: application.contactId,
              id: application.id,
              appIdentifier: application.appIdentifier,
              activitiesLabel: variable?.activitiesInfo?.activitesLabel ?? ''
            },
          },
          activitiesTypeId: application.id,
          companyId: user?.companyId as string,
          contactId: application.contactId,
          userId: user?.id as string,
        });
      }
      queryClient.invalidateQueries({
        queryKey: ['application-details', application.id]
      })
      queryClient.invalidateQueries({
        queryKey: ['deal-applications', dealId],
      });
    },
    onError(err: AxiosError<{ detail: string }>) {
      if (axios.isAxiosError(err)) {
        errorNotificationHandler(err?.response?.data?.detail as string, 1.5);
      }
    }
  })
}

export default useUpdateApplicationMutation;
