import Divider from 'antd/es/divider';
import React, { useState } from 'react';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { classNames } from '@moxie/constants';
import { WithSearch } from '@elastic/react-search-ui';
import { groupBy } from 'lodash';
import SearchResultsMapper from './search-results-mapper';
import { Mapping } from '@model/crm/elastic-search';
import MultiTagSelect from '@crm/src/shared/select/multi-tag-select';
import Typography from 'antd/es/typography';
import { capitalizeFirstLetter } from '@moxie/shared';

interface Props {
  visible: boolean;
  closeModal: () => void;
}

const Filters = [
  'enquiries',
  'contacts',
  'deals',
  'applications',
  'notes'
]

const SearchResults: React.FC<Props> = ({ visible, closeModal }) => {
  const [filter, setFilter] = useState<string[]>([])
  return (
    <div className={classNames('search--results-container', !visible ? 'search--results-hide' : null)} >
      <WithSearch mapContextToProps={({ results, searchTerm }) => ({ results, searchTerm })}>
        {({ results, searchTerm }) => {
          const filteredResults = filter.length ? results.filter((result: any) => filter.includes(result.index.replace('idx_', ''))) : results;
          const grouped: Mapping = groupBy(filteredResults, 'index') as any;
          return (
            <>
              <header className="search__header">
                <div className="search__filter-tags">
                  <MultiTagSelect
                    options={Filters.map(value => ({ label: capitalizeFirstLetter(value), value }))}
                    onChange={setFilter}
                    values={filter}
                  />

                </div>
                <CloseOutlined onClick={() => closeModal()} style={{ fontSize: '20px' }} />
              </header>
              <Divider />
              <div className="search__results-listing" >
                {
                  searchTerm?.length ? (
                    <>
                      {Object.entries(grouped).length ? (
                        <>
                          {Object.entries(grouped).map(([key, results]) => <SearchResultsMapper
                            indexKey={key as keyof Mapping}
                            key={key}
                            results={results}
                          />)}
                        </>

                      ) : (
                        <Typography.Title level={2} style={{ textAlign: 'center' }}>
                          No results found
                        </Typography.Title>
                      )}
                    </>
                  ) : (
                    <Typography.Title level={2} style={{ textAlign: 'center' }}>
                      Type on search bar for results
                    </Typography.Title>
                  )
                }

              </div>
            </>
          )
        }}
      </WithSearch>
    </div>
  )
}


export default SearchResults;
