import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { TEXT, URL_PRODUCTS } from '@moxie/constants';
import { Button, OverViewHeader } from '@shared-components/elements';
import { Link, useParams } from 'react-router-dom';
import ProductOverview from './components/product-overview';
import ProductDetailsTabs from './components/product-details-tabs';
import { useGetProductById } from '../hooks/useGetProductById';
import { Spin } from 'antd';

const ProductDetailComponent: React.FC = () => {
  const { productId }: { productId: string } = useParams();
  const { isLoading } = useGetProductById(productId);
  return (
    <Spin spinning={isLoading}>
      <OverViewHeader
        title={TEXT.PRODUCT}
        actions={
          <Link to={URL_PRODUCTS}>
            <Button icon={<LeftOutlined />}>{TEXT.PRODUCT_LIST}</Button>
          </Link>
        }
      />
      <ProductOverview />
      <ProductDetailsTabs />
    </Spin>
  );
};
export { ProductDetailComponent };
