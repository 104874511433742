import React from 'react';
import moment from 'moment';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { useAppSelector } from '@crm/core';
import { Tooltip, Typography } from 'antd';
import { IContact } from '@model/data.model';
import { LABEL } from '@moxie/constants';
import { capitalizeFirstLetter, formatDateWithTimezone } from '@moxie/shared';

const { Text } = Typography;

export const ContactTableColumns = (
  setFilter: (key: string, value: string) => void
) => {
  const userTimeZone =
    useAppSelector((store: any) => store?.auth?.user?.time_zone) || '';

  return [
    {
      title: LABEL.INTERNAL_ID,
      width: 120,
      dataIndex: 'internalId',
      ellipsis: true,
      render: (internalId: string, value: any) => {
        return (
          <Link to={`/contact/detail/${value?.id}/deals`}>
            {internalId}
          </Link>
        );
      },
    },

    {
      title: LABEL.CONTACT,
      width: 200,
      dataIndex: 'contact',
      ellipsis: true,
      render: (
        value: any,
        { id, firstName, lastName }: IContact
      ): ReactNode => {
        return (
          <Link to={`/contact/detail/${id}/deals`}>
            <Tooltip
              title={capitalizeFirstLetter(`${firstName} ${lastName}`)}
            >
              <Text className="text-primary-color initial_capital ">{`${firstName} ${lastName}`}</Text>
            </Tooltip>
          </Link>
        );
      },
    },

    {
      title: LABEL.PHONE,
      width: 150,
      dataIndex: 'phone',
      ellipsis: true,
      render: (phone: number, value: any): ReactNode => {
        return phone ? `${value?.countryCode} ${phone}` : '-';
      },
    },

    {
      title: LABEL.EMAIL,
      width: 150,
      dataIndex: 'email',
      ellipsis: true,
      render: (email: string): ReactNode => {
        return (
          <a className="text-primary-color" href={`mailto:${email}`}>
            {email ?? '-'}
          </a>
        );
      },
    },

    {
      title: LABEL.DATE_OF_BIRTH,
      width: 160,
      dataIndex: 'dateOfBirth',
      ellipsis: true,
      render: (value: string): ReactNode => {
        return value ? moment(value).format('MMM DD, YYYY') : '-';
      },
    },

    {
      title: LABEL.ADDED_DATE,
      width: 120,
      dataIndex: 'createdAt',
      ellipsis: true,
      render: (value: string): ReactNode => {
        return formatDateWithTimezone(value, userTimeZone);
      },
    },

    {
      title: LABEL.ASSIGNEE,
      width: 120,
      dataIndex: 'assignee',
      ellipsis: true,
      render: (data: IContact): ReactNode => {
        return data ? `${data?.firstName} ${data?.lastName}` : 'Unassigned';
      },
    },

    {
      title: LABEL.PRIMARY_BRANCH,
      width: 130,
      dataIndex: 'branch',
      ellipsis: true,
      render: (data: any): ReactNode => {
        return data ? `${data?.name}` : '-';
      },
    },

    {
      title: LABEL.CITY,
      width: 100,
      dataIndex: 'city',
      ellipsis: true,
      render: (value: string): ReactNode => {
        return (
          <div className="lead-table__city">
            {value ? capitalizeFirstLetter(value) : '-'}
          </div>
        );
      },
    },

    {
      title: LABEL.STATE,
      width: 100,
      dataIndex: 'state',
      ellipsis: true,
      render: (value: string): ReactNode => {
        return value ? capitalizeFirstLetter(value) : '-';
      },
    },

    {
      title: LABEL.COUNTRY_OF_RESIDENCE,
      width: 180,
      dataIndex: 'country',
      ellipsis: true,
      render: (value: string): ReactNode => {
        return value ?? '-';
      },
    },

    {
      title: LABEL.COUNTRY_OF_PASSPORT,
      width: 180,
      dataIndex: 'countryOfPassport',
      ellipsis: true,
      render: (value: string): ReactNode => {
        return value ?? '-';
      },
    },

    {
      title: LABEL.PASSPORT_NUMBER,
      width: 120,
      dataIndex: 'passportNo',
      ellipsis: true,
      render: (value: string): ReactNode => {
        return <div className="lead-table__city">{value ?? '-'}</div>;
      },
    },

    {
      title: LABEL.PASSPORT_EXPIRY_DATE,
      width: 180,
      dataIndex: 'passportExpiryDate',
      ellipsis: true,
      render: (value: string): ReactNode => {
        return (
          <div className="lead-table__visa-expiry-date">
            {value ? moment(value).format('MMM DD, YYYY') : '-'}
          </div>
        );
      },
    },

    {
      title: LABEL.VISA_EXPIRY_DATE,
      width: 150,
      dataIndex: 'visaExpiryDate',
      ellipsis: true,
      render: (value: string): ReactNode => {
        return (
          <div className="lead-table__visa-expiry-date">
            {value ? moment(value).format('MMM DD, YYYY') : '-'}
          </div>
        );
      },
    },
  ];
};
