import React, { useEffect, useState } from 'react';
import {
  Empty,
  Spin
} from 'antd';
import {
  CONFIRM_MESSAGE,
  DELETE_BTN,
  LABEL,
  TEXT,
  TITLE
} from '@moxie/constants';
import Text from 'antd/lib/typography/Text';
import { IELTSDrawer } from './drawer/ielts';
import { TOEFLDrawer } from './drawer/toefl';
import { PTEDrawer } from './drawer/pte';
import { popConfirm } from '@shared-components/elements';
import {
  usePteDelete,
  useToeflDelete,
  useIeltsDelete
} from '@crm/libs/hooks/contacts';
import { ICrmTests, IEnglishTestScore } from '@model/index';
import { DuolingoDrawer } from './drawer/duolingo-drawer';
import { useDuolingoDelete } from '@crm/libs/hooks/contacts/useDuoLingoMutation';
import TestScoreCard from './test-score-card';

const getTestData = (data: ICrmTests[], testName: string) => {
  if (data.length > 0) {
    const testScoreData = data?.find((testData: any) => testData?.tests?.find((item: any) => {
      return item.name === testName.toLowerCase()
    }))
    if (!testScoreData) {
      return null;
    }
    const { id, parentId, tests: testData } = testScoreData;

    if (!testData) {
      return null
    }

    const { id: testId, name, overallScore, testScoreId, testTypes: typeData } = testData[0];

    const testTypes = typeData?.reduce((acc: any, item: any) => {
      acc[item.name] = item;
      return acc;
    }, {});

    return {
      id,
      parentId,
      testId,
      testName: name,
      testScoreId,
      reading: testTypes['reading'] || null,
      writing: testTypes['writing'] || null,
      speaking: testTypes['speaking'] || null,
      listening: testTypes['listening'] || null,
      overallScore,
    };
  } else {
    return null;
  }
};

export type Tab = 'ielts' | 'pte' | 'toefl' | 'duolingo';

const EnglishTestScorePanelContent = ({
  data,
  loading,
  panelKey,
  setShowExtraIcon,
}: {
  data: ICrmTests[];
  loading: boolean;
  panelKey: string;
  setShowExtraIcon: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<Tab | undefined>();

  const ieltsData = React.useMemo(() => getTestData(data, LABEL.IELTS), [data]);
  const toeflData = React.useMemo(() => getTestData(data, LABEL.TOEFL), [data]);
  const pteData = React.useMemo(() => getTestData(data, LABEL.PTE), [data]);
  const duolingoData = React.useMemo(() => getTestData(data, "duolingo"), [data]);

  const handleIeltsEditMode = () => {
    setEditMode(true);
    setCurrentTab('ielts');
  };

  const handleToeflEditMode = () => {
    setEditMode(true);
    setCurrentTab('toefl')
  };

  const handlePteEditMode = () => {
    setEditMode(true);
    setCurrentTab('pte')
  };

  const handleDuolingoEdit = () => {
    setEditMode(true);
    setCurrentTab('duolingo');
  }

  const ieltsDelete = useIeltsDelete();
  const toeflDelete = useToeflDelete();
  const pteDelete = usePteDelete();
  const duolingoDelete = useDuolingoDelete();

  const handleIeltsDelete = () => {
    if (ieltsData) {
      popConfirm(
        DELETE_BTN,
        `${CONFIRM_MESSAGE.DELETE_IELTS_SCORE}`,
        async () => {
          await ieltsDelete.mutateAsync(ieltsData?.id);
        },
        true
      );
    }
  };

  const handleToeflDelete = () => {
    if (toeflData) {
      popConfirm(
        DELETE_BTN,
        `${CONFIRM_MESSAGE.DELETE_TOEFL_SCORE}`,
        async () => {
          await toeflDelete.mutateAsync(toeflData?.id);
        },
        true
      );
    }
  };

  const handleDuolingoDelete = () => {
    if (duolingoData) {
      popConfirm(
        DELETE_BTN,
        `${CONFIRM_MESSAGE.DELETE_DUOLINGO_SCORE}`,
        async () => {
          await duolingoDelete.mutate(duolingoData?.id);
        },
        true
      );
    }
  };

  const handlePteDelete = () => {
    if (pteData) {
      popConfirm(
        DELETE_BTN,
        `${CONFIRM_MESSAGE.DELETE_PTE_SCORE}`,
        async () => {
          await pteDelete.mutateAsync(pteData?.id);
        },
        true
      );
    }
  };

  useEffect(() => {
    if (panelKey === TITLE.ENGLISH_TEST_SCORES) {
      if (ieltsData && toeflData && pteData && duolingoData) {
        setShowExtraIcon(false);
      } else {
        setShowExtraIcon(true);
      }
    }
  }, [ieltsData, toeflData, pteData, duolingoData, panelKey]);

  return (
    <Spin spinning={loading}>
      {!data.length ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={TEXT.NOT_AVAILABLE}
        />
      ) : (
        <>
          {ieltsData && (
            <TestScoreCard
              data={ieltsData}
              label={LABEL.IELTS}
              testScoreProgressPercent={(ieltsData?.overallScore / 9) * 100}
              onDelete={handleIeltsDelete}
              handleEdit={handleIeltsEditMode}
              progressFormat={(percent: any) => (
                <Text className="test-score-progress-text">
                  {Math.round((percent / 100) * 9)}
                </Text>
              )}
            />
          )}

          {toeflData && (
            <TestScoreCard
              data={toeflData}
              label={LABEL.TOEFL}
              testScoreProgressPercent={(toeflData?.overallScore / 120) * 100}
              onDelete={handleToeflDelete}
              handleEdit={handleToeflEditMode}
              progressFormat={(percent: any) => (
                <Text className="test-score-progress-toefl-text">
                  {Math.round((percent / 100) * 120)}
                </Text>
              )}
            />
          )}

          {pteData && (
            <TestScoreCard
              cardClassName="test-score-card margin-top-1"
              data={toeflData}
              label={LABEL.PTE}
              onDelete={handlePteDelete}
              handleEdit={handlePteEditMode}
              testScoreProgressPercent={(pteData?.overallScore / 90) * 100}
              progressFormat={(percent: any) => (
                <Text className="test-score-progress-pte-text">
                  {Math.round((percent / 100) * 90)}
                </Text>
              )}
            />
          )}

          {duolingoData && (
            <TestScoreCard
              data={duolingoData}
              label={LABEL.DUOLINGO}
              onDelete={handleDuolingoDelete}
              handleEdit={handleDuolingoEdit}
              testScoreProgressPercent={(duolingoData?.overallScore / 160) * 100}
              progressFormat={(percent: any) => (
                <Text className="test-score-progress-pte-text">
                  {Math.round((percent / 100) * 160)}
                </Text>
              )}
            />
          )}

          <IELTSDrawer
            showDrawer={currentTab === 'ielts'}
            setShowDrawer={setCurrentTab}
            editMode={editMode}
            setEditMode={setEditMode}
            ieltsData={ieltsData as IEnglishTestScore}
          />

          <TOEFLDrawer
            showDrawer={currentTab === 'toefl'}
            setShowDrawer={setCurrentTab}
            editMode={editMode}
            setEditMode={setEditMode}
            toeflData={toeflData as IEnglishTestScore}
          />

          <PTEDrawer
            showDrawer={currentTab === 'pte'}
            setShowDrawer={setCurrentTab}
            editMode={editMode}
            setEditMode={setEditMode}
            pteData={pteData as IEnglishTestScore}
          />

          <DuolingoDrawer
            showDrawer={currentTab === 'duolingo'}
            setShowDrawer={setCurrentTab}
            editMode={editMode}
            setEditMode={setEditMode}
            duolingoData={duolingoData as IEnglishTestScore}
          />
        </>
      )}
    </Spin>
  );
};
export { EnglishTestScorePanelContent };
