import React from "react";
import { UploadSheet } from "./upload-sheet";
import { Card, Col, Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ImportContactOptionalFields, ImportContactRequiredFields } from "@moxie/constants";

interface DataType {
    key: number;
    inputField: React.ReactNode;
    validationType?: React.ReactNode | null;
    fieldValue?: React.ReactNode | null;
}

const columns: ColumnsType<DataType> = [
    {
        title: "Input Field",
        dataIndex: "inputField",
        width: 100
    },
    {
        title: "Validation Type",
        dataIndex: "validationType",
        width: 300
    },
    {
        title: "Field Value",
        dataIndex: "fieldValue",
        width: 100
    },
]

const dataSource = [
    {
        key: 0,
        inputField: <Typography.Text className="required"><i>Required Fields</i></Typography.Text>,
        validationType: null,
        fieldValue: null,
    },
    ...ImportContactRequiredFields.map((fieldData, index) => ({
        key: index + 1,
        inputField: <Typography.Text className="text-bold">{fieldData.name}</Typography.Text>,
        validationType: <ul>{fieldData.validations.map((item, idx) => <li key={idx}>{item}</li>)}</ul>,
        fieldValue: <ul>{fieldData.values.map((item, idx) => <li key={idx}>{item}</li>)}</ul>,
    })),
    {
        key: ImportContactRequiredFields.length + 1,
        inputField: <Typography.Text className="text-grey"><i>Optional Fields</i></Typography.Text>
    },
    ...ImportContactOptionalFields.map((fieldData, index) => ({
        key: (ImportContactRequiredFields.length + 2) + index,
        inputField: <Typography.Text className="text-bold">{fieldData.name}</Typography.Text>,
        validationType: <ul>{fieldData.validations.map((item, idx) => <li key={idx}>{item}</li>)}</ul>,
        fieldValue: <ul>{fieldData.values.map((item, idx) => <li key={idx}>{item}</li>)}</ul>,
    })),
]

const UploadFileStep = () => {
    return (
        <div className="upload-container">
            <Row>
                <Col span={7}>
                    <UploadSheet />
                </Col>
                <Col span={16} className="upload-info-card">
                    <Card bordered style={{ height: '85vh' }}>
                        <Row>
                            <Typography.Title level={5}>Contact Field Properties</Typography.Title>
                        </Row>
                        <Table columns={columns} dataSource={dataSource} pagination={false} scroll={{ y: '67vh' }} />
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
export { UploadFileStep };
