import React from 'react';
import { useAppSelector } from '@crm/core';
import {
  ADDRESS1_LABEL,
  ADDRESS2_LABEL,
  LABEL,
  HYPHEN,
} from '@moxie/constants';
import { Row, Typography } from 'antd';
import { RootState } from 'apps/crm/src/core/store';
import { EditAddressInfoDrawer } from './edit-address-info-drawer';

const { Text } = Typography;

const AddressDetailsPanel = ({
  showDrawer,
  setShowDrawer,
}: {
  showDrawer: boolean;
  setShowDrawer: any;
}) => {
  const data = useAppSelector((state: RootState) => state?.contact?.singleData);
  return (
    <>
      <div className="profile-panel-content">
        <Row>
          <div className="flex-direction-vertical">
            <h4 className="text-primary">
              <span>{ADDRESS1_LABEL}</span>
              <span className="margin-left-1-half-px">:</span>
            </h4>
            <Text className="margin-top-minus-5-px text-grey initial_capital">
              {data?.addressLineOne ? data?.addressLineOne : HYPHEN}
            </Text>
          </div>
        </Row>
        <Row className="padding-top-2">
          <div className="flex-direction-vertical">
            <h4 className="text-primary">
              <span>{ADDRESS2_LABEL}</span>
              <span className="margin-left-1-half-px">:</span>
            </h4>
            <Text className="margin-top-minus-5-px text-grey initial_capital">
              {data?.addressLineTwo ? data?.addressLineTwo : HYPHEN}
            </Text>
          </div>
        </Row>
        <Row className="padding-top-2">
          <div className="flex-direction-vertical">
            <h4 className="text-primary">
              <span>{LABEL.STATE}</span>
              <span className="margin-left-1-half-px">:</span>
            </h4>
            <Text className="margin-top-minus-5-px text-grey initial_capital">
              {data?.state ? data?.state : HYPHEN}
            </Text>
          </div>
        </Row>
        <Row className="padding-top-2">
          <div className="flex-direction-vertical">
            <h4 className="text-primary">
              <span>{LABEL.CITY}</span>
              <span className="margin-left-1-half-px">:</span>
            </h4>
            <Text className="margin-top-minus-5-px text-grey initial_capital">
              {data?.city ? data?.city : HYPHEN}
            </Text>
          </div>
        </Row>
        <Row className="padding-top-2">
          <div className="flex-direction-vertical">
            <h4 className="text-primary">
              <span>{LABEL.COUNTRY_OF_RESIDENCE}</span>
              <span className="margin-left-1-half-px">:</span>
            </h4>
            <Text className="margin-top-minus-5-px text-grey initial_capital">
              {data?.country ? data?.country : HYPHEN}
            </Text>
          </div>
        </Row>
        <Row className="padding-top-2">
          <div className="flex-direction-vertical">
            <h4 className="text-primary">
              <span>{LABEL.ZIP_CODE}</span>
              <span className="margin-left-1-half-px">:</span>
            </h4>
            <Text className="margin-top-minus-5-px text-grey text-upper-case">
              {data?.zipCode ? data?.zipCode : HYPHEN}
            </Text>
          </div>
        </Row>
      </div>
      <EditAddressInfoDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        initialData={data}
      />
    </>
  );
};
export { AddressDetailsPanel };
