import { ActivitiesType, ChangeLog } from '@model/contact-activity';

export const CreatedActivityMessage = ({
  activitiesType,
  data,
  followerLabel,
  dealLink,
  applicationLink,
}: {
  activitiesType: ActivitiesType;
  data: ChangeLog;
  followerLabel: string;
  dealLink: false | JSX.Element;
  applicationLink: false | JSX.Element;
}) => {
  switch (activitiesType) {
    case 'language-test':
      return (
        <span>
          Added the{' '}
          <span className="contact-activities-stage">
            english test score for {data?.testName?.toUpperCase()}
          </span>
        </span>
      );
    case 'emergency-contact':
      return (
        <span>
          Created{' '}
          <span className="contact-activities-stage">
            Emergency contact details
          </span>
        </span>
      );

    case 'professional-experience':
      return (
        <span>
          Created{' '}
          <span className="contact-activities-stage">
            Professional experience
          </span>
        </span>
      );
    case 'education':
      return (
        <span>
          Created{' '}
          <span className="contact-activities-stage">education background</span>
        </span>
      );

    case 'document':
      return (
        <span>
          Document<span className="initial_small">(s)</span> uploaded in{' '}
          <span className="contact-activities-stage">{data?.subCategory}</span>{' '}
        </span>
      );

    case 'application':
      return (
        <span>
          Created the application {applicationLink}
          {''}
        </span>
      );
    case 'follower':
      return (
        <span>
          <span className="contact-activities-stage">{followerLabel}</span>{' '}
          added as followers
        </span>
      );

    case 'application-document':
      return (
        <span>
          Uploaded document<span className="initial_small">(s)</span>
          <span className="contact-activities-stage initial-capital">
            from existing contact documents
          </span>
        </span>
      );

    case 'application-comment':
      return (
        <span>
          Added Comments in {' '}
          <span className="contact-activities-stage">
            {data?.data?.applicationStatus === "Completed" ? "Completed" : data?.data?.currentStage?.stage}
          </span>
        </span>
      );

    case 'branch':
      return (
        <span>
          Added branch{' '}
          <span className="contact-activities-stage">{data?.data?.name}</span>
        </span>
      );

    case 'deal':
      return <span>Added {dealLink}</span>;

    default:
      return <span>Created the application</span>;
  }
};
