import React from "react";
import { IMPORT_CONTACT } from "@moxie/constants";
import { DrawerElem } from "@shared-components/elements";
import { Steps } from "antd";
import { useSheetDataContext, useStepContext, useTransformedDataContext } from "./import-context";
import { MatchColumns, PreviewUploadedData, UploadFileStep, ValidateImportedContact } from "./steps";

interface IImportProps {
    drawerOpen: boolean;
    handleClose: () => void;
}

const { Step } = Steps;

const ImportDrawer = (props: IImportProps) => {
    const { currentStep, updateStep } = useStepContext();
    const { updateSheetData } = useSheetDataContext();
    const { updateTransformedData } = useTransformedDataContext();

    const onClose = () => {
        props.handleClose();
        updateStep(0);
        updateSheetData([]);
        updateTransformedData([]);
    }

    const importSteps = [
        {
            title: IMPORT_CONTACT.UPLOAD_FILE,
            content: <UploadFileStep />
        },
        {
            title: IMPORT_CONTACT.PREVIEW,
            content: <PreviewUploadedData />
        },
        {
            title: IMPORT_CONTACT.MATCH_COLUMNS,
            content: <MatchColumns />
        },
        {
            title: IMPORT_CONTACT.VALIDATE_DATA,
            content: <ValidateImportedContact drawerClose={onClose} />
        }
    ]

    return (
        <>
            <DrawerElem title={IMPORT_CONTACT.title} visible={props.drawerOpen} width={1300} onClose={onClose}>
                <Steps current={currentStep}>
                    {importSteps.map((item) =>
                        <Step key={item?.title} title={item?.title} />
                    )}
                </Steps>
                <div style={{ padding: '1rem' }}>{importSteps[currentStep]?.content}</div>
            </DrawerElem>
        </>
    )
}
export { ImportDrawer }

