import { ContactPayload } from '@model/crm/elastic-search';
import { TableProps } from 'antd/es/table';

const contactColumns: TableProps<ContactPayload>['columns'] = [
  {
    dataIndex: 'internal_id',
    title: 'ID'
  },
  {
    dataIndex: 'name',
    title: 'Name'
  },
  {
    dataIndex: 'email',
    title: 'Email'
  },
  {
    dataIndex: 'phone',
    title: 'Phone number'
  },
];

export default contactColumns;

