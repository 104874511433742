import { useState } from 'react';
import { FormInstance } from 'antd';
import { useFetchSuperAgents } from './useFetchPartnerAgents';

export const useFetchSuperAgentBranches = (form: FormInstance<any>) => {
  const [selectedSuperAgent, setSelectedSuperAgent] = useState<string | any>();
  const [superAgentStatus, setSuperAgentStatus] = useState(true);
  const { superAgents, isSuperAgentsLoading } = useFetchSuperAgents();
  const getMainBranchId = (data: any) => {
    const res = data?.find((d: any) => d.is_main_branch);
    return res?.id;
  };

  const handleSuperAgentChange = async (value: string) => {
    if (value !== 'false') {
      setSuperAgentStatus(true);
      const superAgentData = superAgents?.find(
        (item: any) => item.id === value
      );
      setSelectedSuperAgent(superAgentData);
      const mainBranchId = getMainBranchId(superAgentData?.branches);
      form.setFieldsValue({
        super_agent_branch: mainBranchId,
      });
    } else {
      setSuperAgentStatus(false);
      form.setFieldsValue({
        super_agent_branch: null,
      });
    }
  };

  return {
    superAgentsData: superAgents,
    isSuperAgentsLoading,
    selectedSuperAgent,
    setSelectedSuperAgent,
    superAgentStatus,
    setSuperAgentStatus,
    handleSuperAgentChange,
  };
};
