import { InputButtonWrapper } from '@crm/src/shared/input-button-wrapper';
import { UserAvatarGroup } from '@crm/src/shared/user-avatar-group';
import UnassignedCard from '@crm/src/shared/user-card/unassigned-card';
import { UpdateApplicationPayload } from '@model/application';
import { ActivitiesType } from '@model/contact-activity';
import { IGetPopupContainer } from '@model/data.model';
import { COUNTRIES } from '@moxie/constants';
import { successNotificationHandler } from '@moxie/shared';
import {
  Option,
  SearchableSelect,
  UserNameCard,
} from '@shared-components/elements';
import { DatePicker, Input, Select, Space, Typography } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { Moment } from 'moment';
import { ChangeEvent, useContext, useState } from 'react';
import ApplicationDetailContext from '../../../application-detail-context';
import useUpdateApplicationMutation from '../../../hooks/useUpdateApplicationMutation';
import { parseDate } from './application-details-tab';

const { Text } = Typography;

const formInfo = [
  {
    service: 'Visa',
    fields: [
      {
        field: 'visaTRN',
        label: 'Visa TRN',
        type: 'text',
      },
      {
        field: 'visaLodgementDate',
        label: 'Visa lodgment date',
        type: 'date',
      },
      {
        field: 'visaOutcomeDate',
        label: 'Visa outcome date',
        type: 'date',
        // format:""
      },
      {
        field: 'bridgingVisaType',
        label: 'Bridging visa type',
        type: 'number',
      },
      {
        field: 'bridgingVisaExpiry',
        label: 'Bridging visa expiry',
        type: 'date',
      },
      {
        field: 'countryOfPoliceCheck',
        label: 'Country of police check',
        type: 'select-country',
      },
      {
        field: 'policeCheckApplicationDate',
        label: 'Police check application date',
        type: 'date',
      },
      {
        field: 'policeCheckExpiryDate',
        label: 'Police check expiry date',
        type: 'date',
      },
      {
        field: 'HAPID',
        label: 'HAP ID',
        type: 'number',
      },
      {
        field: 'visaMedicalDate',
        label: 'Visa medical date',
        type: 'date',
      },
      {
        field: 'visaMedicalCountry',
        label: 'Visa medical country',
        type: 'select-country',
      },
      {
        field: 'RMA',
        label: 'RMA',
        type: 'searchbox-select',
      },
      {
        field: 'assessmentOccupation',
        label: 'Assessment occupation',
        type: 'text',
      },
      {
        field: 'skillsAssessmentBody',
        label: 'Skills assessment body',
        type: 'text',
      },
      {
        field: 'skillsAssessmentExpiry',
        label: 'Skills assessment expiry date',
        type: 'date',
      },
      {
        field: 'englishTestDate',
        label: 'English test date',
        type: 'date',
      },
      {
        field: 'englishTestExpiry',
        label: 'English test expiry date',
        type: 'date',
      },
    ],
  },
];

interface ActivitiesInfo {
  activitiesType: ActivitiesType;
  activitesLabel?: string;
}

export const VisaForm = () => {
  const { applicationData } = useContext(ApplicationDetailContext);
  const { workflowMetadata } = applicationData;
  const updateApplicationMutation = useUpdateApplicationMutation(
    applicationData,
    applicationData?.dealId
  );
  const initialInput = {
    visaTRN: workflowMetadata?.visaTRN ?? '',
    bridgingVisaType: workflowMetadata?.bridgingVisaType ?? '',
    HAPID: workflowMetadata?.HAPID ?? '',
    assessmentOccupation: workflowMetadata?.assessmentOccupation ?? '',
    skillsAssessmentBody: workflowMetadata?.skillsAssessmentBody ?? '',
  };
  const [input, setInput] = useState(initialInput);
  const [isRmaSelected, setIsRmaSelected] = useState(false);
  const [assigneeOptions, setAssigneeOptions] = useState(
    applicationData.assignees
  );

  const rmaUserList = applicationData?.assignees
    ?.filter((assignee) => workflowMetadata?.RMA?.includes(assignee.id))
    .map((assignee) => ({
      firstName: assignee.firstName,
      lastName: assignee.lastName,
    }));

  function updateData(
    payload: UpdateApplicationPayload,
    activitiesInfo: ActivitiesInfo,
    field: string
  ) {
    updateApplicationMutation.mutate(
      { payload, activitiesInfo },
      {
        onSuccess: () => {
          successNotificationHandler(`${field} updated successfully.`, 1.5);
        },
      }
    );
  }

  const handleSearch = (value: string) => {
    if (value) {
      setAssigneeOptions(
        applicationData.assignees.filter(
          (assignee) =>
            assignee.email?.toLowerCase().includes(value) ||
            assignee.firstName.toLowerCase().includes(value) ||
            assignee.lastName.toLowerCase().includes(value)
        )
      );
    } else {
      setAssigneeOptions(applicationData.assignees);
    }
  };

  return (
    <div className="form-container">
      {formInfo.map((form) =>
        form.fields.map((field) => {
          const activitiesInfo: ActivitiesInfo = {
            activitiesType: 'application-misc',
            activitesLabel: field.label,
          };

          if (field.type === 'text' || field.type === 'number') {
            const payload = {
              workflowMetadata: {
                ...workflowMetadata,
                [field.field]: input[field.field as keyof typeof input],
              },
            };
            const onCancel = () =>
              setInput((input) => ({
                ...input,
                [field.field]: initialInput[field.field as keyof typeof input],
              }));
            const onChange = (e: ChangeEvent<HTMLInputElement>) =>
              setInput((input) => ({
                ...input,
                [field.field]: e.target.value,
              }));

            return (
              <InputButtonWrapper
                onSubmit={() =>
                  updateData(payload, activitiesInfo, field.label)
                }
                onCancel={onCancel}
                key={field.field}
              >
                <Space direction="vertical">
                  <Text strong style={{ textTransform: 'capitalize' }}>
                    {field.label}
                  </Text>
                  <Input
                    type={field.type}
                    onChange={onChange}
                    value={input[field.field as keyof typeof input]}
                  />
                </Space>
              </InputButtonWrapper>
            );
          }

          if (field.type === 'date') {
            const getPayload = (data: Moment | null) => {
              return {
                workflowMetadata: {
                  ...workflowMetadata,
                  [field.field]: data?.format('DD/MM/YYYY') as string,
                },
              };
            };

            return (
              <Space direction="vertical" key={field.field}>
                <Text strong style={{ textTransform: 'capitalize' }}>
                  {field.label}
                </Text>
                <DatePicker
                  format="DD-MM-YYYY"
                  defaultValue={parseDate(
                    workflowMetadata?.[field.field as keyof typeof input],
                    'DD/MM/YYYY'
                  )}
                  picker="date"
                  onChange={(data) => {
                    updateData(getPayload(data), activitiesInfo, field.label);
                  }}
                  allowClear={false}
                />
              </Space>
            );
          }

          if (field.type === 'select-country') {
            const elementId = field.field + '-list';
            const defaultValue =
              workflowMetadata?.[field.field as keyof typeof input];
            const getPayload = (data: SelectValue) => {
              return {
                workflowMetadata: {
                  ...workflowMetadata,
                  [field.field]: data as string,
                },
              };
            };

            return (
              <Space direction="vertical" id={elementId} key={field.field}>
                <Text strong style={{ textTransform: 'capitalize' }}>
                  {field.label}
                </Text>
                <SearchableSelect
                  optionFilter="search_prop"
                  getPopupContainer={(): IGetPopupContainer =>
                    document.getElementById(elementId)
                  }
                  defaultValue={defaultValue}
                  style={{ width: '100%' }}
                  onChange={(data) => {
                    updateData(getPayload(data), activitiesInfo, field.label);
                  }}
                >
                  {COUNTRIES.map((item) => {
                    return (
                      <Option
                        value={item.name}
                        key={item.code}
                        search_prop={`${item.name} ${item.code}`}
                      >
                        {item.name}
                      </Option>
                    );
                  })}
                </SearchableSelect>
              </Space>
            );
          }

          if (field.type === 'searchbox-select') {
            return (
              <Space
                direction="vertical"
                id="visa-country-list"
                key={field.field}
              >
                <Text strong>RMA</Text>
                {isRmaSelected ? (
                  <Select
                    style={{ width: '100%' }}
                    defaultOpen={true}
                    className="assignee-select"
                    mode={'multiple'}
                    maxTagCount={1}
                    autoFocus
                    optionLabelProp="label"
                    optionFilterProp="label"
                    filterOption={false}
                    defaultValue={workflowMetadata?.RMA}
                    onChange={(value) => {
                      updateData(
                        {
                          workflowMetadata: {
                            ...workflowMetadata,
                            RMA: value,
                          },
                        },
                        activitiesInfo,
                        'RMA'
                      );
                      setAssigneeOptions(applicationData.assignees);
                    }}
                    onBlur={() => {
                      setIsRmaSelected(false);
                      setAssigneeOptions(applicationData.assignees);
                    }}
                    onSearch={(value) => handleSearch(value)}
                  >
                    {assigneeOptions.map((assignee) => {
                      return (
                        <Select.Option
                          key={assignee.id}
                          value={assignee.id}
                          label={`${assignee.firstName} ${assignee.lastName}`}
                        >
                          <UserNameCard
                            firstName={assignee.firstName}
                            lastName={assignee.lastName}
                            email={assignee.email}
                          />
                        </Select.Option>
                      );
                    })}
                  </Select>
                ) : (
                  <div onClick={() => setIsRmaSelected(true)}>
                    {rmaUserList.length ? (
                      <UserAvatarGroup userList={rmaUserList} />
                    ) : (
                      <UnassignedCard className="padding-left-2" />
                    )}
                  </div>
                )}
              </Space>
            );
          }

          return null;
        })
      )}
    </div>
  );
};
