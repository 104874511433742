import {
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons';
import React from 'react';
import { SearchBar } from './elastic-search';
import UserMenu from './user-menu';

interface IDashboardHeader {
  setCollapsed: (collapsed: boolean) => any;
  collapsed: boolean;
  setOpen: (val: boolean) => void
}
const DashboardHeader = ({ setCollapsed, collapsed, setOpen }: IDashboardHeader): React.ReactNode | any => {
  return (
    <nav className="dashboard-header__nav">
      <div
        onClick={() => setCollapsed(!collapsed)}
        className="dashboard-header__nav_trigger"
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </div>
      <div className="dashboard-header__nav_menu">
        <div className="padding-left-2">
          <SearchBar
            openSearchResults={() => setOpen(true)}
          />
        </div>
        <UserMenu />

      </div>
    </nav >
  );
};

export default React.memo(DashboardHeader);
