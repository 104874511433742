import React, { useContext, useEffect } from 'react';
import { Badge, Card, Row, Steps } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import ApplicationDetailContext from '../../application-detail-context';
import { WorkflowApplicationStage } from '@model/workflow';
const { Step } = Steps;

const ApplicationStages: React.FC = () => {
  const { applicationData: application, isCompletedStage, isCurrentStage, isSelectedStage, setSelectedStage}  = useContext(ApplicationDetailContext);

  const stages = application?.workflow.workflowApplicationStages;
  const currentStage = application?.activeStage;
  const isCompletedApplication = application?.status === 'Completed';
  const getStageDescription = (stageInfo: WorkflowApplicationStage) => {
    if (isCompletedApplication || isCompletedStage(stageInfo)) {
      return (
        <span
          style={{ marginTop: '0' }}
          className="crm-application-stage-description"
        >
          Completed
        </span>
      );
    }
    if (!isCompletedApplication && isCurrentStage(stageInfo)) {
      return (
        <Badge
          className="crm-application-stage-description"
          style={{ color: '#275A1D' }}
          status="success"
          text="Current Stage"
          color="#275A1D"
        />
      );
    }
    return;
  };

  const scrollToCurrentStage = () => {
    const el = document.querySelector('.ant-steps-item-active');
    if (el) {
      el?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  useEffect(() => {
    scrollToCurrentStage();
  }, [currentStage]);

  return (
    <>
      <Card
        id="crm-application-stage-card"
        style={{ height: '100%', position: 'relative' }}
      >
        <Row
          id="crm-application-stages"
          style={{ height: 'auto', marginLeft: '2rem' }}
          className="crm-application-stage-sb margin-top-2"
          align="middle"
          justify="center"
        >
          <Steps
            direction="vertical"
            current={currentStage?.level}
            className="crm-application-stage-stepper-container crm-application-stage-stepper"
          >
            {stages.map((stage) => {
              return (
                <Step
                  icon={
                    isCompletedApplication || isCompletedStage(stage) ? (
                      <CheckCircleFilled className="crm-application-stage-step-icon-complete" />
                    ) : null
                  }
                  className={
                    isSelectedStage(stage)
                      ? 'crm-application-stage-step crm-application-stage-step-selected initial_capital'
                      : 'crm-application-stage-step initial_capital'
                  }
                  key={stage?.level}
                  title={stage.stage}
                  description={getStageDescription(stage)}
                  onClick={() => setSelectedStage({
                    level:stage.level,
                    stage: stage.stage,
                    wfAppStageId: stage.id
                  })}
                />
              );
            })}
            <Step
              icon={
                isCompletedApplication ? (
                  <CheckCircleFilled
                    className="crm-application-stage-step-icon-complete initial_capital"
                    style={{ color: 'green' }}
                  />
                ) : null
              }
              className={
                isCompletedApplication
                  ? 'crm-application-stage-step crm-application-stage-step-completed initial_capital'
                  : 'crm-application-stage-step initial_capital'
              }
              title="Complete"
              onClick={() => {
                if (isCompletedApplication) {
                  setSelectedStage({
                    level: null,
                    stage: null,
                    wfAppStageId: null
                  });
                }
              }}
            />
          </Steps>
        </Row>
      </Card>
    </>
  );
};

export default ApplicationStages;
