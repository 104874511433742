import moment from 'moment';
import { useContext } from 'react';
import ApplicationDetailContext from '../../../application-detail-context';
import { fieldsData, IFormInfo, IFormInitialInput } from '../fields-data';
import { EducationForm } from './education-form';
import { VisaForm } from './visa-form';
import { TestPreparationForm } from './test-preparation-form';

type DateFormat = 'MMMM YYYY' | 'DD/MM/YYYY';

export const parseDate = (date: string | undefined, dateFormat: DateFormat) => {
  if (moment(date, dateFormat, true).isValid()) {
    return moment(date, dateFormat);
  }
  return undefined;
};

export const ApplicationDetailsTab = () => {
  const { service } = useContext(ApplicationDetailContext);

  return (
    <>
      {fieldsData.map((form: IFormInfo<IFormInitialInput>) => {
        if (service === 'Education' && form.service === "Education") {
          return <EducationForm key={form.service} />;
        }
        if (service === 'Visa Application' && form.service === "Visa") {
          return <VisaForm key={form.service} fields={form.fields} />;
        }
        if ([service, form.service].every(val => val === 'Test Preparation')) {
          return <TestPreparationForm key={form.service} fieldsData={form.fields} />;
        }
        return null
      })
      }
    </>
  )
};
