import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row, Space, Spin, Tag, Typography } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  LoadingOutlined
} from '@ant-design/icons';

import { NO_FOLLOWERS, TEXT } from '@moxie/constants';
import { UserAvatarGroup } from '@shared-components/elements';
import { contactActions, officeBranchActions, useAppSelector } from '@crm/core';
import { useDispatch } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import { TableAssignee, UnassignedCard, UserNameCard } from '../shared';
import { capitalizeAllLetter } from '@moxie/shared';

import BranchForm from './BranchForm';
import Branches from './Branches';
import { ContactEditForm } from '@model/contact';
import isEqual from 'lodash/isEqual';
import { IUserData } from 'libs/shared/src/crm-modules/user/user.model';
import FollowerSearchBox from '../shared/follower-search-box';

const { Text } = Typography;

const OverviewContent = () => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const [openEditForm, setEditForm] = useState<ContactEditForm>(undefined);

  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);

  const {
    followersId: followersId,
    followersData: followers,
    assigneeData: assignee,
    singleData: contact,
    loading,
    secondaryBranches,
    branch,
  } = useAppSelector((state) => state.contact);

  const contactBranches = secondaryBranches
    .concat(branch ?? ({} as any))
    .filter(Boolean)
    .map((branch) => ({
      ...branch,
      isPrimary: branch?.id === branch.id,
    }));

  const editFollowers = useMemo(() => openEditForm === 'follower', [
    openEditForm,
  ]);
  const editAssignee = useMemo(() => openEditForm === 'assignee', [
    openEditForm,
  ]);
  const editBranch = useMemo(() => openEditForm === 'branch', [openEditForm]);

  useEffect(() => {
    dispatch(officeBranchActions.getAllRequest());
  }, []);

  useEffect(() => {
    form.setFieldsValue({ followers: followersId });
  }, [followers]);

  const disabledBranches = React.useMemo(() => {
    if (followers?.length || assignee) {
      return followers
        .map((follower: IUserData) => follower?.branch?.id)
        .concat(assignee ? assignee!.branch?.id : '')
        .filter(Boolean) as string[];
    }
    return [];
  }, [assignee, followers]);

  const enableAssigneeEdit = () => {
    if (contact?.archived !== true) {
      setEditForm('assignee');
    }
  };
  const enableFollowersEdit = () => {
    if (contact?.archived !== true) {
      setEditForm('follower');
      form.setFieldsValue({ followersId: followersId });
    }
  };

  const updateAssigneeSubmit = async (value: string) => {
    const assigneeId = value === '' ? null : value;
    const assigneeData = {
      id: contact?.id,
      assigneeId,
      updateType: 'assignee-changed',
    };

    dispatch(
      contactActions.updateContactAssigneeRequest(assigneeData, (response) => {
        socket?.emit('server::assignee-updated', {
          userId: user?.id as string,
          contactId: contact?.id as string,
          receiverId: assigneeId,
          companyId: user?.companyId as string,
          removedAssignee: (assignee?.id ?? null),
          activitiesType: 'contact',
          activitiesAction: 'assigned',
          activitiesTypeId: contact?.id,
          previousAssignedUserId: assignee?.id,
        });
      })
    );
    handleCancel();
  };
  const handleCancel = () => setEditForm(undefined);

  const handleFollowersSubmit = (values: { followers: string[] }) => {
    const followerUsers = values.followers.map((item) => ({ id: item }));
    const initialFollowers = contact?.followers?.map((item: any) => item.id);
    if (values) {
      const followersData = {
        id: contact?.id,
        followers: followerUsers,
        updateType: 'followers-changed',
      };

      const addedFollowers = values.followers.filter(
        (currentFollower: string) => {
          let isAdded = true;
          initialFollowers?.forEach((initialFollower: string) => {
            if (currentFollower === initialFollower) {
              isAdded = false;
            }
          });
          return isAdded;
        }
      );

      const removedFollowers = initialFollowers?.filter(
        (initialFollower: string) => {
          let isRemoved = true;
          values.followers.forEach((currentFollower: string) => {
            if (currentFollower === initialFollower) {
              isRemoved = false;
            }
          });
          return isRemoved;
        }
      );

      const remainingFollowers = isEqual(values.followers, contact.followers)
        ? []
        : (values.followers
          .filter((id: string) => contact.followers!.includes(id))
          .concat(contact?.assigneeId) as string[]);

      contact?.id &&
        dispatch(
          contactActions.updateContactFollowersRequest(
            followersData,
            (response: any) => {
              socket?.emit('server::followers-updated', {
                userId: user?.id,
                contactId: contact.id,
                companyId: user?.companyId,
                data: response,
                addedFollowers,
                removedFollowers: removedFollowers as string[],
                remainingFollowers,
                activitiesTypeId: contact.id,
              });
            }
          )
        );
      handleCloseFollowersForm();
    }
  };

  const handleCloseFollowersForm = () => {
    setEditForm(undefined);
  };
  return (
    <div className="padding-top-1 overview-content-margin">
      <Row>
        <div className="flex-direction-vertical">
          <h4 className="text-primary">
            <span>{TEXT.INTERNAL_ID}</span>
            <span className="margin-left-1-half-px">:</span>
          </h4>
          {/* TODO:: ADD COMPANY CODE */}
          {loading ? (
            '-'
          ) : (
            <Tag>
              {contact?.user?.company?.comapanyCode
                ? capitalizeAllLetter(contact?.user?.company?.comapanyCode) +
                '-' +
                contact?.internalId
                : contact?.internalId}
            </Tag>
          )}
        </div>
      </Row>
      <Row className="padding-top-2">
        <div className="flex-direction-vertical">
          <h4 className="text-primary">
            <span>{TEXT.CONTACT_TYPE}</span>
            <span className="margin-left-1-half-px">:</span>
          </h4>
          <Space direction="horizontal">
            <Text className="font-weight-500 margin-top-minus-5-px text-grey">
              {contact?.status ? contact?.status : '-'}
            </Text>
            {contact?.archived ? (
              <Tag className="tag-archived margin-left-2">{TEXT.ARCHIVED}</Tag>
            ) : (
              ''
            )}
          </Space>
        </div>
      </Row>
      <Row className="padding-top-2">
        <div className="flex-direction-vertical">
          <h4 className="text-primary">
            <span>{TEXT.BRANCH}</span>
            <span className="margin-left-1-half-px">:</span>
            <Typography.Link
              className="margin-left-1"
              onClick={() => setEditForm('branch')}
            >
              <EditOutlined />
            </Typography.Link>
          </h4>
          {editBranch ? (
            <BranchForm
              selectedBranches={contactBranches}
              onCancel={() => setEditForm(undefined)}
              disabledBranches={disabledBranches}
            />
          ) : loading ? (
            <Spin
              spinning={loading}
              indicator={
                <LoadingOutlined className="text-grey padding-bottom-2" />
              }
            >
              {' '}
            </Spin>
          ) : (
            <Branches
              primaryBranch={branch}
              secondaryBranches={secondaryBranches}
            />
          )}
        </div>
      </Row>
      <Row>
        <div className="flex-direction-vertical">
          <h4 className="text-primary">
            <span>{TEXT.CREATED_BY}</span>
            <span className="margin-left-1-half-px">:</span>
          </h4>
          <Text className="font-weight-500 margin-top-minus-5-px text-grey initial_capital">
            {contact?.createdBy
              ? `${contact?.createdBy?.firstName} ${contact?.createdBy?.lastName}`
              : '-'}
          </Text>
        </div>
      </Row>
      <Row className="padding-top-2">
        <div className="flex-direction-vertical">
          <h4 className="text-primary">
            <span>{TEXT.ASSIGNED_TO}</span>
            <span className="margin-left-1-half-px">:</span>
          </h4>
          {!assignee && !editAssignee && (
            <div className="cursor-pointer" onClick={enableAssigneeEdit}>
              <UnassignedCard />
            </div>
          )}
          {assignee && !editAssignee && (
            <div className="cursor-pointer" onClick={enableAssigneeEdit}>
              <UserNameCard
                firstName={assignee?.firstName || ''}
                lastName={assignee?.lastName || ''}
                email={assignee?.email || ''}
              />
            </div>
          )}
          {editAssignee && !contact?.archived && (
            <TableAssignee
              handleSubmit={updateAssigneeSubmit}
              assigneeId={assignee?.id ?? null}
              contactId={contact.id}
              handleCancel={handleCancel}
            />
          )}
        </div>
      </Row>
      <Row className="padding-top-2">
        <div className="flex-direction-vertical">
          <h4 className="text-primary">
            <span>{TEXT.FOLLOWERS}</span>
            <span className="margin-left-1-half-px">:</span>
          </h4>
          <div>
            {followers?.length > 0 && !editFollowers ? (
              <div className="profile__user" onClick={enableFollowersEdit}>
                <UserAvatarGroup userList={followers} />
              </div>
            ) : (
              !editFollowers && (
                <div onClick={enableFollowersEdit}>
                  <Typography.Text
                    type="secondary"
                    className="font-weight-500 margin-top-minus-5-px"
                  >
                    {NO_FOLLOWERS}
                  </Typography.Text>
                </div>
              )
            )}
            {editFollowers && (
              <Form form={form} onFinish={handleFollowersSubmit}>
                <Row>
                  <Col>
                    <Form.Item className="follower_overflow" name="followers">
                      <FollowerSearchBox
                        contactId={contact.id}
                        className="overview_followers_select_width"
                        filter={{
                          contactId: contact.id,
                        }}
                        value={followersId}
                      />
                    </Form.Item>
                  </Col>

                  <Button
                    icon={<CheckOutlined />}
                    type="link"
                    htmlType="submit"
                    size="small"
                    className="padding-left-1"
                  />
                  <Button
                    icon={<CloseOutlined />}
                    onClick={handleCloseFollowersForm}
                    htmlType="reset"
                    type="link"
                    danger
                    size="small"
                  />
                </Row>
              </Form>
            )}
          </div>
        </div>
      </Row>
    </div>
  );
};
export { OverviewContent };
