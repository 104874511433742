import React, { useContext, useEffect, useMemo } from 'react';
import HeaderMenu from './components/header-menu';
import ProductDetail from './components/product-detail';
import EnrollmentDetail from './components/enrollment-detail';
import { CheckCircleFilled } from '@ant-design/icons';
import Documents from './components/documents';
import Empty from 'antd/lib/empty';
import Footer from './components/footer';
import { useDispatch } from 'react-redux';
import { userActions } from '@crm/core';
import ApplicationDetailContext from '../../application-detail-context';

const DetailSection = () => {
  const { applicationData: application, selectedStage } = useContext(ApplicationDetailContext);

  const applicationIdentifier = `${application?.appIdentifier}`;
  const isCompletedApplication = application?.status === 'Completed';
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.fetchUsers());
    return () => {
      dispatch(userActions.clearUserFetch());
    };
  }, []);

  const completeStatus = useMemo(() => (isCompletedApplication && selectedStage?.wfAppStageId === undefined), [isCompletedApplication, selectedStage])

  if (!application) {
    return null
  }
  return (
    <>
      <div
        style={{
          position: 'relative',
          height: '100%',
        }}
      >
        <HeaderMenu applicationIdentifier={applicationIdentifier} />
        <ProductDetail application={application} />
        <EnrollmentDetail application={application} />
        {!completeStatus ? <Documents /> : (
          <div
            className="crm-application-complete-container"
          >
            <Empty
              image={<CheckCircleFilled className="complete-icon-filled" />}
              description="Completed"
            />
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};

export default DetailSection;
