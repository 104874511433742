import React from 'react';
import { Table, Typography } from 'antd';
import DocumentNameCard from './DocumentNameCard';
import { Media } from '@model/document';
import { SelectedStage } from '@model/application';
import { Checklist } from '@model/workflow';

const { Text } = Typography;

interface Props {
  checklistDocs: Media[];
  isLoadingDocs: boolean;
  selectedStage: SelectedStage;
  selectedChecklist: Checklist | undefined;
  applicationId: string;
}
const DocumentDetailsTable: React.FC<Props> = ({
  checklistDocs,
  isLoadingDocs,
  selectedStage,
  selectedChecklist,
  applicationId,
}) => {
  const documentDetailsColumns = [
    {
      title: 'SN',
      key: 'id',
      render: (x: any, d: any, i: number) => i + 1,
    },
    {
      title: 'File Name',
      width: '24rem',
      render: (value: Media) => (
        <DocumentNameCard
          document={value}
          selectedStage={selectedStage}
          selectedChecklist={selectedChecklist}
          applicationId={applicationId}
        />
      ),
    },
    {
      title: 'Uploaded Date',
      key: 'id',
      width:"150px",
      render: (value: Media) => {
        return (
          <>
            <Text className="initial_capital">
              {new Date(value?.createdAt).toDateString()}
            </Text>
          </>
        );
      },
    },
  ];
  return (
    <div style={{ padding: '0 1rem' }}>
      <Table
        columns={documentDetailsColumns}
        dataSource={checklistDocs}
        loading={isLoadingDocs}
        pagination={false}
        scroll={{ y: '38vh' }}
      />
    </div>
  );
};

export default DocumentDetailsTable;
