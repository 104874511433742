import { useFetchUnReadNotificationCount } from '@crm/libs/hooks/users';
import Badge from 'antd/es/badge';
import Menu from 'antd/es/menu';
import SubMenu from 'antd/es/menu/SubMenu';
import React from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import BellOutlined from '@ant-design/icons/BellOutlined';
import NotificationMenu from './notification-menu';
import Avatar from 'antd/es/avatar';
import { authActions, useAppSelector } from '@crm/core';
import { capitalizeFirstLetter, successNotificationHandler } from '@moxie/shared';
import DownOutlined from '@ant-design/icons/DownOutlined';
import { AUTH_MESSAGE, CRM_COOKIE_ACCESS, CRM_COOKIE_REFRESH, DASHBOARD_ROUTES } from '@moxie/constants';
import UserOutlined from '@ant-design/icons/UserOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import ApartmentOutlined from '@ant-design/icons/ApartmentOutlined';
import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import { logoutUser } from '@crm/services.api';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';

const UserMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { count } = useFetchUnReadNotificationCount();
  const currentUser = useAppSelector((store) => store.auth.user);

  const handleLogout = async () => {
    await logoutUser();
    Cookies.remove(CRM_COOKIE_ACCESS);
    Cookies.remove(CRM_COOKIE_REFRESH);
    dispatch(authActions.logout());
    history.push('/');
    successNotificationHandler(AUTH_MESSAGE.LOGOUT);
  };

  return (
    <Menu
      mode="horizontal"
      triggerSubMenuAction="click"
      selectedKeys={[location.pathname]}
    >
      <SubMenu
        popupOffset={[-20, 0]}
        key="notificationMenu"
        title={
          <Badge size="small" count={count}>
            <BellOutlined />
          </Badge>
        }
      >
        <NotificationMenu />
      </SubMenu>
      <SubMenu
        popupOffset={[8, 0]}
        key="profileMenu"
        className="lyra-dashboard__layout_header_dropmenu"
        title={
          <div className="drop-menu">
            <div className="drop-menu__info">
              <Avatar size="large" className="drop-menu__avatar">
                {currentUser?.firstName?.substring(0, 1)?.toUpperCase()}
                {currentUser?.lastName?.substring(0, 1)?.toUpperCase()}
              </Avatar>
              <div className="drop-menu__text">
                <h4>
                  {capitalizeFirstLetter(currentUser?.firstName as string)} {capitalizeFirstLetter(currentUser?.lastName as string)}
                </h4>
                <p>{currentUser?.email}</p>
              </div>
            </div>
            <DownOutlined />
          </div>
        }
      >
        <Menu.Divider />
        <Menu.Item
          key={DASHBOARD_ROUTES.profile.path}
          icon={<UserOutlined />}
        >
          <NavLink exact to={DASHBOARD_ROUTES.profile.path}>
            {DASHBOARD_ROUTES.profile.name}
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key={DASHBOARD_ROUTES.accountSettings.path}
          icon={<SettingOutlined />}
        >
          <NavLink exact to={DASHBOARD_ROUTES.accountSettings.path}>
            {DASHBOARD_ROUTES.accountSettings.name}
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key={DASHBOARD_ROUTES.settings.path}
          icon={<ApartmentOutlined />}
        >
          <NavLink exact to={DASHBOARD_ROUTES.settings.path}>
            {DASHBOARD_ROUTES.settings.name}
          </NavLink>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="2"
          icon={<LogoutOutlined />}
          onClick={handleLogout}
          danger
        >
          {DASHBOARD_ROUTES.logout.name}
        </Menu.Item>
      </SubMenu>
    </Menu>
  )
}

export default UserMenu;
