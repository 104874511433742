import { DealPayload } from "@model/crm/elastic-search";
import { TableProps } from "antd/es/table";

const dealStatusMap = {
  'discovery': 'Discovery',
  'in-progress': 'In Progress',
  'completed': 'Completed',
  'lost': 'Lost',
  'archived': 'Archived'
} as const;

const dealColumns: TableProps<DealPayload>['columns'] = [
  {
    dataIndex: 'internal_id',
    title: 'ID'
  },
  {
    dataIndex: 'name',
    title: 'Name'
  },
  {
    dataIndex: 'contact_name',
    title: 'Contact'
  },
  {
    dataIndex: 'status',
    title: 'Status',
    render(value: keyof typeof dealStatusMap) {
      return (
        <span>{dealStatusMap[value]}</span>
      )
    }
  }
]

export default dealColumns;
