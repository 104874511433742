import moment from 'moment';
import { useContext } from 'react';
import ApplicationDetailContext from '../../../application-detail-context';
import { EducationForm } from './education-form';
import { VisaForm } from './visa-form';

type DateFormat = 'MMMM YYYY' | 'DD/MM/YYYY';

export const parseDate = (date: string | undefined, dateFormat: DateFormat) => {
  if (moment(date, dateFormat, true).isValid()) {
    return moment(date, dateFormat);
  }
  return undefined;
};

export const ApplicationDetailsTab = () => {
  const { service } = useContext(ApplicationDetailContext);

  if (service === 'Education') {
    return <EducationForm />;
  }

  if (service === 'Visa Application') {
    return <VisaForm />;
  }

  return null;
};
