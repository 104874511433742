import { useQuery } from '@tanstack/react-query';
import { errorNotificationHandler } from '@moxie/shared';
import { crmAxios } from '@crm/services.api';
import { ApplicationDetail } from '@model/application';

export const useApplicationDetails = (id: string) => {
  const { data, isLoading: isLoadingApplication, refetch } = useQuery({
    queryKey: ['application-details', id],
    queryFn: async () =>
      (await crmAxios.get<{data: ApplicationDetail}>(`/v1/applications/${id}`)).data.data,
    enabled: true,
    onError: () => {
      errorNotificationHandler('Error fetching details.');
    },
  });

  return { applicationData: data, isLoadingApplication, refetch };
};
