import {
  ICountry,
  IOfficeBranch,
  IService,
} from '@model/data.model';
import { API_URL } from '@moxie/constants';
import crmAxios from './crm-api-axios';
import { IApplicationReportCrm, IReportWorkflow } from '@model/crm/application-report.model';
import { IUserData } from 'libs/shared/src/crm-modules/user/user.model';
import { CrmResponse } from '@model/api-response.model';
import { Application, ApplicationComment, ApplicationCommentPayload, CreateApplicationPayload, UpdateApplicationPayload } from '@model/application';

interface INameId {
  id: string;
  name: string;
}

const postCRMApplicationComments = async (
  body: ApplicationCommentPayload
) => {
  return crmAxios.post(
    API_URL.CRM_APPLICATION_COMMENTS,
    body
  );
};

const fetchApplications = async (params: Record<string, unknown>) => {
  const { data } = await crmAxios.get<CrmResponse<Application>>(
    API_URL.CRM_API_APPLICATION,
    {
      params,
    }
  )
  return data;
}

const getCRMApplicationComments = async (
  params: Record<string, unknown>
) => {
  return crmAxios.get<CrmResponse<ApplicationComment>>(API_URL.CRM_APPLICATION_COMMENTS, {
    params
  });
};


const getApplicationsReport = async (params: Record<string, unknown>) => {
  const { data } = await crmAxios.get<CrmResponse<IApplicationReportCrm>>(
    `${API_URL.CRM_API_APPLICATION}/reports`,
    {
      params,
    }
  );
  return data;
};

const getApplicationsReportFilters = async () => {
  return (
    await crmAxios.get<{
      data: {
        states: string[];
        passportCountries: string[];
        countries: string[];
      };
    }>(`${API_URL.CRM_API_APPLICATION}/contact-filters`)
  ).data;
};

const getApplicationContactState = async () => {
  return (
    await crmAxios.get<{
      data: {
        states: string[];
        countryOfPassport: string[];
        countries: string[];
      };
    }>(`${API_URL.CRM_API_APPLICATION}/states`)
  ).data;
};

const getApplicationsReportAssignees = async (params?: Record<string, unknown>) => {
  return (
    await crmAxios.get<CrmResponse<IUserData>>(
      `${API_URL.CRM_API_APPLICATION}/assignees`, {
      params
    }
    )
  ).data;
};

const getApplicationsReportBranches = async () => {
  return (
    await crmAxios.get<{
      data: Required<IOfficeBranch>[];
    }>(`${API_URL.CRM_API_APPLICATION}/processing-branches`)
  ).data;
};

const fetchWorkFlows = async () => {
  return (
    await crmAxios.get<{
      data: { workFlows: IReportWorkflow[]; };
    }>(`${API_URL.CRM_API_APPLICATION}/workflows`)
  ).data;
};

const fetchInstitutions = async () => {
  return (
    await crmAxios.get<{
      data: INameId[];
    }>(`${API_URL.CRM_API_APPLICATION}/applied-institutions`)
  ).data;
};

const fetchCurrentStage = async () => {
  return (
    await crmAxios.get<{
      data: { id: string; stage: string }[];
    }>(`${API_URL.CRM_API_APPLICATION}/current-stages`)
  ).data;
};


const updateApplication = (applicationId: string, payload: UpdateApplicationPayload) => {
  return crmAxios.patch(`/v1/applications/${applicationId}`, payload)
}

const createApplication = (payload: CreateApplicationPayload) => crmAxios.post<{ data: Application }>(API_URL.CRM_API_APPLICATION, payload);

const fetchAppReportProductTypes = async () => {
  return (
    await crmAxios.get<{ data: string[] }>(`${API_URL.CRM_API_APPLICATION}/product-types`)
  ).data;
}

const fetchAppReportProductSubTypes = async () => {
  return (
    await crmAxios.get<{ data: string[] }>(`${API_URL.CRM_API_APPLICATION}/product-sub-types`)
  ).data;
}

const fetchInstitutionServices = async (params?: Record<string, unknown>) => {
  return (
    await crmAxios.get<{ data: INameId[] }>(`${API_URL.CRM_API_APPLICATION}/institution-services`)
  ).data;
}


const getApplicationServices = async (params?: Record<string, unknown>): Promise<CrmResponse<IService>> => {
  const { data } = await crmAxios.get(`${API_URL.CRM_API_APPLICATION}/institution-services`, {
    params: {
      ...params
    }
  });
  return data;
}


const fetchInstitutionCountries = async () => {
  return (
    await crmAxios.get<{ data: { country: string }[] }>(`${API_URL.CRM_API_APPLICATION}/institution-countries`)
  ).data;
}

const fetchAppReportSuperAgents = async (params?: Record<string, unknown>) => {
  return (
    await crmAxios.get<CrmResponse<INameId>>(`${API_URL.CRM_API_APPLICATION}/super-agents`, {
      params
    })
  ).data;
}

const fetchAppReportSubAgents = async (params?: Record<string, unknown>) => {
  return (
    await crmAxios.get<CrmResponse<INameId>>(`${API_URL.CRM_API_APPLICATION}/sub-agents`, { params })
  ).data;
}

const bulkDiscontinueCrmApplications = async (ids: string[]) => {
  return crmAxios.put(`${API_URL.CRM_API_APPLICATION}/bulk-discontinue`, { ids });
};

const getApplciationCountries = async (params?: Record<string, unknown>): Promise<CrmResponse<ICountry>> => {
  const { data } = await crmAxios.get(`${API_URL.CRM_API_APPLICATION}/countries`, {
    params: {
      ...params
    }
  });
  return data;
}

export {
  fetchWorkFlows,
  getApplicationsReport,
  getApplicationsReportAssignees,
  getApplicationsReportBranches,
  getApplicationsReportFilters,
  getCRMApplicationComments,
  postCRMApplicationComments,
  fetchInstitutions,
  updateApplication,
  createApplication,
  fetchCurrentStage,
  getApplicationContactState,
  fetchAppReportProductTypes,
  fetchInstitutionServices,
  fetchAppReportProductSubTypes,
  fetchInstitutionCountries,
  fetchAppReportSuperAgents,
  fetchAppReportSubAgents,
  bulkDiscontinueCrmApplications,
  fetchApplications,
  getApplicationServices,
  getApplciationCountries,
};
