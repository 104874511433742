import { updateMedia, uploadExistingDocs } from '@crm/services.api';
import { UpdateMedia, UploadExistingDocsPayload } from '@model/document';
import {
  errorNotificationHandler,
  successNotificationHandler,
} from '@moxie/shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from 'apps/crm/src/libs/services.api/lib/axios';

const deleteDocument = (docId: string) => {
  return axiosInstance.delete(`/crm/documents/${docId}/`);
};

export const useDocumentMutation = (documentId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: UpdateMedia) => updateMedia(documentId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['application-checklist-docs'],
      });
      queryClient.invalidateQueries({
        queryKey: ['documents-by-category'],
      });
    },
    onError: () => {
      errorNotificationHandler('Error updating document.');
    },
  });
};

export const useDocumentDeletion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteDocument,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['application-checklist-docs'],
      });
      queryClient.invalidateQueries({ queryKey: ['stage-documents'] });
      queryClient.invalidateQueries({ queryKey: ['documents-by-category'] });
      successNotificationHandler('Document deleted successfully.', 1.5);
    },
    onError: () => {
      errorNotificationHandler('Error deleting document.');
    },
  });
};

export const useUploadExistingDocs = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: UploadExistingDocsPayload[]) => uploadExistingDocs(payload),
    onSuccess: async () => {

      await queryClient.invalidateQueries({
        queryKey: ['application-checklist-docs']
      })
      await queryClient.invalidateQueries({
        queryKey: ['stage-documents']
      })
      successNotificationHandler('Document uploaded successfully.', 1.5);
    }
  })
}
