import { leadActions, useAppSelector } from '@crm/core';
import {
  PAGE_HEADER_MENU_PROSPECT,
  SLUG_ALL_PROSPECTS,
  TEXT,
} from '@moxie/constants';
import { PageHeaderTitle } from '@shared-components/elements';
import { ContactListRouteParam, IContactCrm } from '@shared-components/models';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useAssigneeChanged } from '@crm/libs/hooks/contacts';
import { PageHeaderMenu } from '../../shared/page-header-menu';
import { LeadAddEdit } from '../contact/components/contact-add-edit';
import { ProspectTableList } from './prospect-table-list';

const ProspectComponent: React.FC = () => {
  const { name } = useParams<ContactListRouteParam>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [initialData, setInitialData] = useState<IContactCrm>();
  const dispatch = useDispatch();
  const history = useHistory();

  const { refresh, triggerRefresh } = useAssigneeChanged();

  if (useAppSelector((item) => item.leads.redirect)) {
    dispatch(leadActions.clearLeads());
  }

  const onClose = () => {
    setIsDrawerOpen(false);
    setDisabled(true);
    setIsSubmitted(false);
  };

  const handleAfterLeadUpdate = () => {
    dispatch(leadActions.clearLeads());
    history.push(`/prospects/${SLUG_ALL_PROSPECTS}`);
    triggerRefresh();
  };

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.PROSPECTS} />
      <PageHeaderMenu
        menu={PAGE_HEADER_MENU_PROSPECT}
        defaultActiveKey={name}
      />
      <LeadAddEdit
        isOpen={isDrawerOpen}
        onAfterSuccess={handleAfterLeadUpdate}
        onClose={onClose}
        initialData={initialData}
        triggerRefresh={triggerRefresh}
        setDisabled={setDisabled}
        disabled={disabled}
        submitted={isSubmitted}
        setSubmitted={setIsSubmitted}
        isEdit={isEditMode}
      />

      <ProspectTableList
        refresh={refresh}
        triggerRefresh={triggerRefresh}
        setInitialData={setInitialData}
        setIsDrawerOpen={setIsDrawerOpen}
        setIsEditMode={setIsEditMode}
      />
    </div>
  );
};

export { ProspectComponent };
