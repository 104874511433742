import React from 'react';
import { ChangeLog, ContactActivity } from '@model/contact-activity';
import { Link } from 'react-router-dom';
import { UNASSIGNED_LABEL } from '@moxie/constants';
import { CreatedActivityMessage } from './created-activity-message';
import { DeletedActivityMessage } from './deleted-activity-message';
import { UpdatedActivityMessage } from './updated-activity-message';

interface Props
  extends Pick<
  ContactActivity,
  | 'activitiesAction'
  | 'activitiesType'
  | 'data'
  | 'assignedUser'
  | 'previousAssignedUser'
  | 'follower'
  | 'activitiesTypeId'
  | 'contactId'
  > {
  showLink: boolean;
}
const ActivityMessage: React.FC<Props> = ({
  activitiesAction: activities_action,
  activitiesType: activities_type,
  activitiesTypeId: activities_type_id,
  data,
  previousAssignedUser: previous_assigned_user,
  assignedUser: assigned_user,
  follower,
  showLink,
  contactId,
}) => {

  let previouslyAssignedUser;
  let assignedUser;

  if (!previous_assigned_user) {
    previouslyAssignedUser = UNASSIGNED_LABEL;
  } else {
    previouslyAssignedUser = `${previous_assigned_user?.firstName} ${previous_assigned_user?.lastName}`;
  }

  if (!assigned_user) {
    assignedUser = UNASSIGNED_LABEL;
  } else {
    assignedUser = `${assigned_user?.firstName} ${assigned_user?.lastName}`;
  }

  const followerLabel = `${follower?.firstName ?? ''} ${follower?.lastName ?? ''
    }`;
  const companyCode = `${data?.data?.company?.company_code ? data?.data?.company?.company_code + '-' : ''}${data?.data?.appIdentifier}`;

  const applicationLink = React.useMemo(() => {
    if (showLink) {
      return (
        <Link
          className="initial_capital"
          to={`/application/detail/${data?.data?.contactId}/${data?.data?.id}`}
        >
          for the application {companyCode}
        </Link>
      );
    }

    return showLink;
  }, [companyCode, data, showLink]);

  const dealLink = React.useMemo(() => {
    if (showLink) {
      return (
        <span> The Deal
          < Link
            className="initial_capital"
            to={`/contact/${contactId}/deals/${activities_type_id}`
            }
          >
            <span>{' '}</span>
            {`${data?.dealInternalId}`}
          </Link >
        </span>
      );
    }

    return showLink;
  }, [companyCode, data, showLink]);


  switch (activities_action) {
    case 'created':
      return (
        <CreatedActivityMessage
          activitiesType={activities_type}
          data={data as ChangeLog}
          followerLabel={followerLabel}
          dealLink={dealLink}
          applicationLink={applicationLink}
        />
      )

    case 'deleted':
      return (
        <DeletedActivityMessage
          activitiesType={activities_type}
          data={data as ChangeLog}
          followerLabel={followerLabel}
        />
      )

    case 'updated':
      return (
        <UpdatedActivityMessage
          activitiesType={activities_type}
          data={data as ChangeLog}
          followerLabel={followerLabel}
          dealLink={dealLink}
          applicationLink={applicationLink}
        />
      )

    case 'discontinued':
      return (
        <span>
          Discontinued the application {applicationLink}
          {''}
        </span>
      );

    case 'next-stage':
      return (
        <>
          Progressed the application {applicationLink} from{' '}
          <span className="contact-activities-stage">
            {data?.data?.previousStage?.stage}
          </span>{' '}
          to{' '}
          <span className="contact-activities-stage">
            {data?.data?.currentStage?.stage}
          </span>
        </>
      );

    case 'revert':
      return (
        <span>
          Reverted the application {applicationLink}
          {''}
        </span>
      );

    case 'previous-stage':
      return (
        <>
          Regressed the application {applicationLink} from{' '}
          <span className="contact-activities-stage">
            {data?.data?.previousStage?.stage}
          </span>{' '}
          to{' '}
          <span className="contact-activities-stage">
            {data?.data?.currentStage?.stage}
          </span>
        </>
      );

    case 'completed':
      return (
        <div>
          Progressed the application {applicationLink} from{' '}
          <span className="contact-activities-stage">
            {data?.data?.previousStage?.stage}
          </span > {' '}
          to <span className="contact-activities-stage" > Complete</span >
        </div >
      );

    case 'reopened':
      return <span>Application Reopened</span>;

    case 'assigned':
      switch (activities_type) {
        case 'contact':
          return (
            <span>
              Changed assignee from{' '}
              <span className="contact-activities-stage">
                {previouslyAssignedUser}
              </span>{' '}
              to{' '}
              <span className="contact-activities-stage">{assignedUser}</span>{' '}
            </span>
          );

        default:
          return <span>Assigned added to application</span>;
      }

    default:
      return <span>Application Updated</span>;
  }
};

export default ActivityMessage;
