import React, { useContext } from 'react';
import { Col, Dropdown, List, Menu, Row, Space, Typography } from 'antd';
import {
  CheckOutlined,
  DownloadOutlined,
  FileAddOutlined,
  //   DownloadOutlined,
} from '@ant-design/icons';
import { CONFIRM_MESSAGE, CONFIRM_TITLE, classNames } from '@moxie/constants';
import { SelectedStage } from '@model/application';
import { ApplicationWorkflowStageChecklist, Checklist as ChecklistModel } from '@model/workflow';
import ApplicationDetailContext from '@crm/src/pages/application-details/application-detail-context';
import { downloadAllStageDocs, downloadFile, findAllMediaBySubjectIdAndMediaType } from '@crm/services.api';
import { popConfirm } from '@shared-components/elements';
import { useDispatch } from 'react-redux';
import { leadActions } from '@crm/core';

interface ChecklistProps {
  docs: ApplicationWorkflowStageChecklist[];
  selectedChecklist: ChecklistModel | undefined;
  selectedStage: SelectedStage;
  setSelectedChecklist: React.Dispatch<React.SetStateAction<ChecklistModel | undefined>>;
  openDocDrawer: () => void;
  applicationId: string;
  openExistingDocDrawer: () => void;
  docsCountMap: Map<string, number> | undefined;
  docsIds: string[]
}
const Checklist: React.FC<ChecklistProps> = ({
  docs,
  selectedChecklist,
  selectedStage,
  setSelectedChecklist,
  openDocDrawer,
  applicationId,
  openExistingDocDrawer,
  docsCountMap,
  docsIds
}) => {
  return (
    <div className="margin-top-1 application-check-list-container">
      <List
        itemLayout="horizontal"
        dataSource={docs}
        size="small"
        renderItem={(item) => {
          return (
            <CheckListRow
              setSelectedChecklist={setSelectedChecklist}
              selectedChecklist={selectedChecklist}
              item={item}
              docsIds={docsIds}
              openDocDrawer={openDocDrawer}
              openExistingDocDrawer={openExistingDocDrawer}
              applicationId={applicationId}
              selectedStage={selectedStage}
              docsCountMap={docsCountMap}
            />
          );
        }}
      />
    </div>
  );
};

interface ChecklistRowProps extends Omit<ChecklistProps, 'docs'> {
  item: ApplicationWorkflowStageChecklist;
  docsCountMap: Map<string, number> | undefined
}

const CheckListRow: React.FC<ChecklistRowProps> = ({
  item,
  selectedChecklist,
  setSelectedChecklist,
  openDocDrawer,
  openExistingDocDrawer,
  docsCountMap,
  docsIds,
}) => {

  const dispatch = useDispatch();
  const { applicationData } = useContext(ApplicationDetailContext)
  const handleDownloadDocs = async () => {

    popConfirm(
      CONFIRM_TITLE.DOWNLOAD_DOCUMENTS,
      `${CONFIRM_MESSAGE.DOWNLOAD_DOCUMENTS}`,
      async () => {
        dispatch(leadActions.downloading(true))
        const docs = await findAllMediaBySubjectIdAndMediaType({
          subjectId: applicationData.id,
          mediaType: item.checklist?.name,
        })
        docsIds = docs.data.map(doc => doc.id);
        const url = (await downloadAllStageDocs({ ids: docsIds }));
        dispatch(leadActions.downloading(false))
        downloadFile(url);
      }
    );

  };

  return (
    <div
      onClick={() => setSelectedChecklist(item?.checklist)}
      className={classNames(
        'crm-document-checklist-row',
        selectedChecklist?.id === item?.checklist?.id ? 'crm-document-checklist-row-selected' : undefined,
      )}
    >
      <List.Item>
        <List.Item.Meta
          title={
            <Row align="middle" justify="space-between" style={{flexWrap: 'nowrap'}}>
              <Col span={10}>
                <Space>
                  {docsCountMap?.get(item.checklist.name) ? (
                    <div className="crm-document-checked">
                      {<CheckOutlined />}
                    </div>
                  ) : (
                    <div className="blank-circle" />
                  )}
                  <Typography.Text
                    style={{maxWidth:"200px"}}
                    ellipsis={{tooltip: true}}
                    className={`doc-checklist-ellipsis-text margin-right-1 margin-left-1 ${selectedChecklist?.id === item?.checklist?.id
                      ? `text-white`
                      : `text-link-color`
                      }`}
                  >
                    {item.checklist.name}
                  </Typography.Text>
                  <div className="circle-with-text">{docsCountMap?.get(item.checklist.name)}</div>
                </Space>
              </Col>
              <Col>
                <Space>
                  {docsCountMap?.get(item.checklist.name) ? (
                    <DownloadOutlined
                      onClick={handleDownloadDocs}
                      className={`profile-document-icons ${selectedChecklist?.id === item?.checklist?.id
                        ? 'text-white'
                        : null
                        }`}
                    />
                  ) : null}
                  <Dropdown
                    overlay={
                      <>
                        <Menu selectable={false}>
                          <Menu.Item
                            className="crm-doc-upload-type-item"
                            onClick={openExistingDocDrawer}
                            data-testid="uploadexisting"
                          >
                            Upload existing
                          </Menu.Item>
                          <Menu.Item
                            className="crm-doc-upload-type-item"
                            onClick={openDocDrawer}
                            data-testid="uploaddevice"
                          >
                            Upload device
                          </Menu.Item>
                        </Menu>
                      </>
                    }
                  >
                    <FileAddOutlined
                      className={`profile-document-icons ${selectedChecklist?.id === item?.checklist?.id
                        ? 'text-white'
                        : ''
                        }`}
                    />
                  </Dropdown>
                </Space>
              </Col>
            </Row>
          }
        />
      </List.Item>
    </div>
  );
};

export default Checklist;
