import React, { useState } from 'react';
import { useAppSelector } from '@crm/core';
import { CONTACTS_REPORT_TITLE, URL_FORBIDDEN_ACCESS } from '@moxie/constants';
import { PageHeaderTitle } from '@shared-components/elements';
import ContactReportTable from './contact-report-table';
import { useForm } from 'antd/lib/form/Form';
import { Redirect } from 'react-router-dom';
import { useFetchUserPermission } from '@crm/libs/hooks/users/useFetchUserPermission';
import { Spin } from 'antd';
import { ICrmPermissionData } from 'libs/shared/src/crm-modules/user/user.model';
import { groupBy } from 'lodash';

const ContactReportComponent: React.FC = () => {
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [filter, setFilter] = useState({});
  const [form] = useForm();

  const userId = useAppSelector((store) => store?.auth?.user?.id);

  const { crmPermissions, permissionLoading } = useFetchUserPermission(userId as string);
  const permissionData = groupBy(crmPermissions, ({ permissionObject }: ICrmPermissionData) => permissionObject.name);
  const reportAccess = permissionData?.Report?.map((permission: ICrmPermissionData) => permission.name)?.includes('view_reports');
  const openDrawer = () => {
    setDrawerVisibility(true);
  };

  const closeDrawer = () => {
    setDrawerVisibility(false);
    form.resetFields();
    setDisabled(true);
    const existingFilter = { ...filter };
    form.setFieldsValue(existingFilter);
  };

  if (permissionLoading) {
    return (
      <div className="page-load">
        <Spin />
      </div>
    )
  }

  if (!reportAccess) {
    return (
      <Redirect to={URL_FORBIDDEN_ACCESS} />
    )
  }

  return (
    <div className="full-height">
      <PageHeaderTitle title={CONTACTS_REPORT_TITLE} />
      <ContactReportTable
        openDrawer={openDrawer}
        closeDrawer={closeDrawer}
        disabled={disabled}
        setDisabled={setDisabled}
        drawerVisibility={drawerVisibility}
        filter={filter}
        setFilter={setFilter}
        form={form}
      />
    </div>
  );
};

export { ContactReportComponent };
