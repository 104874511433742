import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { useForm } from 'antd/lib/form/Form';
import { Col, Row } from 'antd';
import { TEXT } from '@moxie/constants';
import { capitalizeFirstLetter } from '@moxie/shared';
import { EditSuperAgentComponent } from './edit-super-agent';
import { EditSubAgentComponent } from './edit-sub-agent';
import { useFetchSubAgentBranches } from 'apps/crm/src/pages/contact-details/hooks/useFetchSubAgentBranches';
import { useFetchSuperAgentBranches } from 'apps/crm/src/pages/contact-details/hooks/useFetchSuperAgentBranches';
import { ApplicationDetail } from '@model/application';

interface Props {
  application: ApplicationDetail
}
export const EditAgentPartnerComponent: React.FC<Props> = ({ application }) => {
  const [form] = useForm();
  const [editSuperAgent, setEditSuperAgent] = useState<boolean>(false);
  const [editSubAgent, setEditSubAgent] = useState<boolean>(false);
  const { subAgents: subAgentsData, setSelectedSubAgent } = useFetchSubAgentBranches(form);
  const { superAgentsData, setSelectedSuperAgent } = useFetchSuperAgentBranches(
    form
  );
  const super_agent_info = application?.agentPartner?.superAgentPartner?.name;
  const super_agent_branch_info = application?.agentPartner?.superAgentBranch?.name;
  const sub_agent_info = application?.agentPartner.subAgentPartner?.name;
  const sub_agent_branch_info = application?.agentPartner.subAgentBranch?.name;

  const ellipsisText = (str: string, maxTextLength: number) =>
    str?.length >= maxTextLength
      ? { tooltip: capitalizeFirstLetter(str) }
      : false;

  const handleOpenSuperAgentEditField = () => {
    setEditSuperAgent(true);
    const currentSuperAgentValue = form.getFieldValue('super_agent');
    const superAgentData = superAgentsData?.find(
      (item: any) => item.id === currentSuperAgentValue
    );
    setSelectedSuperAgent(superAgentData);
    handleCloseSubAgentEditField();
  };

  const handleCloseSuperAgentEditField = () => {
    setEditSuperAgent(false);
    form.resetFields();
  };

  const handleOpenSubAgentEditField = () => {
    setEditSubAgent(true);
    const currentSubAgentValue = form.getFieldValue('sub_agent');
    const subAgentData = subAgentsData?.find(
      (item: any) => item.id === currentSubAgentValue
    );
    setSelectedSubAgent(subAgentData);
    handleCloseSuperAgentEditField();
  };

  const handleCloseSubAgentEditField = () => {
    setEditSubAgent(false);
    form.resetFields();
  };

  useEffect(() => {
    if (application?.agentPartner && (editSuperAgent || editSubAgent)) {
      form.setFieldsValue({
        superAgent:
          application?.agentPartner?.superAgentPartnerId,
        superAgentBranch: application?.agentPartner?.superAgentBranchId,
        subAgent:
          application?.agentPartner?.subAgentPartnerId,
        subAgentBranch: application?.agentPartner?.subAgentBranchId,
      });
    }
  }, [application, editSuperAgent, editSubAgent]);

  return (
    <>
      <Col>
        <Row justify="space-between" align="middle">
          <Text className="administrative-info-title">{TEXT.SUPER_AGENT}:</Text>
          {!editSuperAgent && application?.status !== 'Completed' ? (
            <EditOutlined
              data-testid="editsuperagent"
              className="margin-right-2"
              onClick={handleOpenSuperAgentEditField}
            />
          ) : null}
        </Row>
        {!editSuperAgent && (
          <>
            {super_agent_info ? (
              <>
                <Col span={14} xxl={17}>
                  <div className="display-flex">
                    <Text
                      className="initial_capital ellipsis-text"
                      ellipsis={ellipsisText(super_agent_info, 14)}
                    >
                      {super_agent_info}
                    </Text>
                    <Text
                      className="initial_capital ellipsis-text"
                      ellipsis={super_agent_branch_info ? ellipsisText(super_agent_branch_info, 14) : undefined}
                    >
                      •&nbsp;&nbsp;&nbsp;{super_agent_branch_info}
                    </Text>
                  </div>
                </Col>
              </>
            ) : (
              <div>N/A</div>
            )}
          </>
        )}
        {editSuperAgent && (
          <EditSuperAgentComponent
            closeSuperAgentEdit={handleCloseSuperAgentEditField}
            application={application}
            setEditSuperAgent={setEditSuperAgent}
            form={form}
          />
        )}
        <Row justify="space-between" align="middle" className="margin-top-2">
          <Text className="administrative-info-title">{TEXT.SUB_AGENT}:</Text>{' '}
          {!editSubAgent && application?.status !== 'Completed' ? (
            <EditOutlined
              data-testid="editsubagent"
              className="margin-right-2"
              onClick={handleOpenSubAgentEditField}
            />
          ) : null}
        </Row>
        {!editSubAgent ? (
          <>
            {sub_agent_info ? (
              <>
                <Col span={14} xxl={17}>
                  <div className="display-flex">
                    <Text
                      className="initial_capital ellipsis-text"
                      ellipsis={ellipsisText(sub_agent_info, 14)}
                    >
                      {sub_agent_info}
                    </Text>
                    <Text
                      className="initial_capital ellipsis-text"
                      ellipsis={sub_agent_branch_info ? ellipsisText(sub_agent_branch_info, 14) : undefined}
                    >
                      •&nbsp;&nbsp;&nbsp;{sub_agent_branch_info}
                    </Text>
                  </div>
                </Col>
              </>
            ) : (
              <div>N/A</div>
            )}
          </>
        ) : (
          editSubAgent && (
            <EditSubAgentComponent
              closeSubAgentEdit={handleCloseSubAgentEditField}
              application={application}
              setEditSubAgent={setEditSubAgent}
              form={form}
            />
          )
        )}{' '}
      </Col>
    </>
  );
};
