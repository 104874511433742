import React from 'react';
import { ApplicationDetailProvider } from "./application-detail-context"
import ApplicationDetails from './application-details';

const ApplicationDetail = () => {

  return (
    <ApplicationDetailProvider>
      <ApplicationDetails />
    </ApplicationDetailProvider>
  )
}

export default ApplicationDetail
