import { getUserPermission } from '@crm/services.api';
import { errorNotificationHandler } from '@moxie/shared';
import { useQuery } from '@tanstack/react-query';

export const useFetchUserPermission = (userId: string) => {
  const { data, isLoading } = useQuery({
    queryKey: ['query::user-permission', userId],
    queryFn: () => getUserPermission(userId),
    onError: () => {
      errorNotificationHandler('Error fetching user permission.');
    },
    enabled: !!userId,
  });

  return {
    crmPermissions: data?.data ?? [],
    permissionLoading: isLoading,
  }
}

