import { fetchEducationProviderCourses } from '@crm/libs/services.api/lib/education-provider.api';
import { useQuery } from '@tanstack/react-query';

export const useFetchEducationProviderCourses = (
  method: string,
  providerInstitutionId: number | undefined
) => {
  const params = {
    method,
    providerInstitutionId,
  };

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['education-provider-courses', providerInstitutionId],
    queryFn: async () => fetchEducationProviderCourses(params),
    enabled: Boolean(providerInstitutionId),
  });

  return { data, isLoading, isFetching };
};
