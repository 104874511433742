import React, { useState } from 'react';
import {
  LABEL,
  TEXT,
  URL_ENQUIRIES_NEW_CONTACTS,
} from '@moxie/constants';
import { Button, Row, Tooltip, Table as AntTable, Input } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import Typography from 'antd/es/typography';
import ShareActionBtn from './form-listing-action/share-action-btn';
import ShareUrlModal from './form-listing-action/share-url-modal';

export type ShareModalType = 'none' | 'online-form' | 'phone-form'

type ColumnType = {
  formName: string,
  type: Exclude<ShareModalType, 'none'>,
};

const FORM_TABLE_DATA: ColumnType[] = [{
  formName:"online form enquiry",
  type: "online-form",
},{
  formName:"phone enquiry",
  type: "phone-form",
}]

const { Search } = Input;

const EnquiryFormsTable = () => {
  const [shareModal, setShareModal] = useState<ShareModalType>("none");
  const [dataSource, setDataSource] = useState(FORM_TABLE_DATA)

  const columns: ColumnsType<ColumnType> = [
    {
      title: LABEL.FORM_NAME,
      dataIndex: 'formName',
      key: 'formName',
      width: 250,
      render: (text: string, rowData: ColumnType) => {
        return (
          <Row align="middle">
            <Typography.Paragraph
              className="initial_capital table-form-name-width"
              ellipsis={{
                rows: 1,
                tooltip: <Tooltip title={rowData.formName}>{rowData.formName}</Tooltip>,
              }}
            >
              {' '}
              {rowData.formName}
            </Typography.Paragraph>
          </Row>
        );
      },
    },
    {
      title: LABEL.SHARING_OPTION,
      dataIndex: 'type',
      key: 'type',
      width: 300,
      render(type: Exclude<ShareModalType, "none">, record: ColumnType) {
        return (
          <ShareActionBtn key={type} onClick={()=> setShareModal(type)} />
        );
      },
    },
  ];

  const handleSearch = (query: string) =>{
    if(query){
      setDataSource(prev => prev.filter(item => item.formName.includes(query)))
    }else{
      setDataSource(FORM_TABLE_DATA)
    }
  }

  return (
    <>
      <div className="bg-white padding-left-1 padding-right-1">
        <div className="padding-x-1">
          <div
            className="align-items-center padding-bottom-1"
            style={{ justifyContent: "space-between"}}
          >
            <Search
              placeholder="Search"
              onSearch={handleSearch}
              className={`table_search margin-left-1 padding-top-1`}
              allowClear
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Link to={URL_ENQUIRIES_NEW_CONTACTS}>
              <Button type="ghost" icon={<LeftOutlined />}>
                {TEXT.ENQUIRIES}
              </Button>
            </Link>
          </div>
          <AntTable dataSource={dataSource} columns={columns} rowKey="type"/>
        </div>

      </div>
      {shareModal !=="none" && (
        <ShareUrlModal shareModal={shareModal} setShareModal={setShareModal} />
      )}
    </>
  );
};
export { EnquiryFormsTable };
