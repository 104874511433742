import React, { useState } from 'react';
import {
  ADD_LANGUAGE_TEST_SCORE,
  CANCEL_BTN,
  EDIT_LANGUAGE_TEST_SCORE,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { DrawerElem } from '@shared-components/elements';
import { useForm } from 'antd/lib/form/Form';
import { Button, Space } from 'antd';
import { TOEFLForm } from '../form/toefl';

const TOEFLDrawer = ({
  showDrawer,
  setShowDrawer,
  editMode,
  setEditMode,
  toeflData,
}: {
  showDrawer: boolean;
  setShowDrawer: any;
  editMode?: boolean;
  setEditMode?:  React.Dispatch<React.SetStateAction<boolean>>;
  toeflData?: any;
}) => {
  const [form] = useForm();
  const [disabled, setDisabled] = useState(true);
  const handleClose = () => {
    setDisabled(true);
    setEditMode && setEditMode(false);
    setShowDrawer();
    form.resetFields();
  };
  const handleSubmitBtnClick = () => {
    form.submit();
  };
  return (
    <DrawerElem
      title={editMode ? EDIT_LANGUAGE_TEST_SCORE : ADD_LANGUAGE_TEST_SCORE}
      width={720}
      visible={showDrawer}
      onClose={handleClose}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={handleClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              onClick={handleSubmitBtnClick}
              type="primary"
              disabled={disabled}
            >
              {editMode ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
      data-testid="toefl"
    >
      <TOEFLForm
        form={form}
        setDisabled={setDisabled}
        handleClose={handleClose}
        toeflData={toeflData}
        editMode={editMode}
      />
    </DrawerElem>
  );
};

export { TOEFLDrawer };
