import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  errorNotificationHandler,
  successNotificationHandler,
} from '@moxie/shared';
import { createEducationProviderApplication } from '@crm/libs/services.api/lib/education-provider.api';
import { AxiosError } from 'axios';

interface Error {
  detail: string;
  title: string;
  status: number;
}

export const useCreateEducationProviderApplication = () => {
  const queryClient = useQueryClient();

  return useMutation(createEducationProviderApplication, {
    onSuccess: (_, payload) => {
      successNotificationHandler(
        'Created applicant on studylink successfully.'
      );
      queryClient.invalidateQueries({
        queryKey: ['application-details', payload.applicationId],
      });
    },
    onError: (error: AxiosError<Error>) => {
      const errMsg = error.response?.data?.detail || error.response?.data?.title || 'Error creating applicant';
      errorNotificationHandler(errMsg);
    },
  });
};
