import {
  ArchivedEnquiryStatus,
  ContactEnquiryStatus,
  DisqualifiedEnquiryStatus,
  EnquiryType,
  LostEnquiryStatus,
  OpenEnquiryStatus,
  QualifiedEnquiryStatus,
  ConvertedEnquiryStatus,
} from "@model/contact-form";

interface IOptions {
  label: string;
  value: ContactEnquiryStatus;
}

export interface EnquiryStatusOptions {
  status: EnquiryType;
  accessedBy: ContactEnquiryStatus[];
  options: IOptions[];
}

export const openEnquiry: OpenEnquiryStatus[] = [
  "new",
  "contacted",
  "evaluating",
];
export const qualifiedEnquiry: QualifiedEnquiryStatus[] = [
  "engage immediately",
  "future lead",
  "assessing",
];
export const disqualifiedEnquiry: DisqualifiedEnquiryStatus[] = [
  "not a fit",
  "service unavailable",
  "personal circumstances",
  "incorrect information",
  "financial limitations",
];
export const lostEnquiry: LostEnquiryStatus[] = [
  "competitor selected",
  "interest withdrawn",
  "unresponsive",
  "changed decision",
];
export const archivedEnquiry: ArchivedEnquiryStatus[] = ["archived"];
export const convertedEnquiry: ConvertedEnquiryStatus[] = ["converted"];
export const allEnquiry = [
  ...openEnquiry,
  ...qualifiedEnquiry,
  ...disqualifiedEnquiry,
  ...lostEnquiry,
  ...archivedEnquiry,
];

export const enquiryStatusOptions: EnquiryStatusOptions[] = [
  {
    status: 'open',
    accessedBy: [...openEnquiry],
    options: [
      {
        label: 'New',
        value: 'new',
      },
      {
        label: 'Contacted',
        value: 'contacted',
      },
      {
        label: 'Evaluating',
        value: 'evaluating',
      },
    ],
  },
  {
    status: 'qualified',
    accessedBy: [...openEnquiry, ...qualifiedEnquiry],
    options: [
      {
        label: 'Engage Immediately',
        value: 'engage immediately',
      },
      {
        label: 'Future Lead',
        value: 'future lead',
      },
      {
        label: 'Assessing',
        value: 'assessing',
      },
    ],
  },
  {
    status: 'disqualified',
    accessedBy: [...openEnquiry, ...qualifiedEnquiry, ...disqualifiedEnquiry],
    options: [
      {
        label: 'Service Unavailable',
        value: 'service unavailable',
      },
      {
        label: 'Not A Fit',
        value: 'not a fit',
      },
      {
        label: 'Personal Circumstances',
        value: 'personal circumstances',
      },
      {
        label: 'Incorrect Information',
        value: 'incorrect information',
      },
      {
        label: 'Financial Limitations',
        value: 'financial limitations',
      },
    ],
  },
  {
    status: 'lost',
    accessedBy: [...openEnquiry, ...qualifiedEnquiry, ...disqualifiedEnquiry, ...lostEnquiry],
    options: [
      {
        label: 'Unresponsive',
        value: 'unresponsive',
      },
      {
        label: 'Competitor Selected',
        value: 'competitor selected',
      },
      {
        label: 'Interest Withdrawn',
        value: 'interest withdrawn',
      },
      {
        label: 'Changed Decision',
        value: 'changed decision',
      },
    ],
  },
  {
    status: 'archived',
    accessedBy: [...openEnquiry, ...qualifiedEnquiry, ...disqualifiedEnquiry, ...lostEnquiry, ...archivedEnquiry],
    options: [
      {
        label: 'Archived',
        value: 'archived',
      },
    ],
  },
  {
    status: 'converted',
    accessedBy: [...convertedEnquiry],
    options: [
      {
        label: 'Converted',
        value: 'converted',
      },
    ],
  },
];

export const srcOptions = {
  "online-form": ["Facebook", "Instagram", "Website"],
  "phone-form": ["Phone"],
}
