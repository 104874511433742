import React, { useContext, useState } from 'react';
import {
  LeftCircleOutlined,
  RightCircleOutlined,
  CloseOutlined,
  UndoOutlined
} from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { successNotificationHandler } from '@moxie/shared';
import { useAppSelector } from '@crm/core';
import { ActivityAction } from '@model/contact-activity';
import ApplicationDetailContext from '../../../application-detail-context';
import useUpdateApplicationMutation from '../../../hooks/useUpdateApplicationMutation';
import { ApplicationStatusChangeRemarks } from '@model/application';
import StatusUpdateModal from './status-update-modal';
import { discontinueReasonsOptions, reopenReasonsOptions, revertReasonsOptions } from '../../../constants';
import Typography from 'antd/es/typography';
import { DEAL_COMPLETE_DESCRIPTION } from '@crm/src/pages/deal-details/components/detail-section/constants';
import DealApplicationStatusUpdateModal from '@crm/src/pages/deal-details/components/detail-section/applications/deal-application-status-update-modal';
import { useFetchDealDetails } from '@crm/src/pages/deal-details/hooks/useFetchDealDetails';
import { IApplication } from '@model/index';
import { useUpdateDeals } from '@crm/src/pages/deals/hooks/useUpdateDeals';
import { useQueryClient } from '@tanstack/react-query';
import { MarkDealStatusConfirmModal } from '@crm/src/pages/deal-details/components/detail-section/applications/mark-deal-status-application-modal';
import { CheckCircleOutlined } from "@ant-design/icons";

type Modal = 'revert' | 'discontinue' | 'reopen' | 'deal-completed' | 'deal-lost' | 'all-applications-completed' | 'confirm-deal-in-progress';

const { Text, Paragraph } = Typography;

const Footer = () => {
  const { applicationData: application, setSelectedStage, service } = useContext(ApplicationDetailContext);

  const { detail } = useFetchDealDetails(application && application?.dealId as string);

  const queryClient = useQueryClient();

  const inProgressApplicationIds = detail?.applications
    ?.filter((application: IApplication) => application?.status === 'In Progress')
    ?.map((application) => application.id) ?? [];

  const completedApplicationsCount = detail?.applications?.filter((application: IApplication) => application.status === "Completed")?.length;


  const activeStage = application.activeStage;
  const allWfStages = application?.workflow.workflowApplicationStages;
  const isSecondLastStage = !application?.nextStageId;
  const hasPreviousStage = !!application?.prevStageId;
  const isDiscontinued = application?.status === 'Discontinued';
  const isCompleted = application?.status === 'Completed';


  const refreshAssociatedData = () => {
    if (detail) {
      queryClient.invalidateQueries({
        queryKey: ['deal-applications', detail.id],
      });
      queryClient.invalidateQueries({
        queryKey: ['contact-deals', detail.contactId]
      })
    }
  }

  const updateApplicationMutation = useUpdateApplicationMutation(application, application && application.dealId);
  const updateDealsMutation = useUpdateDeals(refreshAssociatedData);

  const [activeModal, setActiveModal] = useState<Modal | undefined>();
  const [isSubmitting, setSubmitting] = useState(false);
  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);

  const getStageInfo = (stageId: string | null) => {
    return allWfStages?.find((stage) => stage?.id === stageId);
  };

  const getLastWorkflowStage = () => {
    return allWfStages.pop();
  };

  const isLastApplication = inProgressApplicationIds.length === 1;


  const addChangeLog = (
    previousStage: any,
    nextStage: any,
    action: ActivityAction
  ) => {
    socket?.emit('server::application-updated', {
      activitiesTypeId: application.id,
      activitiesType: 'application',
      activitiesAction: action,
      userId: user?.id as string,
      companyId: user?.companyId as string,
      contactId: application.contactId,
      data: {
        data: {
          currentStage: nextStage,
          previousStage: previousStage,
          id: application.id,
          appIdentifier: application.appIdentifier,
          contactId: application.contactId,
        },
      },
    });
  };
  const handleNextStage = () => {
    const stageInfo = getStageInfo(application?.nextStageId);

    if (isSecondLastStage) {
      updateApplicationMutation.mutate(
        {
          payload: {
            status: 'Completed',
            service: service ?? '',
            updateType: 'application-status-updated',
            changedStatus: 'Completed',
            previousStatus: application.status,
          },
        },
        {
          onSuccess() {
            successNotificationHandler('Application stage updated as completed', 1.5);
            addChangeLog(activeStage, stageInfo, 'completed');
            setSelectedStage({
              level: null,
              stage: null,
              wfAppStageId: null,
            });
            if (isLastApplication) {
              setActiveModal('all-applications-completed')
            }
          },
          onError() {
            const currentStage = getStageInfo(application?.activeStageId);
            setSelectedStage({
              level: currentStage?.level ?? null,
              stage: currentStage?.stage ?? null,
              wfAppStageId: currentStage?.id ?? null,
            });
          },
        }
      );
    } else {
      updateApplicationMutation.mutate(
        {
          payload: {
            activeStageId: application?.nextStageId as string,
          },
        },
        {
          onSuccess() {
            addChangeLog(activeStage, stageInfo, 'next-stage');
            setSelectedStage({
              level: stageInfo?.level ?? null,
              stage: stageInfo?.stage ?? null,
              wfAppStageId: stageInfo?.id ?? null,
            });
          },
        }
      );
    }
  };

  const handlePreviousStage = () => {
    const previousStage = getStageInfo(application?.prevStageId);
    const payload = { activeStageId: application?.prevStageId as string }
    updateApplicationMutation.mutate({ payload },
      {
        onSuccess() {
          successNotificationHandler('Application stage updated.', 1.5);
          addChangeLog(activeStage, previousStage, 'previous-stage');
        },
      }
    );
    setSelectedStage({
      level: previousStage?.level ?? null,
      stage: previousStage?.stage ?? null,
      wfAppStageId: previousStage?.id ?? null,
    });
  };

  const logApplicationState = (action: ActivityAction) => {
    socket?.emit('server::application-updated', {
      activitiesTypeId: application.id,
      activitiesType: 'application',
      activitiesAction: action,
      userId: user?.id as string,
      companyId: user?.companyId as string,
      contactId: application.contactId,
      data: { data: application },
    });
  };

  const handleDiscontinue = (value: ApplicationStatusChangeRemarks) => {
    setSubmitting(true);
    updateApplicationMutation.mutate(
      {
        payload: {
          status: 'Discontinued',
          updateType: 'application-status-updated',
          changedStatus: 'Discontinued',
          statusRemarks: {
            ...application.statusRemarks,
            discontinue: value,
          },
        },
      },
      {
        onSuccess() {
          logApplicationState('discontinued');
          successNotificationHandler('Application updated.', 1.5);
          if (isLastApplication) {
            if (completedApplicationsCount === 0) {
              setActiveModal('deal-lost');
            } else {
              setActiveModal('all-applications-completed');
            }
          } else {
            setActiveModal(undefined);
          }
        },
        onSettled() {
          setSubmitting(false)
        },
      }
    )
  };

  const handleRevert = (value: ApplicationStatusChangeRemarks) => {
    setSubmitting(true)
    updateApplicationMutation.mutate(
      {
        payload: {
          status: 'In Progress',
          updateType: 'application-status-updated',
          changedStatus: 'Reverted',
          statusRemarks: {
            ...application.statusRemarks,
            revert: value,
          },
        },
      },
      {
        onSuccess() {
          successNotificationHandler('Application reverted.', 1.5);
          logApplicationState('revert');
          if (detail?.status !== "in-progress") {
            handleDealMarkInProgress();
          } else {
            setActiveModal(undefined);
          }
        },
        onSettled() {
          setSubmitting(false)
        },
      }
    );
  };

  const handleReopenApplication = (value: ApplicationStatusChangeRemarks) => {
    setSubmitting(true);
    const stageInfo = getLastWorkflowStage();
    updateApplicationMutation.mutate(
      {
        payload: {
          status: 'In Progress',
          updateType: 'application-status-updated',
          changedStatus: 'Reopened',
          previousStatus: application.status,
          statusRemarks: {
            ...application.statusRemarks,
            reopen: value,
          },
        },
      },
      {
        onSuccess() {
          logApplicationState('reopened');
          successNotificationHandler('Application reopened successfully.', 1.5);
          addChangeLog({ stage: 'complete' }, stageInfo, 'previous-stage');
          if ((inProgressApplicationIds.length === 0 && (detail?.status === "completed" || detail?.status === "lost"))) {
            handleDealMarkInProgress();
          }
        },
        onSettled() {
          if (!(inProgressApplicationIds.length === 0 && (detail?.status === "completed" || detail?.status === "lost"))) {
            setActiveModal(undefined);
          }
          setSubmitting(false);
        },
      }
    );
    setSelectedStage({
      level: stageInfo?.level ?? null,
      stage: stageInfo?.stage ?? null,
      wfAppStageId: stageInfo?.id ?? null,
    });
  }

  const handleDealCompletedStage = (values?: ApplicationStatusChangeRemarks) => {
    if (detail) {
      const dealPayload = {
        id: detail.id,
        contactId: detail.contactId,
        data: {
          status: "completed",
          statusRemarks: values && Object.keys(values).length !== 0 ? { lost: values } : { 'lost': { remarks: '', reason: '' } },
          applicationIds: inProgressApplicationIds
        }
      };
      updateDealsMutation.mutate(dealPayload, {
        onSettled: () => {
          setActiveModal(undefined)
        }
      });
    }
  }

  const handleDealLostStage = (values: ApplicationStatusChangeRemarks) => {
    if (detail) {
      const dealPayload = {
        id: detail.id,
        contactId: detail.contactId,
        data: {
          status: "lost",
          statusRemarks: Object.keys(values).length !== 0 ? { lost: values } : {},
          applicationIds: inProgressApplicationIds
        }
      };
      updateDealsMutation.mutate(dealPayload, {
        onSettled: () => {
          setActiveModal(undefined)
        }
      });
    }
  }

  const handleDealMarkInProgress = () => {
    setSubmitting(true);
    if (detail) {
      const dealPayload = {
        id: detail.id,
        contactId: detail.contactId,
        data: {
          status: "in-progress",
          statusRemarks: {},
        }
      };
      updateDealsMutation.mutate(
        dealPayload,
        {
          onSuccess() {
            if (inProgressApplicationIds.length === 0) {
              setActiveModal("confirm-deal-in-progress");
            }
          },
          onSettled() {
            setSubmitting(false)
          },
        }
      );
    }
  }

  return (
    <>
      <div
        style={{
          borderTop: '1px solid #E3E2E2',
          padding: '0.5rem 1rem',
        }}
      >
        <StatusUpdateModal
          isSubmitting={isSubmitting}
          title="Discontinue Application"
          onSubmit={handleDiscontinue}
          onClose={() => setActiveModal(undefined)}
          options={discontinueReasonsOptions}
          isOpen={activeModal === 'discontinue'}
          formName="discontinue-application-form"
          confirmButton={
            <Button htmlType="submit" icon={<CloseOutlined />} disabled={isSubmitting} danger form="discontinue-application-form">
              Discontinue
            </Button>
          }
        />

        <StatusUpdateModal
          isSubmitting={isSubmitting}
          title="Revert Application"
          onSubmit={handleRevert}
          onClose={() => setActiveModal(undefined)}
          options={revertReasonsOptions}
          isOpen={activeModal === 'revert'}
          formName="revert-application-form"
          confirmButton={
            <Button htmlType="submit" type="primary" icon={<UndoOutlined />} disabled={isSubmitting} form="revert-application-form">
              Revert
            </Button>
          }
        />

        <StatusUpdateModal
          isSubmitting={isSubmitting}
          title="Reopen Application"
          onSubmit={handleReopenApplication}
          onClose={() => setActiveModal(undefined)}
          options={reopenReasonsOptions}
          isOpen={activeModal === 'reopen'}
          formName="reopen-application-form"
          confirmButton={
            <Button htmlType="submit" type="primary" icon={<UndoOutlined />} disabled={isSubmitting} form="reopen-application-form">
              Reopen
            </Button>
          }
        />


        <DealApplicationStatusUpdateModal
          isOpen={activeModal === 'deal-completed'}
          title="Help Us Keep Things Clear"
          onSubmit={handleDealCompletedStage}
          dealId={application.dealId as string}
          options={discontinueReasonsOptions}
          onClose={() => setActiveModal(undefined)}
          formName="deal-completion-form"
          canViewOrAddApplication={true}
          isSubmitting={isSubmitting}
          modalDescription={
            <Paragraph strong className="margin-bottom-2" style={{ whiteSpace: "pre-line" }}>
              {DEAL_COMPLETE_DESCRIPTION}
            </Paragraph>
          }
        />

        <DealApplicationStatusUpdateModal
          isOpen={activeModal === 'deal-lost'}
          dealId={application.dealId as string}
          title="Deal Lost And Discontinuing Applications"
          onSubmit={handleDealLostStage}
          options={discontinueReasonsOptions}
          onClose={() => setActiveModal(undefined)}
          formName="deal-lost-form"
          canViewOrAddApplication={true}
          isSubmitting={isSubmitting}
        />

        <MarkDealStatusConfirmModal
          status="Complete"
          modalTitle={
            <p style={{ display: "flex", alignItems: "center" }}>
              <CheckCircleOutlined style={{ color: "blue", marginRight: "8px" }} />
              <Text>Congratulations</Text>
            </p>
          }
          btnTitle="Mark Deal as Complete"
          isOpen={activeModal === 'all-applications-completed'}
          handleClose={() => setActiveModal(undefined)}
          handleDealMarkStatus={() => handleDealCompletedStage()}
        />

        <MarkDealStatusConfirmModal
          status="In Progress"
          modalTitle={
            <Text>Deal Now In Progress</Text>
          }
          btnTitle="Continue"
          isOpen={activeModal === 'confirm-deal-in-progress'}
          handleClose={() => setActiveModal(undefined)}
          handleDealMarkStatus={() => setActiveModal(undefined)}
        />

        <Row align="bottom" justify="space-between">
          <Col>
            <Row>
              {!isCompleted ?
                <Button
                  icon={<LeftCircleOutlined />}
                  style={{
                    display: isDiscontinued ? 'none' : '',
                  }}
                  disabled={!hasPreviousStage || updateApplicationMutation.isLoading}
                  onClick={() => handlePreviousStage()}
                  data-testid="previousbtn"
                >
                  Previous
                </Button>
                :
                <Button
                  icon={<LeftCircleOutlined />}
                  style={{
                    display: isDiscontinued ? 'none' : '',
                  }}
                  disabled={!hasPreviousStage || updateApplicationMutation.isLoading}
                  onClick={() => setActiveModal('reopen')}
                  data-testid="previousbtn"
                >
                  Reopen Application
                </Button>
              }

              {isDiscontinued ? (
                <Button
                  icon={<UndoOutlined />}
                  style={{ width: '8rem' }}
                  type="primary"
                  onClick={() => setActiveModal('revert')}
                  disabled={updateApplicationMutation.isLoading}
                  data-testid="revertbtn"
                >
                  Revert
                </Button>
              ) : (
                <Button
                  onClick={() => setActiveModal('discontinue')}
                  icon={<CloseOutlined />}
                  style={{
                    width: '8rem',
                    marginLeft: '15px',
                    display: isDiscontinued || isCompleted ? 'none' : '',
                  }}
                  danger
                  data-testid="discontinuebtn"
                >
                  Discontinue
                </Button>
              )}
            </Row>
          </Col>
          <Col>
            <Button
              type="primary"
              style={{
                width: isSecondLastStage ? '12rem' : '8rem',
                display: isDiscontinued || isCompleted ? 'none' : '',
              }}
              onClick={() => handleNextStage()}
              disabled={updateApplicationMutation.isLoading}
              data-testid="nextbtn"
            >
              {isSecondLastStage ? 'Complete Application' : 'Next'}
              <RightCircleOutlined />
            </Button>
          </Col>
        </Row >
      </div >
    </>
  );
};
export default Footer;
