import React, { useState } from 'react';
import { Button, Row } from 'antd';
import { CommentOutlined } from '@ant-design/icons';
import ApplicationActivityLogDrawer from './application-activity-log-drawer';
import { useForm } from 'antd/lib/form/Form';
import { usePostContactApplicationComments } from '../hooks/use-contact-application-comments';
import { errorHandler } from '@moxie/utils';
import { ApplicationDetail } from '@model/application';
import ApplicationDetailContext from '../../../application-detail-context';
import { BulkDocumentUpload } from '@crm/src/pages/contact-details/sections/details/components/documents/contact-bulk-document-upload';

interface Props {
  application: ApplicationDetail;
}

const EnrollmentDetail: React.FC<Props> = ({ application }) => {
  const { id: applicationId } = application;

  const [openTab, setOpenTab] = useState(false);
  const [selectedTab, setSelectedTab] = useState('activities');
  const [commentFormButton, setCommentFormButton] = useState<any>(true);
  const commentsMutation = usePostContactApplicationComments(application);
  const [form] = useForm();

  const showDrawer = () => {
    setOpenTab(true);
  };

  const handleCloseDrawer = () => {
    setOpenTab(false);
    form.resetFields();
    setCommentFormButton(true);
    setSelectedTab('activities');
  };

  const handleSubmit = (data: any) => {
    try {
      if (applicationId) {
        commentsMutation.mutate({
          comment: data.comment,
          workflowApplicationStageId: application?.activeStageId,
          applicationId: application.id,
          applicationStatus: application.status
        });
      }
    } catch (err) {
      errorHandler(err);
    }
    form.resetFields();
    setCommentFormButton(true);
  };

  const { allRequiredDocouments: requiredDocuments } = React.useContext(ApplicationDetailContext) || {};

  const requiredDocumentsList = requiredDocuments.map((stage) => {
    return { workflowApplicationStageId: stage.workflowApplicationStageId, name: stage.name, stage: stage.stage, checkListId: stage.checkListId };
  });

  return (
    <React.Fragment>
      <Row className="enrollment-detail">
        <BulkDocumentUpload requiredDocuments={requiredDocumentsList} application={application} />
        <Button
          className="custom_comments_button"
          icon={<CommentOutlined />}
          onClick={showDrawer}
          data-testid="activitybtn"
        >
          Activity Log
        </Button>
      </Row>
      <ApplicationActivityLogDrawer
        isOpen={openTab}
        form={form}
        onClose={handleCloseDrawer}
        handleSubmit={handleSubmit}
        disabled={commentFormButton}
        setDisabled={setCommentFormButton}
        applicationId={applicationId}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
    </React.Fragment>
  );
};

export default EnrollmentDetail;
