import {
  COUNTRIES,
  GUTTER,
  LABEL,
  VALIDATION_MESSAGE,
  regex,
} from '@moxie/constants';
import { disablePastDate } from '@moxie/shared';
import { SearchableSelect, Option } from '@shared-components/elements';
import {
  IContactDetailsInfoForm,
  IGetPopupContainer,
} from '@shared-components/models';
import { Col, DatePicker, Form, Input, Row } from 'antd';
import moment from 'moment';
import React, { FormEvent, useEffect, useState } from 'react';

const PassportVisaInfoForm: React.FC<IContactDetailsInfoForm> = ({
  form,
  handleSubmit,
  setDisabled,
  initialData,
}: IContactDetailsInfoForm) => {
  const [visaType, setVisaType] = useState<any>('');
  const handleFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const fields of allFields) {
        fields.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  const handleVisaTypeChange = (e: FormEvent<HTMLInputElement>) => {
    setVisaType(e.currentTarget.value);
  };

  useEffect(() => {
    if (visaType === '' || visaType?.length === 0) {
      form.setFieldsValue({ visa_expiry_date: '' });
    }
  }, [visaType]);

  useEffect(() => {
    form.setFieldsValue({
      passportNo: initialData?.passportNo,
      visaType: initialData?.visaType,
      visaExpiryDate: initialData?.visaExpiryDate
        ? moment(initialData?.visaExpiryDate)
        : null,
      countryOfPassport: initialData?.countryOfPassport,
      passportExpiryDate: initialData?.passportExpiryDate
        ? moment(initialData?.passportExpiryDate)
        : null,
    });
    setVisaType(initialData?.visaType);
  }, [initialData]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      onFieldsChange={handleFieldsChange}
      scrollToFirstError={true}
    >
      <Row gutter={GUTTER}>
        <Col span={24}>
          <Form.Item
            name="passportNo"
            label={LABEL.PASSPORT_NO}
            rules={[
              {
                pattern: new RegExp(regex.ALPHA_NUMERIC),
                message: VALIDATION_MESSAGE.INVALID_PASSPORT_NUMBER,
              },
            ]}
          >
            <Input
              className="note-title-input"
              data-testid="crm-editpassportandvisainformation-passportno"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <div id="country_list" className="relative">
            <Form.Item
              name="countryOfPassport"
              label={LABEL.COUNTRY_OF_PASSPORT}
              rules={[{ required: true }]}
            >
              <SearchableSelect
                className="note-title-input"
                allowClear={false}
                optionFilter="search_prop"
                getPopupContainer={(): IGetPopupContainer =>
                  document.getElementById('country_list')
                }
                data-testid="crm-editpassportandvisainformation-countryofpassport"
              >
                {COUNTRIES.map((item) => {
                  return (
                    <Option
                      value={item.name}
                      key={item.code}
                      search_prop={`${item.name} ${item.code}`}
                    >
                      {item.name}
                    </Option>
                  );
                })}
              </SearchableSelect>
            </Form.Item>
          </div>
        </Col>
        <Col span={24}>
          <Form.Item
            name="passportExpiryDate"
            label={LABEL.PASSPORT_EXPIRY_DATE}
          >
            <DatePicker
              className="full-width note-title-input"
              format="DD-MM-YYYY"
              disabledDate={disablePastDate}
              data-testid="crm-editpassportandvisainformation-passportexpirydate"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="visaType"
            label={LABEL.VISA_TYPE}
          >
            <Input
              className="note-title-input"
              value={visaType}
              onChange={handleVisaTypeChange}
              data-testid="crm-editpassportandvisainformation-visatype"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="visaExpiryDate"
            label={LABEL.VISA_EXPIRY_DATE}
            extra="Must enter Visa Type first"
            rules={[{ required: visaType }]}
            validateFirst
          >
            <DatePicker
              className="full-width note-title-input"
              disabledDate={disablePastDate}
              format="DD-MM-YYYY"
              disabled={!visaType}
              data-testid="crm-editpassportandvisainformation-visaexpirydate"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export { PassportVisaInfoForm };
