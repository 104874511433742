import React from "react";
import { useTransformedDataContext } from "../import-context";
import { Form, Modal, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useAppSelector } from "@crm/core";
import { PLACEHOLDER } from "@moxie/constants";
import { useContactImport } from "@crm/libs/hooks/contacts/useContactImport";
import { IOfficeBranchCRM } from "@model/branch";
import { ContactData } from "@model/crm/contact.model";
import { capitalizeTrimWord } from "@moxie/shared";
import { crmErrorHandler } from "libs/shared/src/crm-modules/shared";
import { errorNotificationHandler } from "@moxie/shared";
import axios from "axios";

interface CustomError extends Error {
  response?: {
    data?: {
      message?: [],
      statusCode?: number,
    };
  };
}

interface IBranchSelectProp {
  show: boolean;
  setShow: (val: boolean) => void;
  data: ContactData[];
  handleDrawerClose: () => void;
}

const ContactBranchSelect = ({ show, setShow, data, handleDrawerClose }: IBranchSelectProp) => {
  const [form] = useForm();

  const [loading, setLoading] = React.useState(false);

  const importMutation = useContactImport();
  const { updateTransformedData } = useTransformedDataContext();

  const handleCancel = () => {
    setShow(false);
  }

  const handleSubmit = async (formData: { branchId: string; }) => {
    try{
      setLoading(true);
      const dataWithBranch = data.map((entry: ContactData) => {
        Object.entries(entry).forEach(([key, value]) => {
          (entry as { [key: string]: any })[key] = typeof value === "string" ? capitalizeTrimWord(value) : value;
        })
        return { ...entry, ...formData }
      });
      await importMutation.mutateAsync(dataWithBranch);
      updateTransformedData([]);
      handleDrawerClose();
      setLoading(false);
      setShow(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);    
    }
    catch(error ){
      if(axios.isAxiosError(error)){
        const customError = error as CustomError;
        if(customError.response?.data?.statusCode === 400) {
          crmErrorHandler(error);
        }
        else{
          errorNotificationHandler('Error importing contacts.');
        }
      }
    }
    finally{
      setLoading(false);
    }
  }

  const handleOkClick = () => {
    form.submit();
  }

  const loggedUserData = useAppSelector((state) => state.users.singleData);
  const branchList = [loggedUserData?.branch, ...(loggedUserData?.secondaryBranches ?? [])].filter(Boolean) as IOfficeBranchCRM[];

  const branchOptions = branchList.map((branch) => ({
    value: branch.id as string,
    label: branch.name as string,
  }));


  return (
    <Spin spinning={loading} size="small">
      <Modal visible={show} title={"Import Contact"} onCancel={handleCancel} onOk={handleOkClick} confirmLoading={importMutation.isLoading}>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item label="Branch" required={true} name="branchId">
            <Select
              options={branchOptions}
              placeholder={PLACEHOLDER.SELECT_BRANCH}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  )
}
export { ContactBranchSelect };
