import { API_URL } from "@moxie/constants";
import crmAxios from "./crm-api-axios";
import { IAddressBranch } from "@model/crm/enquiry-form.model";
import { PaginationMeta } from "@model/api-response.model";

interface Response<T> {
  data: T[];
  meta: PaginationMeta
}

const fetchBranchesByAddress = async (params: Record<string, unknown>) => {
  const { data } = await crmAxios.get<{ data: Response<IAddressBranch> }>(`${API_URL.CRM_API_ADDRESS}/branches`, { params });
  return data;
};
export { fetchBranchesByAddress }
