import React, { useEffect } from 'react';
import { Card, Checkbox, Col, Form, Input, Row } from 'antd';
import {
  AUTH_MESSAGE,
  EMAIL_LABEL,
  FORGET_PASSWORD_LABEL,
  FORM_SIZE,
  PASSWORD_LABEL,
  PASSWORD_REQUIRED,
  regex,
  REMEMBER_ME_LABEL,
  URL_CRM_FORGET_PASSWORD,
  URL_DASHBOARD,
} from '@moxie/constants';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useDispatch } from 'react-redux';
import { authActions, useAppSelector } from '@crm/core';
import { RootState } from '../../core/store';
import { ILogin } from '@shared-components/models';
import Button from 'antd/es/button';

const Login: React.FC = () => {
  const [isAuthenticated, loading] = useAppSelector((store: RootState) => {
    return [store.auth.isAuthenticated, store.auth.loading];
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogin = (data: ILogin) => {
    data = { ...data, email: data.email.toLowerCase().trim() };
    dispatch(authActions.login(data));
  };

  useEffect(() => {
    if (isAuthenticated && !loading) history.push(URL_DASHBOARD);
  }, [isAuthenticated, loading]);

  return (
    <div className="login-layout">
      <section className="auth-layout auth-layout--login">
        <div className="auth-layout__card">
          <Card className="padding-2">
            <Form
              layout="vertical"
              size={FORM_SIZE}
              name="login-form"
              data-testid="login-form"
              className="auth-layout__form"
              onFinish={handleLogin}
            >
              <div className="header">
                <Form.Item
                  name="email"
                  className="lyra-label"
                  label={EMAIL_LABEL}
                  data-testid="crm-login-form-email"
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp(regex.EMAIL),
                      message: AUTH_MESSAGE.INVALID_EMAIL,
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    prefix={<UserOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={PASSWORD_LABEL}
                  className="lyra-label"
                  data-testid="crm-login-form-password"
                  rules={[{ required: true, message: PASSWORD_REQUIRED }]}
                >
                  <Input.Password prefix={<LockOutlined />} />
                </Form.Item>

                <Form.Item name="remember_me">
                  <Checkbox checked>{REMEMBER_ME_LABEL}</Checkbox>
                </Form.Item>
              </div>
              <Row className="footer">
                <Col span={24}>
                  <Button type="primary" block htmlType="submit" data-testid="crm-login-form-button">
                    Log In
                  </Button>
                </Col>
                <Col span={24} className="padding-top-2 text-align-center">
                  <Link to={URL_CRM_FORGET_PASSWORD}>
                    <Paragraph type="secondary" data-testid="crm-login-forgetpassword-button">
                      {FORGET_PASSWORD_LABEL}
                    </Paragraph>
                  </Link>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
      </section>
    </div>
  );
};

export default Login;
