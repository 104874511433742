import axios from 'axios';
import config from '@crm/src/config/elastic-search.config';
import { ApplicationPayload, ContactFilter, ContactPayload, DealFilter, DealPayload, DefaultFilter, EnquiriesPayload, EnquiryFilter, NoteFilter, NotesPayload, SearchResults } from '@model/crm/elastic-search';

const instance = axios.create({
  baseURL: config.baseUrl,
  auth: {
    username: config.username,
    password: config.password,
  },
  withCredentials: true
});

export const queryApplications = async (searchTerm: string | undefined, queryFilter: DefaultFilter) => {
  const filter = [];
  filter.push({
    term: {
      company_id: queryFilter.companyId
    }
  })
  if (queryFilter.userId) {
    filter.push({
      term: {
        assignees: queryFilter.userId
      }
    });
  }
  const { data } = await instance.post<SearchResults<ApplicationPayload>>('/idx_applications/_search', {
    "query": {
      "bool": {
        "must": [
          {
            "multi_match": {
              "query": searchTerm,
              "fields": [
                "app_identifier",
                "contact_name",
                "email^0.5",
                'email.keyword',
                "phone",
                "institution_name",
                "branch",
                "partner_client_id",
                "product_name",
              ]
            }
          }
        ],
        filter
      }
    }
  });
  return data;
}

export const queryContacts = async (searchTerm: string | undefined, queryFilter: ContactFilter) => {
  const filter = [];

  filter.push({
    "term": {
      "company_id": queryFilter.companyId
    }
  });
  if (queryFilter.branches) {
    filter.push({
      terms: {
        branches: queryFilter.branches
      }
    })
  }

  if (queryFilter.userId) {
    filter.push({
      "bool": {
        "should": [
          {
            "match": { "assignee_id": queryFilter.userId }
          },
          {
            "match": { "followers": queryFilter.userId }
          },
          {
            ...(queryFilter.createdBy && ({
              "match": { "created_by": queryFilter.userId }
            }))
          }
        ],
        "minimum_should_match": 1
      }
    })
  }

  const { data } = await instance.post<SearchResults<ContactPayload>>('/idx_contacts/_search', {
    "query": {
      "bool": {
        "must": [
          {
            "multi_match": {
              "query": searchTerm,
              "fields": ["internal_id", "name", "phone", "email.keyword", "email^0.5"]
            }
          }
        ],
        filter,
      }
    }
  });
  return data;
}

export const queryDeals = async (searchTerm: string | undefined, queryFilter: DealFilter) => {
  const filter = [];

  filter.push({
    "term": {
      "company_id": queryFilter.companyId
    }
  })

  if (queryFilter.userId) {

    if (queryFilter.unassigned) {
      filter.push({
        "bool": {
          "should": [
            {
              "bool": {
                "must_not": {
                  "exists": {
                    "field": "assignees"
                  }
                }
              }
            },
            {
              "term": {
                "assignees": queryFilter.userId
              }
            },
            {
              "term": {
                "followers_and_assignees": queryFilter.userId
              }
            }
          ],
          "minimum_should_match": 1
        }
      })
    }
    else {
      filter.push({
        "term": {
          "assignees": queryFilter.userId
        }
      });
    }
  }

  if (queryFilter.branches?.length) {
    filter.push({
      "terms": {
        "contact_branches": queryFilter.branches
      }
    })
  }
  const { data } = await instance.post<SearchResults<DealPayload>>('/idx_deals/_search', {
    "query": {
      "bool": {
        "should": [
          {
            bool: {
              should: [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": ["internal_id", "phone", 'email^0.5', 'email.keyword']
                  }
                },
                {
                  multi_match: {
                    query: searchTerm,
                    type: "phrase_prefix",
                    fields: ['contact_name', 'name']
                  }
                }
              ],
              "minimum_should_match": 1,
            }
          }
        ],
        filter,
      }
    },
    "min_score": 0.5
  });
  return data;
}


export const queryEnquiries = async (searchTerm: string | undefined, queryFilter: EnquiryFilter) => {
  const filter = [];

  filter.push({
    "term": {
      "company_id": queryFilter.companyId
    }
  })

  if (queryFilter.isCounselor) {
    filter.push({
      bool: {
        should: [
          {
            bool: {
              must_not: {
                exists: {
                  field: 'branches'
                }
              }
            }
          },
          {
            bool: {
              must_not: {
                exists: {
                  field: 'assignee_id'
                }
              }
            }
          }
        ]
      }
    })
  }

  if (queryFilter.branches) {
    filter.push({
      terms: {
        branch_id: queryFilter.branches
      }
    })
  }

  if (queryFilter.canAccessOnlyAssignedQualifiedDisqualified) {
    filter.push({
      bool: {
        should: [
          {
            match: { qualified_by_id: queryFilter.userId }
          },
          {
            match: { disqualified_by_id: queryFilter.userId }
          },
          {
            match: { assignee_id: queryFilter.userId }
          },
        ],
        minimum_should_match: 1
      },
    })
  }
  const { data } = await instance.post<SearchResults<EnquiriesPayload>>('/idx_enquiries/_search', {
    "query": {
      "bool": {
        "must": [
          {
            "multi_match": {
              "query": searchTerm,
              "fields": ["internal_id", "contact_name", "phone", "email^0.5", 'email.keyword']
            }
          }
        ],
        filter
      }
    }
  });
  return data;
}


export const queryNotes = async (searchTerm: string | undefined, queryFilter: NoteFilter) => {
  const filter = [];

  filter.push({
    term: {
      company_id: queryFilter.companyId
    }
  })
  if (queryFilter.noteType?.length) {
    filter.push({
      terms: {
        type: queryFilter.noteType
      }
    })
  }
  const { data } = await instance.post<SearchResults<NotesPayload>>('/idx_notes/_search', {
    "query": {
      "bool": {
        "should": [
          {
            "multi_match": {
              "query": queryFilter.userId,
              "fields": ["created_by_id", "assignees"]
            }
          }
        ],
        "must": {
          "match": {
            "note_description": searchTerm
          }
        },
        filter,
      }
    },
    "min_score": 0.5
  }

  );
  return data;
}

