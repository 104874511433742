import { ContactPayload } from '@model/crm/elastic-search';
import { TableProps } from 'antd/es/table';
import Tag from 'antd/es/tag';

const contactColumns: TableProps<ContactPayload>['columns'] = [
  {
    dataIndex: 'internal_id',
    title: 'ID'
  },
  {
    dataIndex: 'name',
    title: 'Name'
  },
  {
    dataIndex: 'email',
    title: 'Email'
  },
  {
    dataIndex: 'phone',
    title: 'Phone number'
  },
  {
    dataIndex: 'branch_names',
    title: 'Branches',
    render(branches: string[]) {
      return (
        <>
          {branches.map(branch => (
            <Tag key={branch}>
              {branch}
            </Tag>
          ))}
        </>
      )

    }

  }
];

export default contactColumns;

