import { LeadContextProvider } from '@moxie/shared';
import { ProspectComponent } from './prospect-list';

const ProspectListComponent = () => {
  return (
    <LeadContextProvider>
      <ProspectComponent />
    </LeadContextProvider>
  );
};

export { ProspectListComponent };
