import { EnquiriesPayload } from "@model/crm/elastic-search";
import { TableProps } from "antd/es/table";
import Tag from "antd/es/tag";

const enquiriesColumns: TableProps<EnquiriesPayload>['columns'] = [
  {
    dataIndex: 'internal_id',
    title: 'ID'
  },
  {
    dataIndex: 'contact_name',
    title: 'Contact'
  },
  {
    dataIndex: 'email',
    title: 'Email'
  },
  {
    dataIndex: 'phone',
    title: 'phone'
  },
  {
    dataIndex: 'status',
    title: 'Status'
  },
  {
    dataIndex: 'branch_name',
    title: 'branch',
    render(branch: string | null) {
      if (!branch) {
        return <span> - </span>
      }
      return (
        <Tag>
          {branch}
        </Tag>
      )
    }
  }
];


export default enquiriesColumns
