import React from 'react';
import { ProductsListComponent } from './components/products-list';
import ProductFilterProvider from './provider/product-filter-provider';
import ProductFormStateProvider from './provider/product-form-state-provider';

const ProductsPage = () => {
  return (
    <ProductFilterProvider>
      <ProductFormStateProvider>
        <ProductsListComponent />
      </ProductFormStateProvider>
    </ProductFilterProvider>
  );
};
export { ProductsPage };
