import React from 'react';
import Menu from 'antd/es/menu';
import { Agent } from '@model/data.model';
import EditOutlined from '@ant-design/icons/EditOutlined';
import { EDIT_BTN } from '@moxie/constants';

interface AgentMenuProps {
  onEdit: (agent: Agent) => void;
  agent: Agent;
}

export const AgentMenu: React.FC<AgentMenuProps> = ({ agent, onEdit }) => {
  return (
    <Menu selectable={false}>
      <Menu.Item
        key="1"
        onClick={() => onEdit(agent)}
        data-testid="crm-editagentbtn-editmenu"
        icon={<EditOutlined />}
      >
        {EDIT_BTN}
      </Menu.Item>
    </Menu>
  );
};
