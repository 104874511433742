import React, { useState } from 'react';
import { EducationBackgroundDrawer } from './drawer/education-background-drawer';
import { EducationBackgroundContent } from './education-background-content';

const EducationBackgroundPanelContent = ({
  showDrawer,
  setShowDrawer,
}: {
  showDrawer: boolean;
  setShowDrawer: any;
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <>
      <div className="qualification-panel-content">
        <EducationBackgroundContent />
      </div>
      <EducationBackgroundDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        editMode={editMode}
        setEditMode={setEditMode}
      />
    </>
  );
};
export { EducationBackgroundPanelContent };
