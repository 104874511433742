import React from 'react';
import { Spin } from 'antd';
import { ISearchableSelect } from '@shared-components/models';
import { Option, SearchableSelect } from '@moxie/shared';
import { UNASSIGNED_LABEL } from '@moxie/constants';
import { UnassignedCard, UserNameCard } from '../user-name-card';
import { IUserData } from 'libs/shared/src/crm-modules/user/user.model';

interface Props extends Omit<ISearchableSelect, 'users'> {
  users: IUserData[];
  optionTitle?: (userId: string) => string | undefined,
}

const UserSelectBox: React.FC<Props> = ({
  showUnassigned,
  optionTitle,
  followersId,
  followers,
  ...props
}) => {
  let userList: any;
  if (followersId) {
    const propsUsers = props?.users;
    const propsFollowers = followers;

    userList = propsFollowers?.reduce(
      (result: any, userObj: any) => {
        const existingObj = result.find((item: any) => item.id === userObj.id);
        if (existingObj) {
          Object.assign(existingObj, userObj);
        } else {
          result.push(userObj);
        }
        return result;
      },
      [...propsUsers]
    );
  }

  return (
    <SearchableSelect
      {...props}
      optionLabelProp="label"
      optionFilter="search_prop"
      defaultValue={followersId}
    >
      {!userList ? (
        <Option value="null">
          <Spin spinning />
        </Option>
      ) : (
        <>
          {!showUnassigned && (
            <Option
              value="null"
              label={UNASSIGNED_LABEL}
              disabled={!followersId}
            >
              <UnassignedCard />
            </Option>
          )}
          {userList?.map((user: IUserData, index: number) => {
            return (
              <Option
                disabled={
                  user.deactivated ||
                  Boolean(optionTitle && optionTitle(user.id as string))
                }
                value={user.id || ''}
                key={user.id || index}
                label={`${user.firstName} ${user.lastName}`}
                search_prop={`${user.firstName} ${user.lastName} ${user.email}`}
                className="padding-left-1"
                title={optionTitle && optionTitle(user.id as string)}
              >
                <UserNameCard
                  firstName={user.firstName || ''}
                  lastName={user.lastName}
                  email={user.email || ''}
                  index={user.id || ''}
                />
              </Option>
            );
          })}
        </>
      )}
    </SearchableSelect>
  );
};

export { UserSelectBox };
