import React from 'react';
import Select from 'antd/lib/select';
import { SearchBox } from 'libs/shared/src/crm-modules/shared';
import { getBranchesByUserContactPermission } from '@crm/services.api';
import { PLACEHOLDER } from '@moxie/constants';
import { capitalizeFirstLetter } from '@moxie/shared';


interface SelectProps {
  branch: string;
  handleBranchChange: (val: string) => void;
}

const SelectBranch: React.FC<SelectProps> = ({ branch, handleBranchChange }: SelectProps) => {
  return (
    <SearchBox
      fetchQuery={getBranchesByUserContactPermission}
      valueKey="id"
      labelKey="name"
      value={branch}
      size="middle"
      showArrow
      className="table__branch-search-width"
      placeholder={PLACEHOLDER.SELECT_BRANCH}
      optionFilterProp="label"
      defaultOpen={false}
      onChange={handleBranchChange}
    >
      {
        (option) =>
          <Select.Option value={option.id} label={capitalizeFirstLetter(option.name)}>
            {option.name}
          </Select.Option>
      }
    </SearchBox>
  )
}

export default SelectBranch
