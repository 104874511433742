import React, { useEffect } from 'react';
import { OfficeVisitTableComponent } from '@crm/shared';
import { useDispatch } from 'react-redux';
import { officeBranchActions, userActions } from '@crm/core';

const OfficeVisitListComponent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.fetchUsers());
    dispatch(officeBranchActions.getAllRequest());
  }, []);
  return (
    <div>
      <OfficeVisitTableComponent />
    </div>
  );
};

export { OfficeVisitListComponent };
