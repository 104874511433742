import React, { useEffect } from 'react';
import Row from 'antd/es/row';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { IOfficeBranchCRM } from '@model/data.model';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import { useForm } from 'antd/es/form/Form';
import {
  useUpdateBranchMutation,
} from '@crm/libs/hooks/contacts';
import useContactParam from '@crm/libs/hooks/contacts/useContactParam';
import { useAppSelector } from '@crm/core';
import { SearchBox } from 'libs/shared/src/crm-modules/shared';
import { getBranchesList } from '@crm/services.api';
import { capitalizeFirstLetter } from '@moxie/shared';
import { Select, Tag, Typography } from 'antd';
import { TEXT, classNames } from '@moxie/constants';

interface IModifiedBranch extends IOfficeBranchCRM {
  isPrimary: boolean;
}
interface Props {
  selectedBranches: IModifiedBranch[];
  onCancel: () => void;
  disabledBranches: string[];
}

interface FieldValues {
  branches: string[];
}

const BranchForm: React.FC<Props> = ({
  onCancel,
  selectedBranches,
  disabledBranches,
}) => {
  const contactId = useContactParam();

  const contactPrimaryBranch = useAppSelector((store) => store.contact.branch);

  const [form] = useForm<FieldValues>();
  const updateBranch = useUpdateBranchMutation(contactId);
  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    form.setFieldsValue({
      branches: selectedBranches.map((branch) => branch.id),
    });
  }, [selectedBranches]);

  const isPrimaryBranch = (branchId: string) =>
    Boolean(contactPrimaryBranch.id === branchId);

  const onFinish = async ({ branches }: FieldValues) => {
    const prevBranches = selectedBranches.map((branch) => branch.id);

    const filteredPrimaryBranch = branches.filter(
      (branchId) => branchId !== contactPrimaryBranch.id
    );

    const addedBranches = branches.filter(
      (branch) => !prevBranches.includes(branch)
    );
    const removedBranches = prevBranches.filter(
      (branch) => !branches.includes(branch.toString())
    );

    const secondaryBranches = filteredPrimaryBranch.map((branch) => ({
      id: branch,
    }));

    await updateBranch.mutateAsync(secondaryBranches, {
      onSuccess() {
        socket?.emit('server::branches-updated', {
          addedBranches,
          removedBranches,
          userId: user?.id as string,
          contactId: contactId,
          companyId: user?.companyId as string,
          activitiesTypeId: contactId
        });
      }
    });
    onCancel();
  };

  const isDisabledBranch = (branchId: string) =>
    Boolean(
      selectedBranches.find(
        (branch) =>
          branchId === branch.id && disabledBranches.includes(branch.id)
      )
    );

  return (
    <Form onFinish={onFinish} form={form} layout="inline">
      <Row justify="space-around">
        <Form.Item name="branches" className="overview-branch-select">
          <SearchBox
            fetchQuery={getBranchesList}
            valueKey="id"
            labelKey="name"
            size="middle"
            mode="multiple"
            showArrow
            allowClear={false}
            className="table__branch-search-width"
            optionFilterProp="label"
            defaultOpen={false}
            hideDefaultOptionView
          >
            {
              (option) =>
                <Select.Option
                  key={option.id}
                  value={option.id}
                  label={capitalizeFirstLetter(option.name)}
                  disabled={
                    isPrimaryBranch(option.id as string) ||
                    isDisabledBranch(option.id as string)
                  }
                  title={
                    isPrimaryBranch(option.id as string)
                      ? 'Primary branch cannot be removed'
                      : isDisabledBranch(option.id as string)
                        ? 'Branches with Users that are Assignees or Followers cannot be removed'
                        : undefined
                  }
                >
                  <Row className="branch-option-height">
                    <Typography.Paragraph
                      ellipsis={{
                        rows: 1,
                        expandable: false,
                        tooltip: option.name,
                      }}
                      className={
                        isPrimaryBranch(option?.id as string)
                          ? 'text-grey primary-branch-option initial_capital'
                          : 'sec-branch-option initial_capital'
                      }
                    >
                      {option.name}
                    </Typography.Paragraph>
                    {isPrimaryBranch(option.id as string) && (
                      <>
                        <Tag className="tag-processing overview-branch-tag-height margin-left-2">
                          <Typography.Text
                            className={classNames(
                              'overview-branch-tag-text',
                              isPrimaryBranch(option.id as string)
                                ? 'text-grey'
                                : null
                            )}
                          >
                            {TEXT.PRIMARY}
                          </Typography.Text>
                        </Tag>
                      </>
                    )}
                  </Row>
                </Select.Option>
            }
          </SearchBox>
        </Form.Item>
        <Button
          icon={<CheckOutlined />}
          type="link"
          htmlType="submit"
          size="small"
        />
        <Button
          icon={<CloseOutlined />}
          onClick={onCancel}
          type="link"
          danger
          size="small"
        />
      </Row>
    </Form>
  );
};

export default BranchForm;
