import React from 'react';
import { LABEL } from '@moxie/constants';
import { Button, Menu } from 'antd';

const languageRequirementMenu = (
  cb: (value: string) => void,
  languageRequirement: any[]
) => (
  <Menu>
    {!languageRequirement?.some(
      (item: any) => item.testName?.toUpperCase() === LABEL.IELTS
    ) && (
      <Menu.Item key="1">
        <Button
          type="link"
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event?.stopPropagation();
            cb(LABEL.IELTS);
          }}
        >
          {LABEL.IELTS}
        </Button>
      </Menu.Item>
    )}
    {!languageRequirement?.some(
      (item: any) => item.testName?.toUpperCase() === LABEL.TOEFL
    ) && (
      <Menu.Item key="2">
        <Button
          type="link"
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event?.stopPropagation();
            cb(LABEL.TOEFL);
          }}
        >
          {LABEL.TOEFL}
        </Button>
      </Menu.Item>
    )}
    {!languageRequirement?.some(
      (item: any) => item.testName?.toUpperCase() === LABEL.PTE
    ) && (
      <Menu.Item key="3">
        <Button
          type="link"
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event?.stopPropagation();
            cb(LABEL.PTE);
          }}
        >
          {LABEL.PTE}
        </Button>
      </Menu.Item>
    )}
  </Menu>
);

export { languageRequirementMenu };
