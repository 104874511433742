import { useFetchApplicationStagesByWorkflowId } from "@crm/libs/hooks/application/useFetchApplciationStages";
import { useCallback } from "react";

export const useMenu = (showStages: boolean, workflowId: string, setStageFilter: (stageId: string | undefined) => void) => {
  const { stages } = useFetchApplicationStagesByWorkflowId(workflowId);
  const defaultMenu = [
    {
      name: <span>All</span>,
      id: 'all',
      url: `application/all`,
    },
    {
      name: <span>In Progress</span>,
      id: 'in-progress',
      url: `application/in-progress`,
    },
    {
      name: <span>Complete</span>,
      id: 'complete',
      url: `application/complete`,
    },
    {
      name: <span>Discontinued</span>,
      id: 'discontinued',
      url: `application/discontinued`,
    },
  ];

  const handleStageClick = useCallback((stage) => {
    setStageFilter(stage);
  }, [setStageFilter]);

  const ENQUIRIES_MENU = () => {
    if (showStages) {
      return [
        defaultMenu[0],
        ...stages.map((stage: { stage: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; id: any; }) => {
          return {
            onClick: () => handleStageClick(stage.stage),
            name: <span>{stage.stage}</span>,
            id: stage.id,
            url: `application/stages`,
          }
        }),
      ].concat(defaultMenu.slice(2, 4)).filter((menu, index, self) =>
        index === self.findIndex((m) => m.id === menu.id)
      );
    } else {
      return defaultMenu;
    }
  };

  return ENQUIRIES_MENU;
};
