import React from "react";
import { TEXT } from "@moxie/constants";
import { PageHeaderTitle } from "@shared-components/elements";
import { EnquiryFormsTable } from "./enquiry-forms-table";
import { PageHeaderMenu } from "apps/crm/src/shared/page-header-menu";


const Enquiries_Form_Menu = [
  {
    name: 'Online Enquiry Form',
    id: 'all',
  }
]

const EnquiryFormsList = () => {
  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.ENQUIRY_FORM} />
      <PageHeaderMenu menu={Enquiries_Form_Menu} />
      <EnquiryFormsTable />
    </div>
  )
}
export { EnquiryFormsList };
