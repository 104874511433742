import { Form, FormInstance, Input, Select, Space } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { errorNotificationHandler } from "@moxie/shared";
import { COUNTRIES } from "@moxie/constants";
import { ContactData } from "@model/crm/contact.model";

interface EditableRowProps {
  index: number;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof ContactData;
  record: ContactData;
  handleSave: (record: ContactData) => void;
  checkValidation: (dataIndex: keyof ContactData, record: ContactData) => boolean;
}

const EditableContext = React.createContext<FormInstance<ContactData> | null>(null);

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  checkValidation,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext)!;

  const requiredFields = ['firstName', 'lastName', 'email'];

  const camelToFlat = (camel: string) => {
    const flatStr = camel.replace(/([a-z])([A-Z])/g, '$1 $2').split(" ")

    let capitalizeWord = "";

    flatStr.forEach(word => {
      capitalizeWord = capitalizeWord + word.charAt(0).toUpperCase() + word.slice(1) + " "
    })
    return capitalizeWord;
  }

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    const valueToSet = record[dataIndex] === undefined ? '' : record[dataIndex];
    form.setFieldsValue({ [dataIndex]: valueToSet });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      errorNotificationHandler('Save failed.');
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: requiredFields.includes(dataIndex) ? true : false,
            message: `${camelToFlat(dataIndex)} is required.`,
          },
          {
            min: dataIndex === 'phone' ? 10 : undefined,
            max: dataIndex === 'phone' ? 11 : undefined,
            message: dataIndex === 'phone' ? `Phone must be 10 digits or up to 11 digits.` : undefined,
          }
        ]}
      >
        {dataIndex === 'country' || dataIndex === 'nationality' || dataIndex === 'countryOfPassport' ? (
          <Select showSearch ref={inputRef} onBlur={save} optionFilterProp="key" allowClear={true}>
            {COUNTRIES.map((country) => (
              <Select.Option key={country.name} value={country.name}>
                {country.name}
              </Select.Option>
            ))}
          </Select>
        ) : dataIndex === 'countryCode' ?
          <Select showSearch ref={inputRef} onBlur={save} optionFilterProp="key" allowClear={true}>
            {COUNTRIES.map((country) => (
              <Select.Option key={country.name} value={country.dial_code}>
                <Space direction="horizontal">{country.flag}{country.dial_code}</Space>
              </Select.Option>
            ))}
          </Select> :
          (
            <Input ref={inputRef} onPressEnter={save} onBlur={save} />
          )}
      </Form.Item>
    ) :
      (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24, backgroundColor: checkValidation(dataIndex, record) ? "#ffcccc" : undefined }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      )
  }

  return <td {...restProps}>{childNode}</td>;
};

export { EditableRow, EditableCell };
