/* eslint-disable no-unused-vars */
import React from 'react';
import { IContactCrm, ITableNameCardProps } from '@shared-components/models';
import { useDispatch } from 'react-redux';
import { UserNameCard } from './user-name-card';
import {
  ARCHIVE,
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  DELETE_BTN,
  RESTORE,
  URL_DETAIL_LINK,
} from '@moxie/constants';
import { Menu } from 'antd';
import {
  AppstoreAddOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { contactActions, leadActions } from '@crm/core';
import { popConfirm } from '@shared-components/elements';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';
import { useApplicationDetailsBycontactId } from '@crm/src/pages/application-details/hooks/useApplicationDetailsByContactId';
import { archiveClientRequest, editClientAction, unarchiveClientRequest } from '@crm/src/core/contact/contact.action';
import { archiveLeadRequest, unarchiveLeadRequest } from '@crm/src/core/lead/lead.action';
import { bulkDiscontinueCrmApplications } from '@crm/libs/services.api/lib/application.api';

const userMenu = (
  archived: boolean,
  {
    onArchive,
    onDelete,
  }: {
    onArchive: () => void;
    onDelete: () => void;
  },
  data: IContactCrm,
  user: any
) => {
  return (
    <Menu>
      {
        <Menu.Item key={2} icon={<AppstoreAddOutlined />} onClick={onArchive}>
          {archived ? RESTORE : ARCHIVE}
        </Menu.Item>
      }
      {
        <Menu.Item key={3} icon={<DeleteOutlined />} danger onClick={onDelete}>
          {DELETE_BTN}
        </Menu.Item>
      }
    </Menu>
  );
};

export const ContactTableNameCard: React.FC<ITableNameCardProps | any> = ({
  firstName,
  lastName,
  email,
  index,
  id,
  data,
  userProp,
  triggerRefresh,
}: ITableNameCardProps | any) => {
  const dispatch = useDispatch();
  const user = userProp;
  const canAccess = useCanAccess();
  const { applicationData } = useApplicationDetailsBycontactId(id);


  const onArchive = async () => {
    if (data?.status === 'Client') {
      if (data?.archived) {
        dispatch(
          unarchiveClientRequest(
            id,
            {
              archived: false,
              assigneeId: data.assigneeId,
              archivedBy: null,
            },
            triggerRefresh
          )
        );
      }

      else {
        dispatch(
          archiveClientRequest(
            id,
            {
              archived: true,
              archivedBy: user?.id,
              assigneeId: data?.assigneeId,
            },
            triggerRefresh
          )
        );
      }
    }

    if (data?.status === 'Lead') {
      if (data?.archived) {
        dispatch(
          unarchiveLeadRequest(
            id,
            {
              archived: false,
              assigneeId: data.assigneeId,
              archivedBy: null,
            },
            triggerRefresh
          )
        );
      }
      else {
        dispatch(
          archiveLeadRequest(
            id,
            {
              archived: true,
              archivedBy: user?.id,
              assigneeId: data?.assigneeId,
            },
            triggerRefresh
          )
        );
      }
    }
    triggerRefresh();
  };

  const onFinalArchiveConfirmation = async () => {
    if (applicationData && applicationData.length > 0) {
      finalArchiveConfirmation(id, applicationData);
      return;
    }
    onArchive()
  }

  const onDelete = () => {
    const updateData = { isDeleted: true, deletedBy: user?.id, contactId: data?.id };

    if (applicationData && applicationData.length > 0) {
      dispatch(contactActions.deleteClientRequest(data?.id, updateData, () => triggerRefresh()));
      return;
    }

    dispatch(leadActions.deleteLeadRequest(data?.id, updateData, () => triggerRefresh()));
  };

  const handleArchive = () => {
    popConfirm(
      !data?.archived ? ARCHIVE : RESTORE,
      !data?.archived
        ? `${CONFIRM_MESSAGE.ARCHIVE_LEAD} ${data?.firstName} ${data?.lastName}?`
        : `${CONFIRM_MESSAGE.RESTORE_LEAD} ${data?.firstName} ${data?.lastName}?`,
      !data?.archived ? onFinalArchiveConfirmation : onArchive,
    );

  };

  const finalArchiveConfirmation = (userId: string, applications: any) => {
    popConfirm(ARCHIVE, CONFIRM_MESSAGE.CONTACT_ARCHIVE_FINAL, () => {
      archive(userId, applications);
    });
  };

  const archive = async (id: string, applications: any) => {
    const applicationIds = applications.map(
      (d: any) => d.id
    );
    await bulkDiscontinueCrmApplications(applicationIds);

    const payload =
    {
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      countryCode: data.countryCode,
      crmAssigneeId: data.assigneeId,
      avatar: data.avatar,
    }

    dispatch(
      editClientAction(payload, () => {
        triggerRefresh();
        onArchive();
      }
      )
    );
  };

  const handleSingleLeadDelete = () => {
    popConfirm(
      CONFIRM_TITLE.DELETE_LEAD,
      `${CONFIRM_MESSAGE.DELETE_LEAD} ${data?.firstName} ${data?.lastName}?`,
      onDelete,
      true
    );
  };

  const menuActions = {
    onArchive: handleArchive,
    onDelete: handleSingleLeadDelete,
  };

  return (
    <UserNameCard
      firstName={firstName}
      lastName={lastName}
      id={id}
      email={email}
      link={`${URL_DETAIL_LINK}${id}/deals`}
      actionBar={true}
      index={index}
      menu={canAccess('contact', 'update') ? userMenu(data?.archived || false, menuActions, data, user) : null}
      showEmailAsLink={true}
      showMailIcon={true}
    />
  );
};
