import { ApplicationStatusChangeRemarks } from '@model/application';
import Form from 'antd/es/form';
import Modal from 'antd/es/modal/Modal';
import Select from 'antd/es/select';
import React from 'react';
import TextArea from 'antd/es/input/TextArea';
import { Row } from 'antd/es/grid';
import Button from 'antd/es/button';
import { errorNotificationHandler } from '@moxie/shared';

interface Props {
  isOpen: boolean;
  onSubmit: (val: ApplicationStatusChangeRemarks) => void;
  title: string;
  confirmButton: JSX.Element;
  options: string[];
  formName: string;
  onClose: () => void;
  isSubmitting: boolean;
}
const StatusUpdateModal: React.FC<Props> = ({ isOpen, title, confirmButton, onSubmit, options, formName, onClose, isSubmitting }) => {
  const [form] = Form.useForm<ApplicationStatusChangeRemarks>();

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      onSubmit(values)
      form.resetFields();
    } catch (error) {
      errorNotificationHandler('Please fill all the necessary details')
    }
  }

  const handleClose = () => {
    form.resetFields();
    onClose();
  }
  return (
    <Modal
      title={title}
      visible={isOpen}
      onCancel={handleClose}
      footer={
        <Row style={{ 'columnGap': '0.5rem' }} justify="end">
          <Button htmlType="reset" onClick={handleClose} disabled={isSubmitting}>
            Cancel
          </Button>
          {confirmButton}
        </Row>
      }
    >
      <Form layout="vertical" form={form} id={formName} onFinish={onFinish}>
        <Form.Item
          rules={[{
            required: true,
            message: 'Please select reason'
          }]}
          required
          label="Reason"
          name="reason"
          initialValue={options[0]}
        >
          <Select
            showSearch
            allowClear
            options={options.map(value => ({ value, label: value }))}
          />
        </Form.Item>

        <Form.Item required name="remarks" label="Remarks" rules={[{
          required: true,
          message: 'Please add remarks'
        }]}>
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default StatusUpdateModal
