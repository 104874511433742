import React from 'react';
import { TITLE } from '@moxie/constants';
import { PageHeaderTitle } from '@shared-components/elements';
import { Avatar, Divider, Empty, List, Skeleton } from 'antd';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactHtmlParser from 'react-html-parser';
import { useFetchNotifications, useToggleNotificationStatusMutation } from '@crm/libs/hooks/users';
import { Notification } from '@model/notification';
import { Link } from 'react-router-dom';

const Notifications: React.FC = () => {
    const limit = 20;
    const { hasMore, isLoading, next, notifications } = useFetchNotifications(limit);
    const mutation = useToggleNotificationStatusMutation();
    const markAsRead = (notification_id: string) => {
        mutation.mutate({
            is_read: true,
            notification_id
        })
    };

    return (
        <div>
            <PageHeaderTitle title={TITLE.ALL_NOTIFICATION} />
            <div
                className="bg-white padding-top-2 padding-bottom-2 notification-page-scroll"
                id="notificationScroll"
            >
                <InfiniteScroll
                    dataLength={notifications.length}
                    hasMore={hasMore}
                    next={() => next()}
                    loader={
                        isLoading ? <div className="display-flex notification-item padding-2">
                            <Skeleton.Avatar active />
                            <Skeleton
                                paragraph={{ rows: 1 }}
                                title={false}
                                active
                                className="margin-left-2"
                            />
                        </div> : null
                    }
                    scrollableTarget="notificationScroll"
                    endMessage={
                        notifications.length > 20 && (
                            <Divider plain>You are all caught up.</Divider>
                        )
                    }
                    style={{'overflowY':'hidden'}}
                >
                    {notifications.length <= 0 ? (
                        <Empty
                            className="padding-bottom-2"
                            description="No Notifications"
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    ) : (
                        <List
                            dataSource={notifications}
                            itemLayout="horizontal"
                            renderItem={(item: Notification) => (
                                <Link to={item?.url}>
                                    <List.Item
                                        key={item.id}
                                        className={`notification-item padding-left-2 padding-right-2 ${!item.isRead ? 'unread' : ''
                                            }`}
                                        onClick={() => markAsRead(item.id)}
                                    >
                                        <List.Item.Meta
                                            className="align-items-center"
                                            avatar={
                                                <Avatar>
                                                    {item?.user?.firstName
                                                        ?.substring(0, 1)
                                                        ?.toUpperCase()}
                                                    {item?.user?.lastName
                                                        ?.substring(0, 1)
                                                        ?.toUpperCase()}
                                                </Avatar>
                                            }
                                            description={
                                                <div className="text-black">
                                                    {ReactHtmlParser(item.message)}
                                                </div>
                                            }
                                        />
                                        <div>{moment(item.createdAt).fromNow()}</div>
                                    </List.Item>
                                </Link>
                            )}
                        />
                    )}
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default Notifications;
