import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Typography,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import {
  PageHeaderTitle,
  capitalizeFirstLetter,
  errorNotificationHandler,
  successNotificationHandler,
} from '@moxie/shared';
import { APP_REDIRECT_URI, TEXT, USER_ID } from '@moxie/constants';
import Cookies from 'js-cookie';
import { addNewEvent, deleteEvent, fetchAuthorizeUrl, fetchGoogleEvents, updateCalendarEvent } from '../../libs/services.api/lib/calendar.api';
import { EventInput } from '@fullcalendar/core';
import { FormProps } from 'antd/es/form';
import { CalendarEvent } from '@model/calendar';
import { FieldData } from 'rc-field-form/es/interface.js';

interface EventFormFields {
  title: string;
  startDate: Moment;
  startTime: Moment;
  endDate: Moment;
  endTime: Moment;
}

const renderEventContent = (eventInfo: any) => {
  return (
    <div>
      <i className="truncate">{eventInfo.event.title}</i>
    </div>
  );
};

const CalendarComponent = () => {
  const [events, setEvents] = useState<EventInput[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [state, setState] = useState<any>({ state: '' });
  const [loadingFetchEvents, setLoadingFetchEvents] = useState(false);
  const [postLoading, setPostLoading] = useState(false)
  const [form] = Form.useForm<EventFormFields>();
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    setLoadingFetchEvents(true);
    init()
  }, [])

  const init = async () => {
    setLoadingFetchEvents(true)
    try {
      await fetchEvents()
      Cookies.remove(APP_REDIRECT_URI)
      Cookies.remove(USER_ID)

    } catch (error) {
      const url = await fetchAuthorizeUrl()
      window.location.href = url
    }
    setLoadingFetchEvents(false)
  }

  const fetchEvents = async () => {
    setLoadingFetchEvents(true);
    const events = await fetchGoogleEvents()
    setEvents(events)
    setLoadingFetchEvents(false);
  };

  function handleClose() {
    form.resetFields();
    setState({});
    setModal(false);
    setDisabled(false)
  }

  const handleDateSelect = (selectInfo: any) => {
    selectInfo.view.calendar.unselect();
    setState({ selectInfo, state: 'create' });
    form.setFieldsValue({
      startDate: moment(selectInfo.startStr),
      endDate: moment(selectInfo.startStr),
    });
    setModal(true);
  };

  const handleEventClick = (clickInfo: any) => {
    const isEndAndStartSame = Boolean(clickInfo.event.endStr);
    form.setFieldsValue({
      title: clickInfo.event.title,
      startDate: moment(clickInfo.event.startStr).utc(),
      endDate: isEndAndStartSame ? moment(clickInfo.event.endStr).utc() : moment(clickInfo.event.startStr).utc(),
      startTime: moment(new Date(clickInfo.event.startStr).getTime()).utc(),
      endTime: isEndAndStartSame ? moment(new Date(clickInfo.event.endStr).getTime()).utc() : moment(new Date(clickInfo.event.startStr).getTime()).utc(),
    });
    setState({ clickInfo, state: 'update' });
    setModal(true);
  };

  const handleChange = (newDate: any, field: any) => {
    const object: any = {};
    object[field] = newDate;
    form.setFieldsValue({ ...form.getFieldsValue(), ...object });
    if (field === "startTime") {
      form.validateFields(['startTime']);
    } else if (field === "startDate") {
      form.validateFields(['startDate']);
    }
  };

  const getDateTime = (prefix: string) => {
    const date = form.getFieldValue(`${prefix}Date`)?.format('YYYY-MM-DD')
    const time = form.getFieldValue(`${prefix}Time`)?.format('HH:mm');
    return new Date(`${date}T${time}:00.000Z`).toISOString();
  };

  const handleSubmit: FormProps<EventFormFields>['onFinish'] = async (data) => {
    try {
      setPostLoading(true)
      const eventId = state.clickInfo?.event?.id;
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (eventId) {
        const updatedEvent = {
          summary: form.getFieldValue('title'),
          start: {
            dateTime: getDateTime('start'), timeZone
          },
          end: {
            dateTime: getDateTime('end'), timeZone
          },
        };
        await updateCalendarEvent(eventId, updatedEvent)
        successNotificationHandler(TEXT.EVENT_UPDATED);
      } else {
        const newEvent: CalendarEvent = {
          summary: data.title,
          start: { dateTime: getDateTime('start'), timeZone },
          end: { dateTime: getDateTime('end'), timeZone },
        };
        await addNewEvent(newEvent)
        successNotificationHandler(TEXT.NEW_EVENT_CREATED);
      }

      handleClose();
      await fetchEvents();
    } catch (error: any) {
      errorNotificationHandler(error);
    } finally {
      setPostLoading(false);
    }
  }

  const handleEventDelete = async () => {
    handleClose();
    const eventId = state.clickInfo?.event?.id;
    if (eventId) {
      await deleteEvent(eventId)
    }
    await fetchEvents();
  };

  const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
    const errorCount = allFields.reduce((prev, field) => prev + (field.errors?.length ? 1 : 0), 0)
    errorCount ? setDisabled(true) : setDisabled(false)
  }

  return (
    <Spin spinning={loadingFetchEvents}>
      <PageHeaderTitle title={TEXT.CALENDAR} />
      <div className="full-height bg-white padding-3">
        <FullCalendar
          height={'100%'}
          weekends={true}
          plugins={[dayGridPlugin, interactionPlugin]}
          eventContent={renderEventContent}
          initialView="dayGridMonth"
          events={events}
          editable={true}
          selectable={true}
          buttonText={{ today: 'Today' }}
          select={handleDateSelect}
          eventClick={handleEventClick}
          showNonCurrentDates={false}
          timeZone="UTC"
        />
      </div>
      <Modal
        visible={modal}
        okText={capitalizeFirstLetter(TEXT.DONE)}
        onCancel={handleClose}
        title={state.state === 'create' ? TEXT.ADD_EVENT : TEXT.EDIT_EVENT}
        footer={
          <Row justify="space-between" align="middle">
            <Col>
              {state.state !== 'create' && (
                <Popconfirm
                  title={<Typography.Text>Are you sure ?</Typography.Text>}
                  placement="bottom"
                  onConfirm={handleEventDelete}
                  icon={false}
                >
                  <Button className="text-error-btn">
                    <DeleteOutlined />
                    Delete
                  </Button>
                </Popconfirm>
              )}
            </Col>
            <Col>
              <Button
                onClick={handleClose}
                type="ghost"
              >
                {capitalizeFirstLetter(TEXT.CANCEL)}
              </Button>
              <Button
                onClick={form.submit}
                type="primary"
                disabled={disabled || postLoading}
              >
                {capitalizeFirstLetter(TEXT.DONE)}
              </Button>
            </Col>
          </Row>
        }
      >
        <Form form={form}
          autoComplete="off"
          onFinish={handleSubmit}
          onFieldsChange={onFieldsChange}>
          <Row>
            <Col span={24}>
              <Form.Item
                label={TEXT.TITLE}
                name="title"
                messageVariables={{ label: "title" }}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={TEXT.START_DATE}
                name="startDate"
                messageVariables={{ label: "start date" }}
                rules={[{ required: true }]}
                validateTrigger={"endDate"}
              >
                <DatePicker
                  onChange={(newDate) => handleChange(newDate, 'startDate')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={TEXT.START_TIME}
                name="startTime"
                messageVariables={{ label: "start time" }}
                rules={[{ required: true }]}
                validateTrigger={"endTime"}
              >
                <DatePicker
                  picker="time"
                  minuteStep={15}
                  format={'hh:mm a'}
                  showSecond={false}
                  onChange={(newDate) => handleChange(newDate, 'startTime')}
                  showNow={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label={TEXT.END_DATE}
                name="endDate"
                rules={[{
                  required: true,
                  validator:
                    (_rule, value) => {
                      const startDate = moment(form.getFieldValue('startDate')).format('YYYY-MM-DD');
                      const endDate = moment(form.getFieldValue('endDate')).format('YYYY-MM-DD');
                      if (!value) {
                        return Promise.reject(new Error('Please enter end date'));
                      } else if (moment(startDate).isAfter(endDate)) {
                        return Promise.reject(new Error('Start date is more than end date'))
                      }
                      return Promise.resolve();
                    },
                }]}
              >
                <DatePicker
                  onChange={(newDate) => handleChange(newDate, 'endDate')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={TEXT.END_TIME}
                name="endTime"
                rules={[{
                  required: true,
                  validator:
                    (_rule, value) => {
                      const startDate = moment(form.getFieldValue('startDate')).format('YYYY-MM-DD');
                      const endDate = moment(form.getFieldValue('endDate')).format('YYYY-MM-DD');
                      if (!value) {
                        return Promise.reject(new Error('Please enter end time'));
                      } else if (
                        moment(startDate).isSame(endDate) &&
                        form.getFieldValue('startTime') &&
                        form.getFieldValue('endTime') &&
                        moment(getDateTime('start')).isAfter(getDateTime('end'))
                      ) {
                        return Promise.reject(new Error('Start time is more than end time'));
                      }
                      return Promise.resolve();
                    },
                }]}
              >
                <DatePicker
                  picker="time"
                  minuteStep={15}
                  format={'hh:mm a'}
                  showSecond={false}
                  onChange={(newDate) => handleChange(newDate, 'endTime')}
                  showNow={false}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Spin>
  );
};

export { CalendarComponent };
