import Tabs from 'antd/es/tabs';
import { useMemo, useState } from 'react';
import { ApplicationDetailsTab } from './components/application-details-tab';
import { PaymentsTab } from './components/payments-tab';

export const ApplicationFieldsSection = () => {
  const [activeKey, setActiveKey] = useState('application-details');

  const tablePanels = useMemo(() => {
    return [
      {
        label: 'Application Details',
        url: 'application-details',
        panel: <ApplicationDetailsTab />,
      },
      {
        label: 'Payments',
        url: 'payments',
        panel: <PaymentsTab />,
      },
    ];
  }, []);

  return (
    <section className="application-field-section padding-2">
      <Tabs
        className="custom_tab"
        activeKey={activeKey}
        onTabClick={(key) => setActiveKey(key)}
        defaultActiveKey={'activities'}
      >
        {tablePanels.map((tab) => (
          <Tabs.TabPane className="custom_tab" key={tab.url} tab={tab.label}>
            {tab.panel}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </section>
  );
};
