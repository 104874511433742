import { IApplicationReportCrm, IReportAgentPartner, IReportClient, IReportInstitution, IReportWorkflow } from '@model/crm/application-report.model';
import { IOfficeBranchCRM } from '@model/data.model';
import { WorkflowApplicationStage } from '@model/workflow';
import { LABEL, TEXT } from '@moxie/constants';
import { capitalizeFirstLetter, formatDateWithTimezone } from '@moxie/shared';
import { IUserData } from 'libs/shared/src/crm-modules/user/user.model';
import moment from 'moment';
import { ReactNode } from 'react';

export const exportApplicationReportColumns = (userTimeZone: string) => [
  {
    title: LABEL.APPLICATION_ID,
    width: 200,
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    render(id: string, data: IApplicationReportCrm): ReactNode {
      return `${data?.appIdentifier}`;
    },
  },
  {
    title: LABEL.CONTACT_ID,
    width: 200,
    dataIndex: 'client',
    render: (client: IReportClient): ReactNode => {
      return `${client.internalId}`;
    },
  },

  {
    title: LABEL.CONTACT_NAME,
    width: 300,
    dataIndex: 'client',
    render(row: IReportClient) {
      return `${row?.firstName} ${row?.lastName}`;
    },
  },

  {
    title: TEXT.PHONE,
    width: 200,
    dataIndex: 'client',
    render: (data: IReportClient): ReactNode => {
      return data?.phone ? `${data?.countryCode} ${data?.phone}` : '-';
    },
  },

  {
    title: LABEL.EMAIL,
    width: 200,
    dataIndex: 'client',
    render: (data: IReportClient): ReactNode => {
      return data.email ?? '-';
    },
  },

  {
    title: LABEL.DATE_OF_BIRTH,
    width: 300,
    dataIndex: 'client',
    render: (value: IReportClient): ReactNode => {
      return value?.dateOfBirth
        ? moment(value?.dateOfBirth).format('MMM DD, YYYY')
        : '-';
    },
  },

  {
    title: LABEL.CONTACT_STATE,
    width: 200,
    dataIndex: 'client',
    render: (value: IReportClient): ReactNode => {
      return value?.state ? capitalizeFirstLetter(value?.state) : '-';
    },
  },

  {
    title: LABEL.COUNTRY_OF_RESIDENCE,
    width: 200,
    dataIndex: 'client',
    render: (value: IReportClient): ReactNode => {
      return value?.country ?? '-';
    },
  },

  {
    title: LABEL.COUNTRY_OF_PASSPORT,
    width: 200,
    dataIndex: 'client',
    render: (value: IReportClient): ReactNode => {
      return value?.countryOfPassport ?? '-';
    },
  },

  {
    title: LABEL.VISA_EXPIRY_DATE,
    width: 200,
    dataIndex: 'client',
    render: (value: IReportClient): ReactNode => {
      return value?.visaExpiryDate
        ? moment(value?.visaExpiryDate).format('MMM DD, YYYY')
        : '-';
    },
  },

  {
    title: LABEL.ADDED_DATE,
    width: 200,
    dataIndex: 'createdAt',
    render: (value: string): ReactNode => {
      return formatDateWithTimezone(value, userTimeZone);
    },
  },
  {
    title: LABEL.ASSIGNEE,
    width: 200,
    dataIndex: 'assignees',
    ellipsis: true,
    render: (data: IUserData[]) =>
      data.map((item) => `${item?.firstName} ${item?.lastName}`)
        .join(', '),
  },

  {
    title: LABEL.PROCESSING_BRANCH,
    width: 150,
    dataIndex: 'processingBranch',
    ellipsis: true,
    render: (data: { id: string; name: string }): ReactNode => {
      return data?.name ?? '-';
    },
  },
  {
    title: LABEL.SERVICE,
    width: 150,
    dataIndex: 'institution',
    ellipsis: true,
    render: (data: IReportInstitution): ReactNode => {
      return data?.service?.name ?? '-';
    },
  },
  {
    title: LABEL.WORKFLOW,
    width: 150,
    dataIndex: 'workflow',
    ellipsis: true,
    render: (data: IReportWorkflow): ReactNode => {
      return data?.workflowType?.name ?? '-';
    },
  },
  {
    title: LABEL.APPLIED_INSTITUTION,
    width: 170,
    dataIndex: 'institution',
    ellipsis: true,
    render: (data: IReportInstitution): ReactNode => {
      return data?.name ?? '-';
    },
  },
  {
    title: LABEL.APPLIED_PRODUCT,
    width: 150,
    dataIndex: 'product',
    ellipsis: true,
    render: (data: { id: string; name: string; }): ReactNode => {
      return capitalizeFirstLetter(data?.name) ?? '-';
    },
  },
  {
    title: LABEL.INSTITUTION_COUNTRY,
    width: 170,
    dataIndex: 'workflow',
    ellipsis: true,
    render: (data: IReportWorkflow): ReactNode => {
      return data?.country ?? '-';
    },
  },
  {
    title: LABEL.INSTITUTION_BRANCH,
    width: 150,
    dataIndex: 'institutionBranch',
    ellipsis: true,
    render: (data: IOfficeBranchCRM): ReactNode => {
      return data?.name ?? '-';
    },
  },
  {
    title: LABEL.PRODUCT_TYPE,
    width: 120,
    dataIndex: 'productType',
    ellipsis: true,
    render: (data: string): ReactNode => {
      return data ?? '-';
    },
  },
  {
    title: LABEL.PRODUCT_SUB_TYPE,
    width: 150,
    dataIndex: 'productSubType',
    ellipsis: true,
    render: (data: string): ReactNode => {
      return data ?? '-';
    },
  },
  {
    title: LABEL.STATUS,
    width: 120,
    ellipsis: true,
    dataIndex: 'status',
    render: (value: string): ReactNode => {
      return value ?? '-';
    },
  },
  {
    title: LABEL.CURRENT_STAGE,
    width: 120,
    ellipsis: true,
    dataIndex: 'activeStage',
    render: (value: WorkflowApplicationStage): ReactNode => {
      return capitalizeFirstLetter(value?.stage) ?? '-';
    },
  },
  {
    title: LABEL.START_DATE,
    dataIndex: 'startDate',
    width: 200,
    ellipsis: true,
    render: (data: string): ReactNode => {
      return data ?? '-';
    },
  },
  {
    title: LABEL.END_DATE,
    dataIndex: 'endDate',
    width: 200,
    ellipsis: true,
    render: (data: string): ReactNode => {
      return data ?? '-';
    },
  },
  {
    title: TEXT.FEE,
    width: 150,
    ellipsis: true,
    dataIndex: 'productFeeAmount',
    render: (productFeeAmount: string, value: IApplicationReportCrm): ReactNode => {
      return (
        `${value?.productFeeCurrency} ${productFeeAmount}` ?? '-'
      );
    },
  },
  {
    title: TEXT.SUPER_AGENT,
    width: 150,
    ellipsis: true,
    dataIndex: 'agentPartner',
    render: (data: IReportAgentPartner): ReactNode => {
      return data?.superAgentPartner?.name
        ? capitalizeFirstLetter(data?.superAgentPartner?.name)
        : '-';
    },
  },
  {
    title: TEXT.SUB_AGENT,
    width: 150,
    ellipsis: true,
    dataIndex: 'agentPartner',
    render: (data: IReportAgentPartner): ReactNode => {
      return data?.subAgentPartner?.name
        ? capitalizeFirstLetter(data?.subAgentPartner?.name)
        : '-';
    },
  },
];
