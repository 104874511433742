import { updateApplication } from '@crm/libs/services.api/lib/application.api';
import useApplicationParam from './useApplicationParam';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  errorNotificationHandler,
  successNotificationHandler,
} from '@moxie/shared';
import { useDispatch } from 'react-redux';
import { contactActions } from '@crm/core';

export const useAssigneeUpdate = (contactId: string, dealId?: string) => {
  const applicationId = useApplicationParam();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (body: string[]) => {
      return updateApplication(applicationId, { assignees: body.map(id => ({ id })) });
    },
    onSuccess: () => {
      successNotificationHandler('Assignees updated successfully.', 1.5);
      queryClient.invalidateQueries({
        queryKey: ['application-details', applicationId],
      });
      queryClient.invalidateQueries({
        queryKey: ['deal-applications', dealId],
      });
      dispatch(contactActions.getContactDetailsRequest(contactId));
    },
    onError: () => {
      errorNotificationHandler('Error updating assignee.');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['application-assignee-update'],
      });
    },
  });
};
