import React, { useState } from 'react';
import { Tabs, Typography } from 'antd';
import { ProfileOutlined, UserOutlined, ReadOutlined } from '@ant-design/icons';
import { TEXT, classNames } from '@moxie/constants';
import { OverviewContent } from './overview-tab';
import { ProfileContent } from './profile-tab';
import { QualificationsContent } from './qualifications-tab';
import { useDispatch } from 'react-redux';
import { userActions } from '@crm/core';
import Divider from 'antd/es/divider';

const { Text } = Typography;

const OverviewTabs: React.FC = () => {
  const [tab, setTab] = useState(TEXT.OVERVIEW);
  const dispatch = useDispatch();

  const handleTabChange = (key: string) => {
    setTab(key);
    if (key === TEXT.OVERVIEW) {
      dispatch(userActions.fetchUsers());
    } else {
      dispatch(userActions.clearUserFetch());
    }
  };

  const isActiveTab = (tabKey: string) => tab === tabKey;

  return (
    <>
      <Divider className="margin-top-0 margin-bottom-0" />
      <div className="profile-tabs-container padding-bottom-1">
        <Tabs
          defaultActiveKey={TEXT.OVERVIEW}
          onChange={handleTabChange}
          centered
          size="small"
          tabBarGutter={0}
        >
          <Tabs.TabPane
            tab={
              <div className="justify-content-center flex-direction-vertical">
                <ProfileOutlined
                  className={classNames(
                    'overview-tab-icon',
                    isActiveTab(TEXT.OVERVIEW)
                      ? 'text-primary'
                      : 'text-grey'
                  )}
                />
                <Text className="overview-tabs-font text-primary overview-tab-text-margin">
                  {TEXT.OVERVIEW}
                </Text>
              </div>
            }
            key={TEXT.OVERVIEW}
          >
            <OverviewContent />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <div className="justify-content-center flex-direction-vertical">
                <UserOutlined
                  data-testid="crm-contactdetails-profile"
                  className={classNames(
                    'overview-profile-tab-icon',
                    isActiveTab(TEXT.PROFILE)
                      ? 'text-primary'
                      : 'text-grey'
                  )}
                />
                <Text className="overview-tabs-font text-primary overview-tab-text-margin">
                  {TEXT.PROFILE}
                </Text>
              </div>
            }
            key={TEXT.PROFILE}
          >
            <ProfileContent />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <div className="justify-content-center flex-direction-vertical">
                <ReadOutlined
                  data-testid="crm-contactdetails-qualification"
                  className={classNames(
                    'overview-tab-icon overview-qualification-tab-icon',
                    isActiveTab(TEXT.QUALIFICATIONS)
                      ? 'text-primary'
                      : 'text-grey'
                  )}
                />
                <Text className="overview-tabs-font text-primary overview-tab-text-margin">
                  {TEXT.QUALIFICATIONS}
                </Text>
              </div>
            }
            key={TEXT.QUALIFICATIONS}
          >
            <QualificationsContent />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  )
}

export default OverviewTabs
