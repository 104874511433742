import { useQuery } from '@tanstack/react-query';

import { API_URL } from '@moxie/constants';
import { CrmPaginatedResponse } from '@model/api-response.model';
import { errorNotificationHandler } from '@moxie/shared';
import { crmAxios, fetchContactAssignees, fetchContactBranches, fetchContactCountries, fetchContactPassportCountries, fetchContactStates } from '@crm/services.api';
import { IContactCrm } from '@model/crm/contact.model';
import axios from 'axios';

export const useGetContactsReport = ({
  page,
  limit,
  reportParams
}: {
  page: number;
  limit: number;
  reportParams: string;
}) => {
  const { data, isLoading } = useQuery({
    queryKey: ['contact-report', page, limit, reportParams],
    queryFn: ({ signal }) => {
      // use cancel token to cancel query when called multiple times
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const promise = crmAxios.get<CrmPaginatedResponse<IContactCrm>>(
        `${API_URL.CRM_CONTACTS_REPORTS}?${reportParams}`,
        { params: { page, limit }, cancelToken: source.token }
      );
      signal?.addEventListener('abort', () => {
        source.cancel('Query Cancelled');
      });
      return promise;
    },
    enabled: true,
    onError: () => errorNotificationHandler('Error fetching Contacts Report'),
  });
  return {
    contactsReportData: data?.data?.data?.data ?? [],
    isLoading,
    meta: data?.data?.data?.meta,
    hasMorePage: Number(data?.data?.data?.meta?.itemsPerPage) <= 10,
  };
};


export const useContactStates = () => {
  const { data } = useQuery({
    queryKey: ['contact-states'],
    queryFn: () => fetchContactStates(),
    refetchIntervalInBackground: false
  });

  return data ?? []
}

export const useContactAssignees = () => {
  const { data } = useQuery({
    queryKey: ['contact-assignees'],
    queryFn: () => fetchContactAssignees(),
    refetchIntervalInBackground: false
  });

  return data ? data?.data.users
    .map((val) => ({
      label: `${val.first_name} ${val.last_name}`,
      value: val.id as string
    }))
    .concat([{ label: 'Unassigned', value: 'Unassigned' }]) : []
}


export const useContactCountries = () => {
  const { data } = useQuery({
    queryKey: ['contact-countries'],
    queryFn: () => fetchContactCountries(),
    refetchIntervalInBackground: false
  });

  return data ?? []
}

export const useContactPassportCountries = () => {
  const { data } = useQuery({
    queryKey: ['contact-passport-countries'],
    queryFn: () => fetchContactPassportCountries(),
    refetchIntervalInBackground: false
  });

  return data ?? []

}

export const useContactBranches = () => {

  const { data } = useQuery({
    queryKey: ['contact-branches'],
    queryFn: () => fetchContactBranches(),
    refetchIntervalInBackground: false
  });

  return data ? data?.data.data
    .map((val) => ({
      label: val.name,
      value: val.id
    })) : []
}
