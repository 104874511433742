import { default as React } from 'react';
import { RedoOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router';
import { Button, Drawer, Form, Row, Select } from 'antd';

import { Option } from '@shared-components/elements';
import { IContactsReportDrawer } from '@model/data.model';
import { DRAWER_WIDTH, LABEL, TEXT } from '@moxie/constants';
import { useFetchAssignees } from '@crm/libs/hooks/contacts';
import { useContactBranches, useContactCountries, useContactPassportCountries, useContactStates } from '../hooks/useGetContactsReport';

const ContactReportDrawer: React.FC<IContactsReportDrawer> = ({
  drawerVisibility,
  onClose,
  disabled,
  setDisabled,
  form,
  setReportParams,
  setFilter,
}) => {
  const history = useHistory();
  const location = useLocation();

  const { users } = useFetchAssignees();
  const assigneeList = users
    .map((val) => ({
      label: `${val.firstName} ${val.lastName}`,
      value: val.id as string
    }))
    .concat([{ label: 'Unassigned', value: 'Unassigned' }]) || [];

  const states = useContactStates();
  const countryList = useContactCountries();
  const passportCountries = useContactPassportCountries();
  const branches = useContactBranches()

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  const getFilteredData = async (values: any) => {
    const queryParams = [];
    if (values.branch) {
      queryParams.push(`branch=${values.branch}`);
    }
    if (values.country) {
      queryParams.push(`country=${values.country}`);
    }
    if (values.countryOfPassport) {
      queryParams.push(`countryOfPassport=${values.countryOfPassport}`);
    }
    if (values.state) {
      queryParams.push(`state=${values.state}`);
    }
    if (values.assignees && values.assignees?.length > 0) {
      queryParams.push(`assignees=${values.assignees}`);
    }

    const queryString = queryParams.join('&');
    setReportParams(queryString);
    setFilter(values);

    history.replace(location.pathname + '?' + queryString)
  };

  const handleResetFields = () => {
    form.resetFields();
    setDisabled(true);
    setReportParams('');
    history.replace(location.pathname)
  };

  const handleSubmit = () => {
    form.submit();
  };

  return (
    <div>
      <Drawer
        title={TEXT.APPLY_FILTERS}
        width={DRAWER_WIDTH}
        visible={drawerVisibility}
        onClose={onClose}
        footer={
          <div className="padding-bottom-2 text-align-right">
            <Row justify="space-between">
              <Button onClick={() => handleResetFields()}>
                <RedoOutlined className="margin-right-1" />
                {TEXT.RESET_FILTERS}
              </Button>
              <Button type="primary" onClick={handleSubmit} disabled={disabled}>
                {TEXT.APPLY_FILTERS}
              </Button>
            </Row>
          </div>
        }
      >
        <div>
          <Form
            form={form}
            name="service-form"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            layout="horizontal"
            onFinish={getFilteredData}
            onFieldsChange={onFieldsChange}
          >
            <Form.Item name="assignees" label={LABEL.ASSIGNEE}>
              <Select
                placeholder="Select Assignee"
                mode="multiple"
                allowClear
                options={assigneeList}
              >
              </Select>
            </Form.Item>
            <Form.Item name="branch" label={LABEL.PRIMARY_BRANCH}>
              <Select
                placeholder="Select Primary Branch"
                mode="multiple"
                allowClear
                options={branches}
                optionFilterProp="label"
              >
              </Select>
            </Form.Item>
            <Form.Item name="country" label={LABEL.COUNTRY_OF_RESIDENCE}>
              <Select
                placeholder="Select Country"
                mode="multiple"
                allowClear
                optionFilterProp="label"
              >
                {countryList?.map((data: any) => {
                  return (
                    <Option key={data} value={data} className="initial_capital">
                      {data !== null || data !== undefined ? data : ''}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="state" label={LABEL.STATE}>
              <Select
                placeholder="Select State"
                mode="multiple"
                allowClear
                optionFilterProp="label"
              >
                {states?.map((data: any) => {
                  return (
                    <Option key={data} value={data} className="initial_capital">
                      {data !== null || data !== undefined ? data : ''}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="countryOfPassport"
              label={LABEL.COUNTRY_OF_PASSPORT}
            >
              <Select
                placeholder="Select Country of Passport"
                mode="multiple"
                allowClear
              >
                {passportCountries?.map((data: any) => {
                  return (
                    <Option key={data} value={data} className="initial_capital">
                      {data !== null || data !== undefined ? data : ''}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

export default ContactReportDrawer;
