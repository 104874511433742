import { updateApplication } from '@crm/libs/services.api/lib/application.api';
import { ApplicationAgentPartner } from '@model/application';
import { errorNotificationHandler } from '@moxie/shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type Payload = Partial<Pick<ApplicationAgentPartner,
  'superAgentPartnerId' |
  'subAgentBranchId' |
  'subAgentPartnerId' |
  'superAgentBranchId'
>>
export const usePartnerAgentsMutation = (applicationId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (agentPartner: Payload) => updateApplication(applicationId, { agentPartner }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['application-details'],
      });
    },
    onError: () => {
      errorNotificationHandler('Error updating partner agent');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['partner-agents-update'],
      });
    },
  });
};
