import React from 'react';
import Text from 'antd/lib/typography/Text';
import { Col, Row, Spin, Table, Tooltip } from 'antd';
import { GUTTER, PRODUCT_TABLE } from '@moxie/constants';
import { capitalizeFirstLetter } from '@moxie/shared';
import { ITestBand } from '@model/data.model';
import LanguageRequirementNameCard from './language-requirement-name-card';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';

interface Props {
  requirements: any;
  isLoading: boolean;
  openEditDrawer: (id: string) => void
}

const ProductLanguageRequirementsTable: React.FC<Props> = ({
  isLoading,
  requirements,
  openEditDrawer
}) => {
  const canAccess = useCanAccess();
  const columns = [
    {
      title: PRODUCT_TABLE.NAME,
      key: 'testName',
      width: 300,
      render(data: { test_name: string, id: string } | any) {
        return (
          <Row gutter={GUTTER}>
            <Col span={21} className="prd-title uppercase">
              {data ? data?.testName : ''}
            </Col>
            {canAccess('product-requirement', 'update') ? <Col span={3}>
              <LanguageRequirementNameCard id={data?.id} onEdit={() => openEditDrawer(data.id)} />
            </Col> : null}
          </Row>
        );
      },
    },
    {
      title: PRODUCT_TABLE.LISTENING,
      key: 'listening',
      render(data: any) {
        const listeningScore = data?.eachBand?.filter(
          (band: ITestBand) => band?.name.toLowerCase() === 'listening'
        )[0]?.score;
        return (
          <span className="prd-title prd-primary">{listeningScore ?? '-'}</span>
        );
      },
    },
    {
      title: PRODUCT_TABLE.WRITING,
      key: 'writing',
      render(data: any) {
        const writingScore = data?.eachBand?.filter(
          (band: ITestBand) => band?.name.toLowerCase() === 'writing'
        )[0]?.score;
        return (
          <span className="prd-title prd-primary">{writingScore ?? '-'}</span>
        );
      },
    },
    {
      title: PRODUCT_TABLE.READING,
      key: 'reading',
      render(data: any) {
        const readingScore = data?.eachBand?.filter(
          (band: ITestBand) => band?.name.toLowerCase() === 'reading'
        )[0]?.score;
        return (
          <span className="prd-title prd-primary">{readingScore ?? '-'}</span>
        );
      },
    },
    {
      title: PRODUCT_TABLE.SPEAKING,
      key: 'speaking',
      render(data: any) {
        const speakingScore = data?.eachBand?.filter(
          (band: ITestBand) => band?.name.toLowerCase() === 'speaking'
        )[0]?.score;
        return (
          <span className="prd-title prd-primary">{speakingScore ?? '-'}</span>
        );
      },
    },
    {
      title: PRODUCT_TABLE.OVERALL,
      dataIndex: 'overallScore',
      key: 'overallScore',
      className: 'prd-primary',
      render(data: string) {
        return <span className="prd-title prd-primary">{data && data}</span>;
      },
    },
    {
      title: PRODUCT_TABLE.REMARK,
      dataIndex: 'remark',
      key: 'remark',
      width: 350,
      className: 'prd-primary ',
      render(remark: string | null) {
        return (
          <div className="prd-primary">
            {remark ? (
              remark?.length >= 60 ? (
                <Tooltip title={capitalizeFirstLetter(remark)}>
                  <Text ellipsis className="product-ellipsis">
                    {capitalizeFirstLetter(remark)}
                  </Text>
                </Tooltip>
              ) : (
                <Text>{capitalizeFirstLetter(remark)}</Text>
              )
            ) : (
              '-'
            )}
          </div>
        );
      },
    },
  ];
  return (
    <Spin spinning={isLoading}>
      <Table dataSource={requirements} columns={columns} pagination={false} />
    </Spin>
  );
};

export default ProductLanguageRequirementsTable;
