import React from 'react';
import { Typography } from 'antd';
import notFoundImage from '../../assets/page-not-found.png'


const { Title } = Typography;

const NotValidDomain: React.FC = () => {
  return (
  <div className="not-found-container ">
  <div className="content">
    <div className="inner-content">
      <Title>Oops! The page doesn&apos;t exist!</Title>
      <p>
        You may have typed in the wrong address or the page has been modified.
        <br />
        If you haven&apos;t registered yet, now&apos;s the perfect time.{' '}
        <a href={process.env.NX_REGISTER_URL}>
          <b>Register now</b>
        </a>{' '}
        to start your abroad journey!
      </p>
      <img src={notFoundImage} alt="Not Found" />
    </div>
  </div>
</div>
  

);
};

export default NotValidDomain;


