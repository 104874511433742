import Space from "antd/es/space"
import { ActivitiesInfo, IField, IFormInitialInput, TestPreparationFormInput } from "../fields-data"
import Typography from "antd/es/typography"
import DatePicker from "antd/es/date-picker";
import { parseDate } from "./application-details-tab";
import { useContext } from "react";
import ApplicationDetailContext from "../../../application-detail-context";
import { Moment } from "moment";
import useUpdateApplicationMutation from "../../../hooks/useUpdateApplicationMutation";
import { successNotificationHandler } from "@moxie/shared";
import { UpdateApplicationPayload, WorkflowMetadata } from "@model/application";
import { DateField, TextField } from "./form-fields";
import isEqual from "lodash/isEqual";

interface Props {
  fieldsData: IField<IFormInitialInput>[]
}

const { Text } = Typography;

export const TestPreparationForm = ({ fieldsData }: Props) => {
  const { applicationData } = useContext(ApplicationDetailContext);
  const {
    endDate,
    startDate,
    intakeMonth,
    intakeYear,
    workflowMetadata,
    workflow
  } = applicationData;

  const { workflowType } = workflow;

  const isCompleted = applicationData?.status === 'Completed';
  const updateApplicationMutation = useUpdateApplicationMutation(
    applicationData,
    applicationData?.dealId
  );

  const initialInput: TestPreparationFormInput = {
    examDate: workflowMetadata?.examDate ?? '',
  }

  const handleIntakeUpdate = (data: Moment | null) => {
    const intakeYear = Number(data?.format('YYYY') ?? 0);
    const intakeMonth = data?.format('MMMM') as string;
    const payload = { intakeMonth, intakeYear };
    updateApplicationMutation.mutate(
      {
        payload,
        activitiesInfo: {
          activitiesType: 'application-intake',
        },
      },
      {
        onSuccess() {
          successNotificationHandler('Application updated.', 1.5);
        },
      }
    );
  };

  const handleEnrollmentUpdate = (data: Moment[] | any) => {
    const startDate = data[0]?.format('DD/MM/YYYY');
    const endDate = data[1]?.format('DD/MM/YYYY');
    const payload = { startDate, endDate };
    updateApplicationMutation.mutate(
      {
        payload,
        activitiesInfo: {
          activitiesType: 'application-enrollment',
        },
      },
      {
        onSuccess() {
          successNotificationHandler('Application updated.', 1.5);
        },
      }
    );
  };

  function updateData(
    payload: UpdateApplicationPayload,
    activitiesInfo: ActivitiesInfo,
    field: IField<IFormInitialInput>
  ) {
    if (payload.workflowMetadata && !(isEqual(payload.workflowMetadata[field.field as keyof WorkflowMetadata], initialInput[field.field as keyof TestPreparationFormInput]))) {
      updateApplicationMutation.mutate(
        { payload, activitiesInfo },
        {
          onSuccess: () => {
            successNotificationHandler(`${field.label} updated successfully.`, 1.5);
          },
        }
      );
    }
  }

  return (
    <div className="form-container">
      {
        fieldsData.map((fieldData) => {
          const activitiesInfo: ActivitiesInfo = {
            activitiesType: 'application-misc',
            activitesLabel: fieldData.label,
          };

          if (fieldData.field === "intake") {
            return (
              <Space direction="vertical">
                <Text strong>{fieldData.label}</Text>
                <DatePicker
                  style={{ width: '100%' }}
                  format="MMMM YYYY"
                  defaultValue={parseDate(
                    `${intakeMonth} ${intakeYear}`,
                    'MMMM YYYY'
                  )}
                  picker="month"
                  onChange={handleIntakeUpdate}
                  disabled={isCompleted}
                  allowClear={false}
                />
              </Space>
            )
          }
          if (fieldData.field === "enrollmentDate") {
            return (
              <Space direction="vertical">
                <Text strong>{fieldData.label}</Text>
                <DatePicker.RangePicker
                  format="DD/MM/YYYY"
                  picker="date"
                  onChange={handleEnrollmentUpdate}
                  defaultValue={[
                    parseDate(startDate, 'DD/MM/YYYY') as Moment,
                    parseDate(endDate, 'DD/MM/YYYY') as Moment,
                  ]}
                  disabled={isCompleted}
                  allowClear={false}
                />
              </Space>
            )
          }
          if (!["enrollmentPeriod", "intake"].includes(fieldData.field)) {

            if (['text', 'number'].some(val => val === fieldData.type)) {
              const getPayload = (workflowMetadata: WorkflowMetadata, field: string, input: string): UpdateApplicationPayload => {
                return {
                  workflowMetadata: {
                    ...workflowMetadata,
                    [field]: input,
                  },
                };
              }

              return (
                <TextField
                  key={fieldData.field}
                  field={fieldData}
                  updateData={updateData}
                  isCompleted={isCompleted}
                  activitiesInfo={activitiesInfo}
                  initialInput={workflowMetadata?.[fieldData.field as keyof WorkflowMetadata] as string ?? ''}
                  getPayload={getPayload}
                  workflowMetadata={workflowMetadata}
                />
              )
            }

            if (fieldData.type === 'date') {
              const getPayload = (workflowMetadata: WorkflowMetadata, data: Moment | null) => {
                return {
                  workflowMetadata: {
                    ...workflowMetadata,
                    [fieldData.field]: data?.format('DD/MM/YYYY') as string,
                  },
                };
              };
              if (fieldData.field === 'examDate' && workflowType?.name === 'English Test Preparation') {
                return null;
              }
              return (
                <DateField
                  key={fieldData.field}
                  field={fieldData}
                  updateData={updateData}
                  isCompleted={isCompleted}
                  activitiesInfo={activitiesInfo}
                  defaultValue={parseDate(workflowMetadata?.[fieldData.field as keyof WorkflowMetadata] as string, "DD/MM/YYYY")}
                  picker="date"
                  getPayload={getPayload}
                  workflowMetadata={workflowMetadata}
                />
              )
            }

          }
          return null
        })
      }
    </div>
  )
}
