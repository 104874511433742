import React, { useEffect } from 'react';
import { GUTTER, LABEL } from '@moxie/constants';
import { Col, Form, InputNumber, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { ILanguageScoreProps } from '../ielts';
import { IEnglishTestScore, ITestScore, ITestTypeData, TestType } from '@model/index';
import { useDuolingoMutation, useDuolingoUpdate } from '@crm/libs/hooks/contacts/useDuoLingoMutation';
import { convertIntoNumber } from '@crm/libs/helpers';

const { Text } = Typography;

interface TOEFLFormProps extends Omit<ILanguageScoreProps, 'ieltsData'> {
  duolingoData?: IEnglishTestScore;
}

const DuolingoForm = ({
  form,
  setDisabled,
  handleClose,
  duolingoData,
  editMode,
}: TOEFLFormProps) => {
  const { id }: { id: string } = useParams();
  const duolingoMutation = useDuolingoMutation();
  const duolingoUpdate = useDuolingoUpdate();

  const handleSubmit = async (data: Required<ITestScore>) => {
    let mutationData: any;
    let testTypeData: ITestTypeData[];

    const { overallScore, ...restData } = data;

    if (duolingoData) {
      testTypeData = Object.entries(restData).map(([name, score]) => ({
        id: duolingoData[name as TestType]?.id,
        name: name as keyof ITestScore,
        score: score,
      }));
      mutationData = {
        id: duolingoData?.id,
        parentId: duolingoData?.parentId,
        tests: [
          {
            id: duolingoData?.testId,
            name: duolingoData?.testName,
            testScoreId: duolingoData?.testScoreId,
            testTypes: testTypeData,
            overallScore,
          },
        ],
      };
      await duolingoUpdate.mutateAsync(mutationData);
    } else {
      testTypeData = Object.entries(restData).map(([name, score]) => ({
        name: name as keyof ITestScore,
        score: score,
      }));
      mutationData = {
        parentId: id,
        tests: [
          {
            name: 'duolingo',
            testTypes: testTypeData,
            overallScore,
          },
        ],
      };

      await duolingoMutation.mutateAsync(mutationData);
    }

    handleClose();
  };

  const handleFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  useEffect(() => {
    const initialData = {
      reading: convertIntoNumber(duolingoData?.reading?.score),
      speaking: convertIntoNumber(duolingoData?.speaking?.score),
      writing: convertIntoNumber(duolingoData?.writing?.score),
      listening: convertIntoNumber(duolingoData?.listening?.score),
      overallScore: convertIntoNumber(duolingoData?.overallScore),
    };
    form.setFieldsValue(initialData);
  }, [editMode, duolingoData, form]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      onFieldsChange={handleFieldsChange}
    >
      <Row gutter={GUTTER} align="top">
        <Col span={3} className="align-vertically-centered">
          <Text className="align-score-title" strong>
            {LABEL.DUOLINGO}
          </Text>
        </Col>

        <Col span={4}>
          <Form.Item
            name="reading"
            label={LABEL.READING}
            rules={[{ type: 'number', min: 10, max: 160 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="reading" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="listening"
            label={LABEL.LISTENING}
            rules={[{ type: 'number', min: 10, max: 160 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="listening" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="writing"
            label={LABEL.WRITING}
            rules={[{ type: 'number', min: 10, max: 160 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="writing" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="speaking"
            label={LABEL.SPEAKING}
            rules={[{ type: 'number', min: 10, max: 160 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="speaking" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="overallScore"
            label={LABEL.OVERALL_SCORE}
            rules={[
              {
                type: 'number',
                min: 10,
                max: 160,
              },
              {
                required: true,
              },
            ]}
          >
            <InputNumber className="full-width" data-testid="overall_score" />
          </Form.Item>
        </Col>

      </Row>
    </Form>
  );
};
export default DuolingoForm;
