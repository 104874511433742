import {
  ADD_LANGUAGE_TEST_SCORE,
  CANCEL_BTN,
  DRAWER_WIDTH,
  EDIT_LANGUAGE_TEST_SCORE,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { Button, Drawer, FormInstance, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePostCRMProductLanguageRequirements } from '../../../hooks/useGetProductLanguageRequirement';
import { useUpdateProductLanguageRequirement } from '../../../hooks/useUpdateProductLanguageRequirement';
import { LanguageRequirementForm } from './language-requirement-form';

interface Props {
  isEdit: boolean;
  initialData?: any;
  name?: string;
  onClose: () => void;
  isOpen: boolean;
  form: FormInstance<any>;
}

const ProductLanguageRequirementsDrawer: React.FC<Props> = ({
  initialData,
  form,
  isOpen,
  onClose,
  name,
  isEdit,
}: Props) => {
  const { productId } = useParams<{ productId: string }>();
  const [disabled, setDisabled] = useState(true);
  const onFormSubmit = async () => {
    form.submit();
  };

  const languageRequirementMutation = usePostCRMProductLanguageRequirements(
    productId
  );

  const { updateLanguageRequirement } = useUpdateProductLanguageRequirement(
    initialData?.id
  );

  useEffect(() => {
    if (isEdit) {
      const eachBands = initialData?.eachBand?.reduce(
        (obj: any, item: any) =>
          Object.assign(obj, { [item.name]: item.score }),
        {}
      );
      form.setFieldsValue({
        ...initialData,
        eachBands: eachBands,
      });
    }
  }, [initialData, isEdit]);


  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  const handleSubmit = async (data: any) => {
    if (initialData?.id) {
      data.productId = productId;
      updateLanguageRequirement({
        ...data,
        testName: initialData?.testName,
        productId,
      });
    } else
      await languageRequirementMutation.mutateAsync({
        ...data,
        testName: name,
        productId,
      });
    setDisabled(true);
    onClose();
  };

  const handleClose = () => {
    setDisabled(true);
    onClose();
  };


  return (
    <Drawer
      title={initialData ? EDIT_LANGUAGE_TEST_SCORE : ADD_LANGUAGE_TEST_SCORE}
      placement="right"
      onClose={handleClose}
      visible={isOpen}
      width={DRAWER_WIDTH}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={handleClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              type="primary"
              onClick={onFormSubmit}
              disabled={disabled}
            >
              {initialData ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <LanguageRequirementForm
        name={name}
        form={form}
        onSubmit={handleSubmit}
        onFieldsChange={onFieldsChange}
      />
    </Drawer>
  );
};

export default ProductLanguageRequirementsDrawer;
