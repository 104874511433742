import React, { useEffect } from 'react';
import { GUTTER, LABEL } from '@moxie/constants';
import { Col, Form, InputNumber, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useToeflMutation, useToeflUpdate } from '@crm/libs/hooks/contacts';

const { Text } = Typography;

const TOEFLForm = ({
  form,
  setDisabled,
  handleClose,
  toeflData,
  editMode,
}: {
  form: any;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
  toeflData?: any;
  editMode?: boolean;
}) => {
  const { id }: { id: string } = useParams();
  const toeflMutation = useToeflMutation();
  const toeflUpdate = useToeflUpdate();

  const handleSubmit = async (data: Record<string, number>) => {
    let mutationData: any;
    let testTypeData: any;

    const overallScore = Object.values(data).reduce(
      (acc, score) => acc + score,
      0
    );

    if (toeflData) {
      testTypeData = Object.entries(data).map(([name, score]) => ({
        id: toeflData[name]?.id,
        name: name,
        score: score,
      }));
      mutationData = {
        id: toeflData?.id,
        parentId: toeflData?.parentId,
        tests: [
          {
            id: toeflData?.testId,
            name: toeflData?.testName,
            testScoreId: toeflData?.testScoreId,
            testTypes: testTypeData,
            overallScore,
          },
        ],
      };
      await toeflUpdate.mutateAsync(mutationData);
    } else {
      testTypeData = Object.entries(data).map(([name, score]) => ({
        name: name,
        score: score,
      }));
      mutationData = {
        parentId: id,
        tests: [
          {
            name: 'toefl',
            testTypes: testTypeData,
            overallScore,
          },
        ],
      };

      await toeflMutation.mutateAsync(mutationData);
    }

    handleClose();
  };

  const handleFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  useEffect(() => {
    const initialData = {
      reading: Number(toeflData?.reading?.score),
      speaking: Number(toeflData?.speaking?.score),
      writing: Number(toeflData?.writing?.score),
      listening: Number(toeflData?.listening?.score),
    };
    form.setFieldsValue(initialData);
  }, [editMode === true]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      onFieldsChange={handleFieldsChange}
    >
      <Row gutter={GUTTER} align="top">
        <Col span={3} className="align-vertically-centered">
          <Text className="align-score-title" strong>
            {LABEL.TOEFL}
          </Text>
        </Col>

        <Col span={5}>
          <Form.Item
            name="reading"
            label={LABEL.READING}
            rules={[{ type: 'number', min: 1, max: 30 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="reading" />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Form.Item
            name="listening"
            label={LABEL.LISTENING}
            rules={[{ type: 'number', min: 1, max: 30 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="listening" />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Form.Item
            name="writing"
            label={LABEL.WRITING}
            rules={[{ type: 'number', min: 1, max: 30 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="writing" />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Form.Item
            name="speaking"
            label={LABEL.SPEAKING}
            rules={[{ type: 'number', min: 1, max: 30 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="speaking" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export { TOEFLForm };
