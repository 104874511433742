import React from 'react';
import { useAppSelector } from '@crm/core';
import { Row, Typography } from 'antd';
import { RootState } from 'apps/crm/src/core/store';
import { LABEL, HYPHEN } from '@moxie/constants';
import { EditPassportVisaInfoDrawer } from './edit-passport-visa-info-drawer';
import { formatDate } from '@moxie/shared';

const { Text } = Typography;

const VisaDetailsPanel = ({
  showDrawer,
  setShowDrawer,
}: {
  showDrawer: boolean;
  setShowDrawer: any;
}) => {
  const data = useAppSelector((state: RootState) => state?.contact?.singleData);
  return (
    <>
      <div className="profile-panel-content">
        <Row>
          <div className="flex-direction-vertical">
            <h4 className="text-primary">
              <span>{LABEL.PASSPORT_NUMBER}</span>
              <span className="margin-left-1-half-px">:</span>
            </h4>
            <Text className="margin-top-minus-5-px text-grey text-upper-case">
              {data?.passportNo ? data?.passportNo : HYPHEN}
            </Text>
          </div>
        </Row>
        <Row className="padding-top-2">
          <div className="flex-direction-vertical">
            <h4 className="text-primary">
              <span>{LABEL.COUNTRY_OF_PASSPORT}</span>
              <span className="margin-left-1-half-px">:</span>
            </h4>
            <Text className="margin-top-minus-5-px text-grey">
              {data?.countryOfPassport ? data?.countryOfPassport : HYPHEN}
            </Text>
          </div>
        </Row>
        <Row className="padding-top-2">
          <div className="flex-direction-vertical">
            <h4 className="text-primary">
              <span>{LABEL.PASSPORT_EXPIRY_DATE}</span>
              <span className="margin-left-1-half-px">:</span>
            </h4>
            <Text className="margin-top-minus-5-px text-grey">
              {data?.passportExpiryDate
                ? formatDate(data?.passportExpiryDate,'DD-MM-YYYY')
                : HYPHEN}
            </Text>
          </div>
        </Row>
        <Row className="padding-top-2">
          <div className="flex-direction-vertical">
            <h4 className="text-primary">
              <span>{LABEL.VISA_TYPE}</span>
              <span className="margin-left-1-half-px">:</span>
            </h4>
            <Text className="margin-top-minus-5-px text-grey initial_capital">
              {data?.visaType ? data?.visaType : HYPHEN}
            </Text>
          </div>
        </Row>
        <Row className="padding-top-2">
          <div className="flex-direction-vertical">
            <h4 className="text-primary">
              <span>{LABEL.VISA_EXPIRY_DATE}</span>
              <span className="margin-left-1-half-px">:</span>
            </h4>
            <Text className="margin-top-minus-5-px text-grey padding-bottom-2">
              {data?.visaExpiryDate
                ? formatDate(data?.visaExpiryDate,'DD-MM-YYYY')
                : HYPHEN}
            </Text>
          </div>
        </Row>
      </div>
      <EditPassportVisaInfoDrawer
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        initialData={data}
      />
    </>
  );
};
export { VisaDetailsPanel };
