import { NoteType } from "@model/crm/note.model";
import { Prettify, VisitStatusTypeCrm } from "@model/index";

interface DealDetailParam {
  dealId: string;
  contactId: string;
}

interface ContactDetailParam {
  contactId: string;
  tab?: 'deals' | 'activities' | 'documents' | 'office-visits' | 'enquiries';
}

interface ApplicationDetailParam {
  contactId: string;
  applicationId: string;
}

interface EnquiryDetailParam {
  enquiryId: string;
}


interface BaseNoteParams {
  contactId: string;
  relatedId: string;
  officeVisitStatus?: VisitStatusTypeCrm;
}
interface OfficeVisitNoteDetailParams extends BaseNoteParams {
  officeVisitStatus: VisitStatusTypeCrm;
  type: 'officeVisit';
  branchId: string | null;
}
interface OtherNoteDetailParams extends BaseNoteParams {
  type: Exclude<NoteType, 'officeVisit'>;
}
type NoteDetailParams = Prettify<OfficeVisitNoteDetailParams | OtherNoteDetailParams>;

const officeVisitMap: Record<VisitStatusTypeCrm, string> = {
  Attending: 'attending',
  Completed: 'completed',
  Unattended: 'archived',
  Waiting: 'waiting'
}
const routesMapper = {
  deal: {
    detail: ({ contactId, dealId }: DealDetailParam) => `/contact/${contactId}/deals/${dealId}`,
  },
  contact: {
    detail: ({ contactId, tab }: ContactDetailParam) => `/contact/detail/${contactId}/${tab ?? 'deals'}`
  },
  application: {
    detail: ({ applicationId, contactId }: ApplicationDetailParam) => `/application/detail/${contactId}/${applicationId}`,
  },
  enquiry: {
    detail: ({ enquiryId }: EnquiryDetailParam) => `/enquiries/all?enquiryId=${enquiryId}`
  },
  notes: {
    detail: (props: NoteDetailParams) => {
      switch (props.type) {
        case 'deal':
          return `/contact/${props.contactId}/deals/${props.relatedId}/activities/notes`;

        case 'officeVisit':
          return `/office-visit/${officeVisitMap[props.officeVisitStatus]}?officeVisitId=${props.relatedId}&filter.branchId=${props.branchId}&type=${props.officeVisitStatus}`;
        default:
          break;
      }
      return ''
    }
  }
}

export default routesMapper;
