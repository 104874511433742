import React, { useEffect, useMemo, useState } from 'react';
import {
  ADD_FEES,
  CANCEL_BTN,
  DRAWER_WIDTH,
  LABEL,
  PRODUCT_FEE_RESPONSE_MESSAGE,
  SAVE_BTN,
  TEXT,
  TITLE,
  UPDATE_BTN,
} from '@moxie/constants';
import { Button, DrawerElem } from '@shared-components/elements';
import Space from 'antd/es/space';
import ProductFeeForm from './ProductFeeForm';
import { ProductFeeItem } from '@model/data.model';
import Form from 'antd/es/form';
import { useForm } from 'antd/es/form/Form';
import Divider from 'antd/es/divider';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ProductFeeItemForm from './ProductFeeItemForm';
import { addProductFee, updateProductFee } from '@crm/services.api';
import { useParams } from 'react-router-dom';
import { successNotificationHandler } from '@moxie/shared';
import { useQueryClient } from '@tanstack/react-query';
import { IProductFeeCrm } from '../../product.model';
import { crmErrorHandler } from 'libs/shared/src/crm-modules/shared';

export interface IProductFeeDrawerProps {
  showDrawer: boolean;
  currency: string | undefined;
  isEdit?: boolean;
  onClose: () => void;
  fee: IProductFeeCrm | undefined;
}

interface FieldValues {
  feeName: string;
  country: string;
  feeItems: ProductFeeItem[]
}

const ProductFeeDrawer: React.FC<IProductFeeDrawerProps> = ({
  currency,
  showDrawer,
  onClose,
  fee,
}) => {
  const { productId } = useParams<{ productId: string }>();
  const queryClient = useQueryClient();
  const [form] = useForm<FieldValues>();
  const [isLoading, setLoading] = useState(false);
  const [netTotalFee, setNetTotalFee] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [deleteFeeItems, setDeleteFeeItems] = useState<string[]>([]);

  useEffect(() => {
    if (fee) {
      form.setFieldsValue({
        country: TEXT.ALL_COUNTRIES,
      });

      setNetTotalFee(fee?.feeItems.reduce((sum, item) => sum += Number(+item.installmentAmount * +item.installments), 0));
      form.setFieldsValue(fee);
      setDisabled(true);
    } else {
      form.resetFields();
      form.setFieldsValue({ feeItems: [{}] });
      setNetTotalFee(0.0);
      setDisabled(true);
    }
  }, [fee]);

  useEffect(() => {
    setDisabled(showDrawer);
  }, [showDrawer])

  const isEdit = useMemo(() => fee?.id, [fee]);

  const handleFinish = async (values: FieldValues) => {
    setLoading(true);
    try {
      if (!values.country) {
        values.country = TEXT.ALL_COUNTRIES;
      }
      let res= {message: ''};
      if (fee?.id) {
        const updatedValue = {
          ...values,
          deleteFeeItems,
        }
        await updateProductFee(fee.id, productId, updatedValue)
        queryClient.invalidateQueries({
          queryKey: ['productData', productId],
        })
        res = {
          ...res,
          message: PRODUCT_FEE_RESPONSE_MESSAGE.UPDATE_SUCCESS
        }
      }
      else {
        await addProductFee(productId, values);
        res = {
          ...res,
          message: PRODUCT_FEE_RESPONSE_MESSAGE.ADD_SUCCESS
        }
      }
      setDeleteFeeItems([]);
      successNotificationHandler(res?.message);
      queryClient.invalidateQueries({
        queryKey: ['product-fees', productId]
      });
      closeDrawer();
    } catch (error) {
      crmErrorHandler(error);
    }
    finally {
      setLoading(false);
    }
  }

  const closeDrawer = () => {
    onClose();
    form.resetFields();
    form.setFieldsValue({ feeItems: [{}] });
  }

  return (
    <>
      <DrawerElem
        title={
          !isEdit ? TITLE.ADD_PRODUCT_FEE_TITLE : TITLE.EDIT_PRODUCT_FEE_TITLE
        }
        width={DRAWER_WIDTH}
        onClose={closeDrawer}
        visible={showDrawer}
        footer={
          <div className="padding-bottom-2 text-align-right">
            <Space direction="horizontal">
              <Button onClick={closeDrawer} className="lead-margin-right">
                {CANCEL_BTN}
              </Button>
              <Button
                loading={isLoading}
                onClick={() => form.submit()}
                type="primary"
                disabled={disabled || isLoading}
              >
                {!isEdit ? SAVE_BTN : UPDATE_BTN}
              </Button>
            </Space>
          </div>
        }
      >
        <Form
          form={form}
          initialValues={{ feeItems: [{}] }}
          className="product-fee-form"
          layout="vertical"
          onFinish={handleFinish}
          onFieldsChange={() => setDisabled(false)}
        >
          <ProductFeeForm fee={fee} />
          <Form.List name="feeItems">
            {(fields, { add, remove }) => (
              <React.Fragment>
                {fields.map(
                  ({ key, name, fieldKey }) => (
                    <ProductFeeItemForm
                      removeItem={remove}
                      key={key}
                      form={form}
                      name={name}
                      setNetTotalFee={setNetTotalFee}
                      currency={currency}
                      netTotalFee={netTotalFee}
                      fieldKey={fieldKey}
                      fee={fee}
                      setDeleteFeeItems={setDeleteFeeItems}
                    />
                  )
                )}
                <Space size={14} />
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {ADD_FEES}
                  </Button>
                </Form.Item>
                <Space size={8} />
                <Divider />
                <div className="margin-top-1">
                  <b>
                    {LABEL.FEE_NET_TOTAL}: {currency}{' '}
                    {netTotalFee && netTotalFee.toFixed(3)}
                  </b>
                </div>
              </React.Fragment>
            )
            }
          </Form.List>
        </Form>
      </DrawerElem>
    </>
  );
};
export default ProductFeeDrawer;
