import { fetchEducationProviderBranches } from '@crm/libs/services.api/lib/education-provider.api';
import { useQuery } from '@tanstack/react-query';

export const useFetchEducationProviderBranches = (method: string, isApplicationConnected: boolean) => {
  const params = { method };
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['education-provider-branches', method],
    queryFn: async () => fetchEducationProviderBranches(params),
    enabled: !isApplicationConnected && Boolean(method), // only make request if applicaton is not connected
  });

  return { data, isLoading, isFetching };
};
