import React, { SetStateAction, useEffect } from 'react';
import { LABEL } from '@moxie/constants';
import { Col, Form, InputNumber, Row, Typography } from 'antd';
import { useIeltsMutation, useIeltsUpdate } from '@crm/libs/hooks/contacts';
import { useParams } from 'react-router-dom';
import { IEnglishTestScore, ITestScore, ITestTypeData, TestType } from '@model/index';
import { FormInstance } from 'antd/es/form';
import { convertIntoNumber } from '@crm/libs/helpers';

export interface ILanguageScoreProps {
  form: FormInstance;
  setDisabled: React.Dispatch<SetStateAction<boolean>>;
  handleClose: VoidFunction;
  ieltsData?: IEnglishTestScore;
  editMode?: boolean;
}

const { Text } = Typography;

const IELTSForm = ({
  form,
  setDisabled,
  handleClose,
  ieltsData,
  editMode,
}: ILanguageScoreProps) => {
  const { id }: { id: string } = useParams();

  const ieltsMutation = useIeltsMutation();
  const ieltsUpdate = useIeltsUpdate();

  const handleSubmit = async (data: Required<ITestScore>) => {
    let mutationData: any;
    let testTypeData: ITestTypeData[];
    const { overallScore, ...restData } = data;

    if (ieltsData) {
      testTypeData = Object.entries(restData).map(([name, score]) => {
        return {
          id: ieltsData[name as TestType]?.id,
          name: name as keyof ITestScore,
          score: score,
        }
      });
      mutationData = {
        id: ieltsData?.id,
        parentId: ieltsData?.parentId,
        tests: [
          {
            id: ieltsData?.testId,
            name: ieltsData?.testName,
            testScoreId: ieltsData?.testScoreId,
            testTypes: testTypeData,
            overallScore,
          },
        ],
      };
      await ieltsUpdate.mutateAsync(mutationData);
    } else {
      testTypeData = Object.entries(restData).map(([name, score]) => ({
        name: name as keyof ITestScore,
        score: score,
      }));

      mutationData = {
        parentId: id,
        tests: [
          {
            name: 'ielts',
            testTypes: testTypeData,
            overallScore,
          },
        ],
      };
      await ieltsMutation.mutateAsync(mutationData);
    }

    handleClose();
  };

  const handleFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  useEffect(() => {
    const initialData = {
      reading: convertIntoNumber(ieltsData?.reading?.score),
      speaking: convertIntoNumber(ieltsData?.speaking?.score),
      writing: convertIntoNumber(ieltsData?.writing?.score),
      listening: convertIntoNumber(ieltsData?.listening?.score),
      overallScore: convertIntoNumber(ieltsData?.overallScore),
    };
    form.setFieldsValue(initialData);
  }, [editMode, form, ieltsData]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      onFieldsChange={handleFieldsChange}
    >
      <Row gutter={16} align="top">
        <Col span={3} className="align-vertically-centered">
          <Text className="align-score-title" strong>
            {LABEL.IELTS}
          </Text>
        </Col>

        <Col span={4}>
          <Form.Item
            name="reading"
            label={LABEL.READING}
            rules={[{ type: 'number', min: 1, max: 9 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="reading" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="listening"
            label={LABEL.LISTENING}
            rules={[{ type: 'number', min: 1, max: 9 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="listening" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="writing"
            label={LABEL.WRITING}
            rules={[{ type: 'number', min: 1, max: 9 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="writing" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="speaking"
            label={LABEL.SPEAKING}
            rules={[{ type: 'number', min: 1, max: 9 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="speaking" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="overallScore"
            label={LABEL.OVERALL_SCORE}
            rules={[{ type: 'number', min: 1, max: 9 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="overallScore" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export { IELTSForm };
