export const revertReasonsOptions = [
  'Administrative Errors',
  'Error by Team Member',
  'Client\'s Decision'
]

export const discontinueReasonsOptions = [
  'Administrative Errors',
  'Course Cancelled',
  'Enrolled into Another Course',
  'Change Agent',
  'Error by Team Member',
  'Financial Issues',
  'Lack of Required Documentation',
  'Enrolled with Another Institution',
  'Rejected by Institution',
  'Visa Denied',
  'Withdrawn'
]

export const reopenReasonsOptions = [
  'Administrative Errors',
  'Payment Issue'
]
