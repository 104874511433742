import { API_URL } from '@moxie/constants';
import axios from './axios';
import crmAxios from './crm-api-axios';
import { AgentPartnerWithBranches } from '@model/agent-partner';
import { Agent, Prettify } from '@model/data.model';
import { CrmResponse } from '@model/api-response.model';


const getBranchesByAgentType = (agentType: string): Promise<any> => {
  return axios.get(`${API_URL.CRM_API_AGENT}/all/${agentType}`);
};

const getAllPartnerAgentByType = async (type: any) => {
  return crmAxios.get<{ data: Prettify<AgentPartnerWithBranches[]> }>(`${API_URL.CRM_API_AGENT}/all`, {
    params: { type }
  });
};

export const getAgentPartners = async (params: Record<string, unknown>) => {
  const { data } = await crmAxios.get(API_URL.CRM_API_AGENT, { params })
  return data;
}
const queryAgentPartner = async (params: Record<string, unknown>) => {
  const { data } = await crmAxios.get<CrmResponse<Agent>>(`${API_URL.CRM_API_AGENT}/query`, {
    params
  });
  return data;
}

export { getBranchesByAgentType, getAllPartnerAgentByType, queryAgentPartner };
