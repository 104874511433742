import React from "react";
import { Avatar, Col, Modal, Row, Space, Typography } from "antd";
import { ExclamationCircleOutlined, MailOutlined } from "@ant-design/icons";
import { useStepContext, useTransformedDataContext } from "../import-context";
import { ContactData } from "@model/crm/contact.model";

interface IContactInfo {
  firstName: string;
  lastName: string;
  email: string;
  key: string;
}

interface IEmailModalProps {
  emailList: { email : string, isDeleted: boolean}[];
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExistingEmailModal = ({ emailList, showModal, setShowModal, setDisabled }: IEmailModalProps) => {
  const { transformedData, updateTransformedData } = useTransformedDataContext();
  const { currentStep, updateStep } = useStepContext();

  const { existingEmail, existingDeletedEmail } = emailList.reduce<{
    existingEmail: string[];
    existingDeletedEmail: string[];
  }>((acc, item : { email: string, isDeleted : boolean}) => {
    if (item.isDeleted) {
      acc.existingDeletedEmail.push(item.email);
    } else {
      acc.existingEmail.push(item.email);
    }
    return acc;
  }, { existingEmail: [], existingDeletedEmail: [] });

  const handleOkClick = () => {
    const nonExistingEmailData = transformedData.filter((entry: ContactData) => ![...existingEmail,...existingDeletedEmail].includes(entry.email));
    updateTransformedData(nonExistingEmailData);
    updateStep(currentStep + 1);
  }
  const handleCancel = () => {
    setShowModal(false);
    setDisabled(false);
  }

  const { existingContactInfo, existingDeleteContactInfo } = transformedData.reduce<{
    existingContactInfo: IContactInfo[];
    existingDeleteContactInfo:  IContactInfo[];
  }>((acc, entry) => {
    const { firstName, lastName, email, key } = entry;
    
    if (existingEmail.includes(email)) {
      acc.existingContactInfo.push({ firstName, lastName, email, key });
    }

    if (existingDeletedEmail.includes(email)) {
      acc.existingDeleteContactInfo.push({ firstName, lastName, email, key });
    }
  
    return acc;
  }, {
    existingContactInfo: [],
    existingDeleteContactInfo: []
  });

  return (
    <Modal
      title={
        <>
          <Row>
            <Space direction="horizontal">
              <ExclamationCircleOutlined className="warning font-size-18px" />
              <Space direction="vertical" size={1}>
                <Typography.Text className="font-size-1-rem">Some contact(s) already exists!</Typography.Text>
                <Typography.Text className="text-grey font-regular">The following entries will be discarded.</Typography.Text>
              </Space>
            </Space>
          </Row>
        </>
      }
      visible={showModal}
      onCancel={handleCancel}
      onOk={handleOkClick}
    >
    {existingContactInfo.length > 0 ? (
      <Typography.Text className="font-size-1-rem">Contact(s) already exists!</Typography.Text>
    ) :null}
    {existingContactInfo?.map((contact: IContactInfo) => (
        <>
          <div className="contact-modal-scroll">
            <Row align="middle" className="margin-left-2 margin-bottom-1" key={contact?.key}>
              <Col>
                <Avatar>{contact.firstName.charAt(0).toUpperCase()}{contact.lastName.charAt(0).toUpperCase()}</Avatar>
              </Col>
              <Col className="margin-left-1">
                <Row align="middle">
                  <Typography.Text ellipsis>{`${contact.firstName} ${contact.lastName}`}</Typography.Text>
                </Row>
                <Row align="middle" className="text-grey">
                  <Space direction="horizontal">
                    <MailOutlined />
                    <Typography.Text className="text-primary">{contact.email}</Typography.Text>
                  </Space>
                </Row>
              </Col>
            </Row>
        </div>
      </>
      ))}

    {existingDeleteContactInfo.length > 0 ? (
    <Typography.Text className="font-size-1-rem">Contact(s) exist and deactivated!</Typography.Text>
    ) :null}
    {existingDeleteContactInfo?.map((contact: IContactInfo) => (
        <>
          <div className="contact-modal-scroll">
            <Row align="middle" className="margin-left-2 margin-bottom-1" key={contact?.key}>
              <Col>
                <Avatar>{contact.firstName.charAt(0).toUpperCase()}{contact.lastName.charAt(0).toUpperCase()}</Avatar>
              </Col>
              <Col className="margin-left-1">
                <Row align="middle">
                  <Typography.Text ellipsis>{`${contact.firstName} ${contact.lastName}`}</Typography.Text>
                </Row>
                <Row align="middle" className="text-grey">
                  <Space direction="horizontal">
                    <MailOutlined />
                    <Typography.Text className="text-primary">{contact.email}</Typography.Text>
                  </Space>
                </Row>
              </Col>
            </Row>
          </div>
        </>
      ))}

  
    </Modal >
  )
}
export { ExistingEmailModal };
