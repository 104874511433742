import { ApplicationPayload } from "@model/crm/elastic-search"
import { TableProps } from "antd/es/table"
import Tag from "antd/es/tag";
import Typography from "antd/es/typography";

const applicationColumns: TableProps<ApplicationPayload>['columns'] = [
  {
    dataIndex: 'app_identifier',
    title: 'ID'
  },
  {
    dataIndex: 'contact_name',
    title: 'Contact'
  },
  {
    dataIndex: 'product_name',
    title: 'Product',
    render(_, record) {

      return (
        <div>
          <Typography.Text>{record.product_name}</Typography.Text>
          <br />
          <Typography.Text type="secondary" style={{ fontSize: '12px' }}>{record.institution_name}</Typography.Text>
        </div>
      )
    }
  },
  {
    dataIndex: 'partner_client_id',
    title: 'Partner Client ID',
  },
  {
    dataIndex: 'status',
    title: 'Status',
    render(status: ApplicationPayload['status'], record) {

      if (status === 'In Progress') {
        return (
          <span>{status} ({record.active_stage})</span>
        )
      }
      return (
        <span>{status}</span>
      )
    }
  },
  {
    dataIndex: 'branch',
    title: 'Branch',
    render(value: string) {
      return (
        <Tag >{value}</Tag>
      )
    },
  },
]

export default applicationColumns;
