import React, { useEffect } from 'react';
import { GUTTER, LABEL } from '@moxie/constants';
import { Col, Form, InputNumber, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { usePteMutation, usePteUpdate } from '@crm/libs/hooks/contacts';
import { convertIntoNumber } from '@crm/libs/helpers';
import { ILanguageScoreProps } from '../ielts';
import { IEnglishTestScore, TestType } from '@model/crm/english-test-score.model';

const { Text } = Typography;

interface PTEFormProps extends Omit<ILanguageScoreProps, 'ieltsData'> {
  pteData?: IEnglishTestScore;
}

const PTEForm = ({
  form,
  setDisabled,
  handleClose,
  pteData,
  editMode,
}: PTEFormProps) => {
  const { id }: { id: string } = useParams();
  const pteMutation = usePteMutation();
  const pteUpdate = usePteUpdate();

  const handleSubmit = async (data: any) => {
    let mutationData: any;
    let testTypeData: any;

    const { overallScore } = data;
    delete data.overallScore;

    if (pteData) {
      testTypeData = Object.entries(data).map(([name, score]) => ({
        id: pteData[name as TestType]?.id,
        name: name,
        score: score,
      }));
      mutationData = {
        id: pteData?.id,
        parentId: pteData?.parentId,
        tests: [
          {
            id: pteData?.testId,
            name: pteData?.testName,
            testScoreId: pteData?.testScoreId,
            overallScore: overallScore,
            testTypes: testTypeData,
          },
        ],
      };
      await pteUpdate.mutateAsync(mutationData);
    } else {
      testTypeData = Object.entries(data).map(([name, score]) => ({
        name: name,
        score: score,
      }));
      mutationData = {
        parentId: id,
        tests: [
          {
            name: 'pte',
            overallScore: overallScore,
            testTypes: testTypeData,
          },
        ],
      };
      await pteMutation.mutateAsync(mutationData);
    }

    handleClose();
  };

  const handleFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  useEffect(() => {
    if (editMode === true) {
      const initialData = {
        reading: convertIntoNumber(pteData?.reading?.score),
        speaking: convertIntoNumber(pteData?.speaking?.score),
        writing: convertIntoNumber(pteData?.writing?.score),
        listening: convertIntoNumber(pteData?.listening?.score),
        overallScore: convertIntoNumber(pteData?.overallScore),
      };
      form.setFieldsValue(initialData);
    }
  }, [editMode]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      onFieldsChange={handleFieldsChange}
    >
      <Row gutter={GUTTER} align="top">
        <Col span={3} className="align-vertically-centered">
          <Text className="align-score-title" strong>
            {LABEL.PTE}
          </Text>
        </Col>

        <Col span={4}>
          <Form.Item
            name="reading"
            label={LABEL.READING}
            rules={[{ type: 'number', min: 1, max: 90 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="reading" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="listening"
            label={LABEL.LISTENING}
            rules={[{ type: 'number', min: 1, max: 90 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="listening" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="writing"
            label={LABEL.WRITING}
            rules={[{ type: 'number', min: 1, max: 90 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="writing" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="speaking"
            label={LABEL.SPEAKING}
            rules={[{ type: 'number', min: 1, max: 90 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="speaking" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="overallScore"
            label={LABEL.OVERALL_SCORE}
            rules={[
              {
                type: 'number',
                min: 1,
                max: 90,
              },
              {
                required: true,
              },
            ]}
          >
            <InputNumber className="full-width" data-testid="overall_score" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export { PTEForm };
