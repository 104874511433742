import { ActivitiesType } from "@model/contact-activity";

export interface VisaFormInput {
  visaTRN: string;
  bridgingVisaType: string;
  HAPID: string;
  assessmentOccupation: string;
  skillsAssessmentBody: string;
  visaLodgementDate?: string;
  visaOutcomeDate?: string;
  bridgingVisaExpiry?: string;
  countryOfPoliceCheck?: string;
  policeCheckApplicationDate?: string;
  policeCheckExpiryDate?: string;
  visaMedicalDate?: string;
  visaMedicalCountry?: string;
  RMA?: string[];
  skillsAssessmentExpiry?: string;
  englishTestDate?: string;
  englishTestExpiry?: string;
}

export interface EducationFormInput {
  intake: string;
}

export interface TestPreparationFormInput {
  examDate?: string;
  enrollmentDate?: string;
}

export type IFormInitialInput = keyof VisaFormInput | keyof EducationFormInput | keyof TestPreparationFormInput

export interface IField<T extends string> {
  label: string;
  type: 'text' | 'date' | 'number' | 'select-options' | 'rma-field' | "date-range";
  field: T;
}

export interface IFormInfo<T extends string> {
  service: "Education" | "Visa" | "Test Preparation";
  fields: IField<T>[];
}

export const fieldsData: IFormInfo<IFormInitialInput>[] = [
  {
    service: "Education",
    fields: [
      {
        field: 'intake',
        label: 'Intake',
        type: 'date',
      },
    ],
  },
  {
    service: 'Visa',
    fields: [
      {
        field: 'visaTRN',
        label: 'Visa TRN',
        type: 'text',
      },
      {
        field: 'visaLodgementDate',
        label: 'Visa lodgment date',
        type: 'date',
      },
      {
        field: 'visaOutcomeDate',
        label: 'Visa outcome date',
        type: 'date',
        // format:""
      },
      {
        field: 'bridgingVisaType',
        label: 'Bridging visa type',
        type: 'number',
      },
      {
        field: 'bridgingVisaExpiry',
        label: 'Bridging visa expiry',
        type: 'date',
      },
      {
        field: 'countryOfPoliceCheck',
        label: 'Country of police check',
        type: 'select-options',
      },
      {
        field: 'policeCheckApplicationDate',
        label: 'Police check application date',
        type: 'date',
      },
      {
        field: 'policeCheckExpiryDate',
        label: 'Police check expiry date',
        type: 'date',
      },
      {
        field: 'HAPID',
        label: 'HAP ID',
        type: 'number',
      },
      {
        field: 'visaMedicalDate',
        label: 'Visa medical date',
        type: 'date',
      },
      {
        field: 'visaMedicalCountry',
        label: 'Visa medical country',
        type: 'select-options',
      },
      {
        field: 'RMA',
        label: 'RMA',
        type: 'rma-field',
      },
      {
        field: 'assessmentOccupation',
        label: 'Assessment occupation',
        type: 'text',
      },
      {
        field: 'skillsAssessmentBody',
        label: 'Skills assessment body',
        type: 'text',
      },
      {
        field: 'skillsAssessmentExpiry',
        label: 'Skills assessment expiry date',
        type: 'date',
      },
      {
        field: 'englishTestDate',
        label: 'English test date',
        type: 'date',
      },
      {
        field: 'englishTestExpiry',
        label: 'English test expiry date',
        type: 'date',
      },
    ],
  },
  {
    service: "Test Preparation",
    fields: [
      {
        field: 'intake',
        label: 'Intake',
        type: 'date',
      },
      {
        field: 'enrollmentDate',
        label: "Enrollment Period",
        type: "date-range"
      },
      {
        field: 'examDate',
        label: 'Exam Date',
        type: "date"
      }
    ],
  },
];

export interface ActivitiesInfo {
  activitiesType: ActivitiesType
  activitesLabel?: string;
}
