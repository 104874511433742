import { updateCRMProductLanguageRequirements } from '@crm/services.api';
import {
  errorNotificationHandler,
  successNotificationHandler,
} from '@moxie/shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUpdateProductLanguageRequirement = (id: string) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (body) => updateCRMProductLanguageRequirements(id!, body),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['CRMProductLanguageRequirements'],
      });
      successNotificationHandler('Language Requirement updated successfully');
    },
    onError: () => {
      errorNotificationHandler('Error updating Language Requirement');
    },
  });

  const updateLanguageRequirement = (data: any) => {
    mutation.mutate(data);
  };
  return {
    isLoading: mutation.isLoading,
    updateLanguageRequirement,
  };
};
