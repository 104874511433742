import axios, { AxiosPromise, AxiosResponse } from 'axios';
import cookies from 'js-cookie';
import { handleTokens, unauthorizedAccess } from '@moxie/utils';
import {
  API_URL,
  AUTH_MESSAGE,
  COOKIE_REFRESH,
  CRM_COOKIE_ACCESS,
  CRM_COOKIE_REFRESH,
  JWT_TOKEN_HEADER,
} from '@moxie/constants';
import { errorNotificationHandler } from '@moxie/shared';
import { authActions, store } from '@crm/core';

const crmAxios = axios.create({
  baseURL: process.env.NX_CRM_API_URL,
});

crmAxios.interceptors.request.use((config) => {
  const accessToken = cookies.get(CRM_COOKIE_ACCESS);
  if (accessToken && accessToken.length > 10) {
    config.headers['Authorization'] = `Bearer ${accessToken}`
  }
  return config;
});

crmAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err?.message === 'Network Error') {
      errorNotificationHandler(AUTH_MESSAGE.SERVICE_DOWN);
      throw err;
    }
    err.response.data.error = err.response.data.detail;

    if (
      err.response.config.url.includes('login') ||
      err.response.config.url.includes('forget-password') ||
      err.response.config.url.includes('verify') ||
      err.response.config.url.includes('logout')
    ) {
      throw err;
    }
    return new Promise((resolve, reject) => {
      const originalReq = err.config;
      if (
        err?.response?.status === 401 &&
        (!err?.response?.subStatusCode ||
          err?.response?.subStatusCode !== 463) &&
        cookies.get(CRM_COOKIE_ACCESS) &&
        err.config &&
        !err.config.__isRetryRequest
      ) {
        const config: RequestInit = {
          method: 'POST',
          cache: 'no-cache',
          headers: {
            'content-type': 'application/json',
            authorization: `${JWT_TOKEN_HEADER} ${cookies.get(
              CRM_COOKIE_ACCESS
            )}`,
          },
          body: JSON.stringify({
            token: cookies.get(COOKIE_REFRESH),
          }),
        };
        originalReq.__isRetryRequest = true;
        const refreshToken = fetch(
          `${process.env.NX_CRM_API_URL}/${API_URL.CRM_API_REFRESH_TOKEN}`,
          config
        )
          .then((res) => res.json())
          .then((res): AxiosResponse | AxiosPromise | void => {
            if (res.statusCode === 401 && res.subStatusCode === 463) {
              store.dispatch(authActions.openSessionModal());
              reject(err);
            } else if (res.statusCode === 401) {
              handleUnauthorizedAccess();
            } else {
              handleTokens({ data: res });
              return crmAxios(originalReq);
            }
          })
          .catch(err => {
            if (err.response.status === 401) {
              handleUnauthorizedAccess();
            }
          })
          ;

        resolve(refreshToken);
      } else if (err?.response?.status === 401) {
        handleUnauthorizedAccess();
      }
      return reject(err);
    });
  }
);

const handleUnauthorizedAccess = () => {
  store.dispatch(authActions.logout());
  unauthorizedAccess(CRM_COOKIE_ACCESS, CRM_COOKIE_REFRESH);
};

export { crmAxios };
export default crmAxios;
