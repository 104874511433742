import React from 'react';
import { Button, Col, Row, Space } from 'antd';
import { TEXT } from '@moxie/constants';
import { PlusOutlined } from '@ant-design/icons';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';
// const Search = Input.Search;

interface Props {
  openDrawer: () => void;
}
const Toolbar: React.FC<Props> = ({ openDrawer }) => {
  const canAccess = useCanAccess();
  return (
    <div className="padding-x1">
      <Row justify="space-between" className="padding-bottom-1">
        {/* <Col>
          <Space>
            <Search
              onChange={(e) => {
                if (e.target.value === '') setSearchKey('');
              }}
              onSearch={(value) => setSearchKey(value)}
              placeholder="search"
              className="table_search"
              allowClear
            />
          </Space>
        </Col> */}
        {canAccess('agent', 'create') && <Col>
          <Space>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              data-testid="crm-addagentbtn"
              onClick={openDrawer}
            >
              {TEXT.ADD_AGENT}
            </Button>
          </Space>
        </Col>}
      </Row>
    </div >
  );
};

export default Toolbar;
