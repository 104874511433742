import { crmAxios } from '@crm/services.api';
import { ContactEnquiry, CrmPaginatedResponse, EnquiryActivity, IAdditionalFormData, PersonalInfoData, UpdateContactEnquiryPayload } from '@model/index';
import { API_URL } from '@moxie/constants';

interface PaginationPayload {
  page?: number;
  limit?: number;
  filter?: Record<string, unknown>;
}
export const fetchContactEnquiries = async (query: PaginationPayload) => {
  const val = await crmAxios.get(API_URL.CRM_API_CONTACT_ENQUIRIES, {
    params: query,
  });
  return val.data;
};

export const saveEnquiryBasicDetails = async (data: PersonalInfoData) => {
  const res = await crmAxios.post<{ data: IAdditionalFormData }>(
    API_URL.CRM_API_CONTACT_ENQUIRIES,
    data
  );
  return res.data.data;
};

export const updateEnquiryData = async (id: string, data: Partial<UpdateContactEnquiryPayload>) => {
  return await crmAxios.put(`${API_URL.CRM_API_CONTACT_ENQUIRIES}/${id}`, data);
};

export const fetchContactEnquirySources = async (query: PaginationPayload) => {
  const res = await crmAxios.get(`${API_URL.CRM_API_CONTACT_ENQUIRIES}/sources`, {
    params: query,
  });
  return res.data;
};

export const fetchEnquiryActivities = async (
  params: Record<string, unknown>
) => {
  return (
    await crmAxios.get<CrmPaginatedResponse<EnquiryActivity>>(
      `${API_URL.CRM_ENQUIRY_ACTIVITIES}/`, { params }
    )
  ).data;
};

export const fetchEnquiryDetail = async (enquiryId?: string) => {
  const { data } = await crmAxios.get<{ data: ContactEnquiry }>(`${API_URL.CRM_API_CONTACT_ENQUIRIES}/${enquiryId}`);
  return data;
}
