import moment from 'moment';
import ActivityMessage from './activity-message';
import React from 'react';
import { ContactActivity } from '@model/contact-activity';
import Avatar from 'antd/lib/avatar/avatar';

interface Props {
  activity: ContactActivity;
  showLink?: boolean;
}
const Activity = ({ activity, showLink = false }: Props) => {
  const {
    activitiesAction,
    createdAt,
    activitiesType,
    user,
    data,
    assignedUser,
    previousAssignedUser,
    follower,
    activitiesTypeId,
    contactId,
  } = activity;

  const name = `${user?.firstName} ${user?.lastName}`;

  const nameInitials = `${user.firstName.at(0)}${user.lastName.at(
    0
  )}`.toUpperCase();

  return (
    <div className="application-activity activity-card">
      <Avatar>{nameInitials}</Avatar>
      <div className="margin-left-1">
        <div className="margin-0 initial_capital">
          <ActivityMessage
            activitiesAction={activitiesAction}
            activitiesType={activitiesType}
            data={data}
            assignedUser={assignedUser}
            previousAssignedUser={previousAssignedUser}
            follower={follower}
            showLink={showLink}
            activitiesTypeId={activitiesTypeId}
            contactId={contactId}
          />
        </div>
        <span className="ant-comment-content-author-name">
          by <span className="initial_capital">{name}</span>,{' '}
          {moment(createdAt).format('dddd, MMMM D, YYYY')}
        </span>
      </div>
    </div>
  );
};

export default Activity;
