import { NoteMode, TaskFormFields } from "@model/crm/note.model";
import React, { useState } from "react";
import { Editor } from '@tiptap/react';
import { FormInstance } from "antd/es/form";
import extractMentions from "../editor/extract-mentions";

interface NoteValidationProps {
  skipValidation?: boolean;
  message?: string;
}

type ValidateForm = (editor: Editor | null, form: FormInstance, values: TaskFormFields, callback: (content: string, mentions: string[]) => Promise<void>, validationRule?: NoteValidationProps) => Promise<void>;

interface ContextProps {
  noteMode: NoteMode;
  setNoteMode: (val: NoteMode) => void;
  validateForm: ValidateForm
}
export const NoteFormContext = React.createContext<ContextProps>({
  noteMode: undefined,
  setNoteMode: (val: NoteMode) => val,
  validateForm: async (editor: Editor | null, form: FormInstance, values: TaskFormFields, callback: (content: string, mentions: string[]) => Promise<void>, validationRule?: NoteValidationProps) => { return },
});


const NoteFormProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [noteMode, setNoteMode] = useState<NoteMode>();

  const validateForm: ValidateForm = async (editor, form, values, callback, validationRule) => {
    if (!editor) {
      return;
    }
    const html = new DOMParser().parseFromString(editor.getHTML(), 'text/html');

    const text = html.body.textContent || '';
    if (!text.length && !validationRule?.skipValidation) {
      form.setFields([{
        name: 'description',
        errors: [validationRule?.message ?? 'Please enter description'],
      }])
      return
    }
    const content = editor?.getJSON().content;
    const { mentions } = extractMentions(content);
    await callback(editor.getHTML() as string, mentions)
  }
  return (
    <NoteFormContext.Provider value={{
      noteMode,
      setNoteMode,
      validateForm,
    }}>
      {children}
    </NoteFormContext.Provider>
  )
}

export default NoteFormProvider;
