import { Service } from '@model/workflow';
import React, { useEffect, useState } from 'react';
import { useFetchInstitutionServices } from '../reports/hooks/useGetApplicationsContact';
import Form, { FormInstance } from 'antd/es/form';
import { fetchServiceCountry, getWorkflow } from '@crm/services.api';
import { useApplicationParams } from './hooks/use-application-params';
import { TEXT } from '@moxie/constants';
import { useHistory } from 'react-router-dom';

interface SelectOption {
  label: string;
  value: string;
}

const statusMap = {
  all: ['Discontinued', 'In Progress', 'Completed'],
  'in-progress': ['In Progress'],
  discontinued: ['Discontinued'],
  complete: ['Completed'],
  stages: ['In Progress'],
};

interface ApplicationListContext {
  isServiceLoading: boolean;
  services: Service[];
  form: FormInstance;
  workflows: SelectOption[];
  setWorkflows: React.Dispatch<React.SetStateAction<SelectOption[]>>;
  workflowCountry?: string | undefined;
  countryList: string[];
  type: string;
  filter: ApplicationFilter;
  setFilter: React.Dispatch<React.SetStateAction<ApplicationFilter>>;
  setCountryList: React.Dispatch<React.SetStateAction<string[]>>;
  setStageFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
  stageFilter?: string | undefined;
  setShowWorkflowMenuStages: React.Dispatch<React.SetStateAction<boolean>>;
  showWorkflowMenuStages: boolean;
  initialLoading: boolean;
}

interface ApplicationFilter {
  status: string[];
  assignees: string[];
  branch?: string;
  service?: string;
  workflow: string[];
  workflowType?: string;
  country?: string;
  stage?: string;
}


export const ApplicationContext = React.createContext<ApplicationListContext>({
  isServiceLoading: false,
  services: [],
  form: {} as FormInstance,
  workflows: [],
  setWorkflows: () => [],
  workflowCountry: undefined,
  countryList: [],
  setCountryList: () => [],
  type: 'all',
  filter: {} as ApplicationFilter,
  setFilter(val) { return val },
  setStageFilter: (val) => { return val },
  stageFilter: undefined,
  setShowWorkflowMenuStages: (val) => { return val },
  showWorkflowMenuStages: false,
  initialLoading: true
})


const ApplicationContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { institutionServices: services, isLoading } = useFetchInstitutionServices();
  const [form] = Form.useForm();
  const { type } = useApplicationParams();

  const [stageFilter, setStageFilter] = useState<string | undefined>();
  const [filter, setFilter] = useState<ApplicationFilter>({
    status: [],
    assignees: [],
    branch: undefined,
    service: undefined,
    workflow: [],
    workflowType: undefined
  });

  const [countryList, setCountryList] = useState<string[]>([]);
  const [workflowCountry, setWorkflowCountry] = useState<string | undefined>();
  const [showWorkflowMenuStages, setShowWorkflowMenuStages] = useState(false);
  const [workflows, setWorkflows] = useState<SelectOption[]>([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const history = useHistory();


  useEffect(() => {
    const urlParams = Object.fromEntries(new URLSearchParams(window.location.search.replace('?', '')))
    const assigneesFromUrl = urlParams?.['filter.assignees']?.split(',').filter(s => s.length) ?? [];
    const branchFormURL = urlParams['filter.processingBranch.id'] ? urlParams['filter.processingBranch.id']?.replace('$in:', '') : undefined;
    const serviceFromURL = urlParams['filter.institution.serviceId'] ? urlParams['filter.institution.serviceId']?.replace('$eq:', '') : undefined;
    const counttryFromURL = urlParams['filter.country'] ? urlParams['filter.country']?.replace('$eq:', '') : undefined;
    const workflowFromURL = urlParams['filter.workflow.id'] ? urlParams['filter.workflow.id']?.replace('$in:', '').split(',') : undefined;
    const workflowTypeFromURL = urlParams['filter.workflowType'] ? urlParams['filter.workflowType'] : TEXT.ALL_WORKFLOWS;

    if (services.length) {
      const service = serviceFromURL ? serviceFromURL : services.at(0)?.id;
      fetchCountriesForService(service as string).then((defaultCountry) => {
        fetchWorkflows(service as string, defaultCountry).then((defaultworkflows) => {
          form.setFieldsValue({
            serviceId: service,
            country: counttryFromURL ? counttryFromURL : defaultCountry,
            workflow: workflowTypeFromURL === TEXT.ALL_WORKFLOWS ? 'all' : workflowTypeFromURL,
            branchId: branchFormURL ? branchFormURL : undefined
          });
          setFilter((prev) => {
            return {
              ...prev,
              assignees: assigneesFromUrl,
              status: statusMap.all,
              branch: branchFormURL ? branchFormURL : undefined,
              service: service,
              workflow: workflowFromURL ? workflowFromURL : defaultworkflows.map((workflow) => workflow.id),
              workflowType: workflowTypeFromURL ? workflowTypeFromURL : TEXT.ALL_WORKFLOWS,
              stage: undefined,
              country: counttryFromURL ? counttryFromURL : defaultCountry,
            }
          });
          setInitialLoading(false)
          history.push(`/application/all`)
        })
      });
    }
  }, [services]);

  const fetchCountriesForService = async (serviceId: string) => {
    const countries = await fetchServiceCountry(serviceId);
    if (countries?.data?.length) {
      setCountryList(countries.data);
      setWorkflowCountry(countries.data[0]);
      return countries.data[0];
    }
    setCountryList([]);
    setWorkflowCountry(undefined);
    return countries.data[0];
  };

  const fetchWorkflows = async (serviceId: string, country: string) => {
    const workflow = await getWorkflow(serviceId, country);
    setWorkflows(workflow.data.data.map(wrkfl => ({
      value: wrkfl.id,
      label: wrkfl.workflowType.name
    })));

    return workflow.data.data;
  };

  useEffect(() => {
    if (type !== 'stages') {
      setStageFilter(undefined);
    }

    setFilter((prev: any) => {
      return {
        ...prev,
        status: type ? statusMap[type] || [type] : [],
      }
    })
  }, [type, filter.workflow]);

  useEffect(() => {
    setFilter((prev: any) => ({
      ...prev,
      stage: stageFilter || undefined,
    }));
  }, [setStageFilter, stageFilter]);


  useEffect(() => {
    setShowWorkflowMenuStages(filter.workflowType !== TEXT.ALL_WORKFLOWS)
  }, [filter.workflowType]);

  return (
    <ApplicationContext.Provider value={{
      services,
      isServiceLoading: isLoading,
      form,
      workflows,
      setWorkflows,
      workflowCountry,
      countryList,
      type,
      filter,
      setFilter,
      setCountryList,
      setStageFilter,
      stageFilter,
      setShowWorkflowMenuStages,
      showWorkflowMenuStages,
      initialLoading
    }}>
      {children}
    </ApplicationContext.Provider>
  )
}

export default ApplicationContextProvider;
