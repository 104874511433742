import React, { useContext, useState } from 'react';
import PageHeader from './components/page-header';
import ApplicationTable from './components/application-table';
import { useAppSelector } from '@crm/core';

import ForbiddenAccess from '../forbidden-access/forbidden-access';
import ApplicationContextProvider, { ApplicationContext } from './application-context';
import { useMenu } from './sections/components/application-menu';

const ContextWrapper: React.FC = () => {
  const user = useAppSelector(store => store.auth.user);
  const permission = useAppSelector(store => store.auth.authUserPermission);
  const { type, filter, setStageFilter, showWorkflowMenuStages, initialLoading } = useContext(ApplicationContext);
  const [search, setSearch] = useState<string | undefined>();


  const workflowId = showWorkflowMenuStages ? filter.workflow[0] : undefined
  const menus = useMenu(showWorkflowMenuStages, workflowId as string, setStageFilter)();

  if (!permission?.payload.permissions.application?.length) {
    return <ForbiddenAccess />
  }

  if (initialLoading) {
    return null;
  }

  return (
    <section id="crm-application-page">
      <PageHeader
        defaultBranch={user?.branchId || undefined}
        menu={menus}
        defaultActiveKey={type}
        search={search}
        setSearch={setSearch}
      />

      <ApplicationTable
        search={search}
        defaultBranch={user?.branchId || ''}
      />
    </section>
  )
}

const Applications = () => {
  return (
    <ApplicationContextProvider>
      <ContextWrapper />
    </ApplicationContextProvider>
  )
}

export default Applications;
