import { useQuery } from '@tanstack/react-query';
import { errorNotificationHandler } from '@moxie/shared';
import { getAllWfDocumentsLists } from '@crm/libs/services.api/lib/workflow.api';

export const useFetchAllRequiredDocs = (workflowStageIds: string[] | null) => {
  const { data, isLoading: isLoadingRequiredDocuments } = useQuery({
    enabled: !!workflowStageIds,
    queryKey: ['all-stage-documents', workflowStageIds],
    queryFn: async () => {
      const { data } = await getAllWfDocumentsLists(workflowStageIds as string[]);
      return data.data
    },
    onError: () => {
      errorNotificationHandler("Error fetching all stage documents.");
    }
  });

  return { allRequiredDocouments: data ?? [], isLoadingRequiredDocuments };
};
