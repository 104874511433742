import { IOfficeBranchCRM } from '@model/data.model';
import { TEXT } from '@moxie/constants';
import { capitalizeFirstLetter } from '@moxie/shared';
import Space from 'antd/es/space';
import Tag from 'antd/es/tag';
import Typography from 'antd/es/typography';

import React from 'react';

interface Props {
  primaryBranch: IOfficeBranchCRM;
  secondaryBranches: IOfficeBranchCRM[];
}
const Branches: React.FC<Props> = ({ primaryBranch, secondaryBranches }) => {

  return (
    <>
      {primaryBranch?.name ? (
        <Space direction="horizontal" align="baseline">
          <Typography.Paragraph className="font-weight-500 text-grey initial_capital overview-primary-branch-text" ellipsis={{ rows: 1, expandable: false, tooltip: capitalizeFirstLetter(primaryBranch?.name as string) }}>
            {primaryBranch?.name}
          </Typography.Paragraph>
          <Tag className="tag-processing overview-branch-tag-height margin-left-2">
            <Typography.Text className="overview-branch-tag-text">
              {TEXT.PRIMARY}
            </Typography.Text>
          </Tag>
        </Space>
      ) : (
        <Typography.Text className="font-weight-500 margin-top-minus-5-px text-grey initial_capital">
          -
        </Typography.Text>
      )}
      {secondaryBranches.map((item: IOfficeBranchCRM) => (
        <Typography.Paragraph ellipsis={{ rows: 1, expandable: false, tooltip: capitalizeFirstLetter(item.name as string) }}
          key={item.id}
          className="font-weight-500 text-grey initial_capital overview-primary-branch-text"
        >
          {item?.name}
        </Typography.Paragraph>
      )
      )
      }
    </>
  )
}

export default Branches;
