import { queryApplications, queryContacts, queryDeals, queryEnquiries, queryNotes } from "@crm/libs/services.api/lib/search.api";
import { APIConnector, AutocompleteQueryConfig, AutocompleteResponseState, QueryConfig, RequestState, ResponseState } from "@elastic/search-ui";
import { BranchPermissionPayload, CanAccess } from "@model/permission.model";
import {
  ContactFilter, DealFilter, DefaultFilter, EnquiryFilter, NoteFilter
} from "@model/crm/elastic-search";
import { AuthUser } from "@model/user";
export enum RoleEnum {
  MARKETING = 1,
  COUNSELING,
  COMPLETE
}
class ElasticSearchConnector implements APIConnector {
  private permission: BranchPermissionPayload | undefined;

  constructor(private readonly user: AuthUser | null, private canAccess: CanAccess) {
    if (user) {
      const payload = user.branchPermission?.payload;
      if (payload) {
        this.permission = payload;
      }
    }
  }

  async onSearch(state: RequestState, queryConfig: QueryConfig): Promise<ResponseState> {
    const { searchTerm } = state;

    const companyId = this.user?.companyId as string;
    if (!companyId) {
      return {
        results: [],
        totalResults: 0,
        rawResponse: {},
        facets: [],
        wasSearched: false,
        pagingEnd: 0,
        pagingStart: 0,
        requestId: '',
        totalPages: 1,
        resultSearchTerm: searchTerm ?? ''
      }
    }

    const permissions = this.permission?.permissions;
    const requests = []
    if (permissions?.contact?.length) {
      requests.push(queryContacts(searchTerm, this.generateContactFilterValues(companyId)))
    }

    if (permissions?.application?.length) {
      requests.push(queryApplications(searchTerm, this.generateApplicationFilterValues(companyId)))
    }
    if (permissions?.deal?.filter(level => !['transfer branch', 'delete', 'archive'].includes(level))?.length) {
      requests.push(queryDeals(searchTerm, this.generateDealFilterValues(companyId)))
    }

    const res = await Promise.all([
      ...requests,
      queryEnquiries(searchTerm, this.generateEnquiryFilterValues(companyId)),
      queryNotes(searchTerm, this.generateNoteFilterValues(companyId))
    ]);
    const allHits = res.flatMap((response) => response?.hits?.hits as any);
    const results = allHits.map((hit) => ({
      id: hit._id,
      index: hit._index,
      ...hit._source
    }));

    return {
      results: results,
      totalResults: allHits.length,
      rawResponse: res,
      facets: [],
      wasSearched: false,
      pagingEnd: 0,
      pagingStart: 0,
      requestId: '',
      totalPages: 1,
      resultSearchTerm: searchTerm ?? ''
    };
  }
  onAutocomplete(state: RequestState, queryConfig: AutocompleteQueryConfig): Promise<AutocompleteResponseState> {
    throw new Error("Method not implemented.");
  }
  onResultClick(params: any): void {
    throw new Error("Method not implemented.");
  }
  onAutocompleteResultClick(params: any): void {
    throw new Error("Method not implemented.");
  }

  private generateContactFilterValues(companyId: string): ContactFilter {
    const filter: ContactFilter = {
      companyId,
    }
    if (!this.canAccess('contact', 'all', 'unassigned')) {
      if (this.canAccess('contact', 'assigned', 'followed')) {
        filter.userId = this.user?.id;
      }
      if (this.canAccess('contact', 'createdBy')) {
        filter.createdBy = this.user?.id;
      }
    }

    if (!this.permission?.canAccessAllBranches) {
      filter.branches = this.user?.branchPermission?.payload.branches;
    }
    return filter
  }

  private generateApplicationFilterValues(companyId: string): DefaultFilter {
    const filter: DefaultFilter = {
      companyId,
    };

    if (this.permission?.level === 'Associated') {
      filter.userId = this.user?.id;
    }

    return filter;
  }

  private generateDealFilterValues(companyId: string): DealFilter {
    const filter: DealFilter = {
      companyId,
    }

    if (this.canAccess('deal', 'assigned')) {
      filter.userId = this.user?.id;
    }

    if (!this.permission?.canAccessAllBranches) {
      filter.branches = this.user?.branchPermission?.payload.branches;
    }

    if (this.canAccess('deal', 'unassigned')) {
      filter.unassigned = true;
    }

    return filter;
  }

  private generateEnquiryFilterValues(companyId: string): EnquiryFilter {
    const filter: EnquiryFilter = {
      companyId
    };

    if (!this.canAccess('enquiry', 'all')) {
      filter.userId = this.user?.id;
      filter.branches = this.permission?.branches;
      if (this.canAccess('enquiry', 'assigned', 'qualified', 'disqualified') || this.permission?.roleId === RoleEnum.COUNSELING) {

        if (this.permission?.roleId === RoleEnum.COUNSELING) {
          filter.isCounselor = true;
          filter.canAccessOnlyAssigned = true;
        }
        if (this.canAccess('enquiry', 'assigned', 'disqualified', 'qualified')) {
          filter.canAccessOnlyAssignedQualifiedDisqualified = true;
        }
      }
    }

    return filter;
  }

  private generateNoteFilterValues(companyId: string): NoteFilter {
    const filter: NoteFilter = {
      companyId,
      userId: this.user?.id as string,
      noteType: []
    }

    if (!this.permission?.permissions.deal?.length) {
      filter.noteType?.push('officeVisit', 'enquiry')
    }

    return filter;
  }

}
export default ElasticSearchConnector;
