import { findDocumentCount } from "@crm/services.api";
import { useQuery } from "@tanstack/react-query";
import { FindDocumentCountParams } from '@model/document';


const useDocumentCount = ({ subjectId, mediaTypes, isDeletedFromApplication, stageId, }: FindDocumentCountParams) => {

  const { data } = useQuery({
    queryKey: ['docs-count', subjectId, mediaTypes],
    enabled: !!mediaTypes.length && !!subjectId,
    queryFn: async () => {
      const checklists = await findDocumentCount({ subjectId, mediaTypes, isDeletedFromApplication, stageId })
      const checklistMap = new Map<string, number>();
      mediaTypes.reduce((map, type) => {
        const typeCount = checklists.find(checklist => checklist.mediaType === type);
        map.set(type, typeCount?.totalDocs ?? 0);
        return map;
      }, checklistMap)

      return checklistMap;
    }
  })

  return {
    docsCountMap: data
  }
}

export default useDocumentCount;
