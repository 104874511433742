import React from 'react';
import { Spin, Timeline, Empty, Divider } from 'antd';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TEXT } from '@moxie/constants';
import { Activity } from '../../../../../shared/activity-log';
import useContactApplicationActivities from '../hooks/use-contact-application-activities';

interface RouteParam {
    contactId: string;
    applicationId: string;
}
const ActivityLog: React.FC = () => {
    const { applicationId } = useParams<RouteParam>()
    const { activities, hasMore, next, isLoading } = useContactApplicationActivities(applicationId)

    if (!activities) {
        return null;
    }
    return (
        <div id="scrollableDiv"
            className="margin-top-2 padding-left-3 application-activities"
        >
            <InfiniteScroll
                hasMore={hasMore}
                next={() => next()}
                dataLength={activities.length}
                loader={isLoading ? <Spin /> : null}
                scrollableTarget="scrollableDiv"
                endMessage={activities.length > 10 ? <Divider plain>You are all caught up</Divider> : null}
                style={{ 'overflowY': 'hidden' }}
            >
                {activities.length === 0 ? <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={TEXT.NO_DATA}
                /> : <Timeline className="margin-top-1">
                    {activities.map(item => (
                        <Timeline.Item key={item.id}>
                            <Activity activity={item} key={item.id} />
                        </Timeline.Item>
                    ))}
                </Timeline>}
            </InfiniteScroll>
        </div>
    )
}

export default ActivityLog
