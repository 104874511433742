import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Empty,
  Menu,
  Progress,
  Row,
  Space,
  Spin,
} from 'antd';
import {
  CONFIRM_MESSAGE,
  DELETE_BTN,
  EDIT_BTN,
  GUTTER,
  LABEL,
  TEXT,
  TITLE,
} from '@moxie/constants';
import { MoreOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { IELTSDrawer } from './drawer/ielts';
import { TOEFLDrawer } from './drawer/toefl';
import { PTEDrawer } from './drawer/pte';
import { popConfirm } from '@shared-components/elements';
import {
  usePteDelete,
  useToeflDelete,
  useIeltsDelete,
} from '@crm/libs/hooks/contacts';
import { ICrmTests } from '@model/index';

const getTestData =  (data: any, testName: string) => {
  if (data.length > 0 ) {
    const testScoreData =  data?.find((testData: any) => testData?.tests?.find((item: any) => {
      return item.name === testName.toLowerCase()
    }))
    if (!testScoreData) {
      return null;
    }
    const { id, parentId, tests: testData } = testScoreData;

    if(!testData) {
      return null
    }

    const {id: testId, name, overallScore, testScoreId, testTypes: typeData } = testData[0];

    const testTypes =  typeData?.reduce((acc: any, item: any) => {
      acc[item.name] = item;
      return acc;
    }, {});

    return {
      id,
      parentId,
      testId,
      testName: name,
      testScoreId,
      reading: testTypes['reading'] || null,
      writing: testTypes['writing'] || null,
      speaking: testTypes['speaking'] || null,
      listening: testTypes['listening'] || null,
      overallScore: overallScore || null,
    };
  } else {
    return null;
  }
};

const EnglishTestScorePanelContent = ({
  data,
  loading,
  panelKey,
  setShowExtraIcon,
}: {
  data: ICrmTests[];
  loading: boolean;
  panelKey: string;
  setShowExtraIcon: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showIeltsDrawer, setShowIeltsDrawer] = useState<boolean>(false);
  const [showToeflDrawer, setShowToeflDrawer] = useState<boolean>(false);
  const [showPteDrawer, setShowPteDrawer] = useState<boolean>(false);

  const ieltsData = React.useMemo(() =>  getTestData(data, LABEL.IELTS), [data]);
  const toeflData =   React.useMemo(() =>  getTestData(data, LABEL.TOEFL), [data]);
  const pteData =   React.useMemo(() =>  getTestData(data, LABEL.PTE), [data]);

  const handleIeltsEditMode = () => {
    setEditMode(true);
    setShowIeltsDrawer(true);
  };

  const handleToeflEditMode = () => {
    setEditMode(true);
    setShowToeflDrawer(true);
  };

  const handlePteEditMode = () => {
    setEditMode(true);
    setShowPteDrawer(true);
  };

  const ieltsDelete = useIeltsDelete();
  const toeflDelete = useToeflDelete();
  const pteDelete = usePteDelete();

  const handleIeltsDelete = () => {
    popConfirm(
      DELETE_BTN,
      `${CONFIRM_MESSAGE.DELETE_IELTS_SCORE}`,
      async () => {
        await ieltsDelete.mutateAsync(ieltsData?.id);
      },
      true
    );
  };

  const handleToeflDelete = () => {
    popConfirm(
      DELETE_BTN,
      `${CONFIRM_MESSAGE.DELETE_TOEFL_SCORE}`,
      async () => {
        await toeflDelete.mutateAsync(toeflData?.id);
      },
      true
    );
  };

  const handlePteDelete = () => {
    popConfirm(
      DELETE_BTN,
      `${CONFIRM_MESSAGE.DELETE_PTE_SCORE}`,
      async () => {
        await pteDelete.mutateAsync(pteData?.id);
      },
      true
    );
  };

  useEffect(() => {
    if (panelKey === TITLE.ENGLISH_TEST_SCORES) {
      if (ieltsData && toeflData && pteData) {
        setShowExtraIcon(false);
      } else {
        setShowExtraIcon(true);
      }
    }
  }, [ieltsData, toeflData, pteData]);

  return (
    <Spin spinning={loading}>
      {!data.length ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={TEXT.NOT_AVAILABLE}
        />
      ) : (
        <>
          {ieltsData && (
            <Card className="test-score-card">
              <Row gutter={GUTTER}>
                <Col span={7}>
                  <Row className="margin-top-2" justify="center">
                    <Text className="test-score-title">{LABEL.IELTS}</Text>
                  </Row>

                  <Row className="margin-top-2" justify="center">
                    <>
                      <Progress
                        type={'circle'}
                        strokeWidth={10}
                        strokeColor={'#4a2362'}
                        percent={(ieltsData?.overallScore / 9) * 100}
                        format={(percent: any) => (
                          <Text className="test-score-progress-text">
                            {Math.round((percent / 100) * 9)}
                          </Text>
                        )}
                        width={40}
                      />
                    </>
                  </Row>
                </Col>
                <Col span={14} xl={13} xxl={14}>
                  <Row justify="space-around" className="margin-top-2">
                    <Col>
                      <Row justify="center">
                        <Text className="test-score-label-text">
                          {LABEL.WRITING}
                        </Text>
                      </Row>
                      <Row justify="center">
                        <Text> {ieltsData?.writing?.score} </Text>
                      </Row>
                    </Col>
                    <Col>
                      <Row justify="center">
                        <Text className="test-score-label-text">
                          {LABEL.SPEAKING}
                        </Text>
                      </Row>
                      <Row justify="center">
                        <Text>{ieltsData?.speaking?.score} </Text>
                      </Row>
                    </Col>
                  </Row>
                  <Row justify="space-around" className="margin-top-2">
                    <Col>
                      <Row justify="center">
                        <Text className="test-score-label-text">
                          {LABEL.READING}
                        </Text>
                      </Row>
                      <Row justify="center">
                        <Text>{ieltsData?.reading?.score} </Text>
                      </Row>
                    </Col>
                    <Col>
                      <Row justify="center">
                        <Text className="test-score-label-text">
                          {LABEL.LISTENING}
                        </Text>
                      </Row>
                      <Row justify="center">
                        <Text>{ieltsData?.listening?.score} </Text>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="margin-top-2 margin-left-1">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key={1} onClick={handleIeltsEditMode}>
                          <Space direction="horizontal">
                            <EditOutlined data-testid="editielts" />
                            {EDIT_BTN}
                          </Space>
                        </Menu.Item>
                        <Menu.Item key={2} danger onClick={handleIeltsDelete}>
                          <Space direction="horizontal">
                            <DeleteOutlined data-testid="deleteielts" />
                            {DELETE_BTN}
                          </Space>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button
                      type="text"
                      className="table-cell-action-btn"
                      icon={<MoreOutlined className="font-size-18px" />}
                    />
                  </Dropdown>
                </Col>
              </Row>
            </Card>
          )}

          {toeflData && (
            <Card
              className={
                ieltsData ? 'test-score-card margin-top-1' : 'test-score-card'
              }
            >
              <Row gutter={GUTTER}>
                <Col span={7}>
                  <Row className="margin-top-2" justify="center">
                    <Text className="test-score-title">{LABEL.TOEFL}</Text>
                  </Row>

                  <Row className="margin-top-2" justify="center">
                    <>
                      <Progress
                        type={'circle'}
                        strokeColor={'#4a2362'}
                        strokeWidth={10}
                        percent={(toeflData?.overallScore / 120) * 100}
                        format={(percent: any) => (
                          <Text className="test-score-progress-toefl-text">
                            {Math.round((percent / 100) * 120)}
                          </Text>
                        )}
                        width={40}
                      />
                    </>
                  </Row>
                </Col>
                <Col span={14} xl={13} xxl={14}>
                  <Row justify="space-around" className="margin-top-2">
                    <Col>
                      <Row justify="center">
                        <Text className="test-score-label-text">
                          {LABEL.WRITING}
                        </Text>
                      </Row>
                      <Row justify="center">
                        <Text>{toeflData?.writing?.score}</Text>
                      </Row>
                    </Col>
                    <Col>
                      <Row justify="center">
                        <Text className="test-score-label-text">
                          {LABEL.SPEAKING}
                        </Text>
                      </Row>
                      <Row justify="center">
                        <Text>{toeflData?.speaking?.score}</Text>
                      </Row>
                    </Col>
                  </Row>
                  <Row justify="space-around" className="margin-top-2">
                    <Col>
                      <Row justify="center">
                        <Text className="test-score-label-text">
                          {LABEL.READING}
                        </Text>
                      </Row>
                      <Row justify="center">
                        <Text>{toeflData?.reading?.score}</Text>
                      </Row>
                    </Col>
                    <Col>
                      <Row justify="center">
                        <Text className="test-score-label-text">
                          {LABEL.LISTENING}
                        </Text>
                      </Row>
                      <Row justify="center">
                        <Text>{toeflData?.listening?.score}</Text>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="margin-top-2 margin-left-1">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key={1} onClick={handleToeflEditMode}>
                          <Space direction="horizontal">
                            <EditOutlined data-testid="edittoefl" />
                            {EDIT_BTN}
                          </Space>
                        </Menu.Item>
                        <Menu.Item key={2} danger onClick={handleToeflDelete}>
                          <Space direction="horizontal">
                            <DeleteOutlined data-testid="deletetoefl" />
                            {DELETE_BTN}
                          </Space>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button
                      type="text"
                      className="table-cell-action-btn"
                      icon={<MoreOutlined className="font-size-18px" />}
                    />
                  </Dropdown>
                </Col>
              </Row>
            </Card>
          )}

          {pteData && (
            <Card
              className={
                toeflData || ieltsData
                  ? 'test-score-card margin-top-1'
                  : 'test-score-card'
              }
            >
              <Row gutter={GUTTER}>
                <Col span={7}>
                  <Row className="margin-top-2" justify="center">
                    <Text className="test-score-title">{LABEL.PTE}</Text>
                  </Row>

                  <Row className="margin-top-2" justify="center">
                    <>
                      <Progress
                        type={'circle'}
                        strokeColor={'#4a2362'}
                        strokeWidth={10}
                        percent={(pteData?.overallScore / 90) * 100}
                        format={(percent: any) => (
                          <Text className="test-score-progress-pte-text">
                            {Math.round((percent / 100) * 90)}
                          </Text>
                        )}
                        width={40}
                      />
                    </>
                  </Row>
                </Col>
                <Col span={14} xl={13} xxl={14}>
                  <Row justify="space-around" className="margin-top-2">
                    <Col>
                      <Row justify="center">
                        <Text className="test-score-label-text">
                          {LABEL.WRITING}
                        </Text>
                      </Row>
                      <Row justify="center">
                        <Text>{pteData?.writing?.score} </Text>
                      </Row>
                    </Col>
                    <Col>
                      <Row justify="center">
                        <Text className="test-score-label-text">
                          {LABEL.SPEAKING}
                        </Text>
                      </Row>
                      <Row justify="center">
                        <Text>{pteData?.speaking?.score}</Text>
                      </Row>
                    </Col>
                  </Row>
                  <Row justify="space-around" className="margin-top-2">
                    <Col>
                      <Row justify="center">
                        <Text className="test-score-label-text">
                          {LABEL.READING}
                        </Text>
                      </Row>
                      <Row justify="center">
                        <Text>{pteData?.reading?.score}</Text>
                      </Row>
                    </Col>
                    <Col>
                      <Row justify="center">
                        <Text className="test-score-label-text">
                          {LABEL.LISTENING}
                        </Text>
                      </Row>
                      <Row justify="center">
                        <Text>{pteData?.listening?.score}</Text>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="margin-top-2 margin-left-1">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key={1} onClick={handlePteEditMode}>
                          <Space direction="horizontal">
                            <EditOutlined data-testid="editpte" />
                            {EDIT_BTN}
                          </Space>
                        </Menu.Item>
                        <Menu.Item key={2} danger onClick={handlePteDelete}>
                          <Space direction="horizontal">
                            <DeleteOutlined data-testid="deletepte" />
                            {DELETE_BTN}
                          </Space>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button
                      type="text"
                      className="table-cell-action-btn"
                      icon={<MoreOutlined className="font-size-18px" />}
                    />
                  </Dropdown>
                </Col>
              </Row>
            </Card>
          )}

          <IELTSDrawer
            showDrawer={showIeltsDrawer}
            setShowDrawer={setShowIeltsDrawer}
            editMode={editMode}
            setEditMode={setEditMode}
            ieltsData={ieltsData}
          />

          <TOEFLDrawer
            setShowDrawer={setShowToeflDrawer}
            showDrawer={showToeflDrawer}
            editMode={editMode}
            setEditMode={setEditMode}
            toeflData={toeflData}
          />

          <PTEDrawer
            showDrawer={showPteDrawer}
            setShowDrawer={setShowPteDrawer}
            editMode={editMode}
            setEditMode={setEditMode}
            pteData={pteData}
          />
        </>
      )}
    </Spin>
  );
};
export { EnglishTestScorePanelContent };
