import React, { useEffect } from 'react';
import {
  // URL_CALENDAR,
  URL_CHECK_MAIL,
  URL_CLIENTS,
  URL_CONTACTS,
  URL_CRM_VERIFY_ACCOUNT,
  URL_CRM_VERIFY_ACCOUNT_SUCCESS,
  URL_CRM_AUTH,
  URL_CRM_FORGET_PASSWORD,
  URL_DASHBOARD,
  URL_INSTITUTE,
  URL_INSTITUTE_DETAIL,
  URL_INSTITUTE_IMPORT,
  URL_LEAD,
  URL_LOGIN,
  URL_OFFICE,
  URL_OFFICE_VISIT,
  URL_PRODUCTS,
  URL_PROSPECTS,
  URL_SERVICE,
  URL_USER,
  URL_USER_DETAIL,
  URL_AGENTS,
  URL_CRM_WORKFLOWS,
  URL_CRM_WORKFLOW_DETAIL,
  URL_CRM_WORKFLOW_TYPES,
  URL_NOTIFICATION,
  URL_ENQUIRIES,
  URL_ENQUIRIES_FORM,
  URL_CONTACT_REPORT,
  URL_APPLICATION_REPORT,
  URL_FORBIDDEN_ACCESS,
  URL_CALENDAR,
  URL_ENQUIRY_SUCCESS,
  URL_ENQUIRY_BASIC_DETAILS,
  URL_ENQUIRY_ADDITIONAL_DETAILS,
  URL_DEALS_LIST_PAGE,
  URL_DEALS_DETAILS_PAGE,
  URL_APPLICATION_PAGE,
  URL_EDUCATION_PROVIDER_PAGE,
  URL_OFFICE_VISIT_FORM
} from '@moxie/constants';
import {
  CrmLogProtectedRoute,
  CrmProtectedRoute,
  CrmAuth
} from '@moxie/shared';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { CrmDashboardRoute } from './dashboard';
import Login from './login';
import { OfficeVisitListComponent } from './office-visit';
import { ContactsPage } from './contact';
// import { CalendarComponent } from './calendar';
import { useAppSelector } from '../core/use-store';
import { useDispatch } from 'react-redux';
import { ProductDetails, ProductsPage } from './products';
import { ProspectListComponent } from './prospect';
import Agents from './agents';
import ApplicationDetails from './application-details';
import { io } from 'socket.io-client';
import { socketSagaActions } from '../core/socket/socket.action';
import { useQueryClient } from '@tanstack/react-query';
import { Socket } from '../core/socket/socket.reducer';
import { Notifications } from './notifications';
import { EnquiryFormsList } from './enquiries/components/enquiry-forms-list';
import { ApplicationReportComponent, ContactReportComponent } from './reports';
import NotFound from './not-found';
import { useFetchDomainValidation } from '@crm/libs/hooks/domain-check/useFetchDomainValidation';
import NotValidDomain from './not-valid-domain';
import ForbiddenAccess from './forbidden-access/forbidden-access';
import { CalendarComponent } from './calendar';
import PublicRoute from 'libs/shared/src/elements/public-route';
import * as Sentry from "@sentry/react";
import Applications from './application/applications';
import { LoaderComponent } from '../shared/loader';

const LazyForgetPassword = React.lazy(
  async () => await import('./login/forget-password')
);

const LazyCheckMail = React.lazy(async () => await import('./auth/check-mail'));

const LazyVerifyAccount = React.lazy(
  async () => await import('./auth/verify-account/verify-account')
);

const LazyVerifyAccountSuccess = React.lazy(
  async () => await import('./auth/verify-account/verify-account-success')
);

const Enquiries = React.lazy(
  async () =>
    await import('./enquiries').then((module) => {
      return { default: module.Enquiries };
    })
);

const ServiceListComponent = React.lazy(
  async () =>
    await import('./service').then((module) => {
      return { default: module.ServiceListComponent };
    })
);

const WorkflowListComponent = React.lazy(
  async () =>
    await import('./workflow').then((module) => {
      return { default: module.WorkflowListComponent };
    })
);

const WorkflowDetail = React.lazy(
  async () =>
    await import('@crm/shared').then((module) => {
      return { default: module.WorkflowDetail };
    })
);

const WorkflowTypeListComponent = React.lazy(
  async () =>
    await import('./workflow-type').then((module) => {
      return { default: module.WorkflowTypeListComponent };
    })
);

const Institution = React.lazy(
  async () =>
    await import('./institution').then((module) => {
      return { default: module.Institution };
    })
);
const InstitutionImport = React.lazy(
  async () =>
    await import('./institution').then((module) => {
      return { default: module.InstitutionImport };
    })
);

const InstitutionDetail = React.lazy(
  async () =>
    await import('./institution').then((module) => {
      return { default: module.InstitutionDetail };
    })
);

const UserDetail = React.lazy(
  async () =>
    await import('./user').then((module) => {
      return { default: module.UserDetail };
    })
);

const UserListComponent = React.lazy(
  async () =>
    await import('./user').then((module) => {
      return { default: module.UserListComponent };
    })
);

const OfficeListComponent = React.lazy(
  async () =>
    await import('./office').then((module) => {
      return { default: module.OfficeListComponent };
    })
);

const ContactDetailComponent = React.lazy(
  async () =>
    await import('./contact-details').then((module) => {
      return { default: module.ContactDetailComponent };
    })
);

const LeadListComponent = React.lazy(
  async () =>
    await import('./lead').then((module) => {
      return { default: module.LeadListComponent };
    })
);

const ClientListComponent = React.lazy(
  async () =>
    await import('./client').then((module) => {
      return { default: module.ClientListComponent };
    })
);

const EnquiryBasicDetailFormComponent = React.lazy(() => import('./enquiry-form/basic-details-info/basic-details-form'));
const EnquiryAdditionalDetailsFormComponent = React.lazy(() => import('./enquiry-form/additional-details-info/additional-details'));
const EnquiryFormSuccessComponent = React.lazy(() => import('./enquiry-form/success'));
const Deals = React.lazy(
  async () =>
    await import('./deals').then((module) => {
      return { default: module.Deals };
    })
);

const DealDetailsPage = React.lazy(
  async () =>
    await import('./deal-details').then((module) => {
      return { default: module.DealDetailsPage };
    })
);

const EducationProviderPage = React.lazy(
  async () =>
    await import('./education-provider').then((module) => {
      return { default: module.EducationProviderPage };
    })
);

const OfficeVisitFormComponent = React.lazy(() => import('./office-visit-form/office-visit-form'));

const App: React.FC = () => {
  const dispatch = useDispatch();
  const authUser = useAppSelector((state) => state.auth.user);
  const queryClient = useQueryClient();

  const { domainValidationStatus, isLoading } = useFetchDomainValidation();

  useEffect(() => {

    const socket: Socket = io(process.env.NX_CRM_API_URL! as string, {
      timeout: 50000,
      transports: ['websocket'],
    });

    dispatch({
      type: socketSagaActions.SET_SOCKET_CONNECTION,
      payload: socket,
    });

    socket.on('client::update-activity-logs', (contactId) => {
      queryClient.invalidateQueries({
        queryKey: ['contacts', contactId],
      });
    });

    socket.on('client::deals-created-logs', (contactId) => {
      queryClient.invalidateQueries({
        queryKey: ['contacts', contactId],
      });
    });

    socket.on('client::update-application-logs', (applicationId) => {
      queryClient.invalidateQueries({
        queryKey: ['application-log', applicationId],
      });
    });

    socket.on('client::notification-updated', (notification) => {
      queryClient.invalidateQueries({
        queryKey: ['notifications'],
      });
      queryClient.invalidateQueries({
        queryKey: ['notification-count'],
      });
    });

    if (authUser) {
      socket.emit('server::join-room', {
        userId: authUser.id as string,
        socketId: socket.id,
      });
    }
    return () => {
      dispatch({
        type: socketSagaActions.REMOVE_SOCKET_CONNECTION,
      });
      socket.emit('server::leave-room', {
        userId: authUser?.id as string,
        socketId: socket.id,
      });

      socket.close();
    };
  }, [authUser]);

  if (isLoading) {
    return (
      <LoaderComponent />
    )
  }

  if (!domainValidationStatus) {
    return (
      <NotValidDomain />
    )
  }

  return (
    <Router>
      <Switch>
        <CrmLogProtectedRoute exact path={URL_LOGIN} component={Login} />
        <CrmLogProtectedRoute
          exact
          path={URL_CRM_VERIFY_ACCOUNT}
          component={LazyVerifyAccount}
        />
        <CrmLogProtectedRoute
          exact
          path={URL_CRM_VERIFY_ACCOUNT_SUCCESS}
          component={LazyVerifyAccountSuccess}
        />
        <CrmLogProtectedRoute
          exact
          path={URL_CRM_FORGET_PASSWORD}
          component={LazyForgetPassword}
        />
        <CrmLogProtectedRoute
          exact
          path={URL_CHECK_MAIL}
          component={LazyCheckMail}
        />
        <CrmProtectedRoute
          exact
          path={URL_APPLICATION_PAGE}
          component={Applications}
        />
        <CrmLogProtectedRoute exact path={URL_CRM_AUTH} component={CrmAuth} />
        <CrmLogProtectedRoute
          exact
          path={URL_CRM_VERIFY_ACCOUNT}
          component={LazyVerifyAccount}
        />
        <CrmLogProtectedRoute
          exact
          path={URL_CRM_VERIFY_ACCOUNT_SUCCESS}
          component={LazyVerifyAccountSuccess}
        />
        <CrmProtectedRoute path={URL_DASHBOARD} component={CrmDashboardRoute} />
        <CrmProtectedRoute path={URL_ENQUIRIES} component={Enquiries} />
        <CrmProtectedRoute
          path={URL_ENQUIRIES_FORM}
          component={EnquiryFormsList}
        />
        <CrmProtectedRoute exact path={URL_CONTACTS} component={ContactsPage} />
        <CrmProtectedRoute
          exact
          path={'/contact/detail/:id/:slug'}
          component={ContactDetailComponent}
        />
        <CrmProtectedRoute
          exact
          path={'/application/detail/:contactId/:applicationId'}
          component={ApplicationDetails}
        />
        <CrmProtectedRoute path={URL_LEAD} component={LeadListComponent} />
        <CrmProtectedRoute
          path={URL_PROSPECTS}
          component={ProspectListComponent}
        />
        <CrmProtectedRoute path={URL_CLIENTS} component={ClientListComponent} />
        <CrmProtectedRoute
          path={URL_OFFICE_VISIT}
          component={OfficeVisitListComponent}
        />
        <CrmProtectedRoute path={URL_OFFICE} component={OfficeListComponent} />
        <CrmProtectedRoute path={URL_USER_DETAIL} component={UserDetail} />
        <CrmProtectedRoute path={URL_USER} component={UserListComponent} />
        <CrmProtectedRoute
          path={'/products/detail/:productId'}
          component={ProductDetails}
        />
        <CrmProtectedRoute path={URL_PRODUCTS} component={ProductsPage} />
        <CrmProtectedRoute
          path={URL_INSTITUTE_IMPORT}
          component={InstitutionImport}
        />
        <CrmProtectedRoute
          path={URL_INSTITUTE_DETAIL}
          component={InstitutionDetail}
        />
        <CrmProtectedRoute path={URL_INSTITUTE} component={Institution} />

        <CrmProtectedRoute
          path={URL_SERVICE}
          component={ServiceListComponent}
        />
        <CrmProtectedRoute
          path={URL_CRM_WORKFLOWS}
          component={WorkflowListComponent}
        />
        <CrmProtectedRoute
          path={URL_CRM_WORKFLOW_DETAIL}
          component={WorkflowDetail}
        />
        <CrmProtectedRoute
          path={URL_CRM_WORKFLOW_TYPES}
          component={WorkflowTypeListComponent}
        />
        <CrmProtectedRoute path={URL_AGENTS} component={Agents} />
        <CrmProtectedRoute path={URL_CALENDAR} component={CalendarComponent} />
        <CrmProtectedRoute path={URL_NOTIFICATION} component={Notifications} />
        <CrmProtectedRoute
          path={URL_CONTACT_REPORT}
          component={ContactReportComponent}
        />
        <CrmProtectedRoute
          path={URL_APPLICATION_REPORT}
          component={ApplicationReportComponent}
        />
        <CrmProtectedRoute
          exact
          path={URL_DEALS_LIST_PAGE}
          component={Deals}
        />
        <CrmProtectedRoute
          path={URL_DEALS_DETAILS_PAGE}
          component={DealDetailsPage}
        />
        <CrmProtectedRoute
          path={URL_FORBIDDEN_ACCESS}
          component={ForbiddenAccess}
        />
        <CrmProtectedRoute
          path={URL_EDUCATION_PROVIDER_PAGE}
          component={EducationProviderPage}
        />
        <PublicRoute
          exact
          path={URL_ENQUIRY_BASIC_DETAILS}
          component={EnquiryBasicDetailFormComponent}
        />
        <PublicRoute
          exact
          path={URL_ENQUIRY_ADDITIONAL_DETAILS}
          component={EnquiryAdditionalDetailsFormComponent}
        />
        <PublicRoute
          exact
          path={URL_ENQUIRY_SUCCESS}
          component={EnquiryFormSuccessComponent}
        />
        <PublicRoute
          exact
          path={URL_OFFICE_VISIT_FORM}
          component={OfficeVisitFormComponent}
        />

        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Sentry.withProfiler(App);
