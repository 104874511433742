import { capitalizeString } from "@crm/libs/helpers";
import { fetchBranchesByAddress } from "@crm/libs/services.api/lib/address-branches.api";
import { getAvailableStatesByCountry } from "@crm/services.api";
import { errorNotificationHandler } from "@moxie/shared";
import { useQuery } from "@tanstack/react-query";
import { crmErrorHandler } from "libs/shared/src/crm-modules/shared";
import { groupBy } from "lodash";

export const useFetchBranchesByCountry = (countryName: string) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['branch-state-by-country', countryName],
    queryFn: () => getAvailableStatesByCountry(countryName),
    onError: () => errorNotificationHandler('Error fetching states'),
    enabled: Boolean(countryName),
  });
  return {
    branchStateData: data?.data,
    isLoading,
    isFetching,
  }
}

type AddressType = "country" | "state";

export const useFetchBranchesByAddress = (
  addressType: AddressType,
  address: string,
  search = ""
) => {
  const params = { search, [`filter.${addressType}`]: address };
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["fetch-branch-by-address", address, search],
    queryFn: () => fetchBranchesByAddress(params),
    onError: (err) => crmErrorHandler(err),
    enabled: Boolean(address),
  });

  return {
    addressData: data?.data?.data ?? [],
    isLoading,
    isFetching,
  };
};

export const useFetchBranchCountriesList = (params?: Record<string, unknown>) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['branch-countries-list'],
    queryFn: () => fetchBranchesByAddress({ limit: 0, ...params }),
    onError: () => errorNotificationHandler('Error fetching countries list'),
  });

  const resData = data?.data?.data ?? [];
  const branchesByCountry = groupBy(resData, data => capitalizeString(data?.country ?? ''));
  const countriesList = Object.keys(branchesByCountry).sort();

  return {
    data: resData,
    branchesByCountry,
    countriesList,
    isLoading,
    isFetching,
  }
}

