import React, { useState } from "react";
import Modal from "antd/es/modal";
import { Form, Typography } from "antd";
import Select from "antd/es/select";
import Row from "antd/es/row";
import QRCode from "react-qr-code";
import CopyableInputText from "@crm/src/shared/copyable-input-text/copyable-input-text";
import { srcOptions } from "../../constants";
import { ShareModalType } from "../enquiry-forms-table";
import countriesData from "@crm/src/pages/enquiry-form/country-state-data.json"
import { ICountryStateData } from "@crm/src/pages/enquiry-form/additional-details-info/address-info/address-info-form";
import { useFetchBranchesByAddress } from "@crm/libs/hooks/branches/useFetchBranchesStatesByCountry";
import { IAddressBranch } from "@model/crm/enquiry-form.model";

interface ShareUrlModalProps {
  shareModal: ShareModalType;
  setShareModal: React.Dispatch<React.SetStateAction<ShareModalType>>;
}
interface UrlAddress {
  country?: string;
  branchName?: string;
  state?: string;
  branch?: string;
}

const countriesStateData: ICountryStateData = countriesData;

const getQueryStrings = (address:UrlAddress): string =>{
  let result = ''
  for(const a in address){
    const key = a as keyof UrlAddress;
    if(address[key]){
      result = result.concat('&' + key + '=' + address[key]);
    }
  }
  return result
}

const ShareUrlModal = ({ shareModal, setShareModal }: ShareUrlModalProps) => {
  const [source, setSource] = useState( shareModal !== "none" ? srcOptions[shareModal][0] : "");
  const [address,setAddress] = useState<UrlAddress>({
    country: undefined,
    branchName: undefined,
    state: undefined,
    branch: undefined,
  })

  const url = `${window.location.origin}/enquiry-form?source=${source}${getQueryStrings(address)}`;
  const { addressData: branchData } = useFetchBranchesByAddress('country',address.country ?? "");
  const countriesList = Object.keys(countriesStateData).sort();

  return (
    <Modal visible={shareModal !== "none"}
      title={<Typography.Text style={{ fontWeight: '700' }}>Sharing Options</Typography.Text>}
      onCancel={() => setShareModal('none')}
      footer={null}
    >
      <Form layout="vertical" className="padding-1">
        <Form.Item
          label="Country"
        >
          <Select
            value={address.country}
            placeholder="Please select country"
            showSearch
            allowClear
            optionFilterProp="label"
            onChange={(country: string) => setAddress(prev =>({...prev, country, branchName: undefined, state: undefined, branch: undefined}))}
          >
            {countriesList.map((country, index) => (
              <Select.Option value={country} key={index} label={country}>
                {country}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Branch"
        >
          <Select
            value={address.branchName}
            placeholder="Please select branch"
            showSearch
            allowClear
            optionFilterProp="label"
            onChange={(value: string)=> {
              const addressObj = JSON.parse(value) as IAddressBranch;
              setAddress(prev => ({...prev, branchName: addressObj.branch?.name ?? '', state: addressObj?.state ?? '', branch: addressObj?.branch?.id ?? ''}))
            }}
          >
            {branchData.map((branch, index) => (
              <Select.Option value={JSON.stringify(branch)} key={index} label={branch?.branch?.name ?? ''}>
                {branch.branch?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Source">
          <Select value={source} showArrow onChange={setSource}>
            {shareModal !== "none" && srcOptions[shareModal]?.map((src, index) =>
              (<Select.Option key={index} value={src}>{src}</Select.Option>)
            )}
          </Select>
        </Form.Item>
        <Row align="middle" justify="center" className="margin-bottom-2">
          <QRCode value={url} size={150} />
        </Row>
        <Form.Item label="Shareable Link">
          <CopyableInputText key={`text-${source}`} value={url} />
        </Form.Item>
        <Form.Item label="Embed Code">
          <CopyableInputText key={`iframe-${source}`} value={`<iframe src="${url}" title="${url}"></iframe>`} />
        </Form.Item>
      </Form>
    </Modal >
  )
}

export default ShareUrlModal;
