import { ActivitiesType, ChangeLog } from '@model/contact-activity';

export const DeletedActivityMessage = ({
  activitiesType,
  data,
  followerLabel,
}: {
  activitiesType: ActivitiesType;
  data: ChangeLog;
  followerLabel: string;
}) => {

  switch (activitiesType) {
    case 'language-test':
      return (
        <span>
          Deleted the{' '}
          <span className="contact-activities-stage">
            english test score for {data?.testName?.toUpperCase()}
          </span>
        </span>
      );
    case 'emergency-contact':
      return (
        <span>
          Deleted the{' '}
          <span className="contact-activities-stage">
            Emergency contact details
          </span>
        </span>
      );

    case 'professional-experience':
      return (
        <span>
          Deleted the{' '}
          <span className="contact-activities-stage">
            professional experience
          </span>
        </span>
      );
    case 'education':
      return (
        <span>
          Deleted{' '}
          <span className="contact-activities-stage">education background</span>
        </span>
      );

    case 'document':
      return (
        <span>
          Document deleted in{' '}
          <span className="contact-activities-stage">{data?.subCategory}</span>{' '}
        </span>
      );

    case 'follower':
      return (
        <span>
          <span className="contact-activities-stage">{followerLabel}</span>{' '}
          removed as followers
        </span>
      );

    case 'application-document':
      return (
        <span>
          Deleted document from{' '}
          <span className="contact-activities-stage">
            {data?.data?.documentType}
          </span>{' '}
          in{' '}
          <span className="contact-activities-stage">
            {data?.data?.currentStage?.stage}
          </span>
        </span>
      );

    case 'branch':
      return (
        <span>
          Removed branch{' '}
          <span className="contact-activities-stage">{data?.data?.name}</span>
        </span>
      );

    default:
      return <span>Deleted the application</span>;
  }
};
