import React, { useEffect } from 'react';
import { GUTTER, LABEL } from '@moxie/constants';
import { Col, Form, InputNumber, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useToeflMutation, useToeflUpdate } from '@crm/libs/hooks/contacts';
import { ILanguageScoreProps } from '../ielts';
import { IEnglishTestScore, ITestScore, ITestTypeData, TestType } from '@model/index';
import { convertIntoNumber } from '@crm/libs/helpers';

const { Text } = Typography;

interface TOEFLFormProps extends Omit<ILanguageScoreProps, 'ieltsData'> {
  toeflData: IEnglishTestScore;
}

const TOEFLForm = ({
  form,
  setDisabled,
  handleClose,
  toeflData,
  editMode,
}: TOEFLFormProps) => {
  const { id }: { id: string } = useParams();
  const toeflMutation = useToeflMutation();
  const toeflUpdate = useToeflUpdate();

  const handleSubmit = async (data: Required<ITestScore>) => {
    let mutationData: any;
    let testTypeData: ITestTypeData[];

    const { overallScore, ...restData } = data;

    if (toeflData) {
      testTypeData = Object.entries(restData).map(([name, score]) => ({
        id: toeflData[name as TestType]?.id,
        name: name as keyof ITestScore,
        score: score,
      }));
      mutationData = {
        id: toeflData?.id,
        parentId: toeflData?.parentId,
        tests: [
          {
            id: toeflData?.testId,
            name: toeflData?.testName,
            testScoreId: toeflData?.testScoreId,
            testTypes: testTypeData,
            overallScore,
          },
        ],
      };
      await toeflUpdate.mutateAsync(mutationData);
    } else {
      testTypeData = Object.entries(restData).map(([name, score]) => ({
        name: name as keyof ITestScore,
        score: score,
      }));
      mutationData = {
        parentId: id,
        tests: [
          {
            name: 'toefl',
            testTypes: testTypeData,
            overallScore,
          },
        ],
      };

      await toeflMutation.mutateAsync(mutationData);
    }

    handleClose();
  };

  const handleFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  useEffect(() => {
    const initialData = {
      reading: convertIntoNumber(toeflData?.reading?.score),
      speaking: convertIntoNumber(toeflData?.speaking?.score),
      writing: convertIntoNumber(toeflData?.writing?.score),
      listening: convertIntoNumber(toeflData?.listening?.score),
      overallScore: convertIntoNumber(toeflData?.overallScore),
    };
    form.setFieldsValue(initialData);
  }, [editMode, toeflData, form]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      onFieldsChange={handleFieldsChange}
    >
      <Row gutter={GUTTER} align="top">
        <Col span={3} className="align-vertically-centered">
          <Text className="align-score-title" strong>
            {LABEL.TOEFL}
          </Text>
        </Col>

        <Col span={4}>
          <Form.Item
            name="reading"
            label={LABEL.READING}
            rules={[{ type: 'number', min: 1, max: 30 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="reading" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="listening"
            label={LABEL.LISTENING}
            rules={[{ type: 'number', min: 1, max: 30 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="listening" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="writing"
            label={LABEL.WRITING}
            rules={[{ type: 'number', min: 1, max: 30 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="writing" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="speaking"
            label={LABEL.SPEAKING}
            rules={[{ type: 'number', min: 1, max: 30 }, { required: true }]}
          >
            <InputNumber className="full-width" data-testid="speaking" />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="overallScore"
            label={LABEL.OVERALL_SCORE}
            rules={[
              {
                type: 'number',
                min: 1,
                max: 120,
              },
              {
                required: true,
              },
            ]}
          >
            <InputNumber className="full-width" data-testid="overall_score" />
          </Form.Item>
        </Col>

      </Row>
    </Form>
  );
};
export { TOEFLForm };
