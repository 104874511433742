import React from 'react';
import { PLACEHOLDER, TEXT } from '@moxie/constants';
import Table, { ColumnsType } from 'antd/lib/table';
import { capitalizeFirstLetter } from '@moxie/shared';
import { TableRowSelection } from 'antd/lib/table/interface';
import { List, Spin, Typography } from 'antd';
import { DocumentFileName } from './DocumentFIleName';
import { Media } from '@model/document';

interface Props {
  applicationChecklistDocs: Media[];
  contactDocs: Media[];
  isLoading: boolean;
  selectedDocs: Media[];
  setSelectedDocs: React.Dispatch<Media[]>
  selectedDocsRef: React.Key[];
  setSelectedDocsRef: React.Dispatch<React.Key[]>;
  submitLoading: boolean;
  setSubmitLoading: React.Dispatch<boolean>;
}
const ContactExistingDocumentSelector = ({
  contactDocs,
  isLoading,
  applicationChecklistDocs,
  selectedDocsRef,
  setSelectedDocsRef,
  submitLoading,
  selectedDocs,
  setSelectedDocs,
}: Props) => {
  const columns = getExistingDocsCols();
  const filteredApplicationDocs = applicationChecklistDocs.filter(doc => doc.syncId).map(d => d.syncId);

  const filterDocs = contactDocs.filter(contactDoc => {
    return !filteredApplicationDocs.includes(contactDoc.id);
  })?.map((x) => ({ ...x, key: x?.id }))

  const rowSelection: TableRowSelection<Media> = {
    selectedRowKeys: selectedDocsRef,
    onChange: (selectedKeys: React.Key[], selectedRows: Media[]) => {
      setSelectedDocsRef(selectedKeys);
      setSelectedDocs(selectedRows);
    },
  };
  const handleRemove = async (file: Media) => {
    const filterDocument = selectedDocs.filter((item) => item?.id !== file?.id);
    setSelectedDocsRef(filterDocument.map(doc => doc.id));
    setSelectedDocs(filterDocument)
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={filterDocs}
        loading={isLoading}
        pagination={false}
        rowSelection={rowSelection}
      />
      {selectedDocsRef.length !== 0 ? (
        <Typography.Title level={5} className="margin-top-3">
          {TEXT.SELECTED_DOCUMENTS}
        </Typography.Title>
      ) : null}
      <div className="margin-top-1">
        <Spin
          spinning={submitLoading}
          tip={TEXT.UPLOAD_DOCUMENTS}
          className="margin-top-3"
        >
          {selectedDocsRef?.length !== 0 ? (
            <List
              bordered={true}
              dataSource={selectedDocs}
              renderItem={(item: Media) => {
                return (
                  <DocumentFileName
                    item={item}
                    documents={selectedDocs}
                    onSetDocument={setSelectedDocs}
                    onRemove={handleRemove}
                  />
                );
              }}
            />
          ) : null}
        </Spin>
      </div>
    </>
  );
};

const getExistingDocsCols = () => {
  const columns: ColumnsType<any> = [
    {
      title: TEXT.SN,
      key: 'id',
      render: (d: any, r: any, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: TEXT.FILE_NAME,
      dataIndex: 'name',
    },
    {
      title: PLACEHOLDER.DOCUMENT_TYPE,
      dataIndex: 'mediaType',
      render: (text: string) => {
        return <span>{capitalizeFirstLetter(text)}</span>;
      },
    },
    {
      title: TEXT.FILE_SIZE,
      dataIndex: 'size',
      render: (text: any) => {
        return <span>{`${Math.round(text / 1024)} KB`}</span>;
      },
    },
  ];
  return columns;
};

export default ContactExistingDocumentSelector;
