import React from 'react';
import { Avatar, Tooltip } from 'antd';

interface Props {
  userList: { firstName: string, lastName: string }[];
}

const UserAvatarGroup: React.FC<Props> = ({
  userList,
}) => (
  <Avatar.Group
    size="default"
    maxCount={3}
    maxStyle={{ backgroundColor: '#4a2362' }}
    className="crm-avatar-group"
  >
    {userList.map((user, idx: number) => {
      return (
        <Tooltip key={idx} title={`${user.firstName} ${user.lastName}`}>
          <Avatar>
            {user.firstName?.substring(0, 1).toUpperCase()}
            {user.lastName?.substring(0, 1).toUpperCase()}
          </Avatar>
        </Tooltip>
      );
    })}
  </Avatar.Group>
);

export { UserAvatarGroup };
