import React from 'react';
import { Tag, Tooltip } from 'antd';

const WorkflowTagGroup = ({
  data: institution_workflows,
  numberOfWorkflow,
}: any) => {
  const tooltipData = (
    <div>
      {institution_workflows &&
        institution_workflows
          ?.slice(numberOfWorkflow, institution_workflows?.length)
          ?.map((workflow: any, index: number) => {
            return (
              <Tag
                className="margin-bottom-1 initial_capital"
                key={workflow?.id ?? index}
                color="default"
              >
                {workflow || ''}
              </Tag>
            );
          })}
    </div>
  );
  return (
    <>
      {institution_workflows &&
        institution_workflows
          ?.slice(0, numberOfWorkflow)
          .map((workflow: any, index: number) => {
            return (
              <Tag
                key={workflow?.id ?? index}
                color="default"
                className="initial_capital"
              >
                {workflow || ''}
              </Tag>
            );
          })}

      {institution_workflows &&
        institution_workflows?.length > numberOfWorkflow && (
          <Tooltip placement="bottomRight" color="white" title={tooltipData}>
            <Tag color="default">
              +{institution_workflows?.length - numberOfWorkflow}
            </Tag>
          </Tooltip>
        )}
    </>
  );
};
WorkflowTagGroup.defaultProps = {
  numberOfWorkflow: 3,
};

export { WorkflowTagGroup };
