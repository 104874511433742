import React from 'react';
import Badge from 'antd/es/badge';
import { ProductFeeItemCrm as ProductFeeItemModel} from '../../product.model';

import { LABEL, TEXT } from '@moxie/constants';

interface Props {
  feeList: ProductFeeItemModel;
  currency?: string;
}

const ProductFeeItem: React.FC<Props> = ({ feeList, currency }) => {

  return (
    <div className="fee_lists__item" key={feeList?.id}>
      <div className="fee_lists__info">
        <Badge dot />
        <span className="margin-left-2 fee_lists__info_name">
          {feeList?.feeType?.replace('_', ' ')}
        </span>
      </div>
      <div className=" fee_lists__installment">
        <span className=" fee_lists__installment_label">
          {LABEL.INSTALLMENT_TYPE}
        </span>
        <span className="margin-left-1 fee_lists__installment_amount">
          {feeList?.installmentType?.replace('_', ' ')}
        </span>
      </div>

      <div className=" fee_lists__installment">
        <span className=" fee_lists__installment_label">
          {TEXT.INSTALLMENTS}
        </span>
        <span className="margin-left-1 fee_lists__installment_amount">
          {feeList?.installments}
        </span>
      </div>
      <div className=" fee_lists__installment">
        <span className=" fee_lists__installment_label">
          {TEXT.INSTALLMENT_AMOUNT}
        </span>
        <span className="margin-left-1 fee_lists__installment_amount">
          {currency} {feeList?.installmentAmount}
        </span>
      </div>
      <div className="fee_lists__price">
        <b>
          {currency} {feeList?.totalFee}
        </b>
      </div>
    </div>
  )
}
export default ProductFeeItem
