import Fuse from 'fuse.js';

export const documentCategories = [
  {
    category: "Personal",
    labels: [
      "Application Form",
      "Birth Certificate",
      "Citizenship/National ID",
      "Employment Evidence",
      "Marriage Certificate",
      "National Police Check",
      "Passport",
      "Photo",
      "Photo ID",
      "Relation Certificate",
      "Resume",
      "Statement of Purpose",
      "Visa"
    ]
  },
  {
    category: "Other Documents",
    labels: [
      "Existing Skill Assessment Report",
      "JRE Form",
      "NAATI Certificate",
      "Professional Year Certificate",
      "Qualification (for TRA)",
      "Supporting Documents"
    ]
  },
  {
    category: "Official Responses",
    labels: [
      "COE",
      "Conditional Offer Letter",
      "Full Offer Letter",
      "Offer Acceptance",
      "PSA Outcome Letter",
      "RPL Qualification",
      "Skill Assessment Outcome",
      "Skill Progress Report",
      "Visa Outcome Letter"
    ]
  },
  {
    category: "Language and Academics",
    labels: [
      "Academic Completion Certificate",
      "Academic Transcript",
      "Character Certificate",
      "English Language Test Result",
      "Existing COE",
      "Migration Certificate",
      "Other Academic Documents",
      "Partner's English Proficiency Result"
    ]
  },
  {
    category: "Insurance",
    labels: [
      "Insurance Certificate"
    ]
  },
  {
    category: "Financial",
    labels: [
      "Bank Balance Certificate",
      "GTE Form",
      "Income Verification",
      "Loan Disbursement Letter",
      "Other Financials",
      "Payment Receipt",
      "Property Valuation",
      "Salary Statement",
      "Sponsorship Letter",
      "Sponsor's ID and Relationship Evidence"
    ]
  }
];


const extractLabelFromFileName = (fileName: string) => {
  const specialCharacterRegex = /[^a-zA-Z0-9]+/;
  const labelWithoutExtension = fileName.split('.').slice(0, -1).join('.');
  const parts = labelWithoutExtension.split(specialCharacterRegex);
  const matchedParts = parts
  .map(part => {
    const searchResult = fuse.search(part.toLowerCase());
    if (searchResult.length > 0) { 
      return part.toLowerCase();
    }
    return null;
  })
  .filter(Boolean);
  
  if (matchedParts.length > 0) {
    return matchedParts.join(' ').trim();
  }
  return ''; 
};

const labelCategoryMap = documentCategories.flatMap(({ category, labels }) =>
  labels.map(label => ({ label: label, category }))
);

export const fuse = new Fuse(labelCategoryMap, {
  keys: ['label'],
  threshold: 0.3,
  distance: 60,
  minMatchCharLength: 3,
  ignoreLocation: true,
  // Note: need to adjust these config later for better results
  // findAllMatches: true,
  // useExtendedSearch: true,
  // shouldSort: true,
  // ignoreFieldNorm : true,
  // location: 6,
});

export const searchFileName = (fileName: string) => {
  const documentLabel = extractLabelFromFileName(fileName);
  const result = fuse.search(documentLabel);
  return result;
};
