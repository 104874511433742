import React from 'react';
// @ts-ignore
import { ReactQueryDevtools } from '@tanstack/react-query-devtools/build/lib/index.prod.js';

const QueryDevTool: React.FC = () => {
  const [showDevtools, setShowDevtools] = React.useState<boolean>(false)

  React.useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  if (!showDevtools) {
    return null
  }
  return (
    <ReactQueryDevtools initialIsOpen={false} position={'bottom-left'} />
  )
}

export default QueryDevTool;
