import React, { useEffect, useState } from 'react';
import { contactActions, useAppSelector } from '@crm/core';
import { Button, Collapse, Dropdown, Menu, Spin, Tooltip } from 'antd';
import { ADD_BTN, LABEL, TITLE } from '@moxie/constants';
// import Can, { PermissionAction } from '@crm/privilege';
import { PlusOutlined } from '@ant-design/icons';
import {
  EducationBackgroundPanelContent,
  EnglishTestScorePanelContent,
  ProfessionalExperiencePanelContent,
} from './qualifications-tab-components';
import { useDispatch } from 'react-redux';
import { RootState } from 'apps/crm/src/core/store';
import { IELTSDrawer } from './qualifications-tab-components/drawer/ielts';
import { TOEFLDrawer } from './qualifications-tab-components/drawer/toefl';
import { PTEDrawer } from './qualifications-tab-components/drawer/pte';
import { useFetchEnglishTestScore } from '@crm/libs/hooks/contacts';
import { useParams } from 'react-router-dom';

const { Panel } = Collapse;

type Tab = 'ielts' | 'pte' | 'toefl';

const QualificationsContent = () => {
  const [panelKey, setPanelKey] = useState(TITLE.EDUCATION_BACKGROUND);
  const [showExtraIcon, setShowExtraIcon] = useState<boolean>(true);
  const [showEduBgDrawer, setShowEduBgDrawer] = useState<boolean>(false);
  const [showProfExpDrawer, setShowProfExpDrawer] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<Tab | undefined>();

  const dispatch = useDispatch();
  // const loggedUser = useAppSelector((state: RootState) => state?.auth?.user);
  const contactData = useAppSelector(
    (state: RootState) => state?.contact?.singleData
  );

  const { id, contactId } = useParams<{ id?: string, contactId?: string }>();

  const { englishTestScore, isLoading } = useFetchEnglishTestScore((id ?? contactId as string));
  const testData = englishTestScore?.map((data: any) => data.tests).flat();

  const handlePanelChange = (panelSelected: any) => {
    if (panelSelected !== undefined) {
      if (panelSelected === TITLE.ENGLISH_TEST_SCORES) {
        // if (!englishTestScore || englishTestScore?.tests.length < 3) {
        if (!englishTestScore || testData?.length < 3) {
          setPanelKey(() => {
            setShowExtraIcon(true);
            return panelSelected;
          });
        } else {
          setShowExtraIcon(false);
        }
      } else {
        setShowExtraIcon(true);
        setPanelKey(() => {
          return panelSelected;
        });
      }
    } else {
      setPanelKey(panelSelected);
      setShowExtraIcon(false);
    }
  };

  useEffect(() => {
    dispatch(
      contactActions.getContactEducationBackgroundRequest(contactData?.id)
    );
    dispatch(
      contactActions.getContactProfessionalExperienceRequest(contactData?.id)
    );
    return () => {
      contactActions.clearContactEducationBackgroundFetch();
      contactActions.clearContactProfessionalExperienceFetch();
    };
  }, []);

  const genExtra = (panelKey: string) => {
    if (
      // Can(loggedUser, PermissionAction.Update) &&
      contactData?.archived === false
    ) {
      return (
        <Tooltip title={ADD_BTN}>
          <div
            className="extra-icon-container"
            onClick={(event: any) => {
              event.stopPropagation();
              switch (panelKey) {
                case TITLE.EDUCATION_BACKGROUND:
                  setShowEduBgDrawer(true);
                  break;
                case TITLE.PROFESSIONAL_EXPERIENCE:
                  setShowProfExpDrawer(true);
                  break;
                default:
                  setShowEduBgDrawer(false);
                  setShowProfExpDrawer(false);
              }
            }}
          >
            <PlusOutlined
              className="text-primary"
              data-testid="crm-contactdetails-addicon"
            />
          </div>
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <div className="profile-content-margin">
      <Collapse
        defaultActiveKey={panelKey}
        accordion={true}
        onChange={handlePanelChange}
      >
        <Panel
          key={TITLE.EDUCATION_BACKGROUND}
          header={
            <span className="text-primary profile-panel-header">
              {TITLE.EDUCATION_BACKGROUND}
            </span>
          }
          extra={
            showExtraIcon && TITLE.EDUCATION_BACKGROUND === panelKey
              ? genExtra(TITLE.EDUCATION_BACKGROUND)
              : false
          }
        >
          <EducationBackgroundPanelContent
            showDrawer={showEduBgDrawer}
            setShowDrawer={setShowEduBgDrawer}
          />
        </Panel>
        <Panel
          key={TITLE.PROFESSIONAL_EXPERIENCE}
          header={
            <span className="text-primary profile-panel-header">
              {TITLE.PROFESSIONAL_EXPERIENCE}
            </span>
          }
          extra={
            showExtraIcon && TITLE.PROFESSIONAL_EXPERIENCE === panelKey
              ? genExtra(TITLE.PROFESSIONAL_EXPERIENCE)
              : false
          }
        >
          <ProfessionalExperiencePanelContent
            showDrawer={showProfExpDrawer}
            setShowDrawer={setShowProfExpDrawer}
          />
        </Panel>
        <Panel
          key={TITLE.ENGLISH_TEST_SCORES}
          header={
            <span className="text-primary profile-panel-header">
              {TITLE.ENGLISH_TEST_SCORES}
            </span>
          }
          extra={
            showExtraIcon && TITLE.ENGLISH_TEST_SCORES === panelKey ? (
              <Dropdown
                placement="bottomCenter"
                disabled={testData?.length >= 3 && true}
                overlayClassName="test-score-dropdown"
                overlay={
                  <Spin spinning={isLoading}>
                    <Menu className="margin-0" selectable={false}>
                      {!testData?.some(
                        (item: any) => item.name?.toUpperCase() === LABEL.IELTS
                      ) && (
                          <Menu.Item key="1" className="margin-0 padding-0">
                            <Button
                              className="test-score-menu-item"
                              type="link"
                              onClick={() => setCurrentTab('ielts')}
                              data-testid="ieltsitem"
                            >
                              {LABEL.IELTS}
                            </Button>
                          </Menu.Item>
                        )}
                      {!testData?.some(
                        (item: any) => item.name?.toUpperCase() === LABEL.TOEFL
                      ) && (
                          <Menu.Item key="2" className="margin-0 padding-0">
                            <Button
                              type="link"
                              onClick={() => setCurrentTab('toefl')}
                              className="test-score-menu-item"
                              data-testid="toeflitem"
                            >
                              {LABEL.TOEFL}
                            </Button>
                          </Menu.Item>
                        )}

                      {!testData?.some(
                        (item: any) => item.name?.toUpperCase() === LABEL.PTE
                      ) && (
                          <Menu.Item key="3" className="margin-0 padding-0">
                            <Button
                              className="test-score-menu-item"
                              type="link"
                              onClick={() => setCurrentTab('pte')}
                              data-testid="pteitem"
                            >
                              {LABEL.PTE}
                            </Button>
                          </Menu.Item>
                        )}
                    </Menu>
                  </Spin>
                }
              >
                {showExtraIcon ? (
                  <Tooltip title={ADD_BTN}>
                    <div className="extra-icon-container">
                      <PlusOutlined data-testid="crm-contactdetails-addicontestscore" />
                    </div>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </Dropdown>
            ) : (
              false
            )
          }
        >
          <EnglishTestScorePanelContent
            data={englishTestScore}
            loading={isLoading}
            panelKey={panelKey}
            setShowExtraIcon={setShowExtraIcon}
          />
          <IELTSDrawer
            showDrawer={currentTab === 'ielts'}
            setShowDrawer={setCurrentTab}
            editMode={editMode}
            setEditMode={setEditMode}
          />
          <TOEFLDrawer
            showDrawer={currentTab === 'toefl'}
            setShowDrawer={setCurrentTab}
            editMode={editMode}
            setEditMode={setEditMode}
          />
          <PTEDrawer
            showDrawer={currentTab === 'pte'}
            setShowDrawer={setCurrentTab}
            editMode={editMode}
            setEditMode={setEditMode}
          />
        </Panel>
      </Collapse>
    </div>
  );
};
export { QualificationsContent };
