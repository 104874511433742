import React from 'react';
import {
  FileExcelOutlined,
  FileImageOutlined,
  FileTextOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FilePptOutlined,
  FileUnknownOutlined,
} from '@ant-design/icons';

const DocumentsFileIcon = ({ mimetype }: { mimetype: string | undefined }) => {
  return (
    (mimetype?.match(/(image)/) && (
      <FileImageOutlined className="documents-file-icon" />
    )) ||
    (mimetype?.match(/(pdf)/) && (
      <FilePdfOutlined className="documents-file-icon" />
    )) ||
    (mimetype?.match(/(ms-excel|spreadsheet)/) && (
      <FileExcelOutlined className="documents-file-icon" />
    )) ||
    (mimetype?.match(/(text|json)/) && (
      <FileTextOutlined className="documents-file-icon" />
    )) ||
    (mimetype?.match(/(msword|wordprocessing)/) && (
      <FileWordOutlined className="documents-file-icon" />
    )) ||
    (mimetype?.match(/(ms-powerpoint|presentation)/) && (
      <FilePptOutlined className="documents-file-icon" />
    )) || <FileUnknownOutlined className="documents-file-icon" />
  );
};

export { DocumentsFileIcon };
