import { queryAssignees } from '@crm/services.api';
import Select from 'antd/es/select';
import { SearchBox } from 'libs/shared/src/crm-modules/shared';
import React from 'react';
import { UserNameCard } from '@moxie/shared';
import { useFetchApplicationAssignee } from '@crm/libs/hooks/contacts';

interface Props {
  filter: Record<string, unknown>
  value: string[];
  className?: string;
  contactId: string;
}
const FollowerSearchBox: React.FC<Props> = ({ contactId, ...props }) => {
  const { applicationAssignees } = useFetchApplicationAssignee(contactId);
  const ids = applicationAssignees.map(d => d.id);
  return (
    <SearchBox
      fetchQuery={queryAssignees}
      valueKey="id"
      hideDefaultOptionView
      mode="multiple"
      optionLabelProp="label"
      defaultOpen
      maxTagCount={1}
      {...props}
    >
      {
        (option) =>
          <Select.Option
            value={option.id}
            label={`${option.firstName} ${option.lastName}`}
            disabled={!!ids.includes(option.id) && props.value.includes(option.id)}
            title={ids.includes(option.id) && props.value.includes(option.id) ? 'Users who an assigned in deals/application cannot be removed' : ''}
          >
            <UserNameCard
              firstName={option.firstName}
              lastName={option.lastName}
              email={option.email}
              index={option.id}
            />
          </Select.Option>
      }
    </SearchBox>
  );
}

export default FollowerSearchBox
